import { useEffect } from 'react';
import useApi from 'hooks/useApi';
import { ContentContainer, ModalText, StyledModal } from './Modal.styled';

const NotificationModal = () => {
  const { notificationAvailable, notificationMessage, hideNotifcation } = useApi();

  useEffect(() => {
    if (notificationAvailable) {
      setTimeout(() => {
        hideNotifcation();
      }, 5000);
    }
  }, [notificationAvailable]);

  return (
    <StyledModal open={notificationAvailable}>
      <ContentContainer>
        <ModalText>{notificationMessage}</ModalText>
      </ContentContainer>
    </StyledModal>
  );
};

export default NotificationModal;