import styled from 'styled-components';

export const DashboardStyled = styled.div`
  background-color: white;
  box-shadow: 0px 0px 16px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  z-index: 2;
`;

export const MoreInformationStyled = styled.div`
  border-color: ${props => props.theme.colors.grey200};
  border-radius: 4px;
  border-style: solid;
  border-width: 0px 1px 1px 1px;
  display: flex;
  flex: 1;
  gap: 16px;
  padding: 16px 24px;
`;

export const PlayerErrorStyled = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const PlayerLoadingStyled = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
`;

export const PlayersStyled = styled.div`
  display: flex;
  flex: 1 0 0;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 24px;
  position: relative;

  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    padding-right: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.grey300};
    border-radius: 2px;
  }
`;
