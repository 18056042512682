import Badge from 'components/Badge';
import { DrawerTitleContent } from 'components/Tile/Tile.styled';
import { Color } from 'types/Color';

interface PlayerDrawerTitleProps {
  terminalName: string;
  groupName: string;
  initialActionType: string;
}

const PlayerDrawerTitle = ({ initialActionType, groupName, terminalName }: PlayerDrawerTitleProps) => {
  const getBadgeColors = () => {
    switch (groupName.toUpperCase()) {
      case 'FOBT':
        return {
          color: 'fobt25',
          textColor: 'grey700', 
        };
      case 'SMART Retail':
        return {
          color: 'grey50',
          textColor: 'grey700', 
        };
      case 'SSBT':
      default:
        return {
          color: 'ssbt',
          textColor: 'secondarySsbt700', 
        };
    }
  };

  return (
    <DrawerTitleContent>
      {initialActionType === 'adding' ? 'Add Player' : terminalName }
      {initialActionType != 'adding' ? <Badge text={groupName.toUpperCase()} color={getBadgeColors().color as Color} textColor={getBadgeColors().textColor as Color} /> : null}
    </DrawerTitleContent>
  );
};

export default PlayerDrawerTitle;
