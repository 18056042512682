import { useState, useMemo } from 'react';
import moment from 'moment';
import { DatePicker, Table, Checkbox, Button } from 'antd';
import type { DatePickerProps } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { getPubSubIot } from 'aws/Aws';
import useEndOfDayReports, { EndOfDayReport, RemotelyLoadedCredit, VouchersIn } from 'hooks/useEndOfDayReports';
import Flex from 'styled/Flex';
import { DateHeader } from './EndOfDayReports.styled';
import { ColumnsType } from 'antd/lib/table';
import Badge from 'components/Badge';
import { sleep } from 'utils/sleep';
import useMachines from 'hooks/useMachines';
import { retailCurrency } from 'retail-currency';

const EndOfDayReports = () => {
  const TABLE_COLUMNS: ColumnsType<EndOfDayReport> = [
    {
      title: 'Terminal',
      dataIndex: 'machine',
      render(text: string) {
        const [,,machineNumber] = text.split('-');
        return <Flex>
          <Badge
            borderRadius='4'
            inlineBlock={true}
            text={`FOBT ${machineNumber}`}
            color={'secondaryFobt25'}
            textColor={'secondaryFobt700'}
          />
        </Flex>;
      },
      sorter: (recordA, recordB) => {
        const [,,machineNumberA] = recordA.machine.split('-');
        const [,,machineNumberB] = recordB.machine.split('-');
  
        return machineNumberA < machineNumberB ? -1 : 1;
      },
    },
    {
      title: 'Coins in',
      render: (_: string, record: EndOfDayReport) => {
        const printerReport = !!record.printed_at;
        const coins = printerReport ? Object.entries(record.cashbox).filter(entry => parseFloat(entry[0]) < 5) : Object.entries(record.cashbox).filter(entry => parseFloat(entry[0]) < 500) ;
        const coinsIn = printerReport ? coins.reduce((acc, item) => acc + (item[1]), 0) : coins.reduce((acc, item) => acc + (item[1] / 100), 0);
  
        return retailCurrency.format(coinsIn);
      },
    },
    {
      title: 'Notes in',
      render: (_: string, record: EndOfDayReport) => {
        const printerReport = !!record.printed_at;
        const notes = printerReport ? Object.entries(record.cashbox).filter(entry => parseFloat(entry[0]) >= 5) : Object.entries(record.cashbox).filter(entry => parseFloat(entry[0]) >= 500);
        const notesIn = printerReport ? notes.reduce((acc, item) => acc + (item[1]), 0) : notes.reduce((acc, item) => acc + (item[1] / 100), 0);

        return retailCurrency.format(notesIn);
      },
    },
    {
      title: 'Cash in',
      render: (_: string, record: EndOfDayReport) => {
        const printerReport = !!record.printed_at;
        const cashIn = printerReport ? Object.entries(record.cashbox).reduce((acc, item) => acc + (item[1]), 0) : record.total_cashbox / 100;
  
        return retailCurrency.format(cashIn);
      },
    },
    {
      title: 'Remote Loaded Credit',
      dataIndex: 'remotely_loaded_credit',
      render: (rlc: RemotelyLoadedCredit, record: EndOfDayReport) => {
        const printerReport = !!record.printed_at;
        const remotelyLoadedCredit = printerReport ? Number(rlc?.amount) : record.cashin_bo.amount / 100;

        return retailCurrency.format(remotelyLoadedCredit);
      },
    },
    {
      title: 'Vouchers In',
      dataIndex: 'vouchers_in',
      render: (vi: VouchersIn, record: EndOfDayReport) => {
        const printerReport = !!record.printed_at;
        const vouchersCredit = printerReport ? Number(vi?.amount) : record.cashin_voucher.amount / 100;

        return retailCurrency.format(vouchersCredit);
      },
    },
    {
      title: 'Total in',
      dataIndex: 'total_in',
      render: (text: string, record: EndOfDayReport) => {
        const printerReport = !!record.printed_at;
        const totalIn = printerReport ? Number(text) : (record.total_cashbox + record.cashin_bo?.amount + record.cashin_voucher?.amount) / 100;

        return retailCurrency.format(totalIn);
      },
    },
    {
      title: 'Paid receipts',
      dataIndex: 'paid_receipts',
      render: (text: string, record: EndOfDayReport) => {
        const printerReport = !!record.printed_at;
        const paidReceipts = printerReport ? Number(text) : record.paid_receipts / 100;
        
        return retailCurrency.format(paidReceipts);
      },
    },
    {
      title: 'Cash win',
      dataIndex: 'cash_win_lose',
      render: (text: string, record: EndOfDayReport) => {
        const printerReport = !!record.printed_at;
        const totalIn = (record.total_cashbox + record.cashin_bo?.amount + record.cashin_voucher?.amount) / 100;
        const paidRecepits = record.paid_receipts / 100;
        const cashWin = printerReport ? Number(text) : totalIn - paidRecepits ;

        return retailCurrency.format(cashWin);
      },
    },
    {
      title: 'Stakes',
      dataIndex: 'stakes',
      render: (text: string, record: EndOfDayReport) => {
        const printerReport = !!record.printed_at;
        const stakes = printerReport ? Number(text) : record.total_staked / 100;

        return retailCurrency.format(stakes);
      },
    },
    {
      title: 'Returns',
      dataIndex: 'returns',
      render: (text: string, record: EndOfDayReport) => {
        const printerReport = !!record.printed_at;
        const returns = printerReport ? Number(text) : record.total_won / 100;

        return retailCurrency.format(Number(returns));
      },
    },
    {
      title: 'Rewards Credit',
      dataIndex: 'rewards_credit',
      render: (_text: string, record: EndOfDayReport) => {
        return retailCurrency.format(Number(record.rewards_credit) / 100);
      },
    },
    {
      title: () => {
        const machinesToCheck = machines.machines.map(machine => machine.machineId);
        const setSelectAll = () => checkedMachines.length === 0 ? setCheckedMachines(machinesToCheck) : setCheckedMachines([]);

        return <Checkbox checked={machinesToCheck.length === checkedMachines.length} onChange={setSelectAll}>Check All</Checkbox>;
      },
      dataIndex: '',
      render: (_: string, record) => {
        const onChange = (e: CheckboxChangeEvent) => setCheckedMachines(e.target.checked ? [...checkedMachines, record.machine] : checkedMachines.filter(machine => machine !== record.machine));

        return record.printed_at ? null : <Checkbox checked={checkedMachines.includes(record.machine)} onChange={onChange}></Checkbox>;
      },
    },
  ];
  const [checkedMachines, setCheckedMachines] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState(() => moment());
  const { isLoading, reports, error, fetchReports } = useEndOfDayReports(selectedDate.toDate());
  const machines = useMachines();

  const machinesCurrentData = useMemo(() => {
    const today = new Date().toDateString();
    if (selectedDate.toDate().toDateString() === today) {
      // @ts-ignore temporary, EOD report and machine state cashbox have plenty of differences that need to be unified on BE side
      const currentStateCashboxes: EndOfDayReport[] = machines.machines.map((m) => {
        const funds = m.machineState.machine?.funds;
        if (funds) {
          return {
            ...funds,
            machine: m.machineId,
          };
        }
      }).filter(m => m);

      return [...currentStateCashboxes, ...reports];
    }
    return [...reports];
  }, [reports, machines.machines, selectedDate]);
  
  const handleChangeDate: DatePickerProps['onChange'] = (date) => {
    if (date) {
      setSelectedDate(date);
    }
  };

  const handlePrintEODReport = () => {
    checkedMachines.forEach((report) => {
      console.log(`printing print eod report for ${report} ... `);
      getPubSubIot().publish(`${report}/state/eod/print/request`, {});
      setCheckedMachines([]);
      sleep(2000).then(() => {
        fetchReports();
      });
    });
  };

  if (error) {
    return <Flex>{error}</Flex>;
  }
  
  return (
    <Flex direction='column' gap='16px'>
      <DateHeader>{selectedDate.format('dddd, D MMMM YYYY')}</DateHeader>
      <DatePicker disabledDate={date => date.isAfter(moment())} onChange={handleChangeDate} value={selectedDate} />
      <Table pagination={false} rowClassName={(record) => record.printed_at ? 'printed' : ''} columns={TABLE_COLUMNS} dataSource={machinesCurrentData} loading={isLoading} />
      <Button onClick={handlePrintEODReport} type='primary' disabled={checkedMachines.length === 0} style={{ alignSelf: 'end' }} >Print EOD Report</Button>
    </Flex>
  );
};

export default EndOfDayReports;
