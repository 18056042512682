import { useEffect, useReducer } from 'react';
import { format } from 'date-fns';
import { s3 } from 'aws/Aws';
import useShop from './useShop';
import { getConstant } from 'utils/constants';

export interface Cashbox {
  5: number;
  10: number;
  20: number;
  50: number;
  100: number;
  200: number;
  500: number;
  1000: number;
  2000: number;
  5000: number;
}

interface RecycledTickets {
  amount: number;
  count: number;
}

export interface RemotelyLoadedCredit {
  amount: number;
  count: number;
}

export interface VouchersIn {
  amount: number;
  count: number;
}

export interface CashinBO {
  amount: number;
  count: number;
}

export interface EndOfDayReport {
  cash_win_lose: number;
  cashbox: Cashbox;
  machine: string;
  outlet: string;
  paid_receipts: number;
  previous_printed: string;
  printed_at: string;
  recycled_tickets: RecycledTickets;
  remotely_loaded_credit: RemotelyLoadedCredit;
  vouchers_in: VouchersIn,
  returns: number;
  rewards_credit: number;
  stakes: number;
  total_cashbox: number;
  total_in: number;
  total_staked: number;
  cashin_bo: CashinBO;
  cashin_voucher: VouchersIn;
  total_won: number;
}

const getEodReportsForDate = async (date: Date, shopId: string) => {
  const bucket = getConstant('REACT_APP_EOD_REPORTS_BUCKET', undefined);
  if (!bucket) {
    throw new Error('EoD reports fetch error: bucket not specified.');
  }

  const prefix = `eod/${shopId}`;
  console.log('EndOfDayReports', 'fetching EoD repors from S3, criteria:', {
    Bucket: bucket,
    Prefix: prefix,
  });

  const { Contents: eodObjectsMatchingPrefix } = await s3.listObjects({
    Bucket: bucket,
    Prefix: prefix,
  }).promise();

  const objectsMatchingFilter = eodObjectsMatchingPrefix?.filter(obj => {
    if (!obj.Key) {
      return false;
    }

    const [, shopNumber,, reportDate] = obj.Key.split('/');
    const dateFormatted = format(date, 'y-MM-dd');
    
    return shopNumber === shopId && reportDate === dateFormatted;
  });

  if (!objectsMatchingFilter) {
    throw new Error('An error occurred while fetching Reports. Please try again later.');
  }

  const reportKeys = objectsMatchingFilter.map(object => object.Key).filter(key => key !== undefined);

  const reports: EndOfDayReport[] = [];
  for (const key of reportKeys) {
    // @ts-ignore-next-line
    const { Body: body } = await s3.getObject({
      Bucket: bucket,
      Key: key, 
    }).promise();

    if (body) {
      reports.push(JSON.parse(body.toString()));
    }
  }

  return reports.map(r => ({
    ...r,
    rewards_credit: r.rewards_credit * 100, // this is stored in pounds instead of pences 
  }));
};

type Reports = Array<EndOfDayReport>;

type State = {
  reports: Reports;
  isLoading: boolean;
  error: string | undefined;
}

type Action =
 | { type: 'request' }
 | { type: 'success', reports: Reports }
 | { type: 'failure', error: string };

const requestAction = (): Action => {
  return { type: 'request' };
};

const successAction = (reports: Reports): Action => {
  return {
    type: 'success',
    reports, 
  };
};

const failureAction = (error: string): Action => {
  return {
    type: 'failure',
    error, 
  };
};

const reducer = (state: State, action: Action): State => {
  if (action.type === 'request') {
    return {
      ...state,
      isLoading: true,
      error: undefined,
      reports: [],
    };
  }

  if (action.type === 'success') {
    return {
      ...state,
      isLoading: false,
      error: undefined,
      reports: action.reports,
    };
  }

  if (action.type === 'failure') {
    return {
      ...state,
      isLoading: false,
      error: action.error,
      reports: [],
    };
  }

  return state;
};

const useEndOfDayReports = (date: Date) => {
  const [state, dispatch] = useReducer(reducer, {
    isLoading: true,
    reports: [],
    error: undefined, 
  });
  const { shopId } = useShop();

  const fetchReports = () => {
    dispatch(requestAction());
    getEodReportsForDate(date, shopId)
      .then(reports => dispatch(successAction(reports)))
      .catch(error => dispatch(failureAction(error.message)));
  };

  useEffect(() => {
    fetchReports();
  }, [date.toDateString(), shopId]);

  return {
    ...state,
    fetchReports,
  };
};

export default useEndOfDayReports;
