import styled from 'styled-components';
import { AngleDown, Search } from 'components/Icon';

export const AngleDownIcon = styled(AngleDown)`
  path {
    stroke: ${(props) => props.theme.colors.grey500};
  }
`;

export const CurrentDateHeading = styled.div`
  font-size: 24px;
`;

export const HistoryStyled = styled.div`
  display: flex;
  background-color: white;
  border-radius: 10px;
  flex-direction: column;
  height: 110%;
  & > div {
    margin: 10px 10px 0 10px;
  };
`;

export const HistoryWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 90vh;
  width: 100%;
  padding: 24px;
`;//setting fixed height and width above as a hack to stop strange scrolling/resizing bugs. There's probably a better way to do this

export const SearchHeader = styled.div`
  font-size: 20px;
  line-height: 30px;
  padding-top: 10px;
`;

export const SearchIcon = styled(Search)`
  path {
    fill: ${(props) => props.theme.colors.grey500};
    stroke: ${(props) => props.theme.colors.grey500};
  }
`;

export const TerminalPickerLabel = styled.div`
  white-space: nowrap;
`;
