import { Message, Messages } from 'components/Message';
import useCashinResponse from 'hooks/useCashinResponse';
import { useEffect } from 'react';
import { AlertTypeEnum, BackofficeAlert, BackofficeAlertWithDate } from 'types/alert/GeneralAlert';
import { MachineMap, MachineMode } from 'types/machine/MachineType';
import { retailCurrency } from 'retail-currency';

interface DeviceAlertsProps {
  machine: MachineMap;
  cashinResponses: (ReturnType<typeof useCashinResponse>)['cashinResponses'];
  removeCashinResponse: (ReturnType<typeof useCashinResponse>)['removeCashinResponse'];
  alerts: BackofficeAlertWithDate[];
  dismissAlertById: (id: BackofficeAlert['alert_id']) => void;
}

const mapSeverityToMessageType = (severity: string) => {
  switch (severity) {
    case 'error':
      return 'error';
    case 'alert':
      return 'warning';
    case 'warning':
      return 'warning';
    case 'medium':
      return 'warning';
    default:
      return 'success';
  }
};

const mapSourceToIconName = (source: string | undefined) => {
  if (source === 'printer') {
    return 'printer';
  }

  return 'warning';
};

const DeviceAlerts = ({ machine, cashinResponses, removeCashinResponse, alerts, dismissAlertById }: DeviceAlertsProps) => {  
  
  useEffect(() => {
    if (alerts.length > 0) {
      // This needs to be changed in the future if the backend will organize alerts in a better way
      removeExpiredDoorMessages();
      removeOutOfPaperMessage();
    }
  }, [alerts]);
  
  const removeMessage = (alert: BackofficeAlertWithDate) => {
    if (alert.hardware_alert === true || alert.alert_type === AlertTypeEnum.ERROR) {
      return false;
    } 
    dismissAlertById(alert.alert_id);
  };

  const removeOutOfPaperMessage = () => {
    if (alerts.some((alert) => alert.message.includes('plenty of paper'))) {
      alerts.forEach((item) => {
        if (item.message.includes('out of paper')) {
          dismissAlertById(item.alert_id);
        }
      });
      alerts.forEach((item) => {
        if (item.message.includes('plenty of paper')) {
          dismissAlertById(item.alert_id);
        }
      });
    }
  };

  const removeExpiredDoorMessages = () => {
    if (alerts.some((alert) => (alert.message.includes('door') && (alert.message.endsWith('opened') || alert.message.endsWith('closed'))))) {
      const match = /door (.*?) closed/g;
      const doors: string[] = [];
      alerts.forEach((item) => {
        const door = Array.from(item.message.matchAll(match), (x: string[]) => x[1]);
        if (door.length > 0) {
          const [doorName] = door;
          doors.push(doorName);
        }
      });

      const doorMessages: string[] = [];
      doors.forEach(door => doorMessages.push(`door ${door} closed`, `door ${door} opened`));

      alerts.forEach((item) => {
        if (doorMessages.some(str => item.message.includes(str))) {
          dismissAlertById(item.alert_id);
        }
      });
    }
  };

  const checkPeripheralReadiness = () => {
    if (machine?.machineState?.machine) {
      const { peripheral_readiness } = machine.machineState.machine;
      alerts.map((alert) => {
        if (alert.hardware_alert && alert.source && peripheral_readiness[alert.source] === true ) {
          dismissAlertById(alert.alert_id);
        }
      });
    }
  };

  const checkMachineModeStatus = () => {
    const { machine_mode } = machine.machineState;
    alerts.map((alert) => {
      if (alert.message.includes('is in manager mode') && machine_mode === MachineMode.READY) {
        dismissAlertById(alert.alert_id);
      }
    });
  };

  useEffect(() => {
    checkPeripheralReadiness();
  }, [machine?.machineState?.machine?.peripheral_readiness.coin, 
    machine?.machineState?.machine?.peripheral_readiness.note, 
    machine?.machineState?.machine?.peripheral_readiness.printer, 
    machine?.machineState?.machine?.peripheral_readiness.qr, 
    machine?.machineState?.machine?.peripheral_readiness.screen_led]);

  useEffect(() => {
    checkMachineModeStatus();
  }, [machine?.machineState?.machine_mode]);

  return (
    <Messages>
      {!machine?.machineState.connected ? <Message iconName={'warning'} type={'error'}>Machine is offline</Message> : null}
      {machine?.machineState.machine_mode === 'error' ? <Message iconName={'warning'} type={'error'}>FOBT {machine?.machineState.shop_device_index} is in error mode</Message> : null}
      {alerts
        .filter((alert) => alert.thing_id === machine?.machineId)
        .filter((alert) => !(alert.message.includes('plenty of paper') || alert.message.includes('is in error mode')))
        .map((alert) => 
          <Message 
            key={alert.alert_id} 
            iconName={mapSourceToIconName(alert.source)} 
            type={mapSeverityToMessageType(alert.alert_type)}
            onClick={() => removeMessage(alert)}
          >
            {alert.message}
          </Message>)}
      {cashinResponses.success.filter(response => response.payload.tags.source === 'bo').map((response) => {
        return (
          <Message onClick={() => removeCashinResponse(response.payload.transaction_correlation_id)} key={response.meta.message_correlation_id} type='success' iconName='check'>
            {retailCurrency.format(response.payload.amount / 100)} has been successfully deposited.
          </Message>
        );
      })}
      {cashinResponses.error.map((response) => {
        return (
          <Message onClick={() => removeCashinResponse(response.payload.transaction_correlation_id)} key={response.meta.message_correlation_id} type='error' iconName='critical'>
            Deposit unsuccessful. {response.payload.error_message}
          </Message>
        );
      })}
    </Messages>
  );
};

export default DeviceAlerts;
