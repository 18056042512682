import { StyledBurger } from './HamburgerButton.styled';

interface HamburgerButtonProps { 
  onClickHandler: () => void;
  dataTestId?: string;
}

const HamburgerButton = ({ dataTestId, onClickHandler }: HamburgerButtonProps) => { 
  return (
    <StyledBurger data-test-id={dataTestId} onClick={onClickHandler}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

export default HamburgerButton;
