import Button from 'components/Buttons';
import { CalendarIcon } from 'components/Modal/DatePickerModal/DatePickerModal.styled';
import moment, { Moment } from 'moment';
import useDisclosure from 'hooks/useDisclosure';
import Calendar from '../Calendar';
import { CalendarPopupWrapper, CalendarButtonStyled } from './DatePicker.styled';
import Flex from 'styled/Flex';
import { theme } from 'styled/theme';
import { useState } from 'react';
import { formatShortDate } from 'utils/formatDate';

moment.locale('en-gb');

interface DatePickerProps {
  clearable?: boolean;
  dataTestId?: string;
  onSelect: (date?: Moment) => void;
  selectedDate?: Date;
}

const DatePicker = ({ clearable = true, dataTestId = 'date-picker-button', onSelect, selectedDate }: DatePickerProps) => {
  const [hasDate, setHasDate] = useState(selectedDate !== undefined);
  const datePickerPopUp = useDisclosure();

  const handlePopUp = datePickerPopUp.isOpen ? datePickerPopUp.close : datePickerPopUp.open;

  const handleSelectDate = (date: Moment) => {
    onSelect(date);
    setHasDate(true);
    const currentDate = selectedDate ? selectedDate : new Date();
    if (currentDate.getMonth() === date.month()) {
      handlePopUp();
    }
  };

  const handleClear = () => {
    setHasDate(false);
    onSelect();
  };
  
  return (
    <Flex direction='column' wide>
      {datePickerPopUp.isOpen && 
        <CalendarPopupWrapper>
          <Calendar
            border={`1px solid ${theme.colors.grey50}`}
            onSelect={handleSelectDate}
            restrictAfter={moment()}
            selectedDate={selectedDate ? selectedDate : new Date()}
            shadow={'0px 0px 16px rgba(16, 24, 40, 0.3)'}
          />
        </CalendarPopupWrapper>
      }
      <Flex gap='5px'>
        <CalendarButtonStyled hasDate={hasDate}>
          <Button onClick={handlePopUp} justifyContent='space-between' wide>
            <div data-test-id={dataTestId}><CalendarIcon />{hasDate && selectedDate ? formatShortDate(selectedDate) : 'DD/MM/YYYY'}</div>
          </Button>
        </CalendarButtonStyled>
        {clearable && hasDate && <Button onClick={handleClear}>Clear</Button>}
      </Flex>
    </Flex>
  );
};

export default DatePicker;
