import Button from 'components/Buttons';
import Icon from 'components/Icon';
import SessionTimer from 'components/SessionTimer';
import { MachineMap, Notification } from 'types/machine/MachineType';
import { TileContainer, TileHeader, HeadingIndex, TileContent, TileInfoContainer, Label, Value } from './Tile.styled';
import { createTerminalDisplayNameFromMachineId, createDeviceDisplayNameFromDeviceType } from 'utils/formatTerminal';
import { getConstant } from 'utils/constants';
import { lockTerminal } from 'api/iotTransactionApi';
import { startSession } from 'api/iotSessionApi';
import DeviceAlertIcons from 'components/Demo/DeviceAlertIcons';
import { BackofficeAlertWithDate } from 'types/alert/GeneralAlert';
import { retailCurrency } from 'retail-currency';

const alertingFeature = getConstant('REACT_APP_ALERTING') === 'true';
const environment = getConstant('REACT_APP_NEW_RELIC_ENV');
const errorStateModes = ['error', 'manager'];

const Tile = ({ machine, index, onClick, alerts = [] }: TileProps) => {
  // deviceType value not important as the Start Session/End Session buttons are just for testing and should be
  // toggled off (based on an env setting) before dashboard goes live
  const {
    machineId,
    deviceUuid,
    machineState: {
      device_type: deviceType = '',
      session_id: sessionId,
      session_start_at,
      started_at,
      machine_mode: machineMode,
      balance,
      committed_funds,
      name,
      real_name: realName,
      nickname,
      staked,
      machine: machineState,
      cashin_current_balance_difference: cashinCurrentBalanceDifference,
      session_end_at, // z fobt
    },
  } = machine;
  const endTime = session_end_at;
  let startTime = endTime ? '' : started_at || session_start_at;
  startTime = startTime ? startTime.substring(11, 16) : '';
  const terminalIndex = createTerminalDisplayNameFromMachineId(machineId);
  const playerName = realName || nickname || name || '';
  const sessionInProgress = Boolean(sessionId && startTime);
  const realBalance = balance - committed_funds;

  const onStartSessionClicked = (e: React.MouseEvent) => {
    console.info('onStartSessionClicked', {
      machineId,
      deviceUuid,
      deviceType,
    });
    e.stopPropagation();
    startSession(machineId, deviceUuid, deviceType);
  };

  const onUnlockTerminalClicked = (e: React.MouseEvent) => {
    console.info('onUnlockTerminalClicked', {
      machineId,
      deviceUuid,
    });
    e.stopPropagation();
    lockTerminal(machineId, deviceUuid, 'ready');
  };

  const getTileState = () => {
    if (errorStateModes.includes(machine.machineState.machine_mode)) {
      return 'error';
    }

    if (!machine.machineState.connected) {
      return 'error';
    }

    const isHighOrAlertSeverity = alerts.some(n => ['error'].includes(n.alert_type));
    if (isHighOrAlertSeverity) {
      return 'error';
    }

    const isMediumSeverity = alerts.some(n => ['warning'].includes(n.alert_type));
    if (isMediumSeverity) {
      return 'warning';
    }

    return 'neutral';
  };

  const renderTerminalState = () => {
    if (machineMode === 'locked') {
      return <Button onClick={(e) => onUnlockTerminalClicked(e)} wide cta>Unlock Terminal</Button>;
    }

    const winLossPounds = cashinCurrentBalanceDifference / 100;
    const isPlayerWinning = winLossPounds <= 0;

    if (sessionId && (session_start_at || started_at) && !session_end_at) {
      return (
        <>
          <div>
            <Label data-test-id={`${terminalIndex}-session-timer-label`}>Timer</Label>
            <Value data-test-id={`${terminalIndex}-session-timer`}>{sessionId ? <SessionTimer machine={machine} /> : '00:00'}</Value>
          </div>
          <div>
            <Label data-test-id={`${terminalIndex}-win-loss-label`}>Win / Loss</Label>
            <Value {...{ [isPlayerWinning ? 'postitive' : 'negative']: true }} data-test-id={`${terminalIndex}-win-loss`}>
              {!Number.isNaN(winLossPounds) ?
                <>
                  <span>
                    <Icon name={isPlayerWinning ? 'arrow-up' : 'arrow-down'} width={24} height={24} data-test-id={`${terminalIndex}-win-loss-indicator-${isPlayerWinning ? 'arrow-up' : 'arrow-down'}`}/>
                  </span>
                  <span>{retailCurrency.format(Math.abs(winLossPounds))}</span>
                </>
                : <span data-test-id={`${terminalIndex}-win-loss-none`}>-</span>}
            </Value>
          </div>
        </>
      );
    }

    if (environment === 'dev') {
      return <Button onClick={(e) => onStartSessionClicked(e)} wide cta data-test-id={`${terminalIndex}-start-session`}>Start Session</Button>;
    }
  };
  
  return (
    <TileContainer data-test-id={`tile-container-${terminalIndex}`} key={index} direction='column' onClick={onClick}>
      <TileHeader state={getTileState()} direction='row' justifyContent='space-between' alignItems='center'>
        <div data-test-id={`terminal-${terminalIndex}`}>
          <HeadingIndex data-test-id='heading-index'>{terminalIndex} <span style={{ fontSize: '15px' }}>{createDeviceDisplayNameFromDeviceType(deviceType)}</span></HeadingIndex>
          <span>{name}</span>
        </div>
        {alertingFeature && <DeviceAlertIcons 
          state={getTileState()}
          isMachineConnected={machine.machineState.connected}
          machineMode={machine.machineState.machine_mode}
          alerts={alerts}
        />}
        <div data-test-id= {`session is-${sessionInProgress}`}>
          <Value data-test-id={`${terminalIndex}-tagged-player`}>{(sessionInProgress && playerName) ? playerName : 'Not tagged'}</Value>
        </div>
      </TileHeader>

      <TileContent direction='column'>
        <TileInfoContainer>
          <div>
            <Label data-test-id={`${terminalIndex}-cash-balance-label`}>Cash Balance</Label>
            <Value data-test-id={`${terminalIndex}-cash-balance`}>{retailCurrency.format(realBalance / 100)}</Value>
          </div>
          {machineState?.funds && <div>
            <Label data-test-id={`${terminalIndex}-cash-total-label`}>Total</Label>
            <Value data-test-id={`${terminalIndex}-cash-total`}>{retailCurrency.format(machineState?.funds.total_cashbox / 100)}</Value>
          </div>}
          <div>
            <Label data-test-id={`${terminalIndex}-total-staked-label`}>Total Staked</Label>
            <Value data-test-id={`${terminalIndex}-total-staked`}>{retailCurrency.format(staked / 100)}</Value>
          </div>
          {renderTerminalState()}
        </TileInfoContainer>
      </TileContent>
    </TileContainer>
  );
};

export type TileState = 'neutral' | 'error' | 'warning';

export interface TileProps {
  state?: TileState;
  machine: MachineMap;
  alerts?: BackofficeAlertWithDate[]
  index: number;
  onClick: () => void;
}

export default Tile;
