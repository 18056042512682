import styled from 'styled-components';
import { PlayerSelfExclusionStatus } from 'types/Player';

type StatusProps = {
  status: PlayerSelfExclusionStatus;
}

type DatesProps = {
  isToBeDeleted: boolean;
}

export const SelfExclusionStyled = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.grey100};
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  padding: 20px 24px;
`;

export const SelfExclusionDates = styled.div<DatesProps>`
  align-items: center;
  display: flex;
  gap: 24px;
  ${props => props.isToBeDeleted && 'text-decoration: line-through;'}
`;

export const SelfExclusionStatus = styled.div<StatusProps>`
  align-items: center;
  border-radius: 4px;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  justify-content: center;
  line-height: 24px;
  padding: 2px 12px;
  width: 110px;

  ${props => props.status === 'active' && (`
    background: ${props.theme.colors.error20};
    border: 1px solid ${props.theme.colors.error800};
    color: ${props.theme.colors.error800};
  `)}

  ${props => props.status === 'review' && (`
    background: ${props.theme.colors.warning100};
    border: 1px solid ${props.theme.colors.warning800};
    color: ${props.theme.colors.warning800};
  `)}

  ${props => props.status === 'expired' && (`
    background: ${props.theme.colors.primary10};
    border: 1px solid ${props.theme.colors.primary600};
    color: ${props.theme.colors.primary600};
  `)}
`;

export const UndoIcon = styled.div`
  color: ${props => props.theme.colors.primary600};
  height: 24px;
  width: 24px;
`;

export const CloseIcon = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;