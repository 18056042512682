import { CashReportContainer } from './CashReportDashboard.styled';
import { CashReport, CashReportItem } from '../../types/cashReport/CashReportType';
import Flex from 'styled/Flex/Flex';
import { Text } from 'components/Text';
import { CashReportTile } from './CashReportTile';

const CashReportDashboard = ({ reportItems } :CashReport ): JSX.Element => {
  const tillReportItems = reportItems.filter((item: CashReportItem) => item.dec_type === 'TILL');
  const safeReportItems = reportItems.filter((item: CashReportItem) => item.dec_type === 'SAFE');
  const insertReportItems = reportItems.filter((item: CashReportItem) => item.dec_type === 'INSERT');

  const evenRound = (num: number) => {
    const d = 2; // 'd' represents the number of decimal places to round to.
    const m = Math.pow(10, d); // 'm' is a multiplier used to shift the decimal point.
    const n = +(d ? num * m : num).toFixed(8); // 'n' is the number after shifting the decimal point.
    const i = Math.floor(n), f = n - i; // 'i' is the integer part of 'n', and 'f' is the fractional part.
    const e = 1e-8; // 'e' is a small value used for comparison to determine if rounding should occur.
    const r = (f > 0.5 - e && f < 0.5 + e) ? // If 'f' is within a small range around 0.5, it's considered for rounding.
      ((i % 2 == 0) ? i : i + 1) : Math.round(n); // If 'i' is even, it is returned as the rounded value; otherwise, 'i + 1' is returned. Alternatively, 'n' is rounded to the nearest integer.
    return d ? r / m : r; // The rounded value is divided by 'm' to shift the decimal point back to the original position if 'd' is non-zero; otherwise, 'r' is returned as is.
  };

  const totalCashInShop = reportItems.reduce(
    (acc, item) => evenRound(acc) + evenRound(Number(item.total)), 0
  );

  return (
    <CashReportContainer>
      <Flex direction='column' gap='1rem'>
        <Text color='grey900' fontSize='32px' lineHeight='48px'>Money Management</Text>
        <Flex wrap='wrap' gap='1rem'>
          { tillReportItems.map((till, index) => {
            return (<CashReportTile key={index} name={'Till'} total={till.total} tillNumber={String(till.till_number)} />);
          }) }
        </Flex>

        <Flex wrap='wrap' gap='1rem'>
          { safeReportItems.map((safe, index) => {
            return (<CashReportTile key={index} name={'Cash in Safe'} total={safe.total} />);
          }) }

          { insertReportItems.map((insert, index) => {
            return (<CashReportTile key={index} name={'Cash in Insert'} total={insert.total} />);
          }) }

          {totalCashInShop && <CashReportTile key={0} name={'Total Cash in Shop'} total={totalCashInShop} />}

        </Flex>
      </Flex>
    </CashReportContainer>
  );
};

export default CashReportDashboard;
