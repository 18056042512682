import styled from 'styled-components';

type HeaderCellProps = {
  children: React.ReactNode;
}

const StyledHeaderCell = styled.div<HeaderCellProps>`
  font-family: WH Hoxton;
  font-size: 18px;
`;

const HeaderCell = ({ children }: HeaderCellProps) => {
  return (
    <StyledHeaderCell>
      {children}
    </StyledHeaderCell>
  );
};

export default HeaderCell;