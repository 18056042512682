import Flex from 'styled/Flex';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { Check } from 'components/Icon';
import { StyledSelectableList, StyledSelectableListItem, StyledSelectableListSeparator } from './SelectableList.styled';
import { azSorter } from 'utils/sorters';

const SelectableList = ({ dataTestId, selectedOption, itemPadding, height, listMargin, theme, options, handleOptionSelected, loading = false }: SelectableListProps) => (
  <StyledSelectableList height={height} listMargin={listMargin} theme={theme} options={options} data-test-id={dataTestId}>
    {loading && <Flex alignItems='center' justifyContent='center' height='100%'><LoadingSpinner /></Flex>}
    {!loading && options.sort(azSorter).map((o: string) => (
      <div data-test-id='player-list-item' key={o}>
        <StyledSelectableListItem onClick={() => handleOptionSelected(o)} selected={o === selectedOption} itemPadding={itemPadding}>
          <Flex>
            {o}
            {o === selectedOption && <div style={{ marginLeft: 'auto' }}><Check /></div>}
          </Flex>
        </StyledSelectableListItem>
        <StyledSelectableListSeparator />
      </div>
    ))}
  </StyledSelectableList>
);

interface SelectableListProps {
  dataTestId?: string;
  fontSize?: string;
  handleOptionSelected: (value: any) => void;
  height?: string;
  itemPadding?: string;
  listMargin?: string;
  loading?: boolean;
  options: string[];
  selectedOption?: string;
  theme?: any;
}

export default SelectableList;
