import PubSub from 'pubsub-js';

import Button from 'components/Buttons/Button';
import Flex from 'styled/Flex';
import Label from 'components/Label';
import Value from 'components/Value';
import { DrawerContentHeader } from 'components/Tile/Tile.styled';

const TerminalInfoContent = ({ nickname, setActionType }: TerminalInfoContentProps) => {

  const onTagPlayer = () => {
    setActionType('tagging');
    PubSub.publish('app.alert.drawer.player', null);
  };

  return (
    <>
      <DrawerContentHeader>Terminal Info</DrawerContentHeader>
      <Label padding='24px 24px 0' fontSize='18px' color='grey500'>Player's Name</Label>
      <Value padding='8px 24px'>{nickname || '-'}</Value>
      <Flex justifyContent='center' gap='8px' padding='24px'>
        <Button wide onClick={onTagPlayer}>{nickname ? 'Change Player' : 'Tag Player'}</Button>
        <Button wide>History</Button>
      </Flex>
    </>
  );
};

interface TerminalInfoContentProps {
  setActionType: (actionType: string) => void;
  nickname: string | null;
}

export default TerminalInfoContent;
