import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import { AlertTypeEnum, BackofficeAlert, BackofficeAlertWithDate } from 'types/alert/GeneralAlert';
import { isBackofficeAlert } from 'utils/alertValidator';
import useInterval from 'hooks/useInterval';
import {
  shouldBeClosedAutomatically,
  shouldBeDismissedAWhileAfterSessionEnds,
  shouldUpgradeItsSeverityAutomatically,
} from 'utils/filters';
import useMachines from 'hooks/useMachines';
import useShop from 'hooks/useShop';
import { SubscriptionList } from 'types/aws/SubscriptionTypes';
import { getPubSubIot } from 'aws/Aws';
import { sendAlertToCloudWatch } from 'utils/cloudWatchLogHelper';

function getUniqueListBy<T>(arr: T[], key: keyof T) {
  return [...new Map(arr.map(item => [item[key], item])).values()];
}

const useAlerts = () => {
  const { machines } = useMachines();
  const { shopId } = useShop();
  const [alerts, setAlerts] = useState<BackofficeAlertWithDate[]>([]);
  const [subscriptions, setSubscriptions] = useState<SubscriptionList>([]);

  useEffect(() => {
    const machineIds = machines.map(({ machineId }) => machineId);
    const alertNotificationsTopics: SubscriptionList = subscribeToAlertNotifications(machineIds);
    setSubscriptions([...alertNotificationsTopics]);
    return () => {
      unsubscribe();
    };
  }, [shopId, machines.length]);

  const unsubscribe = () => {
    subscriptions.forEach((topic: any) => {
      topic.unsubscribe();
    });
  };

  const subscribeToAlertNotifications = (machineIds: string[]) => {
    return machineIds.flatMap((id) => {
      const topics = [`${id}/notification/alert`];
      console.log('subscribeToAlertNotifications with topics', topics);

      return getPubSubIot().subscribe(topics)
      // @ts-ignore
        .subscribe((data) => {
          const update = data?.value || data;
          console.log('subscribeToAlertNotifications', update);
        
          if (!isBackofficeAlert(update.payload)) {
            return;
          }
  
          handleIncomingAlertMessage(update.payload);
        });
    });
  };

  const handleIncomingAlertMessage = (incomingAlert: BackofficeAlert) => {
    const newAlert = {
      ...incomingAlert,
      date: DateTime.now(),
    };
    setAlerts(current => {
      const alertsById = getUniqueListBy([...current, newAlert], 'alert_id');
      const alertsByMessage = getUniqueListBy(alertsById, 'message');
      return alertsByMessage;
    });
  };

  const dismissAlertById = (id: BackofficeAlert['alert_id']) => {
    setAlerts(current => [...current.filter(a => a.alert_id !== id)]);
  };

  useInterval(() => {
    const tickDateTime = DateTime.fromJSDate(new Date());
    const alertsToDismiss = alerts.filter(shouldBeClosedAutomatically).map(alert => {
      const isOldEnoughToBeDismissed = tickDateTime.diff(alert.date, ['seconds']).seconds > 10;
      if (isOldEnoughToBeDismissed) {
        return alert.alert_id;
      }
    });

    if (alertsToDismiss.length) {
      setAlerts(current => [...current.filter(a => !alertsToDismiss.includes(a.alert_id))]);
    }
  }, 1000);

  useInterval(() => {
    const tickDateTime = DateTime.fromJSDate(new Date());
    setAlerts(current => {
      const newState = current.map(alert => {
        const isOldEnoughToUpdateSeverity = tickDateTime.diff(alert.date, ['seconds']).seconds > 10;
        if (isOldEnoughToUpdateSeverity && shouldUpgradeItsSeverityAutomatically(alert)) {
          return {
            ...alert,
            alert_type: alert.alert_type === AlertTypeEnum.WARNING ? AlertTypeEnum.INFO : alert.alert_type,
          };
        }

        return alert;
      });

      return newState;
    });
  }, 1000);

  useInterval(() => {
    const tickDateTime = DateTime.fromJSDate(new Date());
    const ongoingSessionsIds = machines.map(machine => machine.machineState.session_id);
    
    const alertsToDismiss = alerts.filter(shouldBeDismissedAWhileAfterSessionEnds).map(alert => {
      const isOldEnoughToBeDismissed = tickDateTime.diff(alert.date, ['seconds']).seconds > 10;
      const isRelatedToEndedSession = !alert.session_id || !ongoingSessionsIds.includes(alert.session_id);

      if (isOldEnoughToBeDismissed && isRelatedToEndedSession) {
        return alert.alert_id;
      }
    });

    setTimeout(() => {
      if (alertsToDismiss.length) {
        setAlerts(current => [...current.filter(a => !alertsToDismiss.includes(a.alert_id))]);
      }
    }, 10000);
  }, 1000);

  const acknowledgeAlertById = (id: BackofficeAlert['alert_id'], actionPerformed: string) => {
    try {
      const alertToAcknowledge = alerts.find(a => a.alert_id === id);
      if (!alertToAcknowledge) {
        throw new Error('Cannot find alert ' + id);
      }
      sendAlertToCloudWatch(alertToAcknowledge, actionPerformed);
      dismissAlertById(id);
    }
    catch (error){     
      console.error(error);
    }
  };

  const machinesInErrorMode = machines.filter(m => m.machineState.machine_mode === 'error');

  return {
    alerts,
    machinesInErrorMode,
    acknowledgeAlertById,
    dismissAlertById,
  };
};

export default useAlerts;
