import styled from 'styled-components';

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const ListingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SelectedDate = styled.p`
  font-size: 24px;
  line-height: 32px;
`;

export const SummaryTiles = styled.div`
  display: flex;
  flex-direction: row;
  gap 8px;
`;

export const Tile = styled.div`
  padding: 20px 24px;
  background: ${(props) => props.theme.colors.primary25};
  border-radius: 4px;
  width: 220px;
  height: 90px;
`;

export const TileTitle = styled.p`
  font-size: 18px;
  color: ${(props) => props.theme.colors.grey500};
  margin-bottom: 8px;
`;

export const TileValue = styled.p`
  font-size: 24px;
  color: ${(props) => props.theme.colors.grey900};
`;

export const SearchFilters = styled.div`
  flex: 1;
`;

export const FiltersHeader = styled.p`
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 8px;
`;

export const TerminalPickerLabel = styled.div`
  white-space: nowrap;
`;

