import useSWR from 'swr';
import { getConstant } from 'utils/constants';

const RSS_URL = getConstant('REACT_APP_RETAIL_SESSION_URL');

export interface Event {
  eventId: string;
  sessionId: string;
  eventMessage: string;
  eventType: string;
  eventTime: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface Session {
  sessionId: string;
  startedAt: Date;
  endedAt: Date;
  sessionEndVoucher?: any;
  shopId: string;
  shopDeviceIndex: string;
  terminalId: string;
  deviceType?: any;
  staffId?: any;
  obAccountId: string;
  plusAccountId?: any;
  lastTransactionAt?: any;
  staked: number;
  monetaryLimit: number;
  monetaryLimitStaked?: any;
  timeLimitInMin: number;
  timeLimitStart?: any;
  gameStartStaked?: any;
  game: string;
  realName?: any;
  nickname?: any;
  nicknameId?: any;
  taggedAt?: any;
  grossWin: number;
  createdAt: Date;
  updatedAt: Date;
  events: Event[];
}

const fetcher = async (uid: string): Promise<Session> => {
  const url = `${RSS_URL}/sessions/mysql/${uid}?thingType=gm`;

  return fetch(url).then(res => res.json());
};

const useSession = (uid: string) => {
  const { data, error } = useSWR(`retail_session_${uid}`, () => fetcher(uid));

  return {
    session: data,
    error,
    isLoading: !error && !data,
  };
};

export default useSession;
