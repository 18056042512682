import { memo } from 'react';
import Logs from 'components/Logs/Logs';
import useEventLogs from 'hooks/useEventLogs';

const EventLogs = () => {
  const events = useEventLogs();

  return (
    <Logs type='events' {...events}/>
  );
};

export default memo(EventLogs);
