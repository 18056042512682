export interface RvsDateInterface {
  nano?: number;
  epochSecond: number;
}

export interface Voucher {
  uid: string;
  barcode: string;
  amount: number;
  totalStake: number;
  totalDeposit: number;
  voucherSource: string;
  voucherType: string;
  machineId: string;
  createdAt: RvsDateInterface;
  redeemed?: boolean;
  redeemedAt: string;
  expirationDate: RvsDateInterface;
  redeemedVia?: string;
  potentialFraud: boolean;
  fraudFlagType: string;
  terminalCredited: boolean;
  terminalPrintedFrom: string;
  shop: string;
  paidOut: boolean;
  approvalNeeded: boolean;
  ssbtId: string;
  originatingShopName: string;
  voucherApprovalCode: number;
  locked: boolean;
  voucherApprovalReason: string;
  online: boolean;
  synchronized?: boolean;
  isNotSynced?: boolean;
}

export interface EncodedVoucherPayload {
  amount: number;
  barcode: string;
  uid: string;
  createdAt: RvsDateInterface;
  expirationDate: RvsDateInterface;
  machineId: string;
  online: boolean;
  potentialFraud: boolean;
  paidOut: boolean;
  shop: string;
  voucherType: string;
  redeemed?: boolean;
  redeemedAt: null;
  redeemedVia: null;
  fraudFlagType: null;
  voucherSource: 'FOBT';
}

export type VoucherOffline = Voucher & {
  potential_fraud: boolean;
  redeemed: boolean;
  online: boolean;
  synchronized: boolean;
}

export interface Totals {
  totalAmount: number;
  totalRedeemed: number;
  totalUnredeemed: number;
}

export interface GetVouchersResponse extends Totals {
  vouchers: Voucher[];
}

export type FraudFlagType = 'no' | 'standard' | 'multiple';

export const isOnlineVoucher = (v: Voucher | VoucherOffline | EncodedVoucherPayload): v is Voucher => v.online;
