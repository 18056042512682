import { useEffect, useState, useCallback } from 'react';
import { MenuItemType } from 'types/logs/Logs';
import { MenuInfo } from 'rc-menu/lib/interface';
import { cloudWatchLogs } from 'aws/Aws';
import useShop from 'hooks/useShop';

export const useCloudWatchMachines = (logGroupName: string) => {
  const [machinesFilters, setMachinesFilters] = useState<MenuItemType[]>([]);
  const [selectedTerminal, setSelectedTerminal] = useState('');
  const { shopId } = useShop();

  const onTerminalMenuItemClickHandler = useCallback((i: MenuInfo) => {
    const key = Number(i.key);
    const terminal = machinesFilters[key].label;

    setSelectedTerminal(terminal);
  }, [machinesFilters, setSelectedTerminal]);

  useEffect(() => {
    const getStreamParams = {
      logGroupName,
      logStreamNamePrefix: `gm-${shopId}`,
    };

    cloudWatchLogs.describeLogStreams(getStreamParams, (err, data) => {
      if (err) {
        console.log(err);
        return;
      }
      if (data?.logStreams) {
        const terminals: string[] = [];
        data.logStreams.filter(stream => stream.logStreamName && terminals.push(stream.logStreamName));
        terminals.sort((a, b) => Number(a.split('-')[2]) - Number(b.split('-')[2]));
        const machinesFilters: MenuItemType[] = terminals.map((terminal, index) => {
          return {
            label: terminal,
            key: index, 
          };
        });

        setMachinesFilters(machinesFilters);
        machinesFilters.length > 0 ? setSelectedTerminal(machinesFilters[0].label) : setSelectedTerminal('No terminals in this shop');
      }
    }); 
  }, []);

  return {
    machinesFilters,
    selectedTerminal,
    setSelectedTerminal,
    onTerminalMenuItemClickHandler,
  };
};
