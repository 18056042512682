import styled from 'styled-components';

export const TagStyled = styled.div`
  align-items: center;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.grey200};
  color: ${props => props.theme.colors.grey500};
  display: flex;
  font-size: 16px;
  font-weight: 400;
  height: max-content;
  justify-content: center;
  line-height: 24px;
  padding: 2px 12px;
  white-space: nowrap;
  width: max-content;
`;