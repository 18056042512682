import Button from 'components/Buttons';
import styled, { css } from 'styled-components';

interface Openable {
  isOpen: boolean;
}

interface ModalProps {
  width: string;
}

export const ModalBody = styled.div`
  background: ${(props) => props.theme.colors.white};
  height: 100%;
`;

export const ModalContainer = styled.div`
  position: absolute;
`;

export const ModalDialog = styled.div<ModalProps>`
  background: ${(props) => props.theme.colors.white};
  border-radius: 12px;
  box-shadow: 0px 0px 16px rgba(16, 24, 40, 0.06);
  position: fixed;
  left: 50%;
  overflow: hidden;
  top: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) => props.width};
  z-index: 1000;
`;

export const Backdrop = styled.div<Openable>`
  visibility: hidden;
  opacity: 0;
  background: rgba(59, 78, 103, 0.45);
  transition: opacity 0.3s ease, visibility 03.s ease;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  z-index: 0;
  ${(props) => props.isOpen && css`
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    z-index: 999;
  `}
`;

export const ModalTitle = styled.h3`
  font-family: WHHoxton-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 48px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.grey900};
  padding: 20px 20px 10px 20px;
  width: 100%;
`;

export const ModalHeading = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey200};;
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const ModalClose = styled.a`
  padding: 14px;
  align-items: center;
  line-height: 14px;
  display: flex;
  box-sizing: border-box;
  border-radius: 4px;
  
  svg {
    path {
      fill: ${(props) => props.theme.colors.grey700};
    }
  }
`;

export const SquareButton = styled(Button)`
  width: 56px;
  height: 56px;
`;
