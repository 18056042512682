import { useContext } from 'react';
import { MachinesContext } from 'providers/MachinesProvider';

const useMachines = () => {
  const context = useContext(MachinesContext);
  if (!context) {
    throw new Error('useMachines must be used within a MachinesProvider');
  }

  return context;
};

export default useMachines;
