import kebabCase from 'lodash.kebabcase';
import PlayerFlag from '../PlayerFlag';
import useShop from 'hooks/useShop';
import { Player } from 'types/Player';
import { CustomerUrn, PlayerComplianceInfoStyled, PlayerFlags } from './PlayerComplianceInfo.styled';
import { dateSorter } from 'utils/sorters';
import { getSelfExclusionStatus, getSelfExclusionStatusLabel } from 'utils/selfExclusion';

interface PlayerComplianceInfoProps {
  isEditMode?: boolean;
  player: Player;
}

const PlayerComplianceInfo = ({ isEditMode, player }: PlayerComplianceInfoProps) => {
  const { customerId, refusalOfServiceId, selfExclusions } = player;
  const { shopId } = useShop();
  const playerFlags = [];

  refusalOfServiceId && playerFlags.push({ label: 'Refusal of Service' });
  const sortedExclusions = (selfExclusions || []).filter(se => se.selfExclusionShopId === shopId).sort((a,b) => dateSorter(b.selfExclusionValidTo, a.selfExclusionValidTo));
  if (sortedExclusions && sortedExclusions.length > 0) {
    const latestExpiry = sortedExclusions[0].selfExclusionValidTo;
    const latestExclusionLabel = getSelfExclusionStatusLabel(latestExpiry);
    playerFlags.push({
      label: `Self excluded - ${latestExclusionLabel.replace('In ', '')}`,
      status: getSelfExclusionStatus(latestExpiry), 
    });
  }
  
  return (
    <PlayerComplianceInfoStyled data-test-id='player-compliance-info'>
      <CustomerUrn data-test-id='customer-urn'>Customer URN: {customerId}</CustomerUrn>
      {playerFlags.length > 0 && !isEditMode &&
        <PlayerFlags>
          {playerFlags.map(flag => <PlayerFlag key={kebabCase(flag.label)} label={flag.label} status={flag.status}/>)}
        </PlayerFlags>
      }
    </PlayerComplianceInfoStyled>
  );
};

export default PlayerComplianceInfo;