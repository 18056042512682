import styled from 'styled-components';

export const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 56px;
  height: 56px;
  padding: 18px 0;
  background: none;
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  border-radius: 4px;
  cursor: pointer;
  div {
    width: 20px;
    height: 2px;
    margin: 2px 0;
    background: ${({ theme }) => theme.colors.grey900};
  }
`;
