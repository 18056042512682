import { ButtonHTMLAttributes, forwardRef } from 'react';
import { StyledButton } from './Button.styled';
import { DefaultTheme } from 'styled-components';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';

type Color = keyof DefaultTheme['colors']

interface ButtonOwnProps {
  as?: 'button' | 'a';
  border?: string;
  borderless?: boolean;
  background?: Color;
  color?: Color;
  cta?: boolean;
  tagNow?: boolean;
  justifyContent?: string;
  loading?: boolean;
  margin?: string;
  padding?: string;
  primary?: boolean;
  secondary?: boolean;
  wide?: boolean;
  width?: string;
}

export type ButtonProps = ButtonOwnProps & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = forwardRef<HTMLButtonElement, ButtonProps> (({ as = 'button', loading = false, disabled = false, ...props }, ref) =>
  <StyledButton {...props} ref={ref} as={as} disabled={loading || disabled}>
    {!loading && props.children}
    {loading && <LoadingSpinner />}
  </StyledButton>
);

export default Button;
