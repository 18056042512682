import { MouseEventHandler } from 'react';
import { theme } from 'styled/theme';
import { ThingType } from 'types/aws/ThingListTypes';
import { createGroupName } from 'utils/formatTerminal';
import { ToggleStyled, ToggleTitle, ToggleIndex } from './TerminalSelectorToggle.styled';

interface TerminalSelectorButtonProps {
  clickHandler: MouseEventHandler<HTMLDivElement>;
  terminalIndex: string,
  deviceType: ThingType,
  selected: boolean,
}

const TerminalSelectorToggle = ({ clickHandler, terminalIndex, deviceType, selected }: TerminalSelectorButtonProps) => {
  const getBackground = () => {
    switch (deviceType) {
      case 'ssbt':
        return selected ? theme.colors.secondarySsbt900 : theme.colors.secondarySsbt25;
      case 'hub':
        return selected ? theme.colors.grey900 : theme.colors.grey50;
      case 'gm':
        return selected ? theme.colors.secondaryFobt700 : theme.colors.fobt25;
      case 'handheld':
        return selected ? theme.colors.secondaryHhbt900 : theme.colors.secondaryHhbt50;
      default:
        return theme.colors.white;
    }
  };

  return (
    <ToggleStyled onClick={clickHandler} background={getBackground()}>
      <ToggleTitle selected={selected}>{createGroupName(deviceType)}</ToggleTitle>
      <ToggleIndex selected={selected}>{terminalIndex}</ToggleIndex>
    </ToggleStyled>
  );
};

export default TerminalSelectorToggle;
