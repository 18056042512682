import styled, { DefaultTheme, css } from 'styled-components';

type Color = keyof DefaultTheme['colors']

type TextProps = {
  color: Color
  fontSize: string
  fontStyle: 'normal' | 'italic' | 'oblique'
  fontWeight: string
  lineHeight: string
  margin: string
}

const Text = styled.div<TextProps>`
  font-family: 'WH Hoxton';
  color: ${(props) => props.theme.colors[props.color]};
  ${(props) => css`font-size: ${props.fontSize}`};
  ${(props) => css`font-style: ${props.fontStyle}`};
  ${(props) => css`font-weight: ${props.fontWeight}`};
  ${(props) => css`line-height: ${props.lineHeight}`};
  ${(props) => css`margin: ${props.margin}`};
`;

export { Text };
