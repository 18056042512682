import { getModalOptionsComponent } from './ModalOptions';

export const getUnsuccessfulModalContent = () => {
  return {
    merge: {
      title: 'Unable to merge players',
      subtitle: 'Error! An unknown error has taken place',
      textContent: [
        'We\'ve been unable to merge the following profiles. Return to manage players or retry the operation.',
        'All sessions from {unselectedOption} will be assigned to {selectedOption}, and {unselectedOption}\'s player info will be deleted.',
      ],
      cancelLabel: 'Back',
      confirmLabel: 'Merge players',
    },
    delete: {
      title: 'Unable to delete player',
      subtitle: 'Error! An unknown error has taken place',
      textContent: [
        'We\'ve been unable to delete the player(s). Please try again.',
      ],
      cancelLabel: 'Back',
      confirmLabel: 'Retry',
    },
    update: {
      title: 'Unable to save changes',
      subtitle: 'Error! Unable to save changes',
      textContent: [
        'We\'ve been unable to save your changes. Return to manage players or retry the operation',
      ],
      cancelLabel: 'Back',
      confirmLabel: 'Retry',
    },
    'cancel-update': {
      title: '',
      subtitle: '',
      textContent: [],
      cancelLabel: '',
      confirmLabel: '',
    },
  };
};

export const getConfirmationModalContent = (options: string[]) => {

  return {
    merge: {
      title: 'Merge players',
      subtitle: 'Please ensure you read carefully, you can\'t undo this action.',
      textContent: [
        'Which player info would you like to keep?',
        getModalOptionsComponent(options),
        'All sessions from {unselectedOption} will now be assigned to {selectedOption}, and {unselectedOption}\'s player info will be deleted.',
        'Please update player descriptions with any info you want to keep before merging.',
      ],
      cancelLabel: 'Cancel',
      confirmLabel: 'Merge players',
    },
    delete: {
      title: 'Delete player',
      subtitle: 'Please ensure you read carefully, you can\'t undo this action.',
      textContent: [
        'Deleting a player will result in this info permanently being deleted.',
        'Are you sure you want to delete {selectedOption}?',
      ],
      cancelLabel: 'Cancel',
      confirmLabel: 'Delete {selectedOption}',
    },
    update: {
      title: 'Save changes',
      subtitle: 'Please ensure you read carefully, you can\'t undo this action.',
      textContent: [
        'All updates made to {selectedOption}\'s info will be saved.',
        'Are you sure you want to save changes?',
      ],
      cancelLabel: 'Continue editing',
      confirmLabel: 'Save changes',
    },
    'cancel-update': {
      title: 'Cancel changes',
      subtitle: 'Please ensure you read carefully, you can\'t undo this action.',
      textContent: [
        'All updates made to {selectedOption}\'s info will be lost.',
        'Are you sure you want to cancel?',
      ],
      cancelLabel: 'Continue editing',
      confirmLabel: 'Cancel changes',
    },
  };
};