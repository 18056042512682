import moment from 'moment';
import { PlayerSelfExclusionStatus } from 'types/Player';

export const getSelfExclusionStatus = (date: Date): PlayerSelfExclusionStatus => {
  const now = moment();
  if (moment(date).isAfter(now)) {
    return 'active';
  }
  const sixMonthsAgo = moment().subtract(6, 'months');
  if (moment(date).isAfter(sixMonthsAgo)) {
    return 'review';
  }
  return 'expired';
};

export const getSelfExclusionStatusLabel = (date: Date): string => {
  const status = getSelfExclusionStatus(date);
  if ( status === 'review') {
    return 'In Review';
  } else {
    const capitaliseFirstLetter = status.charAt(0).toUpperCase();
    const remainingLetters = status.slice(1);
    return capitaliseFirstLetter + remainingLetters;
  }
};