import * as Styled from './Badge.styled';
import { BadgeProps } from './Badge.styled';

const Badge = ({ text, color, textColor, borderRadius, inlineBlock, testId }: BadgeProps) => {
  return (
    <Styled.Span data-test-id={testId} background={color} borderRadius={borderRadius} inlineBlock={inlineBlock}>
      <Styled.Paragraph data-test-id='badge-info' color={textColor}>{text}</Styled.Paragraph>
    </Styled.Span >
  );
};

export default Badge;
