import styled from 'styled-components';

interface CardProps {
  name: 'SSBT' | 'FOBT' | 'Smart Retail';
  equals: boolean;
  withMoreTiles: 'SSBT' | 'FOBT';
}

export const Card = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  padding: 20px;
  height: fit-content;
  width: calc(50% - 30px);
  background: ${({ name, theme }) => {
    if (name === 'SSBT') {
      return theme.colors.ssbt;
    }

    if (name === 'FOBT') {
      return theme.colors.fobt25;
    }

    if (name === 'Smart Retail') {
      return theme.colors.grey50;
    }

    return theme.colors.grey50;
  }};
  border-radius: 12px;
  float: ${({ name, withMoreTiles, equals }) => {
    if (name === 'Smart Retail' && !equals) {
      if (withMoreTiles === 'SSBT') {
        return 'right';
      }

      return 'left';
    }

    if (name === 'FOBT' && !equals) {
      if (withMoreTiles === 'FOBT') {
        return 'right';
      }

      return 'left';
    }

    return 'left';
  }};
  transform: ${({ name, withMoreTiles }) => {
    if (name === 'FOBT' && withMoreTiles === 'FOBT') {
      return 'translateX(-20px)';
    }
  }};
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 32px;
  color: ${({ theme }) => theme.colors.grey900};
  svg {
    path {
      fill: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const CardContent = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
`;

