/* eslint-disable @typescript-eslint/ban-types */
import { useEffect, useState } from 'react';
import PubSub from 'pubsub-js';

import Badge from 'components/Badge';
import Button from 'components/Buttons/Button';
import Flex from 'styled/Flex';
import Input from 'components/Input';
import Label from 'components/Label';
import SelectableList from 'components/SelectableList';
import Value from 'components/Value';
import { OnTagSessionParams, OnUntagSessionParams, makeMachineId, makeTagParams, makeUntagParams } from 'utils/mutateHelpers';
import { Player, PlayerNickname } from 'types/Player';
import { SummarySession } from 'types/session/SummarySession';
import { getPubSubIot } from 'aws/Aws';
import { logError } from 'newrelic';
import useMachines from 'hooks/useMachines';

// FIXME FUTURE - the second part of the OR clause can be removed when RCP-2179 has been deployed
const updateMatchesClientAndSession = (update: any, clientId: string, session: SummarySession) => {
  return (update?.clientId === clientId && update?.cmd === 'update-session' && update?.res?.sessionId === session.sessionId);
};

const updateIsSuccessful = (update: any) => update?.res?.code === '200';

const TaggingPlayerContent = ({ currentPlayer, loading = false, onClose, onTagSession, onUntagSession, players = [], searchInput, session, setActionType, setCurrentPlayer, setSearchInput, updateUiAfterTaggingSession }: TaggingPlayerContentProps) => {
  const { machines } = useMachines();
  const sanitisedPlayerList = players?.filter((player: Player) => !player.merged && !player.deleted);
  const [filteredPlayers, setFilteredPlayers] = useState(sanitisedPlayerList);
  const [selectedPlayer, setSelectedPlayer] = useState('');
  const [tagLoading, setTagLoading] = useState(false);
  const [untagLoading, setUntagLoading] = useState(false);
  const [playersLoading, setPlayersLoading] = useState(loading);
  const filteredPlayerNames = filteredPlayers?.map((player: Player) => player.nickname);
  let subscription: { unsubscribe: () => void; };

  useEffect(() => {
    setFilteredPlayers(sanitisedPlayerList);
    onInputChange({ target: { value: searchInput } });

    return () => {
      subscription && subscription.unsubscribe();
    };
  }, [players]);

  useEffect(() => {
    setPlayersLoading(loading);
  }, [loading]);

  const onAddPlayer = () => {
    setActionType('adding');
    setSearchInput('');
    setFilteredPlayers(players);
    PubSub.publish('app.alert.drawer.player', null);
  };

  const showAlert = (type: string, message: string) => {
    PubSub.publish('app.alert.drawer.player', {
      type,
      message,
      iconName: 'check',
    });
  };

  const handleTagServiceError = (newRelicMessage: string, nickname?: string, err?: unknown) => {
    if (nickname) {
      setTagLoading(false);
      showAlert('error', `${nickname} was not tagged to session.`);
    } else {
      setUntagLoading(false);
      showAlert('error', 'Player was not untagged from session.');
    }
    logError(newRelicMessage, 'critical', err);
  };

  const handleTagNetworkError = (newRelicMessage: string, nickname?: string, err?: unknown) => {
    if (nickname) {
      setTagLoading(false);
      showAlert('error', `Connection lost while tagging ${nickname}. Please check your internet connection and retry.`);
    } else {
      setUntagLoading(false);
      showAlert('error', 'Connection lost while untagging. Please check your internet connection and retry.');
    }
    logError(newRelicMessage, 'critical', err);
  };

  const handleTagNoReplyError = (newRelicMessage: string, nickname?: string, err?: unknown) => {
    if (nickname) {
      setTagLoading(false);
      showAlert('error', `No reply after tagging ${nickname}. Please check your internet connection and retry.`);
    } else {
      setUntagLoading(false);
      showAlert('error', 'No reply after untagging. Please check your internet connection and retry.');
    }
    logError(newRelicMessage, 'critical', err);
  };

  const handleTagSuccess = (updatedSession: SummarySession, nickname?: string, nicknameId?: string | null) => {
    if (nickname) {
      showAlert('success', `${nickname} has been successfully tagged.`);
      setCurrentPlayer({
        nickname: nickname,
        nicknameId: nicknameId,
      });
      setTagLoading(false);
    } else {
      showAlert('success', 'Player has been successfully untagged.');
      setCurrentPlayer(null);
      setUntagLoading(false);
    }
    if (updateUiAfterTaggingSession) updateUiAfterTaggingSession(updatedSession);
    setActionType('terminalInfo');
  };

  const handleTagUnknown = (update: string, clientId: string, session: string, taggedNickname?: string, nickname?: string, nicknameId?: string | null) => {
    showAlert('warning', `Unable to confirm player ${nickname? '' : 'un'}tagging. If session has not updated, please retry.`);
    const player = !nickname ? null : {
      nickname: nickname,
      nicknameId: nicknameId,
    };
    setCurrentPlayer(player);
    setUntagLoading(false);
    setTagLoading(false);
    setActionType('terminalInfo');
    logError(`Failed updateMatchesClientAndSession check. Params were: ( update: ${update}, clientId: ${clientId}, session: ${session},  ${taggedNickname ? 'taggedSession.nickname: ' + taggedNickname: ''} )`, 'medium');
  };

  const onTagPlayerClicked = async () => {
    
    setTagLoading(true);
    let pending = true; //locally scoped boolean for timeout logic below, doesn't work with above state variable
    PubSub.publish('app.alert.drawer.player', null);

    try {
      const playerToTag = players.find((player: Player) => player.nickname === selectedPlayer);

      // This error CAN occur when there are no players in the list or none selected
      if (!playerToTag) {
        throw new Error('Unable to find selected player in list of Players');
      }

      // This error SHOULD never occur!
      if (!playerToTag.nicknameId) {
        throw new Error('Unable to tag player as selected player does not have a nicknameId');
      }

      let taggedSession: any;

      if (session.thingType === 'gm') {
        const machine = machines.find((machine) => machine.machineId === session.deviceUuid)?.machineState;
        taggedSession = {
          ...machine,
          nickname: playerToTag.nickname,
          nicknameId: playerToTag.nicknameId,
          deviceUuid: session.deviceUuid,
          sessionId: session.sessionId,
          machineId: session.machineId || session.terminalId,
          thingType: 'gm',
          shopId: session.shopId,
          shopDeviceIndex: session?.deviceUuid?.split('-')[2],
        };
      } else {
        taggedSession = {
          ...session,
          machineId: makeMachineId(session.thingType, session.shopId, session.shopDeviceIndex) || session.machineId || session.terminalId,
          nickname: playerToTag.nickname,
          nicknameId: playerToTag.nicknameId,
        };
      }

      const clientId = `backoffice-${session.shopId}`;
      if (session.isLiveSession && !session.deviceUuid?.includes('gm')) {
        subscription = getPubSubIot().subscribe(`cmd/device/${taggedSession.deviceUuid}/session/res`, { provider: 'AWSIoTProvider' }).subscribe({
          next: (data: any) => {
            const update = data?.value || data;
            console.log('onTagPlayerClicked subscription', update);
            subscription.unsubscribe();
            if (updateMatchesClientAndSession(update, clientId, session)) {
              if (updateIsSuccessful(update)) {
                handleTagSuccess(taggedSession, playerToTag.nickname, playerToTag.nicknameId);
                pending=false;
              } else {
                console.error('live subscription tagging service error', update);
                handleTagServiceError(`Player was not tagged to session. Error message was: ${update?.res?.message}`, playerToTag.nickname);
                pending=false;
              }
            } else {
              handleTagUnknown(JSON.stringify(update), clientId, JSON.stringify(session), taggedSession.nickname, playerToTag.nickname, playerToTag.nicknameId);
              pending=false;
            }
          },
          error: (error: any) => {
            console.error('live subscription tagging network error', error);
            subscription.unsubscribe();
            handleTagNetworkError(`Connection lost. Error message was: ${error.error.toString()}`, playerToTag.nickname, error);
            pending=false;
          },
        });
        await onTagSession(makeTagParams(taggedSession)).then(() => {
          setTimeout(() => {
            if (pending) {
              handleTagNoReplyError('Timeout waiting for session to be tagged.', playerToTag.nickname);
              pending=false;
            }
          }, 10000);
        });
      } else {
        await onTagSession(makeTagParams(taggedSession)).then(() => {
          handleTagSuccess(taggedSession, playerToTag.nickname, playerToTag.nicknameId);
          pending=false;
        });
      }
    }
    catch (err) {
      setTagLoading(false);
      pending=false;
      PubSub.publish('app.error.drawer.player', err);
    }
  };

  const onUntagPlayerClicked = async () => {
    setUntagLoading(true);
    let pending = true; //locally scoped boolean for timeout logic below, doesn't work with above state variable

    try {
      const unTaggedSession = {
        ...session,
        machineId: makeMachineId(session.thingType, session.shopId, session.shopDeviceIndex) || session?.terminalId || session.machineId,
        nickname: null,
        nicknameId: null,
      };

      const clientId = `backoffice-${session.shopId}`;

      if (session.isLiveSession) {
        subscription = getPubSubIot().subscribe(`cmd/device/${unTaggedSession.deviceUuid}/session/res`, { provider: 'AWSIoTProvider' }).subscribe({
          next: (data: any) => {
            const update = data?.value || data;
            console.log('onUntagPlayerClicked subscription', update);
            subscription.unsubscribe();
            if (updateMatchesClientAndSession(update, clientId, session)) {
              if (updateIsSuccessful(update)) {
                handleTagSuccess(unTaggedSession);
                pending=false;
              } else {
                console.error('live subscription tagging service error', update);
                handleTagServiceError(`Player was not untagged from session. Error message was: ${update?.res?.message}`);
                pending=false;
              }
            } else {
              handleTagUnknown(JSON.stringify(update), clientId, JSON.stringify(session));
              pending=false;
            }
          },
          error: (error: any) => {
            console.error('live subscription untagging network error', error);
            subscription.unsubscribe();
            handleTagNetworkError(`Connection lost. Error message was: ${error.error.toString()}`, undefined, error);
            pending=false;
          },
        });

        await onUntagSession(makeUntagParams(unTaggedSession)).then(() => {
          setTimeout(() => {
            if (pending) {
              handleTagNoReplyError('Timeout waiting for session to be untagged.');
              pending=false;
            }
          }, 10000);
        });
      } else {
        await onUntagSession(makeUntagParams(unTaggedSession)).then(() => {
          handleTagSuccess(unTaggedSession);
          pending=false;
        });
      }
    }
    catch (err) {
      setUntagLoading(false);
      pending=false;
      PubSub.publish('app.error.drawer.player', err);
    }
  };

  const onInputChange = (e: any) => {
    const { value } = e.target;
    setSearchInput(value);

    let filtered = sanitisedPlayerList.filter((p: Player) => p.nickname.toLowerCase().includes(value.toLowerCase()));

    if (filtered && filtered.length === 0 && value === '') {
      filtered = sanitisedPlayerList;
    }

    setFilteredPlayers(filtered);
  };

  const handleOptionSelected = (option: string) => {
    setSelectedPlayer(option);
  };

  return (
    <>
      {currentPlayer?.nickname && (
        <>
          <Label padding='24px 24px 8px' color='grey900' fontSize='20px'>Current Player</Label>
          <Value padding='0 24px 8px' color='grey500' fontSize='24px'>{currentPlayer.nickname}</Value>
        </>
      )}

      <Label data-test-id='search-player-title' padding='24px 24px 8px' color='grey900' fontSize='20px'>Search Player</Label>
      <Flex>
        <Input borderColor='grey300' autoFocus value={searchInput} onChange={onInputChange} placeholder='Enter minimum 2 characters' margin='0 24px 24px' padding='11px' wide />
      </Flex>

      <Flex alignItems='center' padding='0 24px 24px' gap='8px'>
        <Label color='grey900' fontSize='20px'>Players &nbsp;</Label>
        <Badge text={filteredPlayerNames ? filteredPlayerNames.length.toString() : '0'} color='secondary400' textColor='white' borderRadius='4px' />
        <Button data-test-id='add-player-button' onClick={onAddPlayer} primary background='grey100' borderless color='primary600' margin='0 0 0 auto'>Add Player</Button>
      </Flex>

      <SelectableList
        dataTestId = 'player-list'
        fontSize='20px'
        handleOptionSelected={handleOptionSelected}
        height='425px'
        itemPadding='12px'
        listMargin='0 24px'
        loading={Boolean(playersLoading)}
        options={filteredPlayerNames || []}
        selectedOption={selectedPlayer}
      />

      {currentPlayer?.nickname ? (
        <>
          <Flex padding='12px 24px 8px 24px' gap='8px'>
            <Button onClick={onUntagPlayerClicked} data-test-id='untag-player-button' loading={untagLoading} wide primary color='primary600' background='grey100' borderless>Untag Player</Button>
            <Button onClick={onTagPlayerClicked} data-test-id='change-player-button' loading={tagLoading} disabled={tagLoading || !selectedPlayer} wide secondary>Change Player</Button>
          </Flex>
          <Flex padding='0 24px 24px 24px'>
            <Button onClick={onClose} wide>Cancel</Button>
          </Flex>
        </>
      ) : (
        <Flex padding='24px' gap='8px'>
          <Button data-test-id='cancel-button' onClick={onClose} wide primary color='primary600' background='grey100' borderless>Cancel</Button>
          <Button onClick={onTagPlayerClicked} data-test-id='tag-player-button' loading={tagLoading} disabled={tagLoading || !selectedPlayer} wide secondary>Tag Player</Button>
        </Flex>
      )}
    </>
  );
};

export interface TaggingPlayerContentProps {
  currentPlayer?: PlayerNickname;
  loading: boolean;
  onClose: () => void;
  onTagSession: (props: OnTagSessionParams) => Promise<void>;
  onUntagSession: (props: OnUntagSessionParams) => Promise<void>;
  players: Player[];
  searchInput: string;
  session: SummarySession;
  setActionType: (actionType: string) => void;
  setCurrentPlayer: (player: any) => void;
  setSearchInput: (actionType: string) => void;
  updateUiAfterTaggingSession?: (session: SummarySession) => void;
}

export default TaggingPlayerContent;
