import { useState, useEffect } from 'react';
import { getPubSubIot } from 'aws/Aws';
import { PubSub } from 'aws-amplify';

interface CashinResponse {
  meta: {
    message_correlation_id: string;
    routing: unknown;
    timing: {
      published_at: string;
      received_at: string;
      start: string;
    },
  },
  payload: unknown;
}

export interface CashinSuccessResponse extends CashinResponse {
  payload: {
    account_id: string;
    amount: number;
    balance: number;
    barcode?: string;
    bonus_balance: number;
    tags: Record<string, string>
    transaction_correlation_id: string;
  }
}

export interface CashinErrorResponse extends CashinResponse {
  payload: {
    account_id: string;
    error_message: string;
    error_code: string;
    transaction_correlation_id: string;
    amount: number;
    tags: Record<string, string>;
    cashout_type: unknown;
    barcode?: string;
  }
}

const isCashinResponse = (response: unknown): response is CashinResponse => {
  if (typeof response !== 'object' || response === null) {
    return false;
  }

  if (!('meta' in response) || !('payload' in response)) {
    return false;
  }

  return true;
};

const isCashinErrorResponse = (response: CashinResponse): response is CashinErrorResponse => {
  return typeof response.payload === 'object' 
    && response.payload !== null  
    && 'error_message' in response.payload;
};

const isCashinSuccessResponse = (response: CashinResponse): response is CashinSuccessResponse => {
  return !isCashinErrorResponse(response);
};

export interface CashinResponses {
  success: CashinSuccessResponse[],
  error: CashinErrorResponse[],
}

const useCashinResponse = (machineId: string) => {
  const [cashinResponses, setCashinResponses] = useState<CashinResponses>({
    success: [],
    error: [],
  });

  useEffect(() => {
    const pubsub = getPubSubIot() as typeof PubSub;
    const topics = [`${machineId}/cashin/response/+`];
    
    const sub = pubsub
      .subscribe(topics)
      .subscribe((msg) => {
        // depends on what getPubSubIot() returns
        const value = 'meta' in msg && 'payload' in msg ? msg : msg.value;

        if (!isCashinResponse(value)) {
          console.log('Unhandled response, ', value);
          return;
        }

        if (isCashinErrorResponse(value)) {
          setCashinResponses(prev => ({
            error: [...prev.error, value],
            success: prev.success,
          }));

          return;
        } else if (isCashinSuccessResponse(value)) {
          setCashinResponses(prev => ({
            error: [...prev.error],
            success: [...prev.success, value],
          }));

          return;
        }

        console.log('Unhandled response, ', value);
      });
    
    return () => {
      sub.unsubscribe();
    };
  }, [machineId]);

  const removeCashinResponse = (transactionId: string) => {
    setCashinResponses(prev => ({
      success: prev.success.filter(r => r.payload.transaction_correlation_id !== transactionId),
      error: prev.error.filter(r => r.payload.transaction_correlation_id !== transactionId),
    }));
  };

  return {
    cashinResponses,
    removeCashinResponse, 
  };
};

export default useCashinResponse;
