import { Check, Critical, IconProps, Info, Printer, Warning } from 'components/Icon';
import { ListItem, MessageContainer } from './Message.styled';

const Message = ({ children, iconName, type, onClick, noClick = false }: MessageProps) => {

  let Icon;

  switch (type) {
    case 'success':
      Icon = Check;
      break;

    case 'warning':
      Icon = Warning;
      break;

    case 'error':
      Icon = Critical;
      break;

    default:
      Icon = Info;
      break;
  }

  if (iconName === 'printer') {
    Icon = Printer;
  }

  if (noClick) {
    return (
      <MessageContainer  data-test-id= 'message-modal' type={type}>
        <span><Icon /></span>
        {children}
      </MessageContainer>
    );
  } else {
    return (
      <ListItem onClick={onClick}>
        <MessageContainer  data-test-id= 'message-modal' type={type}>
          <span><Icon /></span>
          {children}
        </MessageContainer>
      </ListItem>
    );
  }
};

interface MessageProps {
  type: 'warning' | 'error' | 'success',
  children: React.ReactNode,
  iconName: IconProps['name']
  onClick?: () => void
  noClick?: boolean
}

export default Message;
