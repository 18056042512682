import styled from 'styled-components';

type ToggleProps = {
  background: string,
}

type ToggleTextProps = {
  selected: boolean,
}

export const ToggleStyled = styled.div<ToggleProps>`
  background: ${props => props.background};
  border-radius: 8px;
  height: 90px;
  padding: 20px 22px;
  width: 148px;
`;

export const ToggleTitle = styled.div<ToggleTextProps>`
  color: ${props => props.selected ? 'white' : props.theme.colors.grey500};
  font-size: 18px;
  line-height: 18px;
`;

export const ToggleIndex = styled.div<ToggleTextProps>`
  color: ${props => props.selected ? 'white' : 'black'};
  font-size: 24px;
  line-height: 24px;
  margin: 8px 0px;
`;