import { createGlobalStyle } from 'styled-components';
import WHBold from 'assets/fonts/whhoxton-bold.woff2';
import WHRegular from 'assets/fonts/whhoxton-regular.woff2';

const GlobalStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
  }

  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
      display: block;
  }

  html {
    min-height: 100vh;
  }

  body {
    line-height: 1;
    min-height: 100vh;
  }

  #root {
    min-height: 100vh;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  @font-face {
    font-family: 'WHHoxton-bold';
    src: url(${WHBold}) format('woff2');
  }
    
  @font-face {
    font-family: 'WHHoxton-regular';
    src: url(${WHRegular}) format('woff2');
  }

  body {
    font-family: WHHoxton-regular;
  }
  .ant-table-cell {
    vertical-align: middle;
  }
`;

export default GlobalStyle;
