
import { useEffect, useState, useMemo } from 'react';
import { cloudWatchLogs } from 'aws/Aws';
import { GetLogsEventsTypes , LogGameType, GroupedGameLogTypes } from 'types/logs/Logs';
import { useCloudWatchMachines } from './useCloudWatchMachines';

import { retailCurrency } from 'retail-currency';

const logGroupName = 'gaming-retail/things/games';

const useGamingLogs = () => {
  const {
    machinesFilters, selectedTerminal, setSelectedTerminal, onTerminalMenuItemClickHandler,
  } = useCloudWatchMachines(logGroupName);
  const [logs, setLogs] = useState<GroupedGameLogTypes>({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [nextLogsToken, setNextLogsToken] = useState('');

  const gamingLogs = useMemo(() => logs, [logs]);

  const formatToMatchTableLogs = (event: LogGameType) => {
    //@ts-ignore
    event.message = event.message ? JSON.parse(event.message) : '';
    event.date = new Date(event.timestamp).toLocaleDateString('en-GB');
    //@ts-ignore
    event.message.stake = retailCurrency.format(event.message.stake);
    event.message.start_time = event.message.start_time ? event.message.start_time.slice(11,19) : '';
    event.message.end_time = event.message.end_time ? event.message.end_time.slice(11,19) : '';
    event.message.game_log = '>';

    return event;
  };

  const onNextLogButtonHandler = (nextLogsToken?: string) => {
    const endTime = new Date(selectedDate).setHours(23,59,59,999);
    const startTime = new Date(selectedDate).setHours(0,0,0,0);
    
    let getLogsParams: GetLogsEventsTypes = {
      logGroupName,
      logStreamName: selectedTerminal,
      endTime,
      startTime,
      startFromHead: true,
    };

    if (nextLogsToken) {
      getLogsParams = {
        ...getLogsParams,
        nextToken: nextLogsToken,
      };
    }
    cloudWatchLogs.getLogEvents(getLogsParams, (err, data) => {
      if (err) {
        console.log(err);
        return;
      }
      if (data?.events) {
        console.log(data?.events, 'gaming logs fetched');
        //@ts-ignore
        const finalLogVersion: LogGameType[] = data.events.map(event => {
          //@ts-ignore
          const e = formatToMatchTableLogs(event);
          return { ...e };
        });
        const groupedByGame: GroupedGameLogTypes = {};

        finalLogVersion.forEach((element: LogGameType) => {
          const logGroupId = element?.message?.log_group_id;

          if (!groupedByGame[logGroupId]) {
            groupedByGame[logGroupId] = [element];
          } else {
            groupedByGame[logGroupId].push(element);
          }
        });
        // win value is also not present in all logs of a game
        for (const key in groupedByGame) {
          const elementWithWinValueFilter = groupedByGame[key].filter(element => element.message.win !== undefined);
          const [ firstLogEntry, logEntryWithWin, lastLogEntry ] = [groupedByGame[key][0], elementWithWinValueFilter[elementWithWinValueFilter.length - 1], groupedByGame[key][groupedByGame[key].length - 1]];

          if (elementWithWinValueFilter.length > 0) {
            firstLogEntry.message.win = logEntryWithWin.message.win;
            firstLogEntry.message.stake = lastLogEntry.message.stake;   
          } else {
            firstLogEntry.message.stake = '-';
          }
          firstLogEntry.message.end_time = lastLogEntry.message.end_time;
          firstLogEntry.message.start_time = lastLogEntry.message.start_time;

        }
        // needed to make sure Backoffice user who refreshes logs during ongoing game, ongoing game logs are ammended
        Object.keys(groupedByGame).forEach((key: string) => {
          if (Object.keys(logs).includes(key)) {
            logs[key] = [...logs[key], ...groupedByGame[key]];
          }
        });

        nextLogsToken ? setLogs({
          ...groupedByGame, 
          ...logs,
        }) : setLogs(groupedByGame);
        data?.nextForwardToken && setNextLogsToken(data.nextForwardToken);
      }
    });
  };

  useEffect(() => {
    if (selectedTerminal) {
      setNextLogsToken('');
      onNextLogButtonHandler();
    }

  }, [selectedTerminal, selectedDate]);

  useEffect(() => {
    if (nextLogsToken) {
      onNextLogButtonHandler(nextLogsToken);
    }
  } , [nextLogsToken]);
  
  return {
    machinesFilters,
    selectedTerminal,
    setSelectedTerminal,
    gamingLogs,
    nextLogsToken,
    onTerminalMenuItemClickHandler,
    selectedDate,
    setSelectedDate,
    onNextLogButtonHandler,
  };
};

export default useGamingLogs;
