import { useContext } from 'react';
import { ConnectionStatusContext } from 'providers/ConnectionStatusProvider';

const useConnectionStatus = () => {
  const context = useContext(ConnectionStatusContext);

  if (!context) {
    throw new Error('useConnectionStatus must be used within a ConnectionStatusProvider');
  }

  return context;
};

export default useConnectionStatus;
