import { DefaultTheme } from 'styled-components';
import {
  IconWrapper,
  InputWrapper,
  Input as InputStyled,
  TextArea as TextAreaStyled,
} from './Input.styled';

type Color = keyof DefaultTheme['colors']

type InputProps = {
  autoFocus?: boolean;
  background?: string;
  borderColor: Color
  children?: string;
  disabled?: boolean;
  id?: string;
  fontSize?: string;
  height?: string;
  margin?: string;
  name?: string;
  onBlur?: (e: any) => void;
  minlength?: number;
  maxlength?: number;
  onChange?: (e: any) => void;
  padding?: string;
  prefix?: React.ReactNode;
  placeholder?: string;
  value?: string;
  wide?: boolean;
  dataTestId?: string;
  position?: string;
  type?: string;
}

const Input = ({ autoFocus, background, borderColor, dataTestId, disabled, id, fontSize, height, margin, minlength, maxlength, name, onBlur, onChange, padding, placeholder, position, prefix, type, value, wide }: InputProps) => {

  const StyledComponent: React.ElementType = type ? InputStyled : TextAreaStyled;
  return (
    <InputWrapper
      background={background}
      borderColor={borderColor}
      data-test-id='input-wrapper'
      height={height}
      margin={margin}
      position={position}
      wide={wide}
    >
      {prefix && <IconWrapper>{prefix}</IconWrapper>}
      <StyledComponent
        autoFocus={autoFocus}
        data-test-id={dataTestId}
        disabled={disabled}
        fontSize={fontSize}
        id={id}
        minLength={minlength}
        maxLength={maxlength}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        padding={padding}
        placeholder={placeholder}
        type={type}
        value={value}
      />
    </InputWrapper>
  );
  
};

export default Input;
