import styled, { css } from 'styled-components';

interface EditIconInterface {
  disabled: boolean;
}

export const EditIconStyled = styled.div<EditIconInterface>`
  ${(props) => props.disabled && css`
    path {
      fill: ${(props) => props.theme.colors.grey300};
    }
  `}
`;
