import styled from 'styled-components';
import Flex from 'styled/Flex';
import TextCell from './TextCell';
import { Edit1 } from 'components/Icon';
import { SessionHistory } from 'types/session/SummarySession';
import { retailCurrency } from 'retail-currency';

const EditIcon = styled((props) => <Edit1 {...props} />)`
  display: flex;
  flex-shrink: 0;
  height: 24px;
  margin-right: 12px;
  margin-top: 3px;
  width: 24px;
`;

const LimitsCell = ({ data }: LimitsCellProps) => {
  const { monetaryLimit, timeLimitInMin } = data;
  const monetaryLimitSet = monetaryLimit > 0;
  const timeLimitSet = timeLimitInMin > 0;

  return (
    <Flex alignItems='center' justifyContent='space-between'>
      <TextCell color={monetaryLimitSet ? '#2a5fc9' : '#000' } backgroundColor={monetaryLimitSet ?'#f3f4f7' : 'inherit' }>{monetaryLimitSet ? `${retailCurrency.format(monetaryLimit / 100)}` : 'Not set'}</TextCell>
      -
      <TextCell color={timeLimitSet ? '#2a5fc9' : '#000' } backgroundColor={timeLimitSet ?'#f3f4f7' : 'inherit' }>{timeLimitSet ? timeLimitInMin : 20} min</TextCell>
    </Flex>
  );

};

type LimitsCellProps = {
  data: SessionHistory;
}

export default LimitsCell;
