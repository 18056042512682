export const azSorter = (a?: string | null, b?: string | null) => {
  const nameA = a ? a.toLowerCase() : '';
  const nameB = b ? b.toLowerCase() : '';
  if (nameA < nameB)
    return -1;
  if (nameA > nameB)
    return 1;
  return 0;
};

export const dateSorter = (a?: Date | null, b?: Date | null) => {
  const dateA = a ? new Date(a).getTime() : new Date().getTime();
  const dateB = b ? new Date(b).getTime() : new Date().getTime();
  return dateA - dateB;
};
