import { BackofficeAlertWithDate } from 'types/alert/GeneralAlert';
import { retailCurrency } from 'retail-currency';

export const shouldBeClosedAutomatically = (alert: BackofficeAlertWithDate) => [
  'Set Custom Limit',
  'Cancel Custom Limit',
  'mandatory time limit reached',
].includes(alert.name);

export const shouldUpgradeItsSeverityAutomatically = (alert: BackofficeAlertWithDate) => [
  'Custom Time limit breach',
  'Custom Loss limit breach',
  'APAS - Chaotic Play alert',
  'APAS - Extended Loss alert',
  '60 minute play',
  'Large Bet',
  `15 mins time OR ${retailCurrency.symbol()}100 spend`,
  'loss limit reached',
].includes(alert.name);

export const shouldBeDismissedAWhileAfterSessionEnds = (alert: BackofficeAlertWithDate) => [
  'Custom Time limit breach',
  'Custom Loss limit breach',
  'APAS - Chaotic Play alert',
  'APAS - Extended Loss alert',
  '60 minute play',
  'Large Bet',
  `15 mins time OR ${retailCurrency.symbol()}100 spend`,
  'mandatory time limit reached',
  'loss limit reached',
].includes(alert.name);
