import PubSub from 'pubsub-js';

import { AddPlayerResponse } from 'types/response/AddPlayerResponse';
import { Player, PlayerNickname } from 'types/Player';
import { getConstant } from 'utils/constants';
import { logError } from 'newrelic';
import { throwErrorIfBadResponse } from 'utils/fetchHelpers';
import { MergePlayerResponse, UpdatePlayerResponse } from 'types/ApiTypes';

export const getPlayerById = async (nicknameId?: string): Promise<Player> => {
  if (!nicknameId) {
    return {} as Player;
  }
  const endpoint = getConstant('REACT_APP_RETAIL_SESSION_URL');
  const url = `${endpoint}/sessions/mysql/players/${nicknameId}`;

  try {
    const response = await fetch(url);
    return throwErrorIfBadResponse(response);
  } catch (err) {
    logError(`Failed to get player by ID: ${err}`, 'high', err);
    throw new Error('Failed to get player by ID');
  }
};

export const deletePlayer = async (nicknameId?: string): Promise<UpdatePlayerResponse> => {
  if (!nicknameId) {
    return {
      message: 'No player was provided to deletePlayer request',
    } as UpdatePlayerResponse;
  }
  const deletedPlayer = {
    nicknameId,
    deleted: 1,
  };
  const endpoint = getConstant('REACT_APP_RETAIL_SESSION_URL');
  const url = `${endpoint}/sessions/mysql/players/${nicknameId}`;

  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(deletedPlayer),
  };

  try {
    const response = await fetch(url, requestOptions);
    return throwErrorIfBadResponse(response);
  } catch (err) {
    logError(`Failed to delete player: ${err}`, 'high', err);
    throw new Error('Failed to delete player');
  }
};

export const updatePlayer = async (player?: Player): Promise<UpdatePlayerResponse> => {
  if (!player) {
    return {
      message: 'No player was provided to updatePlayer request',
    } as UpdatePlayerResponse;
  }
  const { nicknameId } = player;
  const endpoint = getConstant('REACT_APP_RETAIL_SESSION_URL');
  const url = `${endpoint}/sessions/mysql/players/${nicknameId}`;

  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(player),
  };

  try {
    const response = await fetch(url, requestOptions);
    return throwErrorIfBadResponse(response);
  } catch (err) {
    logError(`Failed to update player: ${err}`, 'high', err);
    throw new Error('Failed to update player');
  }
};

export const mergePlayers = async (playerToKeep: PlayerNickname, playerToMerge: PlayerNickname): Promise<MergePlayerResponse> => {
  const playerToKeepId = playerToKeep?.nicknameId;
  const playerToMergeId = playerToMerge?.nicknameId;
  if (!playerToKeepId || !playerToMergeId) {
    return {
      message: 'Two players with nicknameIds were not prodived to merge player request',
    } as MergePlayerResponse;
  }
  const endpoint = getConstant('REACT_APP_RETAIL_SESSION_URL');
  const url = `${endpoint}/sessions/mysql/players/merge`;

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      playerToKeepId,
      playerToMergeId,
    }),
  };

  try {
    const response = await fetch(url, requestOptions);
    return throwErrorIfBadResponse(response);
  } catch (error) {
    logError(`Failed to merge player : ${playerToMergeId} into player: ${playerToKeepId}`);
    throw new Error('Failed to merge players');
  }
};

export const getPlayersForShop = async (shopId: string): Promise<Player[]> => {
  if (!shopId) {
    return [];
  }

  const endpoint = getConstant('REACT_APP_RETAIL_SESSION_URL');
  const url = `${endpoint}/sessions/mysql/players?shopId=${shopId}`;

  try {
    const response = await fetch(url);
    return throwErrorIfBadResponse(response);
  } catch (err) {
    logError(`Failed to get players for shop: ${err}`, 'high', err);
    throw new Error('Failed to get players for shop');
  }
};

export const addPlayerToShop = async (nickname: string, shopId: string, description: string, comments: string, lastSgiAt?: Date): Promise<AddPlayerResponse> => {
  if (!shopId) {
    return {} as AddPlayerResponse;
  }

  const endpoint = getConstant('REACT_APP_RETAIL_SESSION_URL');
  const url = `${endpoint}/sessions/mysql/players`;
  const body = {
    nickname,
    shopId,
    description,
    comments,
    lastSgiAt,
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return throwErrorIfBadResponse(response);
  } catch (err) {
    console.error(err);
    PubSub.publish('app.error.drawer.player', `Failed to add '${nickname}' to shop`);
    throw new Error(`Failed to add player to shop using, url: ${url} and body: ${body}`);
  }
};
