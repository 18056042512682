import styled from 'styled-components';
import Flex from 'styled/Flex';
import { IconWrapper, Load } from 'components/Icon';

const SpinningIcon = styled((props) => <Load {...props}/>)`
  animation-name: rotate-s-loader;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  animation-timing-function: linear;
  position: relative;

  @keyframes rotate-s-loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const LoadingSpinner = ({ text }: Props) => {
  return (
    <Flex data-test-id='loading-spinner' justifyContent='center' alignItems='center'>
      <IconWrapper iconName='load-icon'><SpinningIcon /></IconWrapper>
      {text}
    </Flex>
  );
};

interface Props {
  text?: string
}

export default LoadingSpinner;