import { createContext, useState, useEffect, memo } from 'react';

export type StatusTypes = 'online' | 'offline';

export const useConnectionStatusProvider = () => {
  const [connectionStatus, setConnectionStatus] = useState<StatusTypes>('online');

  const changeConnectionStatus = (status: StatusTypes) => {
    setConnectionStatus(status);
  };

  async function isOnline () {
    if (!window.navigator.onLine) {
      return false;
    }
  
    const url = new URL(window.location.origin);
  
    url.searchParams.set('rand', new Date().getTime().toString());
  
    try {
      const response = await fetch(
        url.toString(),
        { method: 'HEAD' },
      );
  
      return response.ok;
    } catch {
      return false;
    }
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      const response = await isOnline();
      if (response) {
        return changeConnectionStatus('online');
      }
      changeConnectionStatus('offline');
    }, 1800000); //temporarily increased from 2s to 30min to reduce firing of expensive AWS requests
    return () => clearInterval(interval);
  }, []);

  return {
    connectionStatus,
    changeConnectionStatus,
    isOnline,
  };
};

export const ConnectionStatusContext = createContext<ReturnType<typeof useConnectionStatusProvider> | null>(null);

type ConnectionStatusProviderProps = {
  children: React.ReactNode;
};

const ConnectionStatusProvider = ({ children }: ConnectionStatusProviderProps) => {
  const { connectionStatus, changeConnectionStatus, isOnline } = useConnectionStatusProvider();

  return <ConnectionStatusContext.Provider value={{
    connectionStatus,
    changeConnectionStatus,
    isOnline,
  }}>{children}</ConnectionStatusContext.Provider>;
};

export default memo(ConnectionStatusProvider);
