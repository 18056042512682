import moment from 'moment';
import Tag from '../../Tag';
import { PlayerEntryDateStyled } from './PlayerEntryDate.styled';

interface PlayerEntryDateProps {
  date?: Date | null;
  label: string;
}

const PlayerEntryDate = ({ date, label }: PlayerEntryDateProps) => {

  return (
    <PlayerEntryDateStyled data-test-id='player-entry-date'>
      {label}
      <Tag label={(date && moment(date).format('DD/MM/YY')) || '--/--/--'}/>
    </PlayerEntryDateStyled>
  );
};

export default PlayerEntryDate;