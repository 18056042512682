import styled from 'styled-components';

export const LayoutOverlay = styled.section`
  display: flex;
  min-height: 100vh;
`;

export const LayoutAside = styled.aside`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.secondary600};
  width: 75px;
`;

export const LayoutContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
