import { CashReportTileContainer } from './CashReportDashboard.styled';
import Flex from 'styled/Flex/Flex';
import { Divider } from 'components/Divider';
import { Text } from 'components/Text';
import { retailCurrency } from 'retail-currency';

interface CashReportTileProps {
  name: string
  total: number
  tillNumber?: string
}

const CashReportTile = (props: CashReportTileProps): JSX.Element => {
  const { name, total, tillNumber = '' } = props;

  return (
    <CashReportTileContainer>
      <Flex direction='column'>
        <Flex>
          { tillNumber && <Text margin='0px 0px 0px 18px' fontWeight='700'>{tillNumber.padStart(2,'0')}</Text> }
          <Text margin='0px 18px'>{ name }</Text>
        </Flex>
        <Divider color='grey200' margin='12px 0px' />
        <Text color='grey500' fontSize='18px' lineHeight='18px' margin='18px'>Total</Text>
        <Text margin='0px 18px' lineHeight='18px'>{`${retailCurrency.format(total)}`}</Text>
      </Flex>
    </CashReportTileContainer>
  );
};

export { CashReportTile };
