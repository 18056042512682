import useVouchers from './useVouchers';

const useVoucher = (uid: string) => {
  const { vouchers, mutateVoucher, ...rest } = useVouchers({ uid });

  return {
    mutateVoucher,
    voucher: vouchers ? vouchers[0] : undefined,
    ...rest,
  };
};

export default useVoucher;
