import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import LabelledInput from '../../LabelledInput';
import { PlayerBioStyled, PlayerNames } from './PlayerBio.styled';
import { PlayerFormValues } from 'types/Player';

interface PlayerBioProps {
  isEditMode?: boolean;
  formValues: PlayerFormValues;
  onInputChange: (inputName: keyof PlayerFormValues, inputValue: string) => [string, string] | void;
  validationErrors: Record<string, string>;
}

const PlayerBio = ({ isEditMode, formValues, onInputChange, validationErrors }: PlayerBioProps) => {
  const { nickname, fullName } = formValues;
  return (
    <PlayerBioStyled data-test-id='player-bio'>
      <Avatar data-test-id='player-avatar' shape='square' size={113} icon={<UserOutlined />} />
      <PlayerNames>
        <LabelledInput charLimit={50} isEditMode={isEditMode} validationErrors={validationErrors} inputHeight='52px' label='Nom de plume' onInputChange={onInputChange} value={nickname}/>
        <LabelledInput charLimit={50} isEditMode={isEditMode} validationErrors={validationErrors} inputHeight='52px' label='Full name' onInputChange={onInputChange} value={fullName || undefined} />
      </PlayerNames>
    </PlayerBioStyled>
  );
};

export default PlayerBio;