import styled, { css } from 'styled-components';

interface StyledListItemProps {
  selected?: boolean;
}

export const MultiSelectListStyled = styled.div`
  background-color: white; 
  border-radius: 8px; 
  box-shadow: 0px 0px 16px rgba(16, 24, 40, 0.06); 
  display: flex; 
  flex-direction: column; 
  max-height: 86vh; 
  padding: 24px; 
  width: 364px;
`;

export const StyledListItem = styled.li<StyledListItemProps>`
  align-items: center;
  ${(props) => props.selected ? css`background-color: #D8E1FF` : 'white'};
  border-top: 1px solid ${(props) => props.theme.colors.grey200};
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  font-size: 20px;
  justify-content: space-between;
  margin-right: 5px;
  padding: 5px 0 5px 0;
`;

export const StyledListWrapper = styled.div`
  overflow: scroll;

  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.grey300};
    border-radius: 2px;
  }
`;
