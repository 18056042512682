import { useState } from 'react';
import Icon from 'components/Icon';
import { CloseIcon, SelfExclusionDates, SelfExclusionStatus, SelfExclusionStyled, UndoIcon } from './SelfExclusion.styled';
import { PlayerSelfExclusion, PlayerSelfExclusionStatus } from 'types/Player';
import { formatShortDate } from 'utils/formatDate';
import { UndoOutlined } from '@ant-design/icons';
import { getSelfExclusionStatusLabel } from 'utils/selfExclusion';

type noParamFunction = () => void;
type deleteSelfExclusionFunction = (id: string, undo?: boolean) => void;

interface SelfExclusionProps {
  dataTestId: string;
  onDelete: noParamFunction | deleteSelfExclusionFunction;
  selfExclusion: PlayerSelfExclusion;
}
  
const SelfExclusion = ({ dataTestId, onDelete, selfExclusion }: SelfExclusionProps) => {
  const [isToBeDeleted, setIsToBeDeleted] = useState(false);
  const { selfExclusionId, selfExclusionValidFrom, selfExclusionValidTo } = selfExclusion;
  const statusLabel = getSelfExclusionStatusLabel(selfExclusionValidTo);
  const status = statusLabel === 'In Review' ? 'review' : statusLabel.toLocaleLowerCase() as PlayerSelfExclusionStatus;

  const handleUndoDelete = () => {
    onDelete(selfExclusionId, true);
    setIsToBeDeleted(false);
  };

  const handleDelete = () => {
    onDelete(selfExclusionId);
    setIsToBeDeleted(true);
  };

  return (
    <SelfExclusionStyled data-test-id={dataTestId}>
      <SelfExclusionDates data-test-id='self-exclusion-dates' isToBeDeleted={isToBeDeleted}>
        {isToBeDeleted
          ? <UndoIcon data-test-id='self-exclusion-undo-button' onClick={handleUndoDelete}><UndoOutlined /></UndoIcon>
          : <CloseIcon data-test-id='self-exclusion-delete-button' onClick={handleDelete}><Icon name='close' height={24} width={24}/></CloseIcon>
        }
        {formatShortDate(selfExclusionValidFrom)} - {formatShortDate(selfExclusionValidTo)}
      </SelfExclusionDates>
      {selfExclusionId === 'unsaved' && <div data-test-id='self-exclusion-adding-unsaved'>Adding - unsaved</div>}
      {isToBeDeleted && <div data-test-id='self-exclusion-deleting-unsaved'>Deleting - unsaved</div>}
      <SelfExclusionStatus data-test-id={`self-exclusion-status-${status}`} status={status}>{statusLabel}</SelfExclusionStatus>
    </SelfExclusionStyled>
  );
};

export default SelfExclusion;