import Button from 'components/Buttons';
import { AlertContainer, AlertType, AlertButtons, AlertMessageContainer, AlertMessage } from './Alert.styled';

interface AlertProps {
  message: string,
  type: AlertType,
  primaryAction?: [string, () => void];
  secondaryAction?: [string, () => void];
}

const Alert = ({ message, type, primaryAction, secondaryAction }: AlertProps) => {
  return (
    <AlertContainer type={type}>
      <AlertMessageContainer>
        <AlertMessage data-test-id='alert-message'>{message}</AlertMessage>
      </AlertMessageContainer>

      <AlertButtons>
        {primaryAction && <Button secondary onClick={primaryAction[1]} data-test-id='primary-button'>{primaryAction[0]}</Button>}
        {secondaryAction && <Button primary onClick={secondaryAction[1]} data-test-id='secondary-button'>{secondaryAction[0]}</Button>}
      </AlertButtons>
    </AlertContainer>
  );
};

export default Alert;
