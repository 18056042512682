import { useEffect, useState, useMemo } from 'react';
import { cloudWatchLogs } from 'aws/Aws';
import { MenuInfo } from 'rc-menu/lib/interface';
import { GetLogsEventsTypes, LogEventType, EventTypes, EventMenuItemType } from 'types/logs/Logs';
import { useCloudWatchMachines } from './useCloudWatchMachines';
import uniqWith from 'lodash.uniqwith';
import isEqual from 'lodash.isequal';

const eventTypes: EventMenuItemType[] = [{
  label: 'all',
  key: 0, 
},{
  label: 'player',
  key: 1, 
}, {
  label: 'maintenance',
  key: 2, 
}, {
  label: 'cash',
  key: 3, 
}, {
  label: 'game',
  key: 4, 
}];

const logGroupName = 'gaming-retail/things/events';

const useEventLogs = () => {
  const {
    machinesFilters, selectedTerminal, setSelectedTerminal, onTerminalMenuItemClickHandler,
  } = useCloudWatchMachines(logGroupName);

  const [logs, setLogs] = useState<LogEventType[]>([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedEventType, setSelectedEventType] = useState<EventTypes>('all');
  const [nextLogsToken, setNextLogsToken] = useState('');

  const onEventsMenuItemClickHandler = (i: MenuInfo) => {
    const key = Number(i.key);
    const label: EventTypes = eventTypes[key].label;

    setSelectedEventType(label);
  };

  const FILTER_MAP = {
    all: () => true,
    byDate: (logEvent: LogEventType) => logEvent.date === new Date(selectedDate).toLocaleDateString('en-GB'),
    byType: (logEvent: LogEventType) => selectedEventType === 'all' ? true : logEvent.message.event_type === selectedEventType,
  };

  const filtredLogs = useMemo(
    () => logs.filter(FILTER_MAP['byDate']).filter(FILTER_MAP['byType']), 
    [logs, selectedDate, selectedTerminal, selectedEventType]
  );

  const onNextLogButtonHandler = (nextLogsToken?: string) => {
    const endTime = new Date(selectedDate).setHours(23,59,59,999);
    const startTime = new Date(selectedDate).setHours(0,0,0,0);

    const getLogsParams: GetLogsEventsTypes = {
      logGroupName,
      logStreamName: selectedTerminal,
      endTime,
      startTime,
      startFromHead: true,
      ...(nextLogsToken && { nextToken: nextLogsToken }),
    };

    cloudWatchLogs.getLogEvents(getLogsParams, (err, data) => {
      if (err) {
        console.log(err);
        return;
      }

      if (data?.events) {
        console.log(data?.events, 'event logs fetched');
        //@ts-ignore
        const logsFormatted: LogEventType[] = data.events.map(event => {
          event.message = event.message ? JSON.parse(event.message) : '';
          //@ts-ignore
          event.date = new Date(event.timestamp).toLocaleDateString('en-GB');
          return { ...event };
        });
        nextLogsToken ? setLogs(prev => uniqWith([...logsFormatted, ...prev], isEqual)) : setLogs(uniqWith(logsFormatted, isEqual));
        data?.nextForwardToken && setNextLogsToken(data.nextForwardToken);
      }
    });
  };

  useEffect(() => {
    if (selectedTerminal) {
      setNextLogsToken('');
      onNextLogButtonHandler();
    }

  }, [selectedTerminal, selectedDate]);

  useEffect(() => {
    if (nextLogsToken) {
      onNextLogButtonHandler(nextLogsToken);
    }
  } , [nextLogsToken]);
  
  return {
    eventTypes,
    machinesFilters,
    selectedTerminal,
    setSelectedTerminal,
    selectedEventType,
    setSelectedEventType,
    filtredLogs,
    nextLogsToken,
    onTerminalMenuItemClickHandler,
    onEventsMenuItemClickHandler,
    selectedDate,
    setSelectedDate,
    onNextLogButtonHandler,
  };
};

export default useEventLogs;
