/* eslint-disable @typescript-eslint/ban-types */
import { useEffect, useState } from 'react';
import Button from '../../components/Buttons/Button';
import Flex from '../../styled/Flex';
import { Label } from '../../components/Label/index';
import { SummarySession } from '../../types/session/SummarySession';
import { formatTime } from '../../utils/formatDate';
import { makeTagParams, makeUntagParams } from '../../utils/mutateHelpers';
import { createTerminalDisplayName } from 'utils/formatTerminal';

interface Drawer {
  openWith: Function;
}

interface DemoSessionsListProps  {
  shopId: string;
  liveSessions: SummarySession[];
  sessions: SummarySession[];
  liveSessionDrawer: Drawer;
  sessionDrawer: Drawer;
  onTagLiveSession: Function;
  onUntagLiveSession: Function;
  onUntagSession: Function;
}

const DemoSessionsList = ({ shopId, liveSessions = [], sessions = [], liveSessionDrawer, sessionDrawer, onTagLiveSession, onUntagLiveSession, onUntagSession }: DemoSessionsListProps) => {
  if (!shopId) return null;

  const [ dedupedLiveSessions, setDedupedLiveSessions ] = useState(liveSessions);
  const excludeMatchingSessionId = (l: SummarySession) => !sessions.findIndex || sessions.findIndex(s => s.sessionId === l.sessionId) == -1;

  // If the same sessionId is in sessions and live sessions then remove it from the live list
  useEffect(() => {
    if (!sessions || !liveSessions) return;
    const deduped = liveSessions.filter(excludeMatchingSessionId);
    const different = deduped.length < liveSessions.length;
    console.log(`duplicated sessions=${different}`, {
      liveSessions,
      deduped, 
    });
    if (different) {
      setDedupedLiveSessions(deduped);
    }
    else {
      setDedupedLiveSessions(liveSessions);
    }
  }, [liveSessions, sessions]);

  return (
    <Flex justifyContent='center'>
      <div>
        <Label fontSize='20px' textAlign='center'>SESSIONS (Demo Purposes Only)</Label>
        <table>
          <tbody>
            <tr>
              <th><Label padding='10px'>Session</Label></th>
              <th><Label padding='10px'>Machine</Label></th>
              <th><Label padding='10px'>Started</Label></th>
              <th><Label padding='10px'>Ended</Label></th>
              <th><Label padding='10px'>Nom de Plume</Label></th>
              <th><Label padding='10px'>Tagged At</Label></th>
              <th><Label padding='10px'>Updated At</Label></th>
            </tr>
            {dedupedLiveSessions.map && dedupedLiveSessions.map((session, index) => (
              <tr key={`livesession ${index}`}>
                <td><Label fontSize='20px' padding='10px'>{session.sessionId}</Label></td>
                <td><Label fontSize='20px' padding='10px'>{createTerminalDisplayName(session.thingType, session.shopDeviceIndex)}</Label></td>
                <td><Label fontSize='20px' padding='10px'>{formatTime(session.startedAt, 'Not started')}</Label></td>
                <td><Label fontSize='20px' padding='10px'>{formatTime(session.endedAt, '')}</Label></td>
                <td><Label fontSize='20px' padding='10px'>{session.nickname}</Label></td>
                <td><Label fontSize='20px' padding='10px'>{formatTime(session.taggedAt, '')}</Label></td>
                <td><Label fontSize='20px' padding='10px'>{formatTime(session.updatedAt, '')}</Label></td>
                <td><Button onClick={() => onTagLiveSession(makeTagParams({
                  ...session,
                  nicknameId: 'DUMMY',
                  nickname: 'DUMMY', 
                }))}>Tag Direct</Button></td>
                <td><Button onClick={liveSessionDrawer.openWith(index)}>Tag UI</Button></td>
                <td><Button onClick={() => onUntagLiveSession(makeUntagParams(session))}>Untag Direct</Button></td>
              </tr>
            ))}
            {sessions.map && sessions.map((session, index) => (
              <tr key={`savedsession-${index}`}>
                <td><Label fontSize='20px' padding='10px'>{session.sessionId}</Label></td>
                <td><Label fontSize='20px' padding='10px'>{createTerminalDisplayName(session.thingType, session.shopDeviceIndex)}</Label></td>
                <td><Label fontSize='20px' padding='10px'>{session.startedAt}</Label></td>
                <td><Label fontSize='20px' padding='10px'>{session.endedAt}</Label></td>
                <td><Label fontSize='20px' padding='10px'>{session.nickname}</Label></td>
                <td><Label fontSize='20px' padding='10px'>{session.taggedAt}</Label></td>
                <td><Label fontSize='20px' padding='10px'>{session.updatedAt}</Label></td>
                <td>&nbsp;</td>
                <td><Button onClick={sessionDrawer.openWith(index)}>Tag UI</Button></td>
                <td><Button onClick={() => onUntagSession(makeUntagParams(session))}>Untag Direct</Button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Flex>
  );
};

export default DemoSessionsList;
