import styled from 'styled-components';

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 16px;
`;

export const TitleStyled = styled.div`
  color: ${props => props.theme.colors.grey900};
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
`;

export const SubtitleStyled = styled.div`
  align-items: center; 
  background: ${props => props.theme.colors.labelError};
  border-radius: 8px;
  color: ${props => props.theme.colors.grey900};
  display: flex;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  padding: 16px; 
  width: 548px;

  svg {
    fill: ${props => props.theme.colors.error600};
    height: 32px;
    left: 16px;
    margin-right: 16px;
    top: 16px;
    width: 32px;
  }
`;

export const TextLineStyled = styled.div`
  align-self: center;
  color: ${props => props.theme.colors.grey900};
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
`;

export const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 36px;
`;

export const ButtonsStyled = styled.div`
  display: flex;
  flex: 1;
  gap: 8px;

  button {
    line-height: 30px;
    max-width: 65%;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export const Critical = styled.div`
  color: ${props => props.theme.colors.error600};
  height: 32px
  width: 32px;

`;

export const SpinnerStyled = styled.div`
  display: flex;
  path {
    stroke: white
  }
`;