import EventEmitter from 'eventemitter3';
import paho, { Message, MQTTError, Qos } from 'paho-mqtt';
import { GamingEvent, VoucherRedeemResponseSuccess, EventTypes, RetailCommand, RetailRequest, VoucherData, ErrorEvent } from 'types/ApiTypes';
import { EncodedVoucherPayload, FraudFlagType, Voucher, VoucherOffline } from 'types/Voucher';
import { EventHandlers } from './EventHandlers';
import { getConstant } from 'utils/constants';

class MqttApi {
  clientId: string;
  client: paho.Client;
  correlations: Record<string, { resolve: (value: GamingEvent) => void; reject: (reason: unknown) => void }>;
  emitter: EventEmitter<EventTypes>;
  eventHandlers: EventHandlers;

  constructor() {
    const host = getConstant('REACT_APP_MOSQUITTO_HOST');
    this.clientId = `api-${Math.floor(Math.random() * 1000)}`;
    this.client = new paho.Client(host, 8883, this.clientId);
    this.client.onConnectionLost = this.onConnectionLost.bind(this);
    this.client.onMessageArrived = this.onMessageArrived.bind(this);
    this.correlations = {};
    this.emitter = new EventEmitter<EventTypes>();

    this.eventHandlers = {
      'vouchers/get/redeemed/response/success': (event: VoucherData) => this.emitter.emit('voucherRedeemedSuccess', event),
      'vouchers/get/redeemed/response/error': (event: ErrorEvent) => this.emitter.emit('voucherRedeemedError', event),
      'vouchers/get/unredeemed/response/success': (event: VoucherData) => this.emitter.emit('voucherUnredeemedSuccess', event),
      'vouchers/get/unredeemed/response/error': (event: ErrorEvent) => this.emitter.emit('voucherUnredeemedError', event),
      'vouchers/redeem/response/success': (event: VoucherRedeemResponseSuccess) => this.emitter.emit('voucherRedeemSuccess', event),
      'vouchers/redeem/response/error': (event: ErrorEvent) => this.emitter.emit('voucherRedeemError', event),
      'vouchers/data': (event: VoucherData) => this.emitter.emit('voucherData', event),
      'vouchers/error': (event: ErrorEvent) => this.emitter.emit('voucherError', event),
    };
  }

  init() {
    return new Promise<boolean>((resolve, reject) => {
      if (this.client.isConnected()) {
        resolve(true);
      }
      else {
        this.client.connect({
          reconnect: true,
          useSSL: false,
          onSuccess: () => {
            console.log('MqttApi Successfully connected');
            this.subscribeToChannels().then(() => resolve(true));
          },
          onFailure: () => {
            console.log('MqttApi failed to connect');
            reject(false);
          },
        });
      }
    });
  }

  onConnectionLost(error: MQTTError) {
    console.log('Connection lost ', error);
  }

  onMessageArrived(message: Message) {
    try {
      const event = JSON.parse(message.payloadString);
      console.log('onMessageArrived', message.destinationName, event);
      const handler = this.eventHandlers[message.destinationName as keyof EventHandlers];

      if (handler) {
        handler(event);
      } else {
        console.log(
          'Unhandled message, please make sure all of the subscribed topics have respective handlers assigned in the constructor.',
          message.destinationName,
          event
        );
      }
    } catch (e) {
      console.error('An error occurred while trying to handle an incoming message:', e);
    }
  }

  subscribeToChannels() {
    const topics = Object.keys(this.eventHandlers);

    return new Promise<void>((resolve, reject) => {
      //@ts-ignore
      this.client.subscribe(topics, {
        qos: 1,
        onSuccess: () => {
          console.log('Successfully subscribed to all topics', topics);
          resolve();
        },
        onFailure: () => {
          console.log('Unable to subscribe to topics');
          reject();
        },
        timeout: 5000,
      });
    });
  }

  subscribeTo(channels: string[]) {
    return new Promise<boolean>((resolve, reject) => {
      if (this.client.isConnected()) {
        resolve(true);
      }
      else {
        this.client.connect({
          reconnect: true,
          useSSL: false,
          onSuccess: () => {
            console.log('MqttApi successfully connected...');
            this.subscribeToChannelsByTopic(channels).then(() => resolve(true));
          },
          onFailure: () => {
            console.log('MqttApi failed to connect...');
            reject(false);
          },
        });
      }
    });
  }

  subscribeToChannelsByTopic(channels: string[]) {
    const topics = Object.keys(channels);
    console.log('channels', channels);
    return new Promise<void>((resolve, reject) => {
      //@ts-ignore
      this.client.subscribe(channels, {
        qos: 1,
        onSuccess: () => {
          console.log('Successfully subscribed to all topics:', channels);
          resolve();
        },
        onFailure: () => {
          console.log('Unable to subscribe to topics...');
          reject();
        },
        timeout: 5000,
      });
    });
  }

  mqttSendEvent(topic: string, event: GamingEvent) {
    console.log('Publishing Gaming event: ', topic, event);
    this.client.send(topic, JSON.stringify(event), 1, false);
  }

  mqttSendCommand(topic: string, event: RetailCommand, quality = 0 as Qos) {
    console.log('Publishing Retail command: ', topic, event);
    this.client.send(topic, JSON.stringify(event), quality, false);
  }

  mqttSendRequest(topic: string, event: RetailRequest, quality = 0 as Qos) {
    console.log('Publishing Retail request: ', topic, event);
    this.client.send(topic, JSON.stringify(event), quality, false);
  }

  onVoucherRedeemSuccess(callback: EventEmitter.EventListener<EventTypes, 'voucherRedeemSuccess'>) {
    this.emitter.on('voucherRedeemSuccess', callback);
  }

  onVoucherRedeemError(callback: EventEmitter.EventListener<EventTypes, 'voucherRedeemError'>) {
    this.emitter.on('voucherRedeemError', callback);
  }

  onVoucherData(callback: EventEmitter.EventListener<EventTypes, 'voucherData'>) {
    this.emitter.on('voucherData', callback);
  }

  onVouchersRedeemSuccess(callback: EventEmitter.EventListener<EventTypes, 'voucherRedeemedSuccess'>) {
    this.emitter.on('voucherRedeemedSuccess', callback);
  }

  onVouchersRedeemError(callback: EventEmitter.EventListener<EventTypes, 'voucherRedeemedError'>) {
    this.emitter.on('voucherRedeemedError', callback);
  }

  onVouchersUnredeemSuccess(callback: EventEmitter.EventListener<EventTypes, 'voucherUnredeemedSuccess'>) {
    this.emitter.on('voucherUnredeemedSuccess', callback);
  }

  onVouchersUnredeemError(callback: EventEmitter.EventListener<EventTypes, 'voucherUnredeemedError'>) {
    this.emitter.on('voucherUnredeemedError', callback);
  }

  onVouchersResponseError(callback: EventEmitter.EventListener<EventTypes, 'voucherResponseError'>) {
    this.emitter.on('voucherResponseError', callback);
  }

  onVoucherError(callback: EventEmitter.EventListener<EventTypes, 'voucherError'>) {
    this.emitter.on('voucherError', callback);
  }

  payoutOfflineVoucher(voucher: Voucher | VoucherOffline | EncodedVoucherPayload, fraudFlagType: FraudFlagType) {
    const topic = 'vouchers/redeem/request';
    this.client.send(topic, JSON.stringify({
      payload: {
        barcode: voucher.barcode,
        fraudFlagType, 
      },
    }), 1, false);
  }

  getSvsVoucher(voucher: Voucher | VoucherOffline | EncodedVoucherPayload) {
    const topic = 'vouchers/get/voucher';
    this.client.send(topic, JSON.stringify({
      payload: {
        barcode: voucher.barcode,
      },
    }), 1, false);
  }

  getUnredeemedVouchersList() {
    const topic = 'vouchers/get/unredeemed/request';
    this.client.send(topic, JSON.stringify({}), 1, false);
  }

  getRedeemedVouchersList() {
    const topic = 'vouchers/get/redeemed/request';
    this.client.send(topic, JSON.stringify({}), 1, false);
  }

}

export default MqttApi;
