import { useEffect, useState } from 'react';
import { DateTime, Duration } from 'luxon';

import { MachineMap } from 'types/machine/MachineType';

const SessionTimer = (props: { machine: MachineMap }) => {
  const startedAt = props.machine.machineState.session_start_at || props.machine.machineState.started_at;
  const startTime = DateTime.fromISO(startedAt);
  const now = DateTime.now();
  const [elapsedTime, setElapsedTime] = useState<Duration>(now.diff(startTime));

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsedTime(prev => prev.plus(Duration.fromMillis(1000)));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return <>{startedAt ? elapsedTime.toFormat('hh:mm:ss') : 'Not started'}</>;
};

export default SessionTimer;