import Flex from 'styled/Flex';
import styled, { css } from 'styled-components';

import { TileState } from './Tile';

const WhiteIcons = css`
  svg {
    path {
      fill: ${(props) => props.theme.colors.white};
      stroke: ${(props) => props.theme.colors.white};
    }
  }
`;

export const TileContainer = styled(Flex)`
  border-radius: 8px;
  max-width: calc(50% - 0.5rem);
  flex-grow: 1;
  filter: drop-shadow(0px 0px 16px rgba(16, 24, 40, 0.06));
  background: ${(props) => props.theme.colors.white};
  overflow: hidden;
`;

export const TileContent = styled(Flex)`
  border-top: 1px solid ${(props) => props.theme.colors.grey200};
  padding: 24px;
`;

export const TileHeader = styled(Flex) <{ state: TileState }>`
  padding: 16px 24px;
  font-size: 24px;

  ${(props) => props.state === 'error' ? css`
    background: ${props.theme.colors.error25};
    color: ${props.theme.colors.error700};
  ` : null}

  ${(props) => props.state === 'warning' ? css`
    background: ${props.theme.colors.warning50};
    color: ${props.theme.colors.warning800};
  ` : null}
`;

export const HeadingIndex = styled.span`
  font-family: 'WHHoxton-bold';
`;

export const Alerts = styled(Flex)`
  ${(props) => css`
    .ant-alert-error {
      width: 100%;
      svg {
        path {
          fill: ${props.theme.colors.error700};
          stroke: ${props.theme.colors.error700};
        }
      }
    }
    .ant-alert-info {
      width: 100%;
      svg {
        path {
          fill: ${props.theme.colors.success600};
          stroke: ${props.theme.colors.success600};
        }
      }
    }
  `}
`;

export const IconsContainer = styled(Flex) <{ state: TileState }>`
  margin: 0 7px;
  gap: 8px;
  flex-grow: 1;
  justify-content: flex-end;
  span {
    width: 40px;
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 4px;

    svg {
      margin: 0;
      padding: 5px
    }
  }

  ${(props) => props.state === 'error' ? css`
    span {
      background: ${props.theme.colors.error100};

      svg {
        path {
          fill: ${props.theme.colors.error700};
          stroke: ${props.theme.colors.error700};
        }
      }
    }
  ` : null}

  ${(props) => props.state === 'warning' ? css`
    span {
      background: ${props.theme.colors.warning200};

      svg {
        path {
          fill: ${props.theme.colors.warning800};
          stroke: ${props.theme.colors.warning800};
        }
      }
    }
  ` : null}

  ${(props) => props.state === 'neutral' ? css`
    span {
      background: ${props.theme.colors.primary25};
    }
  ` : null}

  span:first-of-type {
    ${(props) => props.state === 'warning' ? css`
      background: ${props.theme.colors.warning600};
      ${WhiteIcons}
    ` : null}

    ${(props) => props.state === 'error' ? css`
      background: ${props.theme.colors.error600};
      ${WhiteIcons}
    ` : null}
  }
`;

export const TileInfoContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 24px;
  padding: 20px 24px;
  button {
    grid-column: 1 / 3;
  }
`;

export const Label = styled.p`
  font-size: 18px;
  margin-bottom: 8px;
  color: ${(props) => props.theme.colors.grey500};
`;

export const Value = styled.p<{ positive?: boolean, negative?: boolean }>`
  font-size: 24px;
  color: ${(props) => props.theme.colors.grey900};
  display: flex;
  gap: 13px;

  ${(props) => props.positive ? css`
    color: ${props.theme.colors.success600};

    svg {
      path {
        stroke: ${props.theme.colors.success600};
      }
    }
  ` : null};

  ${(props) => props.negative ? css`
    color: ${props.theme.colors.error600};

    svg {
      path {
        stroke: ${props.theme.colors.error600};
      }
    }
  ` : null};
`;

export const Buttons = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, 52px);
  gap: 8px;
  padding: 20px 24px;
  button:nth-last-child(1):nth-child(odd) {
    grid-column: 1 / span 2;
  }
`;

export const DrawerContent = styled.div`
  background: ${(props) => props.theme.colors.white};

  filter: drop-shadow(0px 0px 16px rgba(16, 24, 40, 0.06));
  border-radius: 8px;
`;

export const DrawerBody = styled.div`
  background: ${(props) => props.theme.colors.grey50};
  height: 100%;
  padding: 20px;
`;

export const WideDiv = styled.div`
  grid-column: span 2;
`;

export const DrawerTitleContent = styled(Flex)`
  gap: 20px;
  font-size: 32px;
`;

export const DrawerContentHeader = styled(Flex)`
  padding: 20px 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey200};
  font-size: 32px;
`;
