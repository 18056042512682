import PubSub from 'pubsub-js';
import Button from 'components/Buttons/Button';
import { useCallback, useEffect, useState } from 'react';
import { PlayerFetchData, PlayerAction, PlayerNickname, PlayerWithAction } from 'types/Player';
import EditButtons from './EditButtons';
import { ButtonGroupStyled, ActionButtonsStyled, ButtonRowGroupStyled, EditButtonGroupStyled, MessageStyled } from './ActionButtons.styled';
import { deletePlayer, mergePlayers } from 'api/playerApi';
import { Message, Messages } from 'components/Message';
import { getSuccessMessage } from './successMessages';
import { formatString } from 'utils/string';

interface ActionButtonsProps {
  addPlayer: () => void;
  selectedPlayers: PlayerNickname[];
  viewedPlayers: PlayerFetchData[];
}

const ActionButtons = ({ addPlayer, selectedPlayers, viewedPlayers }: ActionButtonsProps) => {
  const [canMerge, setCanMerge] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [selectedPlayerForMerge, setSelectedPlayerForMerge] = useState(selectedPlayers[0]);
  const [unselectedPlayerForMerge, setUnselectedPlayerForMerge] = useState(selectedPlayers[1]);
  const [successMessage, setSuccessMessage] = useState('');
  const [successTimeout, setSuccessTimeout] = useState<NodeJS.Timeout>();
  const [playerAction, setPlayerAction] = useState<PlayerAction>();

  const onDelete = useCallback(() => {
    viewedPlayers[0].player && deletePlayer(viewedPlayers[0].player.nicknameId).then(async () => {

      PubSub.publish('app.modal.success', {
        playerAction: 'delete',
        player: viewedPlayers[0].player, 
      });
    }).catch(() => {
      PubSub.publish('app.modal.fail', {
        playerAction: 'delete',
        player: viewedPlayers[0].player,
      });
    });
  }, [viewedPlayers]);

  const updateSuccess = useCallback((topic: string, { playerAction }: PlayerWithAction) => {
    setPlayerAction(playerAction);
    const options = {
      playerToKeep: selectedPlayerForMerge?.nickname,
      playerToMerge: unselectedPlayerForMerge?.nickname,
    };
    const successMessage = formatString(getSuccessMessage()[playerAction], options);
    setSuccessMessage(successMessage);
    const timeout = global.setTimeout(() => {
      setSuccessMessage('');
    }, 10000);
    setSuccessTimeout(timeout);
  }, [selectedPlayerForMerge, unselectedPlayerForMerge]);

  useEffect(() => {
    PubSub.subscribe('app.modal.success', updateSuccess);
    return () => {
      PubSub.unsubscribe(updateSuccess);
    };
  }, [updateSuccess]);

  const onMerge = useCallback(async () => {
    const keptPlayer = viewedPlayers.filter(vp => vp.player?.nicknameId === selectedPlayerForMerge.nicknameId)[0].player;

    await mergePlayers(selectedPlayerForMerge, unselectedPlayerForMerge).then(() => {
      PubSub.publish('app.modal.success', {
        playerAction: 'merge',
        player: keptPlayer,
      });
    }).catch(() => {
      PubSub.publish('app.modal.fail', {
        playerAction: 'merge',
        player: keptPlayer,
      });
    });
  }, [selectedPlayerForMerge, unselectedPlayerForMerge]);

  const onPlayerSelectedForMerge = useCallback((topic: string, player: string) => {
    const selectedPlayer = selectedPlayers.find(sp => sp.nickname === player);
    selectedPlayer && setSelectedPlayerForMerge(selectedPlayer);
    const unselectedPlayer = selectedPlayers.find(sp => sp.nickname !== player);
    unselectedPlayer && setUnselectedPlayerForMerge(unselectedPlayer);
  }, [selectedPlayers]);

  useEffect(() => {
    PubSub.subscribe('app.modal.option.selected', onPlayerSelectedForMerge);

    return () => {
      PubSub.unsubscribe(onPlayerSelectedForMerge);
    };
  }, [onPlayerSelectedForMerge]);

  useEffect(() => {
    PubSub.subscribe('app.modal.confirm.merge', onMerge);

    return () => {
      PubSub.unsubscribe(onMerge);
    };
  }, [onMerge]);

  useEffect(() => {
    PubSub.subscribe('app.modal.confirm.delete', onDelete);

    return () => {
      PubSub.unsubscribe(onDelete);
    };
  }, [onDelete]);

  useEffect(() => {
    setCanMerge(selectedPlayers?.length === 2);
    setCanDelete(selectedPlayers?.length === 1 && viewedPlayers?.length === 1);
    setCanEdit(selectedPlayers?.length === 1 && viewedPlayers?.length === 1);
  }, [selectedPlayers, viewedPlayers]);

  const openConfirmationDialog = (playerAction: PlayerAction) => {
    clearTimeout(successTimeout);
    setSuccessMessage('');
    setSelectedPlayerForMerge(selectedPlayers[0]);
    setUnselectedPlayerForMerge(selectedPlayers[1]);
    PubSub.publish('app.modal.open', playerAction);
  };

  const renderSuccessMessage = () => {
    if (successMessage && playerAction && playerAction != 'cancel-update') {
      return (
        <MessageStyled>
          <Messages data-test-id='success-message'>
            <Message type={'success'} iconName={'check'}>
              {successMessage}
            </Message>
          </Messages>
        </MessageStyled>
      );
    }
  };

  return (
    <ActionButtonsStyled data-test-id='dashboard-action-buttons'>
      <ButtonRowGroupStyled>
        <EditButtonGroupStyled>
          <EditButtons canEdit={canEdit} />
        </EditButtonGroupStyled>
        <ButtonGroupStyled >
          <Button data-test-id='delete-button' onClick={() => openConfirmationDialog('delete')} disabled={!canDelete} color='white' background={`${canDelete ? 'error600' : 'error200'}`} borderless margin='0, 0, 0, 500rem'>Delete</Button>
          <Button data-test-id='merge-players-button' onClick={() => openConfirmationDialog('merge')} disabled={!canMerge} color={`${canMerge ? 'primary600' : 'grey300'}`} background={`${canMerge ? 'grey100' : 'grey25'}`} border={`${canMerge ? '4px solid #D8E1FF' : 'none'}`}>Merge players</Button>
          <Button data-test-id='add-a-player-button' color='white' background='primary600' borderless onClick={addPlayer}>Add a player</Button>
        </ButtonGroupStyled>
      </ButtonRowGroupStyled>
      {renderSuccessMessage()}
    </ActionButtonsStyled>
  );
};

export default ActionButtons;