import styled from 'styled-components';
import Flex from 'styled/Flex';

export const EventLogsContainer = styled(Flex)`
  /* background-color: #e3e4e6; */
  height: 100%;
  /* padding: 0 100px 100px 100px; */
  .ant-dropdown-trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 180px;
    padding: 0 5px;
    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.grey300};;
  }
`;

export const LogsContainer = styled(Flex)`
  background: ${(props) => props.theme.colors.white};
  height: 100%;
  font-size: 2rem;
  padding: 2rem;
  margin-top: 1rem;
`;

export const LogRow = styled(Flex)`
  width: 100;
  padding: 8px 0;
  align-items: space-between;
`;

export const LogElement = styled.p`
  min-width: 165px;
  margin-right: 10px;
`;

export const LogElementCategory = styled.p`
  min-width: 305px;
`;

export const DropdownsContainer = styled(Flex)`
  margin-top: 1rem;
  div {
    margin-right: 10px;
  }
`;

export const WinValue = styled.span`
  display: flex;
  align-items: flex-start;
`;
