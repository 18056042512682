// This file is for additional UI-API integration assistance when fetching data (including caching and mapping)
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';

import { MachineMap, MachineState } from 'types/machine/MachineType';
import { PLAYER_CACHE, SESSION_CACHE } from 'api';
import { SummarySession } from 'types/session/SummarySession';
import { getPlayersForShop } from 'api/playerApi';
import { getSessionsInShop, SessionsInShopParams } from 'api/sessionApi';

// Based on https://www.tjvantoll.com/2015/09/13/fetch-and-errors/
export const throwErrorIfBadResponse = (response: Response, errorMessage?: string) => {
  // Required to handle 500 errors
  if (!response.ok) {
    console.error('Bad response to REST call', {
      errorMessage,
      statusText: response.statusText,
    });
    throw Error(errorMessage || response.statusText);
  }
  return response.json();
};

export const getPlayers = (autoRefresh: boolean, shopId: string) => {
  const refreshRateInSeconds = 60;
  const options = {
    refreshInterval: refreshRateInSeconds * 1000,
    revalidateOnMount: true,
  };

  const { data: players, error, mutate } = autoRefresh ?
    useSWR(PLAYER_CACHE + shopId, () => getPlayersForShop(shopId), options) :
    useSWRImmutable(PLAYER_CACHE + shopId, () => getPlayersForShop(shopId));

  return {
    players,
    error,
    loading: !players && !error,
    mutate,
  };
};

export const getSessions = (autoRefresh: boolean, params: SessionsInShopParams) => {
  const refreshRateInSeconds = 60;
  const options = {
    refreshInterval: refreshRateInSeconds * 1000,
    revalidateOnMount: true,
  };

  const { shopId } = params;
  const { data: sessions, error, mutate } = autoRefresh ?
    useSWR(SESSION_CACHE + shopId, () => getSessionsInShop(params), options) :
    useSWRImmutable(SESSION_CACHE + shopId, () => getSessionsInShop(params));

  return {
    sessions,
    error,
    loading: !sessions && !error,
    mutate,
  };
};

const toDate = (key: string, isoDateTime?: string): Record<string, unknown> => isoDateTime ? { [key]: new Date(isoDateTime) } : {};

const dateFields = (ms: MachineState): Record<string, unknown> => ({
  ...toDate('startedAt', ms.started_at || ms.session_start_at),
  ...toDate('endedAt', ms.ended_at),
  ...toDate('taggedAt', ms.tagged_at),
  ...toDate('updatedAt', ms.updated_at),
});

export const sanitisedMachineState = (ms: MachineState) => ({
  ...ms,
  session_id: '',
  started_at: '',
  ended_at: '',
  tagged_at: '',
  updated_at: '',
  real_name: '',
  nickname: '',
  nickname_id: '',
  plus_account_id: '',
  staked: 0,
  balance: 0,
});

export const machinesToSummarySessions = (machines: MachineMap[]): SummarySession[] => {
  return machines.map(({ machineId, thingType, deviceUuid, machineState: ms }) => ({
    thingType,
    deviceUuid: deviceUuid || machineId,
    sessionId: ms.session_id,
    ...dateFields(ms),
    realName: ms.real_name,
    nickname: ms.nickname,
    nicknameId: ms.nickname_id,
    plusAccountId: ms.plus_account_id,
    shopId: machineId.split('-')[1],
    shopDeviceIndex: machineId.split('-')[2],
    deviceType: ms.device_type,
    staked: ms.staked,
    machineId,
  } as SummarySession));
};
