import styled, { css } from 'styled-components';

type InputCommentProps = {
  hasError: boolean;
}

export const LabelledInputStyled = styled.div`
  display: flex;
  flex: 1 0 48%;
  flex-direction: column;
  gap: 8px;

  textarea {
    color: ${props => props.theme.colors.grey500};
  }
`;

export const InputLabel = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
`;

export const InputComment = styled.span<InputCommentProps>`
  color:${(props) => props.hasError ? css` red` : `${props.theme.colors.grey500}`};
  margin-left: auto;
  height: 16px;
`;