import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 24px;
  svg {
    path {
      stroke: ${({ theme }) => theme.colors.grey500};    }
  }
`;

export const FirstBreadcrumbLink = styled(NavLink)`
  color: ${({ theme }) => theme.colors.grey900};
  :active {
    color: ${({ theme }) => theme.colors.grey900};
  }
`;

export const BreadcrumbLink = styled(NavLink)`
   color: ${({ theme }) => theme.colors.grey500};
`;

export const IconContainer = styled.div`
  margin: 0 7px;
`;

export const Title = styled.p`
  font-size: 38px;
`;

export const Subheading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 28px;
`;
