import PubSub from 'pubsub-js';
import { Radio, RadioChangeEvent } from 'antd';
import { useState } from 'react';
import { RadioButtonsStyled } from './ConfirmationModal/ConfirmationModal.styled';

interface ModalOptionsProps {
  option1: string;
  option2: string;
}

const ModalOptions = ({ option1, option2 }: ModalOptionsProps) => {
  const [value, setValue] = useState(option1);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
    PubSub.publish('app.modal.option.selected', e.target.value);
  };

  return (
    <RadioButtonsStyled data-test-id='modal-options'>
      <Radio.Group onChange={onChange} value={value}>
        <Radio value={option1}>{option1}</Radio>
        <Radio value={option2}>{option2}</Radio>
      </Radio.Group>
    </RadioButtonsStyled>
  );
};

export const getModalOptionsComponent = (options: string[]) => options.length > 0 ? <ModalOptions option1={options[0]} option2={options[1]}/> : null;

export default ModalOptions;
