import { Outlet } from 'react-router-dom';

import Header from 'components/Header';
//import LayoutAside from './LayoutAside';
import { LayoutOverlay, LayoutContent } from './Layout.styled';

const Layout = () => (
  <LayoutOverlay>
    <LayoutContent>
      <Header />
      <Outlet />
    </LayoutContent>
  </LayoutOverlay>
);

export default Layout;
