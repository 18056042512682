import { useEffect, useState, useMemo } from 'react';
import { cloudWatchLogs } from 'aws/Aws';
import { MenuInfo } from 'rc-menu/lib/interface';
import { GetLogsEventsTypes, AlertEventType, AlertsTypes, AlertsMenuItemType } from 'types/logs/Logs';
import { useCloudWatchMachines } from './useCloudWatchMachines';

const alertTypes: AlertsMenuItemType[] = [{
  label: 'All',
  key: 0, 
},{
  label: 'Responsible Gaming',
  key: 1, 
}, {
  label: 'Terminal',
  key: 2, 
}];

const logGroupName = 'gaming-retail/things/alerts';

const useEventLogs = () => {
  const {
    machinesFilters, selectedTerminal, setSelectedTerminal, onTerminalMenuItemClickHandler,
  } = useCloudWatchMachines(logGroupName);

  const [logs, setLogs] = useState<AlertEventType[]>([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedEventType, setSelectedEventType] = useState<AlertsTypes>('All');
  const [nextLogsToken, setNextLogsToken] = useState('');

  const onEventsMenuItemClickHandler = (i: MenuInfo) => {
    const key = Number(i.key);
    const label: AlertsTypes = alertTypes[key].label;

    setSelectedEventType(label);
  };

  const FILTER_MAP = {
    all: () => true,
    byDate: (logEvent: AlertEventType) => logEvent.date === new Date(selectedDate).toLocaleDateString('en-GB'),
    byType: (logEvent: AlertEventType) => selectedEventType === 'All' ? true : logEvent.message.category.toLowerCase() === selectedEventType.toLocaleLowerCase(),
  };

  const filtredLogs = useMemo(
    () => logs.filter(FILTER_MAP['byDate']).filter(FILTER_MAP['byType']), 
    [logs, selectedDate, selectedTerminal, selectedEventType]
  );

  const onNextLogButtonHandler = (nextLogsToken?: string) => {
    const endTime = new Date(selectedDate).setHours(23,59,59,999);
    const startTime = new Date(selectedDate).setHours(0,0,0,0);

    const getLogsParams: GetLogsEventsTypes = {
      logGroupName,
      logStreamName: selectedTerminal,
      endTime,
      startTime,
      startFromHead: true,
      ...(nextLogsToken && { nextToken: nextLogsToken }),
    };

    cloudWatchLogs.getLogEvents(getLogsParams, (err, data) => {
      if (err) {
        console.log(err);
        return;
      }

      if (data?.events) {
        console.log(data?.events, 'event logs fetched');
        //@ts-ignore
        const finalLogVersion: AlertEventType[] = data.events.map(event => {
          event.message = event.message ? JSON.parse(event.message) : '';
          //@ts-ignore
          event.date = new Date(event.timestamp).toLocaleDateString('en-GB');
          return { ...event };
        });

        console.log(finalLogVersion, 'finalLogVersion');

        nextLogsToken ? setLogs(current => ([...finalLogVersion, ...current])) : setLogs(finalLogVersion);
        data?.nextForwardToken && setNextLogsToken(data.nextForwardToken);
      }
    });
  };

  useEffect(() => {
    if (selectedTerminal) {
      setNextLogsToken('');
      onNextLogButtonHandler();
    }

  }, [selectedTerminal, selectedDate]);

  useEffect(() => {
    if (nextLogsToken) {
      onNextLogButtonHandler(nextLogsToken);
    }
  } , [nextLogsToken]);
  
  return {
    alertTypes,
    machinesFilters,
    selectedTerminal,
    setSelectedTerminal,
    selectedEventType,
    setSelectedEventType,
    filtredLogs,
    nextLogsToken,
    onTerminalMenuItemClickHandler,
    onEventsMenuItemClickHandler,
    selectedDate,
    setSelectedDate,
    onNextLogButtonHandler,
  };
};

export default useEventLogs;
