import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';

import { AngleRight } from 'components/Icon';
import * as Styled from './Breadcrumbs.styled';
import { Fragment } from 'react';

const Breadcrumbs = () => {
  const breadcrumbs = useReactRouterBreadcrumbs([
    {
      path: '/',
      breadcrumb: null, 
    },
    {
      path: '/history',
      breadcrumb: 'History',
    },
  ]);
  
  // workaround for session history breadcrumb not displayed due to session history being an index route,
  // todo: update react-router to 6.4.x for better breadcrumbs handling
  if (breadcrumbs.length === 1 && breadcrumbs[0].match.pathname === '/history') {
    breadcrumbs.push({
      key: '/history/session_history',
      location: breadcrumbs[0].location,
      match: breadcrumbs[0].match,
      breadcrumb: 'Session history',
    });
  }

  return (
    <Styled.Breadcrumbs data-test-id='breadcrumb'>
      {breadcrumbs.map(({
        match,
        breadcrumb,
      }, i) => (
        <Fragment key={match.pathname + i + match.params}>
          <span>
            {i === 0 ? (
              <Styled.FirstBreadcrumbLink to={match.pathname}>
                <Styled.Title>{breadcrumb}</Styled.Title>
              </Styled.FirstBreadcrumbLink>
            ) : null}
            {i > 0 ? (
              <Styled.BreadcrumbLink to={match.pathname}>
                <Styled.Subheading>{breadcrumb}</Styled.Subheading>
              </Styled.BreadcrumbLink>
            ) : null}
          </span>
          <Styled.IconContainer key={match.pathname + i + 'icon'}>
            {i < breadcrumbs.length - 1 && <AngleRight color={'black'}/>}
          </Styled.IconContainer>
        </Fragment>
      ))}
      {breadcrumbs.length === 0 ? (
        <Styled.FirstBreadcrumbLink to={'/'}>
          <Styled.Title>{'Dashboard'}</Styled.Title>
        </Styled.FirstBreadcrumbLink>
      ): null}
    </Styled.Breadcrumbs>
  );
};

export default Breadcrumbs;
