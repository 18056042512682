import styled from 'styled-components';

export const ActionButtonsStyled = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.primary200};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
  margin: 0 24px;
  padding: 24px 0 12px;

  button {
    gap: 12px;
  }
`;

export const ButtonGroupStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 51px;
  margin-bottom: 24px;
`;

export const EditButtonGroupStyled = styled.div`
  display: flex;
  height: 51px;
  flex: 1;
  flex-direction: row;
  gap: 8px;
  height: 51px;
`;

export const ButtonRowGroupStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const MessageStyled = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  justify-content: space-between;
  margin-top: 10px;
  position: relative;

  button {
    gap: 12px;
  }
`;
