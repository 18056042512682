import * as dateFns from 'date-fns';
import { EncodedVoucherPayload, FraudFlagType, GetVouchersResponse, Voucher, VoucherOffline } from 'types/Voucher';
import { getConstant } from 'utils/constants';

const RVS_URL = getConstant('REACT_APP_RVS_URL');

const getVouchersRedeemedByDateAndShop = async (shop: string) => {
  const today = dateFns.format(new Date(), 'yyyy-MM-dd');
  const searchParams = new URLSearchParams({
    redeemed: 'true',
    potentialFraud: 'true',
    redeemedAt: today,
    shop,
  });

  return fetch(`${RVS_URL}/fobt/vouchers?${searchParams}`)
    .then(response => response.json() as Promise<GetVouchersResponse>);

};

export const getFraudFlagType = async (voucher: Voucher | VoucherOffline | EncodedVoucherPayload): Promise<FraudFlagType> => {
  const { vouchers: vouchersRedeemedTodayAtShop } = await getVouchersRedeemedByDateAndShop(voucher.shop);
  const amountOfVoucherPounds = voucher.amount / 100;

  if (vouchersRedeemedTodayAtShop.length >= 5) {
    return 'multiple';
  } else if (amountOfVoucherPounds >= 1000) {
    return 'standard';
  }

  return 'no';
};
