import { useEffect, useState } from 'react';
import * as PubSubJs from 'pubsub-js';
import moment from 'moment';
import { ErrorBoundary } from 'react-error-boundary';
import { Alert as DisconnectedAlert } from 'antd';
import Alert from 'components/Alert';
import Button from 'components/Buttons/Button';
import ConnectedPlayerDrawer from 'components/Drawer/PlayerDrawer/ConnectedPlayerDrawer';
import DemoSessionsList from 'components/Demo/DemoSessionList';
import ErrorFallback from '../ErrorFallback/ErrorFallback';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import RetailCard from 'components/Cards/RetailCard';
import RetailsWrapper from 'styled/RetailsWrapper/RetailsWrapper.styled';
import Tile from 'components/Tile';
import TileDrawer from 'components/Drawer/TileDrawer';
import useMachines from 'hooks/useMachines';
import useRightDrawer from 'hooks/useRightDrawer';
import useShop from 'hooks/useShop';
import useConnectionStatus from 'hooks/useConnectionStatus';
import { GeneralAlert } from 'types/alert/GeneralAlert';
import { MachineMap, BonusAlerts } from 'types/machine/MachineType';
import { Message, Messages } from 'components/Message';
import { SummarySession } from 'types/session/SummarySession';
import { createGroupName, createTerminalDisplayNameFromMachineId } from 'utils/formatTerminal';
import { getConstant } from 'utils/constants';
import { getSessions, getPlayers, machinesToSummarySessions } from '../../utils/fetchHelpers';
import { onTagLiveSession, onUntagLiveSession, onUntagSession } from 'utils/mutateHelpers';
import { Alerts } from 'components/Tile/Tile.styled';
import { Critical } from 'components/Icon';
import { AlertContainer } from 'components/Alert/AlertContainer';
import CashReportProvider from '../../providers/CashReportProvider';
import useAlerts from 'hooks/useAlerts';

const Home = () => {
  const { alerts, dismissAlertById } = useAlerts();
  const { machines, notifications, bonusAlerts } = useMachines();
  const { shopId } = useShop();
  const { connectionStatus } = useConnectionStatus();
  const [alert, setAlert] = useState<GeneralAlert | null>();
  const { error: playersError, loading: playersLoading } = getPlayers(false, shopId);
  const liveSessions: SummarySession[] = machinesToSummarySessions(machines);
  // Dummy dates and dummy limit (5)
  const toDate: Date = new Date();
  const fromDate: Date = moment(toDate).subtract(3, 'months').toDate();
  const { sessions, error: sessionsError, loading: sessionsLoading, mutate } = getSessions(false, {
    offset: 0,
    limit: 5,
    shopId,
    toDate,
    fromDate,
  });
  const machineDrawer = useRightDrawer(machines || []);
  const sessionDrawer = useRightDrawer(sessions || []);
  const liveSessionDrawer = useRightDrawer(liveSessions || []);
  const [bonusOptOutFailures, setBonusOptOutFailures] = useState<BonusAlerts[]>([]);
  const moneyManagementFeature = getConstant('REACT_APP_MONEY_MANAGEMENT') === 'true';

  useEffect(() => {
    if (bonusAlerts) {
      setBonusOptOutFailures([...bonusOptOutFailures, bonusAlerts]);
    }
  }, [bonusAlerts]);

  const demoSessions = getConstant('REACT_APP_DEMO_SESSIONS', 'false') == 'true';
  const ssbtTilesVisible = getConstant('REACT_APP_SSBT_TILES', 'false') == 'true';
  const smartRetailTilesVisible = getConstant('REACT_APP_HUB_TILES', 'false') == 'true';

  const onOpenTagSession = (sessionId: string) => {
    console.log('onOpenTagSession');
    machineDrawer.close();
    setTimeout(() => {
      const index = liveSessions.findIndex(l => l.sessionId === sessionId);
      console.log('onOpenTagSession ' + index);
      if (index > -1) {
        liveSessionDrawer?.openWith(index)();
      }
      else {
        console.error(`Cannot find sessionId ${sessionId}`);
      }
    }, 0);
  };

  // Could be trigger by IoT activity happening either inside or outside the app
  const onEndSessionsNotification = () => {
    console.log('onEndSessionsNotification in Home');
    // Tell getSessions SWR to revalidate
    mutate();
  };

  const onError = (topic: string, data: any) => {
    if (!data) {
      setAlert(null);
      return;
    }

    const error: GeneralAlert = {
      iconName: 'critical',
      message: data.toString(),
      type: 'error',
    };
    setAlert(error);
  };

  useEffect(() => {
    PubSubJs.subscribe('app.live.sessions.ended', onEndSessionsNotification);
    PubSubJs.subscribe('app.error.dashboard', onError);
    return () => {
      PubSubJs.unsubscribe(onEndSessionsNotification);
      PubSubJs.unsubscribe(onError);
    };
  }, []);

  const filteredNotifications = notifications
    .filter(notification => notification.machineId === machineDrawer.contextElement?.machineId);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {demoSessions && <DemoSessionsList shopId={shopId} liveSessions={liveSessions} sessions={sessions || []} liveSessionDrawer={liveSessionDrawer} sessionDrawer={sessionDrawer} onTagLiveSession={onTagLiveSession} onUntagLiveSession={onUntagLiveSession} onUntagSession={onUntagSession} />}
      {(playersLoading || sessionsLoading) && <LoadingSpinner text='Loading' />}
      {playersError && <Alert message={`${playersError}`} type='error' />}
      {sessionsError && <Alert message={`${sessionsError}`} type='error' />}
      {connectionStatus === 'offline' && <Alert message='The shop is offline' type='error' />}
      {bonusOptOutFailures.length > 0 && (
        bonusOptOutFailures.map((item: BonusAlerts, index: number) => (
          <Alerts key={item.slug + index}>
            <DisconnectedAlert showIcon={true} icon={<Critical width={16} height={16} />} message={`FOBT ${item.index} ${item.opting} unsuccessful journey`} closable={true} type='error' />
          </Alerts>
        ))
      )}
      <AlertContainer />
      <RetailsWrapper>
        {alert && alert.iconName !== 'critical' && (
          <Messages margin='10px 10px 24px' data-test-id='message-box'>
            <Message type={alert.type} iconName={alert.iconName} >
              {alert.message}
            </Message>
          </Messages>
        )}
        {alert && alert.iconName === 'critical' && (
          <Messages margin='10px 10px 24px' data-test-id='message-box'>
            <Message type={alert.type} iconName={alert.iconName} >
              {alert.message}.&nbsp;
              Please refresh the page.&nbsp;
              If the problem persists, please contact Helpdesk.
              &nbsp;
              <Button primary onClick={() => window.location.reload()} data-test-id='refresh-button'>Refresh</Button>
            </Message>
          </Messages>
        )}
        {machines.filter(m => m.thingType === 'gm').length > 0 ? (
          <RetailCard name={'FOBT'} equals={false} withMoreTiles={'FOBT'}>
            {machines.map((machine: MachineMap, index) =>
              machine.thingType === 'gm' && <Tile
                alerts={alerts.filter(alert => alert.thing_id === machine.machineId)}
                key={machine.machineId}
                machine={machine}
                index={index + 1}
                onClick={machineDrawer.openWith(index)}
              />
            )}
          </RetailCard>
        ) : null}

        {moneyManagementFeature ? <CashReportProvider shop_id={shopId} /> : null}

        {machines.filter(m => m.thingType === 'ssbt').length > 0 && ssbtTilesVisible === true ? (
          <RetailCard name={'SSBT'} equals={false} withMoreTiles={'FOBT'}>
            {machines.map((machine: MachineMap, index) =>
              machine.thingType === 'ssbt' && <Tile
                key={machine.machineId}
                machine={machine}
                index={index + 1}
                onClick={machineDrawer.openWith(index)}
              />
            )}
          </RetailCard>
        ) : null}
        {machines.filter(m => m.thingType === 'hub').length > 0 && smartRetailTilesVisible === true ? (
          <RetailCard name={'Smart Retail'} equals={false} withMoreTiles={'FOBT'}>
            {machines.map((machine: MachineMap, index) =>
              machine.thingType === 'hub' && <Tile
                key={machine.machineId}
                machine={machine}
                index={index + 1}
                onClick={machineDrawer.openWith(index)}
              />
            )}
          </RetailCard>
        ) : null}
        {liveSessionDrawer.contextElement &&
          <ConnectedPlayerDrawer
            isLive={true}
            session={liveSessionDrawer.contextElement}
            isOpen={liveSessionDrawer.isOpen}
            close={liveSessionDrawer.close}
            mutateSessions={mutate}
            updateUiAfterTaggingSession={() => { console.log('Can\'t be bothered to implement this for demo'); }}
            initialActionType={'tagging'}
          />
        }
        {sessionDrawer.contextElement &&
          <ConnectedPlayerDrawer
            isLive={false}
            session={sessionDrawer.contextElement}
            isOpen={sessionDrawer.isOpen}
            close={sessionDrawer.close}
            mutateSessions={mutate}
            updateUiAfterTaggingSession={() => { console.log('Can\'t be bothered to implement this for demo'); }}
            initialActionType={'tagging'}
          />
        }
        {machineDrawer.contextElement &&
          <TileDrawer
            machine={machineDrawer.contextElement}
            isOpen={machineDrawer.isOpen}
            close={machineDrawer.close}
            terminalName={createTerminalDisplayNameFromMachineId(machineDrawer.contextElement.machineId)}
            groupName={createGroupName(machineDrawer.contextElement.thingType)}
            onOpenTagSession={onOpenTagSession}
            alerts={alerts}
            dismissAlertById={dismissAlertById}
          />
        }
      </RetailsWrapper>
    </ErrorBoundary>
  );
};

export default Home;
