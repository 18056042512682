import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { getConstant } from 'utils/constants';
import { EncodedVoucherPayload, Totals, Voucher, GetVouchersResponse, VoucherOffline } from 'types/Voucher';

const RVS_URL = getConstant('REACT_APP_RVS_URL');

interface UseVouchersParams {
  uid: string;
  dateFrom: Date;
  dateTo: Date;
  type: 'redeemed' | 'unredeemed'
  terminal: string[];
  shopId: string;
}

const formatDateToApiCall = (dateToFormat: Date, timeToSend: string) => {
  return [format(dateToFormat, 'yyyy-MM-dd'), timeToSend].join('T'); // 2022-11-17T00:00:00 format is required
};

const formatDateFrom = (date: Date) => formatDateToApiCall(date, '00:00:00');
const formatDateTo = (date: Date) => formatDateToApiCall(date, '23:59:59');

const useVouchers = ({ dateFrom, dateTo, uid, type, terminal, shopId }: Partial<UseVouchersParams>) => {
  const params: Record<string, undefined | boolean | string | string[]> = {
    uid,
    terminal,
    shop: shopId,
    ...(type && { redeemed: type === 'redeemed' ? true : false }),
    ...(dateFrom && { dateFrom: formatDateFrom(dateFrom) }),
    ...(dateTo && { dateTo: formatDateTo(dateTo) }),
  } as const;

  const filteredParams = Object.keys(params).reduce((prev, curr) => {
    if (params[curr] !== undefined) {
      return {
        ...prev,
        [curr]: params[curr],
      };
    }

    return prev;
  }, {});

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [vouchers, setVouchers] = useState<Voucher[]>();
  const [totals, setTotals] = useState<Totals>();
  const [error, setError] = useState<Error | null>(null);
  const [statusCode, setStatusCode] = useState<number | null>(null);

  const refetchVoucher = async (voucher: Voucher | VoucherOffline | EncodedVoucherPayload) => {
    const url = `${RVS_URL}/fobt/vouchers/${voucher.uid}`;
    const data = await fetch(url);
    const refetchedVoucher = await data.json() as Voucher;
    return refetchedVoucher;

  };

  const mutateVoucher = async (voucher: Voucher | VoucherOffline | EncodedVoucherPayload) => {
    const newVoucher = await refetchVoucher(voucher);
    // @ts-ignore temporary solution until BE changes to unify date formats for offline and online vouchers
    setVouchers((prev) => {
      if (prev) {
        const index = prev.findIndex((v) => v.barcode === newVoucher.barcode);
        if (prev[index]?.redeemedAt !== newVoucher?.redeemedAt) {
          return [
            ...prev.slice(0, index),
            newVoucher,
            ...prev.slice(index + 1),
          ];
        }
        return prev;
      }
    });
  };

  useEffect(() => {
    if (shopId || uid) {
      setIsLoading(true);
      let url = `${RVS_URL}/fobt/vouchers`;

      const searchParams = new URLSearchParams(filteredParams);
      const numberOfSearchParams = Array.from(searchParams).length;

      if (numberOfSearchParams === 1 && !!searchParams.get('uid')) {
        url += `/${searchParams.get('uid')}`;
      }
      else if (numberOfSearchParams > 0) {
        url += `?${searchParams}`;
      }

      const getVouchers = async ()=>{
        try {
          const response = await fetch(url);
          const { status, newData } =  {
            status: response.status,
            newData: await response.json() as unknown as GetVouchersResponse,
          };

          if (status !== 200 && 'message' in newData){
            setStatusCode(status);
            //@ts-ignore
            throw new Error(newData.message);
          }
          //eslint-disable-next-line no-extra-boolean-cast
          if (!!Object.getOwnPropertyDescriptor(newData, 'vouchers')) {
            setVouchers(newData.vouchers);
            setTotals({
              totalAmount: newData.totalAmount,
              totalRedeemed: newData.totalRedeemed,
              totalUnredeemed: newData.totalUnredeemed,
            });

          } else {
            //@ts-ignore
            setVouchers([newData]);
          }
          setIsLoading(false);
        } catch (error){
          console.log('Error getting vouchers:', error);
          setIsLoading(false);
          setError(error as Error);
        }
      };
      getVouchers();
    }

  }, [type, dateFrom, dateTo, uid, JSON.stringify(terminal), shopId]);

  return {
    mutateVoucher,
    isLoading,
    totals,
    vouchers,
    error,
    statusCode,
  };
};

export default useVouchers;
