import styled, { DefaultTheme, css } from 'styled-components';

type Color = keyof DefaultTheme['colors']

type DividerProps = {
  color: Color
  margin: string
  width: string
}

const Divider = styled.div<DividerProps>`
  border-top: 1px solid ${(props) => props.theme.colors[props.color]};
  ${(props) => css`margin: ${props.margin}`};
  ${(props) => css`width: ${props.width}`};
  align-self: stretch;
`;

export { Divider };
