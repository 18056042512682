import { useState, useMemo } from 'react';

const useDisclosure = (initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState);

  const functions = useMemo(() => ({
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
    toggle: () => setIsOpen(prev => !prev),
  }), []);

  return {
    isOpen,
    ...functions,
  };
};

export default useDisclosure;
