import styled from 'styled-components';

export const ReportsRootContainer = styled.div`
  background-color: ${props => props.theme.colors.grey50};
  padding: 20px;
  flex: 1;
  .ant-checkbox { // override antd styles
    border: 1px solid ${props => props.theme.colors.black};
  }
  .printed {
    background: ${props => props.theme.colors.grey100};
  }
`;
