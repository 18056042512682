export const createGroupName = (thingType = ''): string => {

  const map: any = {
    'ssbt': 'SSBT',
    'hub': 'Smart Retail',
    'gm': 'FOBT',
    'handheld': 'HHBT',
  };

  const groupName = map[thingType] ?? '';

  return groupName;
};

export const createTerminalDisplayName = (thingType = '', deviceIndex = ''): string => {

  const map: any = {
    'ssbt': `SSBT ${deviceIndex}`,
    'hub': `Smart Retail ${deviceIndex}`,
    'gm': `FOBT ${deviceIndex}`,
    'handheld': `HHBT ${deviceIndex}`,
  };

  const displayName = map[thingType] ?? `${deviceIndex}`;

  return displayName;
};

export const createTerminalDisplayNameFromMachineId = (machineId = ''): string => {
  if (!machineId) {
    return '';
  }
  const machineParts = machineId.split('-');
  return createTerminalDisplayName(machineParts[0], machineParts[2]);
};

export const createDeviceDisplayNameFromDeviceType = (deviceType = ''): string => {

  const map: any = {
    'QX': 'V1',
    'RH1': 'V1',
    'VS': 'V2',
    'SB3': 'V3',
    'BP': 'BetPoint',
    'BT': 'Tablet',
    'handheld': 'Handheld',
  };
  
  const device = map[deviceType] ?? '';

  return device;
};

export const inferIndexFromMachineId = (machineId: string) => {
  return machineId.split('-').length === 3 ? machineId.split('-')[2] : '?';
};