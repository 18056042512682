import styled, { css } from 'styled-components';

import TextCell from './TextCell';
import { createTerminalDisplayName } from '../../../../utils/formatTerminal';
import { theme } from 'styled/theme';

type DeviceLabelProps = {
  backgroundColor: string;
  color: string;
}

type TerminalTypeCellProps = {
  thingType: string;
  deviceIndex: string;
}

const DeviceLabel = styled.div<DeviceLabelProps>`
  ${(props) => css`background-color: ${props.backgroundColor}`};
  border-radius: 4px;
  ${(props) => css`color: ${props.color}`};
  padding: 2px 12px;
`;

const TerminalTypeCell = ({ thingType, deviceIndex }: TerminalTypeCellProps) => {
  const terminalLabel = createTerminalDisplayName(thingType, deviceIndex);
  return (
    <TextCell>
      {thingType === 'ssbt' && <DeviceLabel backgroundColor={theme.colors.ssbt} color={theme.colors.secondarySsbt700}>
        {terminalLabel}
      </DeviceLabel>}
      {thingType === 'hub' && <DeviceLabel backgroundColor={theme.colors.grey50} color={theme.colors.grey700}>
        {terminalLabel}
      </DeviceLabel>}
      {thingType === 'gm' && <DeviceLabel backgroundColor={theme.colors.fobt25} color={theme.colors.grey700}>
        {terminalLabel}
      </DeviceLabel>}
      {thingType === 'handheld' && <DeviceLabel backgroundColor={theme.colors.secondaryHhbt50} color={theme.colors.secondarySsbt700}>
        {terminalLabel}
      </DeviceLabel>}
    </TextCell>
  );
};

export default TerminalTypeCell;