import { cloudWatchLogs } from 'aws/Aws';
import { PutLogEventsRequest } from 'aws-sdk/clients/cloudwatchlogs';
import { BackofficeAlert } from 'types/alert/GeneralAlert';

export const sendAlertToCloudWatch = async (alertToAcknowledge: BackofficeAlert, actionPerformed: string) => {
  try {
    const logGroupName= 'gaming-retail/things/alerts';
    let logStreamDescription = await cloudWatchLogs.describeLogStreams({
      logGroupName: logGroupName,
      logStreamNamePrefix: alertToAcknowledge.thing_id,
    }).promise();
    
    if (!logStreamDescription.logStreams || logStreamDescription.logStreams.length !== 1) {
      try {
        await cloudWatchLogs.createLogStream({
          logGroupName: logGroupName,
          logStreamName: alertToAcknowledge.thing_id,
        }).promise();
        logStreamDescription = await cloudWatchLogs.describeLogStreams({
          logGroupName: logGroupName,
          logStreamNamePrefix: alertToAcknowledge.thing_id,
        }).promise();
      } catch {      
        console.error('Cannot create a log stream to log the acknowledgement.', {
          logGroupName: logGroupName,
          logStreamNamePrefix: alertToAcknowledge.thing_id,
        });}

    }

    const logStream = logStreamDescription.logStreams?.find(ls => ls.logStreamName === alertToAcknowledge.thing_id);
    if (!logStream) {
      console.error('Cannot find a log stream to log the acknowledgement.', {
        logGroupName: logGroupName,
        logStreamNamePrefix: alertToAcknowledge.thing_id,
      });

      return;
    }

    const payload: PutLogEventsRequest = {
      logGroupName: logGroupName,
      logStreamName: alertToAcknowledge.thing_id,
      sequenceToken: logStream?.uploadSequenceToken,
      logEvents: [
        {
          message: JSON.stringify({
            ...alertToAcknowledge,
            acknowledged: `Acknowledged, ${actionPerformed}`, 
          }),
          timestamp: Date.now(),
        },
      ],
    };
    await cloudWatchLogs.putLogEvents(payload).promise();
  } catch (error) {
    console.error(error);
  }
};