import { useEffect, useState } from 'react';
import PubSub from 'pubsub-js';
import Button from 'components/Buttons/Button';
import Icon from 'components/Icon';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import Modal from '../../Modal';
import { ButtonsStyled, ContentStyled, SpinnerStyled, SubtitleStyled, TextLineStyled, TitleStyled, TitleWrapper } from './UnsuccessfulModal.styled';
import { getUnsuccessfulModalContent } from '../modalContent';
import { formatString } from 'utils/string';
import { PlayerAction } from 'types/Player';

interface UnsuccessfulModalProps {
  close: () => void;
  isOpen: boolean;
  options: string[];
  playerAction: PlayerAction;
}

const UnsuccessfulModal = ({ isOpen, close, options, playerAction }: UnsuccessfulModalProps) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [successDelay, setSuccessDelay] = useState<number | undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    PubSub.subscribe('app.modal.option.selected', onOptionSelected);
    return () => {
      PubSub.unsubscribe(onOptionSelected);
    };
  }, []);

  const onOptionSelected = (topic: string, option: string) => {
    setSelectedOption(option);
  };

  const { title, subtitle, textContent, cancelLabel, confirmLabel } = getUnsuccessfulModalContent()[playerAction];

  const Title = () => {
    return (
      <TitleWrapper>
        <TitleStyled data-test-id='modal-title'>{title}</TitleStyled>
        <SubtitleStyled>
          <Icon name='critical' width={32} height={32}/> {subtitle}
        </SubtitleStyled>
      </TitleWrapper>
    );
  };

  const onCancel = () => {
    PubSub.publish('app.modal.dismiss');
    close();
  };

  const onConfirm = () => {
    PubSub.publish(`app.modal.confirm.${playerAction}`);
    setLoading(true);
  };

  useEffect(() => {
    PubSub.subscribe('app.modal.success', onModalSuccess);
    return () => {
      PubSub.unsubscribe(onModalSuccess);
    };
  }, []);

  const onModalSuccess = () => {
    setLoading(false);
    close();
  };

  useEffect(() => {
    PubSub.subscribe('app.modal.fail', onModalFail);
    return () => {
      PubSub.unsubscribe(onModalFail);
    };
  }, []);

  const onModalFail = () => {
    setLoading(false);
  };

  const formatContent = (contentText: string) => {
    const unselectedOption = options.find(o => o !== selectedOption) || '';
    return formatString(contentText, {
      selectedOption,
      unselectedOption, 
    });
  };

  const renderTextLine = (textLine: string | JSX.Element | null, index: number) => {
    if (!textLine) return;

    const line = typeof textLine === 'string' ? formatContent(textLine): textLine;

    return <TextLineStyled key={`text-content-${index}`}>{line}</TextLineStyled>;
  };

  return ( 
    <Modal dataTestId='unsuccessful-modal' isOpen={isOpen} onClose={onCancel} showCloseButton={false} title={<Title/>} width='620px'>
      <ContentStyled>
        {textContent.map(renderTextLine)}
        <ButtonsStyled>
          <Button data-test-id='modal-cancel-button' onClick={onCancel} color='primary600' background='grey100' borderless wide>
            {cancelLabel}
          </Button>
          <Button data-test-id='modal-confirm-button' onClick={onConfirm} color='white' background='primary600' borderless wide disabled={loading}>
            {loading
              ? <SpinnerStyled data-test-id='modal-button-loading-spinner'><LoadingSpinner/></SpinnerStyled>
              : <span>{confirmLabel}</span>
            }
          </Button>
        </ButtonsStyled>
      </ContentStyled>
    </Modal>
  );
};

export default UnsuccessfulModal;
