export const validateRequired = (request: Record<string, unknown>, keys?: string[]) => {
  const missing = [];
  const selectedKeys = keys ? keys : Object.keys(request);
  for (const key of selectedKeys) {
    if (request[key]) continue;
    missing.push(key);
  }
  if (missing.length) {
    throw new Error(`Missing mandatory parameters: ${missing}`);
  }
};
