import styled, { css } from 'styled-components';
import { Color } from 'types/Color';

type ValueProps = {
  positive?: boolean;
  negative?: boolean;
  padding?: string;
  fontSize?: string;
  color?: Color;
}

const Value = styled.p<ValueProps>`
${(props) => props.fontSize ? css`font-size: ${props.fontSize}` : 'font-size: 24px'};
${(props) => props.padding ? css`padding: ${props.padding}` : null};

color: ${(props) => props.color ? props.theme.colors[props.color] : props.theme.colors.grey900};
display: flex;
gap: 13px;

${(props) => props.positive ? css`
  color: ${props.theme.colors.success600};

  svg {
    path {
      stroke: ${props.theme.colors.success600};
    }
  }
` : null};

${(props) => props.negative ? css`
  color: ${props.theme.colors.error600};

  svg {
    path {
      stroke: ${props.theme.colors.error600};
    }
  }
` : null};
`;

export default Value;
