import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { ArrowRightOutlined } from '@ant-design/icons';
import { DatePicker, Table } from 'antd';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import type { Moment } from 'moment';
import useDisclosure from 'hooks/useDisclosure';
import useMachines from 'hooks/useMachines';

import { MachineMap } from 'types/machine/MachineType';

import Flex from 'styled/Flex';

import Badge from 'components/Badge';
import Button from 'components/Buttons';
import Input from 'components/Input';
import TerminalPickerModal from 'components/Modal/TerminalPickerModal';
import { AngleDownIcon, SearchIcon } from 'components/History/History.styled';

import { createTerminalDisplayName, createTerminalDisplayNameFromMachineId } from 'utils/formatTerminal';
import { azSorter } from 'utils/sorters';
import { SortOrder } from 'antd/lib/table/interface';
import { retailCurrency } from 'retail-currency';

import {
  Filters,
  ListingContainer,
  SelectedDate,
  SummaryTiles,
  Tile,
  TileTitle,
  TileValue,
  SearchFilters,
  FiltersHeader,
  TerminalPickerLabel,
} from './VoucherListing.styled';
import useVouchers from 'hooks/useVouchers';
import useShop from 'hooks/useShop';
import useDebounce from 'hooks/useDebounce';
import { formatEpochSecond } from 'utils/formatDate';
import { Voucher, VoucherOffline } from 'types/Voucher';
import StatusBadge from '../StatusBadge';
import useApi from 'hooks/useApi';

const stringRowSorter =  <T,>(a: T, b: T, propertyToSortBy: keyof T) => {
  if (a[propertyToSortBy]===null || a[propertyToSortBy]===undefined) return 1;
  if (b[propertyToSortBy]===null || b[propertyToSortBy]===undefined) return -1;
  if (a[propertyToSortBy]===b[propertyToSortBy])  return 0;

  return a[propertyToSortBy] < b[propertyToSortBy] ? -1: 1;
};

const redeemedColumns = [
  {
    title: 'Terminal type',
    dataIndex: 'voucherSource',
    render(text: string, voucher: Voucher) {
      const machineNumber = voucher?.machineId && 2 in voucher?.machineId?.split('-') ? voucher.machineId.split('-')[2] : '';
      return <Flex>
        <Badge
          borderRadius='4'
          inlineBlock={true}
          text={`${text} ${machineNumber}`}
          color={text === 'FOBT' ? 'secondaryFobt25' : 'secondarySsbt25'}
          textColor={text === 'FOBT' ? 'secondaryFobt700' : 'secondarySsbt700'}
        />
      </Flex>;
    },
    sorter: (a: Voucher, b: Voucher) => stringRowSorter(a, b, 'voucherSource'),
  },
  {
    title: 'Time redeemed',
    dataIndex: 'redeemedAt',
    render(redeemedAt: any) {
      return redeemedAt === null ? '' : format(new Date(redeemedAt), 'dd/MM/yyyy, hh:mm:ss');
    },
    defaultSortOrder: 'descend' as SortOrder,
    sorter: (a: Voucher, b: Voucher) => {
      if (a.redeemedAt === null) return 1;
      if (b.redeemedAt === null) return -1;
      if (a.redeemedAt === b.redeemedAt) return 0;
      const a_epoch_seconds = new Date(a.redeemedAt).getTime();
      const b_epoch_seconds = new Date(b.redeemedAt).getTime();
      return a_epoch_seconds<b_epoch_seconds ? -1 : 1;
    },
  },
  {
    title: 'Redeemed via',
    dataIndex: 'redeemedVia',
    sorter: (a: Voucher, b: Voucher) => stringRowSorter(a, b, 'redeemedVia'),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    render(text: string) {
      return retailCurrency.format(parseInt(text) / 100);
    },
    sorter: (a: Voucher, b: Voucher) => a.amount-b.amount,
  },
  {
    title: 'Ticket reference number',
    dataIndex: 'barcode',
    sorter: (a: Voucher, b: Voucher) => stringRowSorter(a, b, 'barcode'),

  },
  {
    title: 'Alerts',
    width: 400,
    render(text: string, voucher: Voucher) {
      const isOffline = voucher.online === false;

      return (
        <Flex gap='8px' wrap='wrap'>
          {isOffline ? <StatusBadge text='Ticket printed offline' textColor='primary600' color='grey100'/> : null}
        </Flex>
      );
    },
  },
];

const unredeemedColumns = [
  {
    title: 'Terminal type',
    dataIndex: 'voucherSource',
    render(text: string, voucher: Voucher) {
      const machineNumber = voucher?.machineId && 2 in voucher?.machineId?.split('-') ? voucher.machineId.split('-')[2] : '';
      return <Flex>
        <Badge
          borderRadius='4'
          inlineBlock={true}
          text={`${text} ${machineNumber}`}
          color={text === 'FOBT' ? 'secondaryFobt25' : 'secondarySsbt25'}
          textColor={text === 'FOBT' ? 'secondaryFobt700' : 'secondarySsbt700'}
        />
      </Flex>;
    },
    sorter: (a: Voucher, b: Voucher) => stringRowSorter(a, b, 'voucherSource'),
  },
  {
    title: 'Time printed',
    dataIndex: 'createdAt',
    render: (createdAt: any) => {
      return createdAt && createdAt.epochSecond && typeof createdAt.epochSecond === 'number' ? formatEpochSecond(createdAt.epochSecond) : (!createdAt ? '' : format(new Date(createdAt), 'dd/MM/yyyy, hh:mm:ss'));
    },
    defaultSortOrder: 'descend' as SortOrder,
    sorter: (a: Voucher, b: Voucher) => {
      if (!a.createdAt) return 1;
      if (!b.createdAt) return -1;
      if (a.createdAt === b.createdAt) return 0;
      const a_epoch_seconds = Number(a.createdAt.epochSecond);
      const b_epoch_seconds = Number(b.createdAt.epochSecond);
      return a_epoch_seconds<b_epoch_seconds ? -1 : 1;
    },
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    render(text: string) {
      return retailCurrency.format(parseInt(text) / 100);
    },
    sorter: (a: Voucher, b: Voucher) => a.amount - b.amount,
  },
  {
    title: 'Ticket reference number',
    dataIndex: 'barcode',
    sorter: (a: Voucher, b: Voucher) => stringRowSorter(a, b, 'barcode'),
  },
  {
    title: 'Alerts',
    width: 400,
    render(text: string, voucher: Voucher) {
      const isOffline = voucher.online === false;

      return (
        <Flex gap='8px' wrap='wrap'>
          {isOffline ? <StatusBadge text='Ticket printed offline' textColor='primary600' color='grey100'/> : null}
        </Flex>
      );
    },
  },
];

const { RangePicker } = DatePicker;
const DEFAULT_PAGE_SIZE = 10;

interface VoucherListingProps {
  type: 'redeemed' | 'unredeemed';
}

const VoucherListing = ({ type }: VoucherListingProps ) => {
  const [barcode, setBarcode] = useState('');
  const uid = useDebounce(barcode, 750);
  const terminalPicker = useDisclosure();
  const { machines } = useMachines();
  const [selectedTerminals, setSelectedTerminals] = useState<MachineMap[]>([]);
  const navigate = useNavigate();
  const shop = useShop();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);

  useEffect(() => {
    setPage(1);
    setPageSize(DEFAULT_PAGE_SIZE);
    setBarcode('');
  }, [type]);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const { api, svsRedeemedVouchers, svsUnredeemedVouchers } = useApi();

  useEffect(() => {
    api?.getRedeemedVouchersList();
    api?.getUnredeemedVouchersList();
  }, [api, type]);

  const [selectedDate, setSelectedDate] = useState<{ dateFrom?: Date, dateTo?: Date}>({
    dateFrom: undefined,
    dateTo: undefined,
  });

  const { totals, vouchers, isLoading, error } = useVouchers({
    dateFrom: selectedDate.dateFrom,
    dateTo: selectedDate.dateTo,
    type: undefined,
    shopId: shop.shopId,
    ...(uid.length > 0 && { uid }),
    ...(selectedTerminals.length > 0 && { terminal: selectedTerminals.map(t => t.machineId) }),
  });

  const uniqueVouchers = useMemo(() => {
    let svsUniqueVouchers: VoucherOffline[] = [];
    let rdsvouchers: Voucher[] = [];

    if (svsRedeemedVouchers && svsUnredeemedVouchers && vouchers) {
      svsUniqueVouchers = [...svsRedeemedVouchers, ...svsUnredeemedVouchers];

      const svsVouchers = type === 'redeemed' ? svsRedeemedVouchers : svsUnredeemedVouchers;
      const svsFilteredUniqueVouchers = svsVouchers.filter(localVoucher => {
        const match = vouchers.find(v => v.uid === localVoucher.uid);

        if (match && match.paidOut === true && localVoucher.redeemed === true) {
          return false;
        }
        if (match && match.paidOut === true && localVoucher.redeemed === false) {
          return false;
        }
        return true;
      });

      if (uid.length > 0) {
        svsUniqueVouchers = svsFilteredUniqueVouchers.filter(voucher => voucher.uid === uid);
      } else {
        svsUniqueVouchers = svsFilteredUniqueVouchers;
      }
    }

    if (vouchers && vouchers?.length > 0) {
      rdsvouchers = vouchers.filter(rdsVoucher => {
        const match = [...svsRedeemedVouchers, ...svsUnredeemedVouchers].find(s => s.uid === rdsVoucher.uid);
        if (match) {
          if (match.redeemed === true && rdsVoucher.paidOut === true) {
            return true;
          }
          if (match.redeemed === false && rdsVoucher.paidOut === true) {
            return true;
          }
          return false;
        }
        return true;
      });
    }

    if (vouchers && svsRedeemedVouchers && svsUnredeemedVouchers) {
      const vouchersByType = type === 'redeemed' ? [...rdsvouchers, ...svsUniqueVouchers]
        .filter((v) => v.redeemed === true || v.paidOut === true) : [...rdsvouchers, ...svsUniqueVouchers]
        .filter((v) => v.redeemed === false || v.paidOut === false);

      vouchersByType.forEach(voucherData => {
        //@ts-ignore
        const createdAt = 'createdAt' in voucherData && voucherData.createdAt && voucherData.createdAt.epochSecond ? voucherData.createdAt : { epochSecond: ~~(new Date(voucherData.createdAt).getTime() / 1000) };

        if ('createdAt' in voucherData) {
          if (voucherData.createdAt && !voucherData.createdAt.epochSecond) {
            voucherData.createdAt = createdAt;
          }
        } else {
          //@ts-ignore
          voucherData.createdAt = createdAt;
        }

        if (voucherData.redeemed === true || voucherData.paidOut === true) {
          if (!('redeemedAt' in voucherData)) {
            //@ts-ignore
            voucherData.redeemedAt = createdAt;
          }
        }
      });

      return [...vouchersByType];
    }
  }, [vouchers, svsRedeemedVouchers, svsUnredeemedVouchers, type, uid]);

  const onChangeDateRange = (values: [Moment, Moment] | null) => {
    let dateFrom = undefined;
    let dateTo = undefined;
    if (values !== null && values.length === 2) {
      dateFrom = new Date(values[0].toDate().setHours(0, 0, 0, 0));
      dateTo = new Date(values[1].toDate().setHours(23, 59, 59, 99));
    }
    setSelectedDate({
      dateFrom,
      dateTo,
    });
  };

  const getDisplayedDeviceName = (device: MachineMap) => {
    return device.machineState.shop_device_index
      ? createTerminalDisplayName(device.thingType, device.machineState.shop_device_index)
      : createTerminalDisplayNameFromMachineId(device.machineId);
  };

  const getTerminalPickerLabel = () => {
    if (selectedTerminals.length === 0 || selectedTerminals.length === machines.length) {
      return 'All Terminals';
    } else {
      const labels: string[] = [];
      selectedTerminals.map(t => labels.push(getDisplayedDeviceName(t)));
      return labels.sort(azSorter).join(', ');
    }
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBarcode(e.target.value);
  };

  const handleClickSingleRow = ( voucher : Voucher | VoucherOffline) => {
    return {
      onClick: () => voucher.isNotSynced || !voucher.synchronized ? navigate(`/history/${voucher.uid}`, {
        state: { ...voucher },
      }) : navigate(`/history/${voucher.uid}`),
    };
  };

  if (error) {
    console.error(error);
  }

  return (
    <>
      <ListingContainer>
        {(selectedDate.dateFrom && selectedDate.dateTo) ? (
          <SelectedDate>
            <>
              <span>{format(new Date(selectedDate.dateFrom), 'cccc, d MMMM yyyy')}</span>
              {selectedDate.dateTo && new Date (selectedDate.dateTo).getDate() !== new Date (selectedDate.dateFrom).getDate() ? <span>{' '}<ArrowRightOutlined />{' '}{format(new Date(selectedDate.dateTo), 'cccc, d MMMM yyyy')}</span> : null}
            </>
          </SelectedDate>
        ) : null}
        <Filters>
          <SummaryTiles>
            <Tile>
              <TileTitle data-test-id='voucher-listing-type'>{type === 'redeemed' ? 'Redeemed' :'Unredeemed'}</TileTitle>
              {totals && <TileValue>{retailCurrency.format(totals[type === 'redeemed' ? 'totalRedeemed' : 'totalUnredeemed'] / 100)}</TileValue>}
            </Tile>
            <Tile>
              <TileTitle>Total</TileTitle>
              {totals && <TileValue data-test-id='total-amount'>{retailCurrency.format(totals.totalAmount / 100)}</TileValue>}
            </Tile>
          </SummaryTiles>
          <SearchFilters>
            <FiltersHeader>Search / Filters:</FiltersHeader>
            <Flex justifyContent='space-between'>
              <Flex flex='1 1 0' padding='0 5px 0 0'>
                {<Input borderColor='grey300' prefix={<SearchIcon />} placeholder='Search by player / ticket' padding='13px 5px 10px 5px' wide value={barcode} onChange={handleSearchChange}/>}
              </Flex>
              <Flex flex='1 1 0' padding='0 5px'>
                <Button onClick={terminalPicker.open} justifyContent='space-between' wide>
                  <TerminalPickerLabel>{getTerminalPickerLabel()}</TerminalPickerLabel>
                  <AngleDownIcon />
                </Button>
              </Flex>
              <Flex flex='1 1 0' padding='0 0 0 5px'>
                {/*
                  // @ts-ignore */}
                <RangePicker size='large' style={{ flex: '1' }} onChange={onChangeDateRange} />
              </Flex>
            </Flex>
          </SearchFilters>
        </Filters>
        {type === 'redeemed' && <Table
          //@ts-ignore
          columns={redeemedColumns}
          loading={isLoading}
          pagination={{
            position: ['topRight', 'bottomRight'],
            current: page,
            pageSize,
            onChange: (page, paginationSize) => setPageSize(paginationSize),
          }}
          onChange={(e) => setPage(e.current || 1)}
          dataSource={[...uniqueVouchers || []] || []}
          onRow={handleClickSingleRow}/>}
        {type === 'unredeemed' && <Table
          //@ts-ignore
          columns={unredeemedColumns}
          loading={isLoading}
          pagination={{
            position: ['topRight', 'bottomRight'],
            current: page,
            pageSize,
            onChange: (page, paginationSize) => setPageSize(paginationSize),
          }}
          onChange={(e) => setPage(e.current || 1)}
          dataSource={[...uniqueVouchers || []] || []}
          onRow={handleClickSingleRow}/>}
      </ListingContainer>
      {terminalPicker.isOpen &&
          <TerminalPickerModal
            isOpen={terminalPicker.isOpen}
            close={terminalPicker.close}
            machines={machines}
            currentTerminals={selectedTerminals}
            setCurrentTerminals={setSelectedTerminals}
          />
      }
    </>
  );
};

export default VoucherListing;
