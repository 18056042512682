import { ChangeEvent, useEffect, useState } from 'react';
import { LabelledCheckboxContainer, LabelText, StyledCheckbox } from './LabelledCheckbox.styled';

interface CheckboxProps {
  dataTestId?: string;
  disabled?: boolean;
  label: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  selected?: boolean;
  value?: string | undefined;
}

const LabelledCheckbox = ({ dataTestId, label, selected, ...rest }: CheckboxProps) => {
  const [checked, setChecked] = useState(selected);

  const handleChange = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    if (selected !== checked) {
      handleChange();
    }
  }, [selected]);

  return (
    <LabelledCheckboxContainer data-test-id='labelled-checkbox-container'>
      <StyledCheckbox type='checkbox' {...rest} onClick={handleChange} checked={checked} data-test-id={dataTestId || 'checkbox'} />
      <LabelText>{label}</LabelText>
    </LabelledCheckboxContainer>
  );
};

export default LabelledCheckbox;
