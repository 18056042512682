import HeaderCell from './Cells/HeaderCell';
import TextCell from './Cells/TextCell';
import { Column } from 'types/session/TableData';
import { theme } from 'styled/theme';

export const logsColumns: Column[] = [
  {
    title: <HeaderCell>Game Logs</HeaderCell>,
    dataIndex: 'logs',
    key: 'logs',
    width: '100%',
    render(text: string) {
      return <TextCell>{text}</TextCell>;
    },
    onCell: () => {
      return { style: { background: theme.colors.white } };
    },
  },
];
