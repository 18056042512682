import styled, { css } from 'styled-components';

export const MessageContainer = styled.div<{ type: string }>`
  font-size: 24px;
  padding: 16px 20px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 0px 16px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  ${(props) => props.type === 'warning' && css`border-left: 5px solid ${props.theme.colors.warning600}`};
  ${(props) => props.type === 'error' && css`border-left: 5px solid ${props.theme.colors.error600}`};
  ${(props) => props.type === 'success' && css`border-left: 5px solid ${props.theme.colors.success600}`};
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;

  span {
    min-width: 40px;
    min-height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 4px;

    svg {
      margin: 0;
      padding: 0
    }
  }

  ${(props) => props.type === 'error' ? css`
    span {
      background: ${props.theme.colors.error600};

      svg {
        path {
          fill: ${props.theme.colors.white};
          stroke: ${props.theme.colors.white};
        }
      }
    }
  ` : null}

  ${(props) => props.type === 'warning' ? css`
    span {
      background: ${props.theme.colors.warning600};

      svg {
        path {
          fill: ${props.theme.colors.white};
          stroke: ${props.theme.colors.white};
        }
      }
    }
  ` : null}

  ${(props) => props.type === 'success' ? css`
    span {
      background: ${props.theme.colors.success600};

      svg {
        path {
          stroke: ${props.theme.colors.white};
        }
      }
    }
  ` : null}
`;

export const Messages = styled.ul<{ margin?: string }>`
  flex: 1;
  ${(props) => props.margin ? css`margin: ${props.margin}` : css`margin-bottom: 12px;`};
`;

export const ListItem = styled.li`
  margin-bottom: 8px;
`;