import { v4 as uuidv4 } from 'uuid';

import { buildPayloadGaming, buildPayloadRetailCommand } from 'utils/payloadBuilder';
import { getConstant } from 'utils/constants';
import { logError } from 'newrelic';
import { validateRequired } from 'utils/requestValidator';
import { getPubSubIot } from 'aws/Aws';

const gmOverRetail = getConstant('REACT_APP_GM_OVER_RETAIL', 'true') === 'true';

const startSessionGaming = async (machineId: string) => {
  validateRequired({ machineId });
  const payload = {};
  const body = buildPayloadGaming(payload);
  try {
    await getPubSubIot().publish(`${machineId}/state/session/start/request`, body);
  } catch (err) {
    logError('Cannot start Gaming session', 'critical', err);
  }
};

const startSessionRetail = async (machineId: string, deviceUuid: string | undefined, deviceType: string | undefined) => {
  validateRequired({
    machineId,
    deviceUuid,
  });
  const sessionId = uuidv4();
  const machineParts = machineId.split('-');
  const session = {
    sessionId,
    startedAt: new Date().toISOString(),
    startTrigger: 'BACKOFFICE',
    deviceType,
    shopId: machineParts[1],
    shopDeviceIndex: machineParts[2],
    staked: 0,
  };
  const volatile = {
    balance: 0,
    //connected: true,  // TODO get from shadow attributes instead?
  };
  const clientId = 'backoffice-' + machineParts[1];
  const payload = buildPayloadRetailCommand('start-session', clientId, machineId, {
    ...session,
    ...volatile,
  });
  try {
    console.log(`Publishing to cmd/device/${deviceUuid}/session with payload`, payload);
    await getPubSubIot().publish(`cmd/device/${deviceUuid}/session`, payload);
  } catch (err) {
    logError('Cannot start session', 'critical', err);
  }
};

const endSessionGaming = async (machineId: string) => {
  validateRequired({ machineId });
  const payload = {};
  const body = buildPayloadGaming(payload);
  try {
    await getPubSubIot().publish(`${machineId}/state/session/end/request`, body);
  } catch (err) {
    logError('Cannot end Gaming session', 'critical', err);
  }
};

const endSessionRetail = async (machineId: string, deviceUuid: string | undefined, sessionId: string,) => {
  validateRequired({
    machineId,
    deviceUuid,
    sessionId,
  });
  const machineParts = machineId.split('-');
  const clientId = 'backoffice-' + machineParts[1];
  const payload = buildPayloadRetailCommand('update-session', clientId, machineId, {
    sessionId,
    endedAt: new Date().toISOString(),
    endTrigger: 'BACKOFFICE',
    connected: true,
  });

  try {
    console.log('Publishing to', `cmd/device/${deviceUuid}/session`, 'with payload', payload);
    await getPubSubIot().publish(`cmd/device/${deviceUuid}/session`, payload);
  } catch (err) {
    logError('Cannot end session', 'critical', err);
  }
};

export const startSession = async (machineId: string, deviceUuid: string | undefined, deviceType: string | undefined) => {
  if (machineId.startsWith('gm')) {
    console.log('startSessionGaming');
    startSessionGaming(machineId);
  }
  if (!machineId.startsWith('gm') || gmOverRetail) {
    console.log('startSessionRetail');
    startSessionRetail(machineId, deviceUuid, deviceType);
  }
};

export const endSession = async (machineId: string, deviceUuid: string | undefined, sessionId: string) => {
  if (machineId.startsWith('gm')) {
    console.log('endSessionGaming');
    endSessionGaming(machineId);
  }
  if (!machineId.startsWith('gm') || gmOverRetail) {
    console.log('endSessionRetail');
    endSessionRetail(machineId, deviceUuid, sessionId);
  }
};

export const tagSession = async (machineId: string, deviceUuid: string | undefined, sessionId: string, nicknameId: string, nickname: string): Promise<void> => {
  validateRequired({
    machineId,
    deviceUuid,
    sessionId,
    nicknameId,
    nickname,
  });
  const taggedAt = new Date().toISOString();
  const machineParts = machineId.split('-');
  const clientId = 'backoffice-' + machineParts[1];
  const payload = buildPayloadRetailCommand('update-session', clientId, machineId, {
    sessionId,
    nicknameId,
    nickname,
    taggedAt,
  });

  console.log('Tagging a session via IOT with payload', payload);

  try {
    await getPubSubIot().publish(`cmd/device/${deviceUuid}/session`, payload);
    console.log('Publishing to', `${machineId}/state/user/tag`, 'with payload', payload);
    getPubSubIot().publish(`${machineId}/state/user/tag`, payload);
  } catch (err) {
    logError(`Could not tag session: ${err}`, 'critical', err);
  }
};

export const untagSession = async (machineId: string, deviceUuid: string | undefined, sessionId: string): Promise<void> => {
  validateRequired({
    machineId,
    deviceUuid,
    sessionId,
  });
  const machineParts = machineId.split('-');
  const clientId = 'backoffice-' + machineParts[1];
  const payload = buildPayloadRetailCommand('update-session', clientId, machineId, {
    sessionId,
    nicknameId: null,
    nickname: null,
    taggedAt: null,
  });
  const payloadGaming = buildPayloadGaming({ nickname: null });
  console.log('Untagging a session via IOT with payload', payload);

  try {
    await getPubSubIot().publish(`cmd/device/${deviceUuid}/session`, payload);
    console.log('Publishing to', `${machineId}/state/user/tag`, 'with payload', payloadGaming);
    getPubSubIot().publish(`${machineId}/state/user/tag`, payloadGaming);
  } catch (err) {
    logError(`Could not untag session: ${err}`, 'critical', err);
  }
};
