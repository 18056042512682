import styled, { css } from 'styled-components';

type PlayerStyledProps = {
  isLastElement?: boolean;
}

export const PlayerStyled = styled.div<PlayerStyledProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 24px;
  height: fit-content;
  padding: 24px;
`;

export const PlayerSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;

export const PlayerEntryDates = styled.div`
  display: flex;
  font-size: 20px;
  font-weight: 400;
  gap: 24px;
  line-height: 30px;
`;

export const ComplianceEditors = styled.div`
  display: flex;
  flex: 1;
  gap: 24px;
  margin-top: 24px;
`;

export const DatePickerStyled = styled.div<PlayerStyledProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
`;
