import { useRef, useEffect } from 'react';

type VoidCallback = () => void;

const useInterval = (callback: VoidCallback, delay: number) => {
  const savedCallback = useRef<VoidCallback>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const tick = () => {
      if (savedCallback.current) {
        savedCallback.current();
      }
    };

    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};

export default useInterval;
