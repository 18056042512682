import styled from 'styled-components';

export const TabsList = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Tab = styled.div<{ active: boolean }>`
  background: #FFFFFF;
  border-radius: 4px 4px 0px 0px;
  padding: 13px 22px;
  margin-right: 4px;
  font-size: 20px;
  line-height: 30px;

  color: ${(props) => props.active ? props.theme.colors.primary600 : props.theme.colors.grey500};
`;

export const Content = styled.div`
  padding: 24px;
  background: #FFFFFF;
  border-radius: 8px 8px 0px 0px;
`;
