import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export default styled(NavLink)`
  display: flex;
  gap: 16px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.grey700};
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  margin: 8px 0px;

  svg {
    padding: 12px;
    border-radius: 4px;
    background: ${(props) => props.theme.colors.grey100};

    path {
      fill: ${(props) => props.theme.colors.grey700};
    }
  }

  &.active {
    background: ${(props) => props.theme.colors.primary25};
    color: ${(props) => props.theme.colors.primary600};

    svg {
      background: ${(props) => props.theme.colors.primary600};

      path {
        fill: ${(props) => props.theme.colors.white};
      }
    }
  }
`;
