import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import * as PubSubJs from 'pubsub-js';
import debounce from 'lodash.debounce';
import { getPubSubIot, iot, iotdata } from 'aws/Aws';
import useShop from 'hooks/useShop';
import { MachineMap, MachineAttributes, MachineState, Notification, BonusAlerts } from 'types/machine/MachineType';
import { SubscriptionList } from 'types/aws/SubscriptionTypes';
import { ThingList, ThingNameList } from 'types/aws/ThingListTypes';
import { logError } from 'newrelic';
import { sanitisedMachineState } from 'utils/fetchHelpers';
import { getTopicFromUpdate, isComplianceTopic, isValidBonusResponse, isValidTopic } from 'utils/messageValidator';
import isObject from 'utils/isObject';

const useMachinesProvider = () => {
  const [machines, setMachines] = useState<MachineMap[]>([]);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [subscriptions, setSubscriptions] = useState<SubscriptionList>([]);
  const [stateAlerts, setStateAlerts] = useState<Set<number>>(new Set);
  const [bonusAlerts, setBonusAlerts] = useState<BonusAlerts | null>(null);
  const { shopId } = useShop();

  useEffect(() => {
    setMachinesStates();

    return () => {
      unsubscribe();
    };
  }, [shopId]);

  const unsubscribe = () => {
    subscriptions.forEach((topic: any) => {
      topic.unsubscribe();
    });
  };

  const getMachinesIds = async (shopId: string) => {
    const params = {
      thingGroupName: shopId,
      maxResults: 100,
    };
    try {
      const thingsList: ThingList = await iot.listThingsInThingGroup(params).promise();

      return thingsList.things;
    } catch (err) {
      logError(`Failed to get machine ids: ${err}`, 'high', err);
    }
  };

  const getMachineAttributes = async (machineId: string): Promise<MachineAttributes | undefined> => {
    try {
      const response = await iot.describeThing({ thingName: machineId }).promise();
      const thingType = machineId.split('-')[0]; // or get from response.thingTypeName ?
      const deviceUuid = response.attributes?.deviceUuid || machineId;

      return {
        thingType,
        deviceUuid,
      };
    } catch (err) {
      console.log(err);
      logError(`Failed to get machine attributes: ${err}`, 'high', err);
    }
  };

  const getMachineShadowState = async (machineId: string): Promise<MachineState | undefined> => {
    const volatile = {
      connected: false,
      account_id: 'SHARED', // FIXME Only for gm will this be the actual Customer a/c
      balance: 0,
    };

    try {
      const params = {
        thingName: machineId,
      };
      const shadowState = await iotdata.getThingShadow(params).promise();

      // @ts-ignore
      const { state } = JSON.parse(shadowState.payload);

      if (state.reported.account_id && state.reported.balance) {
        return state.reported;
      } else {
        return {
          ...volatile,
          ...state.reported,
        };
      }
    } catch (err) {
      console.error(`error while getThingShadow for thing ${machineId}`);

      const session = {
        session_id: '',
        device_type: machineId.startsWith('hub') ? 'RH1' : null,
      };
      const reported = {
        ...volatile,
        ...session,
        schema_version: 1,
      };

      const request = {
        thingName: machineId,
        payload: JSON.stringify({ state: { reported } }),
      };
      await iotdata.updateThingShadow(request).promise();
      //@ts-ignore
      return reported;
    }
  };

  const setMachinesStates = async () => {
    if (!shopId) {
      console.warn('shopId not set');
      return;
    }
    console.info(`Setting machine states for shop ${shopId}`);

    const machinesIds: ThingNameList = await getMachinesIds(shopId);

    if (machinesIds && machinesIds.length > 0) {
      try {
        const machineStates: { machineId: string; machineState: MachineState | undefined; }[] = await Promise
          .all(
            machinesIds
              .map(async (machineId: string) => {
                const machineAttributes = await getMachineAttributes(machineId);
                let machineState = await getMachineShadowState(machineId) as MachineState;

                if (!machineState.session_id) {
                  machineState = sanitisedMachineState(machineState);
                }

                return {
                  machineId: machineId,
                  ...machineAttributes,
                  machineState,
                };
              })
          );

        // @ts-ignore
        const sortedFilteredStates: MachineMap[] = machineStates
          .filter(state => state.machineState?.account_id)
          .sort((a, b) => a.machineId.localeCompare(b.machineId));

        const subscribedTopics: SubscriptionList = subscribeToUpdates(sortedFilteredStates);
        const subscribedNotificationTopics: SubscriptionList = subscribeToNotifications(sortedFilteredStates);
        const subscribedBonusTopics: SubscriptionList = subscribeToBonusesNotifications(sortedFilteredStates);

        setMachines(sortedFilteredStates);
        setSubscriptions([
          ...subscribedTopics,
          ...subscribedNotificationTopics,
          ...subscribedBonusTopics,
        ]);
      } catch (err) {
        console.log(err);
        logError(`Failed to set machine states: ${err}`, 'high', err);
      }
    }
  };

  const subscribeToUpdates = (machineStates: MachineMap[]): Array<any> => {
    return machineStates.flatMap((machineState) => {
      const topics = [
        `$aws/things/${machineState.machineId}/shadow/update/accepted`,
      ];
      return topics.map((topic) =>
        getPubSubIot()
          .subscribe(topic)
          // @ts-ignore
          .subscribe((data) => {
            const update = data?.value || data;
            console.info('subscribeToUpdates subscription', update);
            updateMachine(machineState, update?.state?.reported);
          })
      );
    });
  };

  const subscribeToBonusesNotifications = (machineStates: MachineMap[]): Array<any> => {
    return machineStates.flatMap((machineState) => {
      const topics = [
        `${machineState.machineId}/bonus/+/response/error`,
      ];
      return getPubSubIot().subscribe(topics)
        // @ts-ignore
        .subscribe((data) => {
          const update = data?.value || data;
          if (!isValidBonusResponse(update)) {
            return;
          }

          const topic = getTopicFromUpdate(update);
          if (!isValidTopic(topic)) {
            return false;
          }
          
          const machineId = machineState.machineId;
          const slug = update?.payload?.slug;
          setBonusAlerts({
            machineId,
            slug,
            opting: topic.includes('optin') ? 'opt-in' : 'opt-out',
            index: machineStates.findIndex((machineState) => machineState.machineId === machineId) + 1,
          });
        });
    });
  };

  const subscribeToNotifications = (machineStates: MachineMap[]): Array<any> => {
    return machineStates.flatMap((machineState) => {
      // there is a limit of subscriptions/connections, 
      const topics = [
        `${machineState.machineId}/notification/#`,
      ];
      return getPubSubIot().subscribe(topics)
        // @ts-ignore
        .subscribe((data) => {
          const update = data?.value || data;
          console.info('subscribeToNotifications subscription', update);
          if (!isObject(update)) {
            return;
          }

          const topic = getTopicFromUpdate(update);
          if (!isValidTopic(topic) || isComplianceTopic(topic)) {
            return;
          }

          addNotification(machineState.machineId, update);
        });
    });
  };

  // To indirectly force requery of DB sessions, and to allow enough time for the IoT rule in
  // Retail Session Service to have saved the shadow to the database
  const notifyLiveSessionsEnded = debounce(() => {
    PubSubJs.publish('app.live.sessions.ended');
  }, 1500, {
    leading: false,
    trailing: true, 
  });

  const activeNotificationCheck = (notification: Notification, oldNotifications: Notification[]) => {
    const alreadyActiveNotification = oldNotifications.some((n) => n.machineId === notification.machineId && n.message === notification.message);

    return alreadyActiveNotification;
  };

  const addNotification = (machineId: string, { payload }: any) => {
    setNotifications(prev => {
      if (activeNotificationCheck(payload, prev)) {
        return [...prev];
      }
      return [...prev, {
        machineId,
        ...payload,
      }];
    });
  };

  const updateMachine = (machine: MachineMap, updates: any) => {
    if (!updates) {
      console.error('Invalid updates sent to updateMachine', updates);
      return;
    }

    setMachines((prevState: MachineMap[]) => {
      const alertIndex = Number(machine.machineId.split('-')[2]);
      const index = prevState.findIndex((machineState) => machineState.machineId === machine.machineId);
      const newState: MachineMap[] = [...prevState];

      if (machine.machineState.connected !== updates.connected && updates.connected === false) {
        setStateAlerts(stateAlerts.add(alertIndex));
      }

      if (updates.ended_at) {
        // replace
        newState[index].machineState = sanitisedMachineState(updates as MachineState);
      }

      else if (updates.started_at) {
        // replace
        newState[index].machineState = {
          ...prevState[index].machineState, // keep old state fields so fobt machines are not replaced with only 7 new fields 
          ...updates,
          ended_at: '', // reset ended_at to '' so ssbt live sessions do not have any time
        };

      } else {
        newState[index].machineState = {
          ...prevState[index].machineState,
          ...updates,
        };
      }

      // resets fobt ui for the nicknames 
      if (updates.session_end_at) {
        newState[index].machineState = {
          ...prevState[index].machineState,
          nickname: '',
          nickname_id: '',
        };

      }

      if (updates.ended_at) {
        console.log('notifyLiveSessionsEnded given newState', newState[index]);
        notifyLiveSessionsEnded();
      }

      return newState;
    });
  };

  return {
    bonusAlerts,
    stateAlerts,
    setStateAlerts,
    notifications,
    setNotifications,
    machines,
    subscriptions,
    unsubscribe,
  };
};

type PrivateModeContextData = ReturnType<typeof useMachinesProvider>;

export const MachinesContext = createContext<PrivateModeContextData | null>(null);

type MachinesProviderProps = PropsWithChildren<unknown>

const MachinesProvider = ({ children }: MachinesProviderProps) => {
  return <MachinesContext.Provider value={useMachinesProvider()}>{children}</MachinesContext.Provider>;
};

export default MachinesProvider;
