import { RetailCommand } from 'types/ApiTypes';

const generateId = (len: number) => {
  const arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);

  return Array.from(arr, dec2hex).join('');
};

const dec2hex = (dec: number) => dec.toString(16).padStart(2, '0');

export const generateCorrelationId = () => generateId(16);

export const buildPayloadGaming = (payload: Record<string, unknown>): Record<string, unknown> => {
  return {
    meta: {
      message_correlation_id: generateCorrelationId(),
      timing: {
        start: new Date().toISOString(),
        published_at: '',
        received_at: '',
      },
    },
    payload,
  };
};

export const buildPayloadRetailCommand = (cmd: string, clientId: string, machineId: string, params: Record<string, unknown>): RetailCommand => {
  return {
    clientId,
    requestId: generateCorrelationId(),
    metadata: {
      thingName: machineId,
    },
    cmd,
    params,
  };
};
