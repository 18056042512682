import useAlerts from 'hooks/useAlerts';
import Button from 'components/Buttons';
import {
  AlertContainer as AC,
  AlertType,
  AlertButtons,
  AlertMessageContainer,
  AlertMessage,
} from './Alert.styled';
import { BackofficeAlertWithDate, AlertType as BackofficeAlertType, AlertCategory as BackofficeAlertCategory, Actions as BackofficeAlertActions, AlertTypeEnum, VisibilityType, VisibilityTypeEnum } from 'types/alert/GeneralAlert';
import useMachines from 'hooks/useMachines';
import useShop from 'hooks/useShop';
import { sendAlertToCloudWatch } from 'utils/cloudWatchLogHelper';
import { v4 as uuid4 } from 'uuid';
import { createTerminalDisplayNameFromMachineId } from 'utils/formatTerminal';

interface Actions {
  primary: [string, () => void]
  secondary?: [string, (() => void)?]
}

interface AlertProps {
  message: string,
  type: BackofficeAlertType,
  visibility: VisibilityType,
  actions?: Actions
}

const Alert = ({ message, type, visibility, actions }: AlertProps) => {
  const primaryAction = actions?.primary ?? undefined; 
  const secondaryAction = actions?.secondary ?? undefined;
  
  return (
    visibility === VisibilityTypeEnum.DASHBOARD || visibility === VisibilityTypeEnum.EVERYWHERE ?
      <AC data-test-id='type' type={type}>
        <AlertMessageContainer>
          <AlertMessage data-test-id='alert-message'>{message}</AlertMessage>
        </AlertMessageContainer>

        <AlertButtons>
          {primaryAction && <Button secondary onClick={primaryAction[1]} data-test-id='primary-button'>{primaryAction[0]}</Button>}
          {secondaryAction && <Button primary onClick={secondaryAction[1]} data-test-id='secondary-button'>{secondaryAction[0]}</Button>}
        </AlertButtons>
      </AC> : null
  );
};

export const AlertContainer = () => {
  const { alerts, acknowledgeAlertById, machinesInErrorMode } = useAlerts();  
  const { stateAlerts, setStateAlerts, machines } = useMachines();
  const { shopId } = useShop();

  const createActions = (alert: BackofficeAlertWithDate): Actions => {
    if (alert.actions.length == 2 && alert.actions[0] == 'yes' && alert.actions[1] == 'no') {
      return {
        primary: ['Yes', () => acknowledgeAlertById(alert.alert_id, 'clicked "Yes"')],
        secondary: ['No', () => acknowledgeAlertById(alert.alert_id, 'clicked "No"')],
      };
    }

    return {
      primary: ['Close', () => acknowledgeAlertById(alert.alert_id, 'clicked "Close"')],
    };
  };

  const createActionsOfflineAlert = (fobtNumber: number): Actions => {
    
    const machine = machines.filter(m => m.machineId.split('-')[2] === fobtNumber.toString())[0];
    let sessionId: null|string = null;
    if (machine){
      sessionId = machine.machineState.session_id;
    }

    return {
      primary: ['Close', ()=>{
        stateAlerts.delete(fobtNumber);
        setStateAlerts(stateAlerts);
        const terminalId = fobtNumber.toString().padStart(2, '0');
        const thingId = ['gm', shopId, terminalId].join('-');
        // Build the alert, since it's NOT received from the machine,
        // which is currently offline
        const machineOfflineAlert = {
          alert_id: uuid4().toString(), 
          session_id: sessionId,
          name: 'Terminal offline',
          message: `FOBT ${fobtNumber} is disconnected`,
          actions: ['close'] as BackofficeAlertActions,
          thing_id: thingId,
          alert_type: 'error' as BackofficeAlertType,
          category: 'Terminal' as BackofficeAlertCategory,
          acknowledged: null,
          visibility: VisibilityTypeEnum.EVERYWHERE,
        };
        sendAlertToCloudWatch(machineOfflineAlert, 'clicked "Close"');
      }],
    };
  };

  return (
    <div>
      {alerts.map((alert) => (
        <Alert
          visibility={alert.visibility}
          key={'alert' + alert.alert_id}
          type={alert.alert_type}
          message={alert.message}
          actions={createActions(alert)}
        />
      ))}

      {Array.from(stateAlerts).map((fobtNumber, index) => (
        <Alert
          visibility={VisibilityTypeEnum.DASHBOARD}
          key={`${fobtNumber}+${index}`}
          type={AlertTypeEnum.ERROR}
          message={`FOBT ${fobtNumber} is disconnected`}
          actions={createActionsOfflineAlert(fobtNumber)}
        />
      ))}
    </div>
  );
};

export default AlertContainer;
