import { useState } from 'react';
import { ReportsRootContainer } from './ReportsRoot.styled';
import { TabsList, Tab, Content } from 'styled/Tabs';
import EndOfDayReports from './EndOfDayReports';

const ReportsRoot = () => {
  const [activeTab, setActiveTab] = useState('eod');

  return (
    <ReportsRootContainer>
      <TabsList>
        <Tab active={activeTab === 'eod'} onClick={() => setActiveTab('eod')}>End of day</Tab>
      </TabsList>
      <Content>
        {activeTab === 'eod' && <EndOfDayReports />}
      </Content>
    </ReportsRootContainer>
  );
};

export default ReportsRoot;
