import Button from 'components/Buttons';
import styled, { css } from 'styled-components';

interface Openable {
  isOpen: boolean;
}

export const DrawerContainer = styled.div`
  position: absolute;
`;

export const DrawerDialog = styled.div<Openable & { position: 'left' | 'right' }>`
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 0px 16px rgba(16, 24, 40, 0.06);
  overflow-y: scroll;
  position: fixed;
  top: 0;
  transition: transform 0.3s ease;
  width: 25vw;
  z-index: 1000;
  ${(props) => props.position === 'left' ? css`left: 0` : css`right: 0`};
  height: 100%;
  transform: translateX(${(props) => props.position === 'left' ? '-100%' : '+100%'});

  ${(props) => props.isOpen && props.position === 'left' && css`
    transform: translateX(${({ theme }) => theme.size.asideWidth})
  `}

  ${(props) => props.isOpen && props.position === 'right' && css`
    transform: translateX(0px)
  `}
`;

export const Backdrop = styled.div<Openable>`
  visibility: hidden;
  opacity: 0;
  background: rgba(59, 78, 103, 0.45);
  transition: opacity 0.3s ease, visibility 03.s ease;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  z-index: 0;
  ${(props) => props.isOpen && css`
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    z-index: 999;
  `}
`;

export const DrawerTitle = styled.h3`
  font-family: WHHoxton-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 48px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.grey900};
  padding: 24px 20px;
`;

export const DrawerHeading = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const DrawerClose = styled.a`
  padding: 14px;
  align-items: center;
  line-height: 14px;
  display: flex;
  box-sizing: border-box;
  border-radius: 4px;
  
  svg {
    path {
      fill: ${(props) => props.theme.colors.grey700};
    }
  }
`;

export const SquareButton = styled(Button)`
  width: 56px;
  height: 56px;
`;
