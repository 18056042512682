import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Button from 'components/Buttons';
import Drawer from 'components/Drawer';
import SessionTimer from 'components/SessionTimer';
import TileDrawerTitle from 'components/Drawer/TileDrawer/TileDrawerTitle';
import { endSession } from 'api/iotSessionApi';
import { lockTerminal, logsSync, resetMachineState } from 'api/iotTransactionApi';
import { Label, TileInfoContainer, Value, DrawerBody, DrawerContent, Buttons, WideDiv, DrawerContentHeader } from 'components/Tile/Tile.styled';
import { MachineMap, MachineMode, MachineModeState } from 'types/machine/MachineType';
import { getConstant } from 'utils/constants';
import DeviceAlerts from 'components/Demo/DeviceAlerts';
import TileDrawerDeposit from './TileDrawerDeposit/TileDrawerDeposit';
import useCashinResponse from 'hooks/useCashinResponse';
import { BackofficeAlert, BackofficeAlertWithDate } from 'types/alert/GeneralAlert';
import { retailCurrency } from 'retail-currency';

interface TileDrawerProps {
  isOpen: boolean;
  onOpenTagSession: (sessionId: string) => void;
  close: () => void;
  machine: MachineMap;
  terminalName: string;
  groupName: string;
  alerts: BackofficeAlertWithDate[];
  dismissAlertById: (id: BackofficeAlert['alert_id']) => void;
}

const lockingFeature = getConstant('REACT_APP_LOCKING') === 'true';
const creditingFeature = getConstant('REACT_APP_CREDITING') === 'true';
const alertingFeature = getConstant('REACT_APP_ALERTING') === 'true';
const environment = getConstant('REACT_APP_NEW_RELIC_ENV');

const TileDrawer = ({ machine, isOpen, onOpenTagSession, close, terminalName, groupName, alerts, dismissAlertById }: TileDrawerProps) => {
  const { 
    machineId, 
    deviceUuid, 
    machineState: {
      started_at,
      session_start_at,
      session_end_at,
      real_name,
      nickname,
      game,
      balance,
      committed_funds,
      staked,
      machine_mode,
      machine: machineState,
      connected,
    },
  } = machine;
  const [drawerPage, setDrawerPage] = useState('root');
  const returnToRoot = () => setDrawerPage('root');

  // FIXME This needs flattening, it is too many levels deep
  // and it will cause issues if used in an IoT rule (which flattens the fields)
  const [lockedState, setLockedState] = useState <MachineModeState>(MachineMode.READY);
  const [loading, setLoading] = useState <boolean> (false);

  const cashoutInProgress = machine.machineState.cashout_in_progress ?? false;
  const buttonStateText = lockedState === 'locked' ? 'Unlock Terminal' : 'Lock Terminal';
  const sessionId = machine.machineState.session_id;
  const endTime = session_end_at;
  let startTime = endTime ? '' : started_at || session_start_at;
  startTime = startTime ? startTime.substring(11, 16) : '';
  const sessionInProgress = Boolean(sessionId && startTime);
  const realBalance = balance - committed_funds;

  useEffect(() => {
    setLockedState(machine_mode);
  }, [machine_mode]);
  
  const { cashinResponses, removeCashinResponse } = useCashinResponse(machineId);
  
  const onLockUnlockClicked = () => {
    setLoading(true);

    switch (lockedState) {
      case 'ready':
        console.info('onLockUnlockClicked to locked');
        setLockedState(MachineMode.LOCKED);
        machine_mode && lockTerminal(machineId, deviceUuid, MachineMode.LOCKED);
        break;
      case 'locked':
        console.info('onLockUnlockClicked to ready');
        setLockedState(MachineMode.READY);
        machine_mode && lockTerminal(machineId, deviceUuid, MachineMode.READY);
        break;
      default:
        break;
    }

    setTimeout(() => {
      setLoading(false);
    }, 15000);
  };

  const onEndSessionClicked = () => {
    console.info('onEndSessionClicked', {
      machineId,
      deviceUuid,
      sessionId,
    });
    endSession(machineId, deviceUuid, sessionId);
  };

  const renderDrawerContent = () => {
    switch (drawerPage) {
      case 'root':
        return (
          <React.Fragment>
            <DrawerContentHeader>
            Terminal Info
            </DrawerContentHeader>
            <TileInfoContainer>
              <WideDiv>
                <Label>Player's name</Label>
                <Value>{sessionInProgress ? (real_name || nickname) : ''}</Value>
              </WideDiv>
              <div>
                <Label>Start Time</Label>
                <Value>{startTime}</Value>
              </div>
              {game && (
                <div>
                  <Label>Game</Label>
                  <Value>{game}</Value>
                </div>
              )}
              <div>
                <Label>Cash Balance</Label>
                <Value>{retailCurrency.format(realBalance / 100)}</Value>
              </div>
              {machineState?.funds && <div>
                <Label>Total</Label>
                <Value>{retailCurrency.format(machineState?.funds.total_cashbox / 100)}</Value>
              </div>}
              <div>
                <Label>Staked</Label>
                <Value>{retailCurrency.format(staked / 100)}</Value>
              </div>
              <div>
                <Label>Timer</Label>
                <Value>{sessionInProgress ? <SessionTimer machine={machine} /> : 'Not started'}</Value>
              </div>
            </TileInfoContainer>

            <Buttons>
              {creditingFeature && <Button data-test-id='deposit-button' disabled={loading} onClick={() => setDrawerPage('deposit')} secondary>Deposit</Button>}
              {environment === 'dev' && creditingFeature && <Button disabled={loading} cta data-test-id='withdraw-button'>Withdraw</Button>}
              {lockingFeature && <Button disabled={loading} onClick={onLockUnlockClicked}>{buttonStateText}</Button>}
              <Button><Link to='/history'>History</Link></Button>
              {sessionInProgress && <Button onClick={() => onOpenTagSession(sessionId)}>{nickname ? 'Change Player' : 'Tag Player'}</Button>}
              {environment !== 'prod' && sessionInProgress && <Button onClick={onEndSessionClicked} data-test-id='end-session-button'>End Session</Button>}
              {environment !== 'prod' ? <Button onClick={() => logsSync(machineId)}>Sync Logs</Button> : null}
              {environment === 'dev' ? <Button onClick={() => resetMachineState(machineId)}>Reset machine state</Button> : null}
            </Buttons>
          </React.Fragment>
        );
      case 'deposit':
        return (
          <TileDrawerDeposit 
            returnToRoot={returnToRoot}
            machineId={machineId}
            deviceUuid={deviceUuid}
            accountId={machine.machineState.account_id}
            lockedState={lockedState}
            isConnected={connected}
            cashoutInProgress={cashoutInProgress}
          />
        );
    }
  };

  return (
    <Drawer
      dataTestId='tile-drawer'
      isOpen={isOpen}
      onClose={close}
      title={<TileDrawerTitle terminalName={terminalName} groupName={groupName} />}
      position='right'
    >
      <DrawerBody>
        {alertingFeature && machine && 
          <DeviceAlerts 
            removeCashinResponse={removeCashinResponse}
            cashinResponses={cashinResponses}
            machine={machine}
            alerts={alerts}
            dismissAlertById={dismissAlertById}
          />}
        <DrawerContent>
          {renderDrawerContent()}
        </DrawerContent>
      </DrawerBody>
    </Drawer>
  );
};

export default TileDrawer;
