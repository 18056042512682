import styled from 'styled-components';
import { Color } from 'types/Color';

export interface BadgeProps {
    inlineBlock?: boolean;
    textColor: Color;
    borderRadius?: string;
    color: Color;
    testId?: string;
    text?: string;
  }

export const Span = styled.span<{ background: BadgeProps['color'], borderRadius: BadgeProps['borderRadius'], inlineBlock: BadgeProps['inlineBlock'] }>`
  background-color: ${(props) => props.theme.colors[props.background]};
  max-height: 32px;
  padding: 4px 12px;
  border-radius: ${(props) => props.borderRadius ? props.borderRadius : '22px'};
  display: ${(props) => props.inlineBlock ? 'inline-block' : 'flex'};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  white-space: nowrap;
`;

export const Paragraph = styled.span<{ color: BadgeProps['color'] }>`
  color: ${(props) => props.theme.colors[props.color]};
  font-size: 16px;
`;
