import PubSub from 'pubsub-js';
import moment from 'moment';

import { SummarySession, SessionHistory } from 'types/session/SummarySession';
import { getConstant } from 'utils/constants';
import { logError } from 'newrelic';
import { throwErrorIfBadResponse } from 'utils/fetchHelpers';

moment.locale('en-gb');

const endpoint = getConstant('REACT_APP_RETAIL_SESSION_URL');

export interface SessionsInShopParams {
  fromDate: Date;
  toDate: Date;
  shopId?: string;
  terminalIndex?: string;
  nicknameId?: string;
  offset?: number;
  limit: number;
  thingType?: 'ssbt' | 'gm';
}

export interface SessionHistoryInShopParams {
  fromDate: Date;
  toDate: Date;
  shopId?: string;
  terminalIndex?: string;
  nicknameId?: string;
  offset?: number;
  limit: number;
}

const toISODateString = (date: Date) => {
  if (!date) return date;
  return moment(date).format('YYYY-MM-DD');
};

export const getSessionsInShop = async ({
  fromDate,
  toDate,
  shopId,
  terminalIndex,
  nicknameId,
  offset = 0,
  limit = 1000,
  thingType,
}: SessionsInShopParams): Promise<SummarySession[] | SessionHistory[]> => {
  if (!fromDate || !toDate) {
    throw new Error('Missing mandatory date parameters');
  }

  if (!shopId && !nicknameId) {
    return [];
  }

  const urlSearchParams = new URLSearchParams({
    fromDate: toISODateString(fromDate),
    toDate: toISODateString(toDate),
    limit: String(limit),
    offset: String(offset),
  });

  if (shopId) {
    urlSearchParams.append('shopId', shopId);
  }

  if (terminalIndex) {
    urlSearchParams.append('shopDeviceIndex', terminalIndex);
  }

  if (nicknameId) {
    urlSearchParams.append('nicknameId', nicknameId);
  }

  if (thingType) {
    urlSearchParams.append('thingType', thingType);
  }

  const url = `${endpoint}/sessions/mysql?${urlSearchParams.toString()}`;

  try {
    const response = await fetch(url);
    // TODO FUTURE - may need to map shopDeviceIndex to terminalIndex for compatibility with components that use that legacy field name
    return throwErrorIfBadResponse(response);
  } catch (err) {
    logError(`Failed to get sessions for shop: ${err}`, 'critical', err);
    PubSub.publish('app.error.history.sessions', 'Could not get sessions');
    throw new Error('Failed to get sessions for shop');
  }
};

export const getSessionHistoryInShop = async ({ fromDate, toDate, shopId, terminalIndex, nicknameId, limit, offset = 0 }: SessionHistoryInShopParams): Promise<SessionHistory[]> => {
  if (!fromDate || !toDate) {
    throw new Error('Missing mandatory date parameters');
  }

  if (!shopId) {
    return [];
  }

  let url = `${endpoint}/sessions/mysql/thingtype/gm?shopId=${shopId}&fromDate=${toISODateString(fromDate)}&toDate=${toISODateString(toDate)}&limit=${limit}&offset=${offset}`;

  if (terminalIndex) {
    url += `&shopDeviceIndex=${terminalIndex}`;
  }
  if (nicknameId) {
    url += `&nicknameId=${nicknameId}`;
  }

  try {
    const response = await fetch(url);
    // TODO FUTURE - may need to map shopDeviceIndex to terminalIndex for compatibility with components that use that legacy field name
    return throwErrorIfBadResponse(response);
  } catch (err) {
    logError(`Failed to get sessions for shop: ${err}`, 'critical', err);
    PubSub.publish('app.error.history.sessions', 'Could not get sessions');
    throw new Error('Failed to get sessions for shop');
  }
};

export const tagSession = async (sessionId: string, nicknameId: string, nickname: string, machineId: string,): Promise<void> => {
  if (!sessionId || !nicknameId || !nickname) {
    throw new Error('Missing mandatory parameters');
  }

  const taggedAt = new Date().toISOString();
  const url = `${endpoint}/sessions/mysql`;
  const body: Record<string, unknown> = {
    sessionId,
    nicknameId,
    nickname,
    taggedAt,
  };

  const [deviceType] = machineId.split('-');
  if (deviceType === 'gm') {
    body['deviceType'] = 'gm';
  }
  console.log('sessionApi | Tagging a session with', url, 'with body', body);

  try {
    const response = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return throwErrorIfBadResponse(response);
  } catch (err) {
    console.error(err);
    PubSub.publish('app.error.drawer.player', err);
    throw new Error(`Failed to tag '${nickname}'`);
  }
};

export const untagSession = async (sessionId: string, machineId: string): Promise<void> => {
  if (!sessionId) {
    throw new Error('Missing mandatory parameters');
  }

  const url = `${endpoint}/sessions/mysql`;
  const body: Record<string, unknown> = {
    sessionId,
    nicknameId: null,
    nickname: null,
    taggedAt: null,
  };

  const [deviceType] = machineId.split('-');
  if (deviceType === 'gm') {
    body['deviceType'] = 'gm';
  }

  console.log('sessionApi | Untagging a session with', url, 'with body', body);

  try {
    const response = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return throwErrorIfBadResponse(response);
  } catch (err) {
    console.error(err);
    PubSub.publish('app.error.drawer.player', err);
    throw new Error('Failed to untag session');
  }
};
