import NavLink from 'components/NavLink';

interface MenuItemProps {
  children: string;
  icon: JSX.Element;
  link: string;
  dataTestId?: string;
  onClick?: () => void;
}

const MenuItem = ({ children, icon, link, dataTestId, onClick }: MenuItemProps) => (
  <li>
    <NavLink to={link} onClick={onClick} data-test-id={dataTestId}>
      {icon}
      {children}
    </NavLink>
  </li>
);

export default MenuItem;
