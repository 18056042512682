import HeaderCell from './Cells/HeaderCell';
import PlayerCell from './Cells/PlayerCell';
import LimitsCell from './Cells/LimitsCell';
import TerminalTypeCell from './Cells/TerminalTypeCell';
import TextCell from './Cells/TextCell';
import { Column, DataSource, SessionHistoryColumn } from 'types/session/TableData';
import { azSorter, dateSorter } from 'utils/sorters';
import { theme } from 'styled/theme';
import { SessionHistory } from 'types/session/SummarySession';
import Icon from 'components/Icon';
import { retailCurrency } from 'retail-currency';
import { sessionHighlightCell } from 'utils/playerTaggingSessionFormatter';

const displayDateIfNotToday = (text: string) => {
  const date = new Date(text);
  if (date.toLocaleDateString('en-GB') === new Date().toLocaleDateString('en-GB')) {
    return date.toLocaleTimeString();
  } else {
    return `${date.toLocaleDateString('en-GB')}, ${date.toLocaleTimeString()}`;
  }
};

export const columns: Column[] = [
  {
    title: <HeaderCell>Terminal Type</HeaderCell>,
    dataIndex: 'thingType',
    key: 'terminalType',
    sorter: (a: DataSource, b: DataSource) => azSorter(`${a.thingType} ${a.deviceIndex}`, `${b.thingType} ${b.deviceIndex}`),
    sortDirections: ['ascend', 'descend'],
    width: '11%',
    render(text: string, record: DataSource) {
      return <TerminalTypeCell thingType={record.thingType} deviceIndex={record.deviceIndex} />;
    },
    onCell: (record: DataSource) => {
      return sessionHighlightCell(record.staked, record.isLiveSession, record.player);
    },
  },
  {
    title: <HeaderCell>Start</HeaderCell>,
    dataIndex: 'start',
    key: 'start',
    sorter: (a: DataSource, b: DataSource) => dateSorter(a.start, b.start),
    sortDirections: ['descend', 'ascend'],
    width: '13%',
    render(text: string) {
      return <TextCell>{text ? displayDateIfNotToday(text) : 'Not Started'}</TextCell>;
    },
    onCell: (record: DataSource) => {
      return sessionHighlightCell(record.staked, record.isLiveSession, record.player);
    },
  },
  {
    title: <HeaderCell>End</HeaderCell>,
    dataIndex: 'end',
    key: 'end',
    sorter: (a: DataSource, b: DataSource) => dateSorter(a.end, b.end),
    sortDirections: ['descend', 'ascend'],
    width: '13%',
    render(text: string) {
      return <TextCell data-test-id={text? 'end-set' : 'no-end-set' }>{text ? displayDateIfNotToday(text) : '-'}</TextCell>;
    },
    onCell: (record: DataSource) => {
      return sessionHighlightCell(record.staked, record.isLiveSession, record.player);
    },
  },
  {
    title: <HeaderCell>Player</HeaderCell>,
    dataIndex: 'player',
    key: 'player',
    sorter: (a: DataSource, b: DataSource) => azSorter(a.player, b.player),
    sortDirections: ['ascend', 'descend'],
    render(text: string, record: DataSource) {
      return <PlayerCell playerName={text} sessionData={record} />;
    },
    onCell: (record: DataSource) => {
      return sessionHighlightCell(record.staked, record.isLiveSession, record.player);
    },
  },
  {
    title: <HeaderCell>Total Stakes</HeaderCell>,
    dataIndex: 'totalStakes',
    key: 'totalStakes',
    sorter: (a: DataSource, b: DataSource) => a.staked - b.staked,
    sortDirections: ['ascend', 'descend'],
    width: '45%',
    render(text: string) {
      return <TextCell>{text}</TextCell>;
    },
    onCell: (record: DataSource) => {
      return sessionHighlightCell(record.staked, record.isLiveSession, record.player);
    },
  },
];

export const sessionHistoryColumns: SessionHistoryColumn[] = [
  {
    title: <HeaderCell>Terminal Type</HeaderCell>,
    dataIndex: 'thingType',
    key: 'terminalType',
    width: '11%',
    render(text: string, record: SessionHistory) {
      return <TerminalTypeCell thingType={record.thingType} deviceIndex={record.terminalId?.split('-')[2]} />;
    },
  },
  {
    title: <HeaderCell>Start</HeaderCell>,
    dataIndex: 'startedAt',
    key: 'startedAt',
    width: '11%',
    render(text: string) {
      return <TextCell>{text ? displayDateIfNotToday(text) : 'Not Started'}</TextCell>;
    },
  },
  {
    title: <HeaderCell>End</HeaderCell>,
    dataIndex: 'endedAt',
    key: 'endedAt',
    width: '11%',
    render(text: string) {
      return <TextCell data-test-id={text? 'end-set' : 'no-end-set'}>{text ? displayDateIfNotToday(text) : '-'}</TextCell>;
    },
  },
  {
    title: <HeaderCell>Player</HeaderCell>,
    dataIndex: 'player',
    key: 'player',
    width: '15%',
    render(text: string, record: SessionHistory) {
      const data = {
        totalStaked : record.staked,
        plusUser: record.plusAccountId ? true : false,
        tagPlayerHandler: record.tagPlayerHandler,
        sessionId: record.sessionId,
        start: record.startedAt,
        end: record.endedAt,
      };
      const displayName = record.nickname ? record.nickname : text;
      return <PlayerCell playerName={displayName} sessionData={data} />;
    },
  },
  {
    title: <HeaderCell>Total credits</HeaderCell>,
    dataIndex: 'totalStakes',
    key: 'totalStakes',
    width: '10%',
    render(text: string, record: SessionHistory) {
      return <TextCell>{retailCurrency.format(record.totalLoaded / 100)}</TextCell>;
    },
  },
  {
    title: <HeaderCell>Win / loss</HeaderCell>,
    dataIndex: 'grossWin',
    key: 'grossWin',
    width: '10%',
    render(text: string, record: SessionHistory) {
      const win = (record.grossWin - record.staked) / 100;
      return <TextCell>{<Icon name={win > 0 ? 'arrow-up' : 'arrow-down'} width={20} height={30}/>}{retailCurrency.format(win)}</TextCell>;
    },
  },
  {
    title: <HeaderCell>Loss & time limits</HeaderCell>,
    dataIndex: 'totalStakes',
    key: 'totalStakes',
    width: '12%',
    render(text: string, record: SessionHistory) {
      return <LimitsCell data={record}/>;
    },
  },
];
