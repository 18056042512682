import { getPubSubIot } from 'aws/Aws';
import { logError } from 'newrelic';
import { EncodedVoucherPayload, FraudFlagType, Voucher, VoucherOffline } from 'types/Voucher';
import { getConstant } from 'utils/constants';
import { generateCorrelationId } from 'utils/payloadBuilder';
import { getFraudFlagType } from './voucherApi';
import MqttApi from 'api';

interface GetVoucherDetailsArgs {
  machineId: string;
  barcode: string;
}

const getVoucherDetails = ({ machineId, barcode }: GetVoucherDetailsArgs) => {
  try {
    const body = {
      meta: {
        message_correlation_id: generateCorrelationId(),
      },
      payload: {
        'barcode': barcode,
        'ssbt_id': getConstant('REACT_APP_SSBT_ID'),
        'uid': '134rtbg',
      },
    };
    console.log('getting voucher details ', `${machineId}/voucher/query/request`, body);
    getPubSubIot().publish(`${machineId}/voucher/query/request`, body);
  } catch (err) {
    logError('Cannot get Voucher details', 'critical', err);
  }
};

const payoutVoucher = async (voucher: Voucher | VoucherOffline | EncodedVoucherPayload, api: MqttApi) => {
  const fraudFlagType = await getFraudFlagType(voucher);
  try {
    if ('synchronized' in voucher) {
      payoutOfflineVoucher(api, voucher, fraudFlagType);
    } else {
      await payoutOnlineVoucher(voucher, fraudFlagType);
    }
  } catch (err) {
    logError('Cannot payout a Voucher', 'critical', err);
  }
};

const payoutOnlineVoucher = async ({ barcode, amount, shop: shopId, machineId }: Voucher | VoucherOffline | EncodedVoucherPayload, fraudFlagType: FraudFlagType) => {
  const body = {
    meta: {
      message_correlation_id: generateCorrelationId(),
    },
    payload: {
      barcode: barcode,
      ssbt_id: '000003-05',
      uid: barcode.slice(-16),
      amount_pence: amount,
      shop_id: shopId,
      fraudFlagType,
    },
  };
  const topic = `${machineId}/voucher/redeem/request`;
  console.log('Paying out an online voucher', barcode);
  return await getPubSubIot().publish(topic, body);
};

const payoutOfflineVoucher = (api: MqttApi, voucher: Voucher | VoucherOffline | EncodedVoucherPayload, fraudFlagType: FraudFlagType) => {
  console.log('Paying out an offline voucher', voucher.barcode);
  api?.payoutOfflineVoucher(voucher, fraudFlagType);
  api?.getSvsVoucher(voucher);
};

export { getVoucherDetails, payoutVoucher };
