import { useEffect, useState } from 'react';
import moment from 'moment';
import InformationDropdown from '../InformationDropdown';
import LabelledInput from '../LabelledInput';
import PlayerBio from './PlayerBio';
import PlayerComplianceInfo from './PlayerComplianceInfo';
import PlayerEntryDate from './PlayerEntryDate';
import SelfExclusionEditor from '../SelfExclusionEditor';
import useShop from 'hooks/useShop';
import validation from './validation';
import { ComplianceEditors, DatePickerStyled, PlayerEntryDates, PlayerSection, PlayerStyled } from './Player.styled';
import { oneYearFrom } from 'utils/formatDate';
import { PlayerFormValues, PlayerSelfExclusion, PlayerSelfExclusionsUpdate, Player as PlayerType } from 'types/Player';
import { updatePlayer } from 'api/playerApi';
import DatePicker from 'components/Calendar/DatePicker';
import { Moment } from 'moment';
import { dateSorter } from 'utils/sorters';
import { getSelfExclusionStatus } from 'utils/selfExclusion';

interface PlayerProps {
  allPlayers: PlayerType[];
  isEditMode?: boolean;
  isLastElement?: boolean;
  player: PlayerType;
}
  
const Player = ({ isEditMode, isLastElement, player: playerProp, allPlayers }: PlayerProps) => {
  const [validationErrors, setErrors] = useState({});
  const [player, setPlayer] = useState(playerProp);
  const { selfExclusions, ...playerWithoutSelfExclusions } = player;
  const [formValues, setFormValues] = useState<PlayerFormValues>(playerWithoutSelfExclusions);
  const { createdAt, nicknameId } = player;
  const { comments, description, lastSgiAt, refusalOfServiceId } = formValues;
  const { shopId } = useShop();

  const formattedLastSgiAt = lastSgiAt ? new Date(lastSgiAt) : undefined;

  const handleInputChange = (inputName: keyof PlayerFormValues, inputValue: string | PlayerSelfExclusionsUpdate) => {
    setFormValues(prevState => {
      if (prevState[inputName] === inputValue) {
        return prevState;
      }
      return {
        ...prevState,
        [inputName]: inputValue,
      };
    });
  };

  const validateChanges = () => {
    const { validated, errors } = validation(formValues, player, allPlayers);
    setFormValues(validated);

    if (Object.keys(errors).length === 0) {
      PubSub.publish('app.modal.open', 'update');
    } else {
      setErrors(errors);
    }
    return errors;
  };

  const handleCancelUpdate = () => {
    setFormValues(playerWithoutSelfExclusions);
    setErrors({});
    PubSub.publish('app.modal.success', {
      playerAction: 'cancel-update',
      player, 
    });
  };

  const organiseSelfExclusions = (player: PlayerType, formValues: PlayerFormValues, selfExclusions?: PlayerSelfExclusion[]) => {
    const newSelfExclusions = selfExclusions ? [...selfExclusions] : [];
    if (formValues.selfExclusions?.addValidFrom) {
      const { addValidFrom } = formValues.selfExclusions;
      const newExclusion: PlayerSelfExclusion = {
        selfExclusionId: `new-cached-self-exclusion-${new Date().getMilliseconds()}`,
        selfExclusionShopId: shopId,
        selfExclusionValidFrom: addValidFrom,
        selfExclusionValidTo: oneYearFrom(addValidFrom),
      };
      newSelfExclusions.push(newExclusion);
    }

    const filteredExclusions = newSelfExclusions.filter(se => !formValues.selfExclusions?.deleteIds?.includes(se.selfExclusionId));
    return {
      ...player,
      selfExclusions: filteredExclusions,
    };
  };

  const handleUpdate = () => {
    const updatedPlayer = {
      ...formValues,
      nicknameId,
    } as PlayerType;

    const updatedPlayerWithSelfExclusions = organiseSelfExclusions(updatedPlayer, formValues, selfExclusions);
    setPlayer({
      ...playerProp,
      ...updatedPlayerWithSelfExclusions,
    });
    updatePlayer(updatedPlayer).then(async () => {
      PubSub.publish('app.modal.success', {
        playerAction: 'update',
        player: updatedPlayerWithSelfExclusions, 
      });
      setErrors({});
    }).catch(() => {
      PubSub.publish('app.modal.fail', {
        playerAction: 'update',
        player: updatedPlayerWithSelfExclusions,
      });
    });
  };

  useEffect(() => {
    PubSub.subscribe('app.modal.confirm.cancel-update', handleCancelUpdate); 
    PubSub.subscribe('app.modal.confirm.update', handleUpdate);
    PubSub.subscribe('app.player.edit.validate', validateChanges);
    return () => {
      PubSub.unsubscribe(handleCancelUpdate);
      PubSub.unsubscribe(handleUpdate);
      PubSub.unsubscribe(validateChanges);
    };
  }, [formValues]);
  
  const orderedExclusions = (selfExclusions || []).filter(se => se.selfExclusionShopId === shopId).sort((a,b) => dateSorter(b.selfExclusionValidTo, a.selfExclusionValidTo));
  const latestExclusionStatus = orderedExclusions.length > 0 ? getSelfExclusionStatus(orderedExclusions[0].selfExclusionValidTo) : undefined;
  const hasRefusalOfServiceId = !!refusalOfServiceId;
  
  const onSelectDate = (moment?: Moment) => {
    if (!moment) {
      setFormValues(prevState => ({
        ...prevState,
        lastSgiAt: null,
      }));
    } else {
      const offSetMins = moment.toDate().getTimezoneOffset();
      const newLastSgiAt = moment.startOf('day').subtract(offSetMins, 'minutes').toDate();
      setFormValues(prevState => ({
        ...prevState,
        lastSgiAt: newLastSgiAt,
      }));
    }
  };
  
  return (
    <PlayerStyled data-test-id='dashboard-player' isLastElement={isLastElement}>
      <PlayerSection data-test-id='player-top-section'>
        <PlayerBio isEditMode={isEditMode} formValues={formValues} onInputChange={handleInputChange} validationErrors={validationErrors} />
        <PlayerComplianceInfo isEditMode={isEditMode} player={player}/>
      </PlayerSection>
      <PlayerSection data-test-id='player-bottom-section'>
        <LabelledInput charLimit={255} isEditMode={isEditMode} inputHeight='142px' label='Description' onInputChange={handleInputChange} validationErrors={validationErrors} value={description || undefined} />
        <LabelledInput charLimit={255} isEditMode={isEditMode} inputHeight='142px' label='Comments' onInputChange={handleInputChange} validationErrors={validationErrors} value={comments || undefined} />
      </PlayerSection>
      <PlayerEntryDates data-test-id='player-entry-dates'>
        <PlayerEntryDate label='Last visit date' />
        <PlayerEntryDate date={createdAt} label='Creation date' />
        { isEditMode
          ? 
          (
            <DatePickerStyled> 
              Last SGI date
              <DatePicker dataTestId='last-sgi-date-picker' selectedDate={formattedLastSgiAt} onSelect={onSelectDate}/>
            </DatePickerStyled>
          )
          : <PlayerEntryDate date={lastSgiAt} label='Last SGI date' />
        }
      </PlayerEntryDates>
      {isEditMode &&
        <ComplianceEditors>
          <InformationDropdown dataTestId='player-self-exclusion-dropdown' icon='critical' title='Self exclusion' status={latestExclusionStatus}>
            <SelfExclusionEditor onInputChange={handleInputChange} selfExclusions={selfExclusions} ></SelfExclusionEditor>
          </InformationDropdown>
          <InformationDropdown dataTestId='player-refusal-of-service-dropdown' icon='critical' title='Refusal of service' status={hasRefusalOfServiceId ? 'active' : undefined}>
            <LabelledInput charLimit={6} isEditMode={isEditMode}  inputHeight='52px' label='Refusal of Service ID' onInputChange={handleInputChange} validationErrors={validationErrors} value={refusalOfServiceId || undefined} />
          </InformationDropdown>
        </ComplianceEditors>
      }
    </PlayerStyled>
  );
};

export default Player;