import styled from 'styled-components';

import Button from 'components/Buttons/Button';
import Flex from 'styled/Flex';
import TextCell from './TextCell';
import { Edit1, Plus1 } from 'components/Icon';
import { theme } from 'styled/theme';
import { DataSource } from 'types/session/TableData';
import { getTagButtonLabel, shouldDisplayTagNow } from 'utils/playerTaggingSessionFormatter';

const EditButton = styled.div`
  display: flex;
`;

const EditIcon = styled((props) => <Edit1 {...props} />)`
  display: flex;
  flex-shrink: 0;
  height: 24px;
  margin-right: 12px;
  margin-top: 3px;
  width: 24px;
`;

const PlusLabel = styled((props) => <Plus1 {...props} />)`
  display: flex;
  flex-shrink: 0;
  background-color: ${theme.colors.primary25};
  border-radius: 4px;
  margin-left: 12px;
  height: 34px;
  padding: 8px;
  width: 34px;
`;

const PlayerCell = ({ playerName, sessionData }: any) => {
  const { plusUser, tagPlayerHandler, sessionId, start, end, staked } = sessionData;
  const editButtonId = end ? 'edit-session-tag-button' : 'live-edit-session-tag-button';
  const tagButtonId = end ? 'tag-session-button' : 'tag-live-session-button';
  return playerName ?
    <TextCell justifyContent={'space-between'}>
      <EditButton onClick={tagPlayerHandler(sessionId)}>
        <div data-test-id={editButtonId}><EditIcon /></div>
        {playerName}
      </EditButton>
      {plusUser && <PlusLabel />}
    </TextCell>
    :
    <Flex alignItems='center' justifyContent={'space-between'}>
      {sessionId && start ? <Button data-test-id={tagButtonId} onClick={tagPlayerHandler(sessionId)} tagNow={shouldDisplayTagNow(staked)} >{getTagButtonLabel(staked)}</Button> :
        <TextCell>-</TextCell>
      }
      {plusUser && <PlusLabel />}
    </Flex>;
};

type PlayerCellProps = {
  playerName: string;
  sessionData: DataSource;
}

export default PlayerCell;
