import styled from 'styled-components';

export const SelfExclusionEditorStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateInput = styled.div`
  margin-top: 8px;
  width: 209px;
`;

export const CalendarButton = styled.div`
  align-items: center;
  display: flex;
  gap: 15px;
  svg {
    color: ${props => props.theme.colors.grey500};
  }
`;

export const EditorInput = styled.div`
  align-items: flex-end;
  display: flex;
  flex: 1;
`;

export const ValidDate = styled.div`
  font-size: 20px;
  line-height: 30px;
  margin-right: 50px;
`;

export const ValidToDate = styled.div`
  color: ${props => props.theme.colors.grey500};
  margin: 18px 0 12px;
`;

export const SelfExclusionHistory = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 20px;
  line-height: 30px;
  margin-top: 24px;
`;

export const AddButton = styled.div`
  line-height: 30px;
`;

export const ErrorMessage = styled.div`
  color: ${props => props.theme.colors.error600};
  margin-top: 15px;
`;