import { memo } from 'react';
import Logs from 'components/Logs/Logs';
import useGamingLogs from 'hooks/useGamingLogs';

const GamingHistory = () => {
  const events = useGamingLogs();

  return (
    <Logs type='games' {...events}/>
  );
};

export default memo(GamingHistory);