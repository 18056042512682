import styled from 'styled-components';

export const HeaderStyled = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 104px;
  padding: 0 20px;
  box-shadow: 0px 0px 16px rgba(16, 24, 40, 0.06);
  button {
    height: 56px;
  }
  width: 100vw;
`;//setting width above as a hack to stop strange resizing bug. There's probably a better way to do this

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Version = styled.p`
  color: ${({ theme }) => theme.colors.grey300};
  font-size: 16px;
  margin: 5px 0 0 auto;
`;
