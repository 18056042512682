import { ChangeEvent, useState, memo } from 'react';

import { Card, Cash } from 'components/Icon';
import Button from 'components/Buttons';
import { retailCurrency } from 'retail-currency';

import {
  Container,
  ContentSection,
  Paragraph,
  PaymentMethodButton, 
  Actions,
  PaymentMethods,
  PaymentValues,
  PaymentInputContainer,
  PaymentInputLabel, 
  PaymentInput,
  DepositLimit,
} from './TileDrawerDeposit.styled';
import { creditTerminal } from 'api/iotTransactionApi';
import { MachineModeState } from 'types/machine/MachineType';
import { Popover } from 'antd';
import Flex from 'styled/Flex';

const PAYMENT_DEFINED_VALUES = [5, 10, 15, 20, 25, 30];
const MIN_DEPOSIT = 0.01;
const MAX_DEPOSIT = 200;

interface TileDrawerDepositProps {
  returnToRoot: () => void;
  machineId: string;
  deviceUuid: string | undefined;
  accountId: string;
  lockedState: MachineModeState;
  isConnected: boolean;
  cashoutInProgress: boolean;
}

const TileDrawerDeposit = ({
  returnToRoot,
  machineId,
  deviceUuid,
  accountId,
  lockedState,
  isConnected,
  cashoutInProgress,
}: TileDrawerDepositProps) => {
  const [paymentMethod, setPaymentMethod] =
    useState<'MANUAL_CARD' | 'NOTE' | null>(null);
  const [paymentAmount, setPaymentAmount] = useState<number | null>(null);
  const [paymentCustomAmount, setPaymentCustomAmount] = useState<null | string>(null);

  const handlePaymentInputChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    if (value.includes('.')) {
      const [, decimals] = value.split('.');
      if (decimals.length > 2) {
        return;
      } 
    }

    if (value.startsWith('.')) {
      const valueWithLeadingZero = 0.00 + value;
      setPaymentCustomAmount(valueWithLeadingZero);
    } else {
      setPaymentCustomAmount(value);
    }

    setPaymentAmount(null);
  };

  const handleSetPaymentValue = (value: number) => {
    setPaymentAmount(value);
    setPaymentCustomAmount(null);
  };

  const handleClickDeposit = () => {
    if (!canDoDeposit) {
      return;
    }

    const amount = Math.max(Number(paymentAmount), Number(paymentCustomAmount));
    if (amount > 0) {
      const amountPenceTruncated = parseFloat(Math.floor(amount * 100).toFixed(2));
      console.log('CREDIT TERMINAL:', {
        machineId,
        deviceUuid,
        paymentMethod,
        amountPenceTruncated,
        accountId, 
      });
      creditTerminal(machineId, deviceUuid, paymentMethod, amountPenceTruncated, accountId);
      returnToRoot();
    }
  };

  const handleClickCancel = () => {
    setPaymentMethod(null);
    setPaymentAmount(null);
    setPaymentCustomAmount(null);
    returnToRoot();
  };

  const isPaymentMethodSelected = !!paymentMethod;
  const isPaymentAmountSelected = (paymentAmount || paymentCustomAmount);
  const isCabinetReady = lockedState === 'ready';

  const amount = Math.max(Number(paymentAmount) ?? 0, Number(paymentCustomAmount) ?? 0);
  const isNumeric = (num: unknown) => 
    (typeof(num) === 'number' || typeof(num) === 'string' && num.trim() !== '') 
    && !isNaN(num as number);

  const isPaymentAmountValid = amount >= MIN_DEPOSIT 
    && amount <= MAX_DEPOSIT 
    && isNumeric(amount);

  const canDoDeposit = isPaymentMethodSelected 
    && isPaymentAmountSelected
    && isCabinetReady
    && isConnected
    && isPaymentAmountValid
    && !cashoutInProgress;

  const popoverContent = <ul>
    {!isConnected && <li>Machine is disconnected</li>}
    {!isCabinetReady && <li>Machine is not in ready state</li>}
    {!isPaymentMethodSelected && <li>Payment method not selected</li>}
    {isPaymentMethodSelected && !isPaymentAmountValid && (
      <li>Payment amount does not match the requirements</li>
    )}
    {cashoutInProgress && <li>Cashout in progress</li>}
  </ul>;

  const depositButton = <Button data-test-id='deposit-confirm-button' wide secondary disabled={!canDoDeposit} onClick={handleClickDeposit}>Deposit</Button>;

  return (
    <Container>
      <ContentSection>
        <Paragraph>
        Select payment method
        </Paragraph>
        
        <PaymentMethods>
          <PaymentMethodButton 
            data-test-id='cash-payment'
            isActive={paymentMethod === 'NOTE'}
            onClick={() => setPaymentMethod('NOTE')}
          >
            <span>
              <Cash />
            </span>
            Cash
          </PaymentMethodButton>
          <PaymentMethodButton
            data-test-id='debit-card-payment'
            isActive={paymentMethod === 'MANUAL_CARD'}
            onClick={() => setPaymentMethod('MANUAL_CARD')}
          >
            <span>
              <Card />
            </span>
            Debit card
          </PaymentMethodButton>
        </PaymentMethods>
      </ContentSection>

      {paymentMethod && (
        <ContentSection>
          <Paragraph>                  
            Select amount
          </Paragraph>
          <PaymentValues>
            {PAYMENT_DEFINED_VALUES.map(value => (
              <PaymentMethodButton
                key={`payment_value_${value}`}
                data-test-id={`payment-value-${value}`}
                center
                isActive={paymentAmount === value}
                onClick={() => handleSetPaymentValue(value)}
              >
                {retailCurrency.format(value)}
              </PaymentMethodButton>
            ))}
            <PaymentInputContainer>
              <PaymentInputLabel><span>{retailCurrency.symbol()}</span></PaymentInputLabel>
              <PaymentInput
                placeholder='Enter amount'
                type='number'
                onChange={handlePaymentInputChange}
                value={paymentCustomAmount ?? ''}
              />
            </PaymentInputContainer>
          </PaymentValues>
          <DepositLimit>
            <p>Minimum: {retailCurrency.format(MIN_DEPOSIT)}</p>
            <p>Maximum: {retailCurrency.format(MAX_DEPOSIT)}</p>
          </DepositLimit>
        </ContentSection>
      )}
      <Actions>
        <Button cta onClick={handleClickCancel}>Cancel</Button>
        {canDoDeposit ? (
          depositButton
        ) : (
          <Popover title={'Cannot deposit the machine.'} content={popoverContent}>
            <Flex wide>{depositButton}</Flex>
          </Popover>
        )}
      </Actions>
    </Container>
  );
};

export default memo(TileDrawerDeposit);
