import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  colors: {
    white: '#fff',
    black: '#000',
    fobt25: '#F0EBF4',
    ssbt: '#F3F8FC',

    grey25: '#FCFCFD',
    grey50: '#F7F8FA',
    grey100: '#F2F4F7',
    grey200: '#E4E7EC',
    grey300: '#D0D5DD',
    grey500: '#667085',
    grey600: '#475467',
    grey700: '#344054',
    grey900: '#101828',

    error20: '#FFEEEE',
    error25: '#FFE3E3',
    error100: '#FFC4C5',
    error200: '#FFABAC',
    error600: '#F12B2C',
    error700: '#C92224',
    error800: '#A51B1D',

    primary10: '#F5f7FF',
    primary25: '#EEF2FF',
    primary200: '#A0B4F4',
    primary500: '#446ECF',
    primary600: '#2A5FC9',

    secondary400: '#213770',
    secondary500: '#0D2152',
    secondary600: '#00143C',
    secondaryFobt25: '#F0EBF4',
    secondaryFobt700: '#6F476C',
    secondaryHhbt50: '#D0BEDE',
    secondaryHhbt900: '#2F005F',
    secondarySsbt25: '#F3F8FC',
    secondarySsbt700: '#2A4F70',
    secondarySsbt900: '#0E4A69',

    success25: '#F7FFF7',
    success600: '#27AE60',
    success900: '#346964',

    warning50: '#FFF0E5',
    warning100: '#FFEDD9',
    warning200: '#FFDCBE',
    warning600: '#FF9A3E',
    warning800: '#AA573E',

    labelInfo: '#EAF1FF',
    labelSuccess: '#F7FFF7',
    labelWarning: '#FFF0E5CC',
    labelError: '#FFE3E380',

    brandDark: '#000c24',
    brandPrimary: '#19243A',

    stakeHighlight: '#ffd7d7',
    tagNowBorder: '#fa9a9a',
  },

  size: {
    asideWidth: '75px',
  },
};
