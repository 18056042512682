import styled from 'styled-components';

export const TerminalSelectionStyled = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0px 0px 16px rgba(16, 24, 40, 0.06);
  height: 100%;
  margin: 12px;
  padding: 0 24px 24px;
`;

export const TerminalSelectionTitle = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 24px 0px;
`;

export const TitleStyled = styled.div`
  color: ${props => props.theme.colors.grey900}
  font-family: WH Hoxton;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
`;

export const SelectAllStyled = styled.div`
  color: ${props => props.theme.colors.primary600};
  font-size: 20px;
  line-height: 30px;
`;