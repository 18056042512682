import { List } from 'antd';
import { useEffect, useRef, useState } from 'react';
import Input from 'components/Input';
import Icon from 'components/Icon';
import LabelledCheckbox from 'components/LabelledCheckbox';
import { azSorter } from 'utils/sorters';
import { Player, PlayerNickname } from 'types/Player';
import { MultiSelectListStyled, StyledListItem, StyledListWrapper } from './MultiSelectList.styled';

interface MultiSelectListProps {
  isEditMode?: boolean;
  onPlayerHighlighted: (players: PlayerNickname[]) => void;
  playersData: {
    players: Player[] | undefined;
    error: any;
    loading: boolean;
    mutate: (players: Player[]) => void;
  };
  selectedPlayers: PlayerNickname[];
  setSelectedPlayers: (players: PlayerNickname[]) => void;
}

const MultiSelectList = ({ isEditMode, onPlayerHighlighted, playersData, selectedPlayers, setSelectedPlayers }: MultiSelectListProps) => {
  const { players: allPlayers, loading } = playersData;
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredPlayers, setFilteredPlayers] = useState<PlayerNickname[]>([]);
  const [highlightedPlayers, setHighlightedPlayers] = useState<PlayerNickname[]>([]);
  const [allPlayerNicknames, setAllPlayerNicknames] = useState<PlayerNickname[]>([]);
  const scrollRef = useRef() as React.MutableRefObject<HTMLLIElement>;

  const getPlayerNicknameIdMap = (playerNicknames: PlayerNickname[]) => playerNicknames.map(pn => pn.nicknameId);

  const highlightedPlayerNicknameIds = getPlayerNicknameIdMap(highlightedPlayers);

  useEffect(() => {
    const playerNicknameList = (allPlayers || []).filter(p => !p.deleted && !p.merged).map(p => ({
      nickname: p.nickname,
      nicknameId: p.nicknameId, 
    }));
    setAllPlayerNicknames(playerNicknameList);

    const noHighlightedPlayers = highlightedPlayers.length === 0;
    const highlightedPlayerWasDeleted = highlightedPlayers.length === 1 && !getPlayerNicknameIdMap(playerNicknameList).includes(highlightedPlayers[0].nicknameId);
    playerNicknameList[0] && (noHighlightedPlayers || highlightedPlayerWasDeleted) && setHighlightedPlayers([playerNicknameList[0]]);
    highlightedPlayerWasDeleted && scrollRef.current.scrollIntoView();
  }, [allPlayers]);

  useEffect(() => {
    highlightedPlayers.length > 0 && onPlayerHighlighted(highlightedPlayers);
  }, [highlightedPlayers]);

  const onPlayerFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchTerm(value);
    if (value) {
      const filtered = allPlayerNicknames.filter((player) =>
        player.nickname.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredPlayers(filtered);
    } else {
      setFilteredPlayers([]);
    }
  };

  const onPlayerCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const checkedPlayer = getPlayerNicknameData(value);
    const nextSelectedPlayers = [] as PlayerNickname[];
    if (e.target.checked) {
      nextSelectedPlayers.push(...selectedPlayers, checkedPlayer);
    } else {
      nextSelectedPlayers.push(...selectedPlayers.filter((player) => player.nicknameId !== checkedPlayer.nicknameId));
    }
    setSelectedPlayers(nextSelectedPlayers);
    nextSelectedPlayers.length > 0 && setHighlightedPlayers(nextSelectedPlayers);
  };

  const getPlayerNicknameData = (nicknameOrId: string): PlayerNickname => {
    return allPlayerNicknames.find(pn => pn.nickname === nicknameOrId || pn.nicknameId === nicknameOrId) || {} as PlayerNickname;
  };

  const onClickPlayer = (playerNickname: PlayerNickname) => {
    switch (selectedPlayers.length) {
      case 2:
        setHighlightedPlayers(selectedPlayers);
        return;
      case 1:
        if (getPlayerNicknameIdMap(highlightedPlayers).includes(playerNickname.nicknameId) && !getPlayerNicknameIdMap(selectedPlayers).includes(playerNickname.nicknameId)) {
          setHighlightedPlayers([...highlightedPlayers.filter((player) => player.nicknameId !== playerNickname.nicknameId)]);
        } else {
          setHighlightedPlayers([selectedPlayers[0], playerNickname]);
        }
        return;
      default:
        setHighlightedPlayers([playerNickname]);
    }
  };

  const displayedPlayerNicknames: PlayerNickname[] = searchTerm.length > 0 ? filteredPlayers : allPlayerNicknames;

  const renderListItem = (playerNickname: PlayerNickname, index: number) => {
    const selectedPlayerIds = getPlayerNicknameIdMap(selectedPlayers);
    const { nickname, nicknameId } = playerNickname;
    return (
      <StyledListItem
        data-test-id={`player-list-player-${index}`}
        key={`${nicknameId}`}
        onClick={() => onClickPlayer(playerNickname)}
        ref={index === 0 ? scrollRef : null}
        selected={highlightedPlayerNicknameIds.includes(nicknameId) || (highlightedPlayers.length === 0 && index === 0)}
      >
        <LabelledCheckbox
          dataTestId={`checkbox-${index}`}
          disabled={!selectedPlayerIds.includes(nicknameId) && (selectedPlayers.length >= 2 || isEditMode) ? true : false}
          label={nickname}
          onChange={onPlayerCheckboxChange}
          selected={selectedPlayerIds.includes(nicknameId)}
          value={nickname}
        />
      </StyledListItem>
    );
  };

  return (
    <MultiSelectListStyled data-test-id='player-list'>
      <Input
        background='white'
        borderColor='grey300'
        dataTestId='player-list-search'
        margin='0 0 5px 0'
        onChange={onPlayerFilterChange}
        padding='13px 5px 10px 5px'
        placeholder='Search by player'
        position='sticky'
        prefix={<Icon name='search' />}
        wide
      />
      <StyledListWrapper>
        <List
          dataSource={displayedPlayerNicknames.sort((a, b) => azSorter(a.nickname, b.nickname))}
          loading={loading}
          renderItem={renderListItem}
        />
      </StyledListWrapper>
    </MultiSelectListStyled>
  );
};

export default MultiSelectList;
