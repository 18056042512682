import styled, { css } from 'styled-components';

type LabelProps = {
  color?: string;
  margin?: string;
  fontSize?: string;
  fontStyle?: string;
  padding?: string;
  textAlign?: string;
  theme?: any;
  htmlFor?: string;
}

export const Label = styled.p<LabelProps>`
    ${(props) => props.color ? css`color: ${props.theme.colors[props.color]}}` : null};
    ${(props) => props.margin ? css`margin: ${props.margin}` : null};
    ${(props) => props.fontSize ? css`font-size: ${props.fontSize}` : null};
    ${(props) => props.fontStyle ? css`font-style: ${props.fontStyle}` : null};
    ${(props) => props.padding ? css`padding: ${props.padding}` : null};
    ${(props) => props.textAlign ? css`text-align: ${props.textAlign}` : null};
`;

export default Label;
