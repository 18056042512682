import { useState } from 'react';
import Modal from '../Modal';
import { TerminalPickerWrapper, TerminalPickerTitle, TerminalPickerSubheading, TerminalSelectionsWrapper, Footer } from './TerminalPickerModal.styled';
import Flex from 'styled/Flex';
import Button from 'components/Buttons';
import { ModalBody } from '../Modal.styled';
import TerminalSelection from './TerminalSelection';
import { MachineMap } from 'types/machine/MachineType';
import { ThingType } from 'types/aws/ThingListTypes';

const TerminalPickerModal = ({ isOpen, close, machines, currentTerminals, setCurrentTerminals }: TerminalPickerModalProps) => {
  const createListsOfMachines = (machines: MachineMap[]) => {
    const ssbtList: MachineMap[] = [];
    const hubList: MachineMap[] = [];
    const gmList: MachineMap[] = [];
    const hhList: MachineMap[] = [];
    machines.map((machine: MachineMap) => {
      switch (machine.thingType) {
        case 'ssbt':
          ssbtList.push(machine);
          break;
        case 'hub':
          hubList.push(machine);
          break;
        case 'gm':
          gmList.push(machine);
          break;
        case 'handheld':
          hhList.push(machine);
          break;
        default:
          console.log(`Unable to find thingType of terminal: ${machine}`);
          break;
      }
    });
    return {
      ssbtList,
      hubList,
      gmList,
      hhList,
    };
  };

  const listOfMachines = createListsOfMachines(currentTerminals);
  const [selectedTerminals, setSelectedTerminals] = useState({
    ssbt: listOfMachines.ssbtList,
    hub:listOfMachines.hubList,
    gm: listOfMachines.gmList,
    handheld: listOfMachines.hhList,
  });

  const onClose = () => {
    close();
  };

  const onApply = () => {
    setCurrentTerminals([
      ...selectedTerminals.ssbt,
      ...selectedTerminals.hub,
      ...selectedTerminals.gm,
      ...selectedTerminals.handheld,
    ]);
    close();
  };

  const onClearSelection = () => {
    setSelectedTerminals({
      ssbt: [],
      hub: [],
      gm: [],
      handheld: [],
    });
  };

  const toggleAllTerminals = (terminals: MachineMap[], selected: boolean) => {
    const type = terminals[0].thingType;
    setSelectedTerminals({
      ...selectedTerminals,
      [type]: selected ? terminals : [],
    });
  };

  const toggleTerminalSelection = (terminal: MachineMap, selected: boolean) => {
    const type: ThingType = terminal.thingType as ThingType;
    if (!selected) {
      const updatedTerminals = selectedTerminals[type].filter(t => t.machineId !== terminal.machineId);
      setSelectedTerminals({
        ...selectedTerminals,
        [type]: updatedTerminals,
      });
    } else {
      const updatedTerminals = { ...selectedTerminals };
      updatedTerminals[type].push(terminal);
      setSelectedTerminals(updatedTerminals);
    }
  };

  const {
    ssbtList: ssbtsInShop,
    hubList: hubsInShop,
    gmList: gmsInShop,
    hhList: hhbtsInShop,
  } = createListsOfMachines(machines);

  const ModalTitle = () => {
    return (
      <Flex alignItems='center' justifyContent='space-between' wide>
        <div>
          <TerminalPickerTitle>Terminal Selection</TerminalPickerTitle>
          <TerminalPickerSubheading>Select one or multiple terminals</TerminalPickerSubheading>
        </div>
      </Flex>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={<ModalTitle/>}>
      <ModalBody>
        <TerminalPickerWrapper>
          <TerminalSelectionsWrapper>
            {ssbtsInShop.length > 0 && <TerminalSelection title='SSBT' terminalList={ssbtsInShop} selectedTerminals={selectedTerminals.ssbt} onSelect={toggleTerminalSelection} onSelectAll={toggleAllTerminals} />}
            {hubsInShop.length > 0 && <TerminalSelection title='Smart Retail' terminalList={hubsInShop} selectedTerminals={selectedTerminals.hub} onSelect={toggleTerminalSelection} onSelectAll={toggleAllTerminals} />}
            {gmsInShop.length > 0 && <TerminalSelection title='FOBT' terminalList={gmsInShop} selectedTerminals={selectedTerminals.gm} onSelect={toggleTerminalSelection} onSelectAll={toggleAllTerminals} />}
            {hhbtsInShop.length > 0 && <TerminalSelection title='HHBT' terminalList={hhbtsInShop} selectedTerminals={selectedTerminals.handheld} onSelect={toggleTerminalSelection} onSelectAll={toggleAllTerminals} />}
          </TerminalSelectionsWrapper>
          <Footer >
            <Button onClick={onClearSelection} width={'184px'} primary background='white'>Clear Selection</Button>
            <Flex gap='8px'>
              <Button onClick={onClose} width={'184px'} primary background='white'>Cancel</Button>
              <Button onClick={onApply} width={'184px'} secondary>Apply</Button>
            </Flex>
          </Footer>
        </TerminalPickerWrapper>
      </ModalBody>
    </Modal>
  );
};

interface TerminalPickerModalProps {
  isOpen: boolean;
  close: () => void;
  machines: MachineMap[];
  currentTerminals: MachineMap[];
  setCurrentTerminals: (machines: MachineMap[]) => void;
}

export default TerminalPickerModal;
