// This file is for additional UI-API integration assistance when mutating data (including mapping)
import { SummarySession } from 'types/session/SummarySession';
import * as iotSessionApi from 'api/iotSessionApi';
import * as sessionApi from 'api/sessionApi';

export type OnTagSessionParams = {
  machineId: string;
  deviceUuid?: string;
  sessionId: string;
  nicknameId?: string | null;
  nickname?: string | null;
}

export type OnUntagSessionParams = {
  machineId: string;
  deviceUuid?: string;
  sessionId: string;
}

export const makeMachineId = (thingType: string, shopId: string, shopDeviceIndex: string) => `${thingType}-${shopId}-${shopDeviceIndex}`;

export const makeTagParams = ({ machineId, thingType, deviceUuid, shopId, shopDeviceIndex, sessionId, nicknameId, nickname }: SummarySession): OnTagSessionParams => {
  return {
    machineId,
    deviceUuid,
    sessionId,
    nicknameId,
    nickname,
  };
};

export const makeUntagParams = ({ machineId, thingType, deviceUuid, shopId, shopDeviceIndex, sessionId }: SummarySession): OnUntagSessionParams => {
  return {
    machineId,
    deviceUuid,
    sessionId, 
  };
};

export const onTagLiveSession = async (params: OnTagSessionParams): Promise<void> => {
  console.log('mutateHelpers | onTagLiveSession with params', params);
  const { machineId, deviceUuid, sessionId, nicknameId, nickname } = params;
  await iotSessionApi.tagSession(machineId, deviceUuid, sessionId, nicknameId || '', nickname || '');
};

export const onUntagLiveSession = async (params: OnUntagSessionParams): Promise<void> => {
  console.log('mutateHelpers | onUntagLiveSession with params', params);
  const { machineId, deviceUuid, sessionId } = params;
  await iotSessionApi.untagSession(machineId, deviceUuid, sessionId);
};

export const onTagSession = async (params: OnTagSessionParams): Promise<void> => {
  console.log('mutateHelpers | onTagSession with params', params);
  const { sessionId, nicknameId, nickname, machineId } = params;
  
  await sessionApi.tagSession(sessionId, nicknameId || '', nickname || '', machineId);
};

export const onUntagSession = async (params: OnUntagSessionParams): Promise<void> => {
  console.log('mutateHelpers | onUntagSession with params', params);
  const { sessionId, machineId } = params;
  await sessionApi.untagSession(sessionId, machineId);
};
