import React from 'react';

import { Close as CloseIcon } from 'components/Icon';
import { ModalContainer, ModalDialog, ModalHeading, ModalClose, ModalTitle, Backdrop, SquareButton } from './Modal.styled';

type Title = string | React.ReactElement;

interface ModalProps {
  children: React.ReactNode;
  dataTestId?: string;
  isOpen: boolean;
  onClose: () => void;
  showCloseButton?: boolean;
  title?: Title;
  width?: string;
}

const Modal = ({ dataTestId='modal', isOpen, children, onClose, title, showCloseButton=true, width='auto' }: ModalProps) => {
  return (
    <ModalContainer data-test-id={dataTestId}>
      <ModalDialog width={width}>
        { title &&
          <ModalHeading>
            <ModalTitle>{title}</ModalTitle>
            { showCloseButton && <ModalClose onClick={onClose}>
              <SquareButton>
                <CloseIcon width={24} height={24} />
              </SquareButton>
            </ModalClose>
            }
          </ModalHeading>
        }
        {children}
      </ModalDialog>
      <Backdrop isOpen={isOpen} onClick={onClose} />
    </ModalContainer>
  );
};

export default Modal;
