import { EndOfDayReport } from 'hooks/useEndOfDayReports';

export enum MachineMode {
  READY = 'ready',
  LOCKED = 'locked',
  DEMO = 'demo',
  ERROR = 'error',
  MANAGER = 'manager'
}

export type MachineModeState = MachineMode.READY | MachineMode.LOCKED | MachineMode.DEMO | MachineMode.ERROR | MachineMode.MANAGER;

export type MachineAttributes = {
  thingType: string;
  deviceUuid?: string; // only set on Retail things
}

export type GameBonuses = {
  amount: number; 
  game_ids: string[]; 
  prize_id: string;
}

export type Compilance = {
  extended_session_reached: boolean;
  chaotic_play_reached: boolean;
  new_cashin_instance: boolean;
  voluntary_time_reached: boolean;
  cashin_amount_between_instances: number;
  set_limits_modal_opened: boolean;
  loss_limit_threshold: number;
  balance_since_new_limits_se: number;
}

export type MachineState = {
  account_id: string; // deprecated
  plus_account_id?: string; // the new name for account_id
  balance: number;
  committed_funds: number;
  session_id: string;
  bonus_balance: number;
  games_bonuses: GameBonuses[];
  bonus_id: string;
  connected: boolean;
  game: string;
  game_launch_id: string;
  game_start_staked: number;
  initial_online_balance: number;
  is_online_account: boolean;
  last_game_cycle_at: string;
  monetary_limit: number;
  monetary_limit_staked: number;
  name: string;
  sound: boolean;
  staked: number;
  won: number;
  cashin_instances: number;
  cashin_in_progress: boolean;
  printing_in_progress: boolean;
  sound_level: number;
  time_limit_in_min: number;
  session_start_at: string; // deprecated
  started_at: string; // the new name for session_start_at
  ended_at?: string;
  total_cashins_amount: number;
  latest_cashin: number;
  total_number_of_cashins: number;
  machine_mode: MachineModeState;
  real_name?: string;
  nickname?: string;
  nickname_id?: string;
  tagged_at?: string;
  shop_id?: string;
  shop_device_index?: string; // the new name for terminal_index
  device_type?: string;
  updated_at?: string;
  schema_version: number;
  voucher_sequence: number;
  compliance: Compilance;
  last_transaction_at: string;
  end_trigger: string;
  // FIXME. This will work better (with IoT rules) if machine.funds removed and total_cashbox moved to top level (same as other fields)
  // This is because IoT rule SQL-like syntax doesn't work well with nested objects
  machine: {
    funds: EndOfDayReport;
    peripheral_readiness: MachinePeripherals;
    service_readiness: ServiceReadiness;
    eod_printed_at: string;
  } | null,
  cashin_current_balance_difference: number;
  cashout_in_progress?: boolean;
  session_end_at: string;
}

export type MachinePeripherals = {
  printer?: boolean;
  qr?: boolean;
  note?: boolean;
  screen_led?: boolean;
  coin?: boolean;
}

export type ServiceReadiness = {
  state_machine?: boolean;
  gateway?: boolean;
}

export type MachinePeripheralsKeys = keyof MachinePeripherals;

export type MachineMap = {
  machineId: string,
  thingType: string,
  deviceUuid?: string,
  machineState: MachineState
}

export type Notification = {
  machineId: MachineMap['machineId'],
  message: string,
  service: string,
  severity: string,
};

export type StateAlerts = {
  disconnected: number;
}

export type BonusAlerts = {
  machineId: string;
  slug: string;
  opting: 'opt-in' | 'opt-out';
  index: number;
}
