import { Table as TableComponent, TablePaginationConfig } from 'antd';

import { DataSource, Column } from 'types/session/TableData';

interface TableProps {
  dataSource: DataSource[];
  columns: Column[];
  pagination?: TablePaginationConfig | false;
  scroll?: { x?: string | number | true | undefined; y?: string | number | undefined; };
  loading: boolean;
}

const Table = ({ dataSource, columns, pagination, scroll, loading = false }: TableProps) => (
  <TableComponent dataSource={dataSource} columns={columns} pagination={pagination} scroll={scroll} loading={loading} />
);

export default Table;
