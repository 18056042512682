import styled, { css } from 'styled-components';

export const CalendarHeader = styled.div`
  align-items: center;
  display: flex;
  font-size: 24px;
  justify-content: space-between;
  width: 100%;
  svg {
    height: 70px;
    padding: 15px;
    width: 100px;
  }
`;

type CalendarProps = {
  border?: string;
  selectedDateIsValid: boolean;
  shadow?: string;
  width?: string;
}

export const CalendarStyled = styled.div<CalendarProps>`
  .ant-picker-calendar {
    font-size: 20px;
  }
  .ant-picker-panel {
    border: none;
  }
  .ant-picker-calendar-header {
    .ant-select-selector {
      font-size: 20px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }
    .ant-select-item-option-content {
      font-size: 20px;
    }
  }
  .ant-picker-cell .ant-picker-cell-inner {
    min-width: 52px;
    height: 52px;
    line-height 52px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid ${(props) => props.theme.colors.primary600};
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    ${(props) => props.selectedDateIsValid ? css`background:${props.theme.colors.primary600}` : css`background: transparent`};
    ${(props) => props.selectedDateIsValid ? css`color:${props.theme.colors.white}` : css`color: rgba(0, 0, 0, 0.25)`};
  }
  ${(props) => props.border ? css`border: ${props.border};` : null};
  ${(props) => props.shadow ? css`box-shadow: ${props.shadow};` : null};
  width: ${(props) => props.width ? props.width : '400px'};
`;

export const CalendarArrow = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;
