import { CashReportItem } from '../types/cashReport/CashReportType';
import { getConstant } from '../utils/constants';
import { logError } from '../newrelic';
import { throwErrorIfBadResponse } from '../utils/fetchHelpers';

export const getCashManagementCISReport = async (shopId: string): Promise<CashReportItem[]> => {
  return getCashManagement<CashReportItem[]>(`/reports/cash-in-shop?shopId=${shopId}`, 'GET');
};

const getCashManagement = async <T>(path: string, method: 'POST' | 'GET', body?: string): Promise<T> => {
  const url = `${getConstant('REACT_APP_RETAIL_API_URL')}/cash-management${path}`;
  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
      ...(body && { body: body }),
    });
    return throwErrorIfBadResponse(response);
  } catch (err: unknown) {
    logError(`Failed to call cash management: ${err}`, 'critical', err);
    throw new Error('Failed to call cash management');
  }
};
