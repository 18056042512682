import { createContext, useEffect, useState } from 'react';

import * as newRelic from 'newrelic';
import { Shop } from 'types/Shop';
import { getConstant } from 'utils/constants';
import { getIdentity } from 'api/identityManagerApi';
import { retailCurrency } from 'retail-currency';
import { CurrencyCode } from 'retail-currency/src/types';

const useShopProvider = () => {
  const [shop, setShop] = useState<Shop>({} as Shop);

  useEffect(() => {
    async function getData() {
      try {

        const getShopDetailsFromIdentityManager = async () => {
          const identity = await getIdentity();
          const { deviceDetails: { shopId, shopName, deviceIndex, currency } } = identity;
          return {
            shopId,
            shopName,
            deviceIndex,
            currency,
          };
        };

        const getShopData = async () => {
          const env = getConstant('REACT_APP_NEW_RELIC_ENV', 'prod');

          if (env === 'dev' || env === 'nonprod') {
            console.log('Environment is', env, 'so looking for the shop id via query string');

            const queryParams = window.location.search
              .substring(1)
              .split('&')
              .map(param => param.split('='))
              .reduce((values: Record<string, string>, [key, value]) => {
                values[key] = value;
                return values;
              }, {});

            const { shopId, shopName, deviceIndex, currency = 'GBP' } = queryParams;

            if (!shopId) {
              console.log('No shop id supplied in query string, going to use identity manager');
              return await getShopDetailsFromIdentityManager();
            }

            return {
              shopId,
              shopName,
              deviceIndex: Number(deviceIndex),
              currency,
            };
          }
          else {
            return await getShopDetailsFromIdentityManager();
          }
        };

        const { shopId, shopName, deviceIndex, currency } = await getShopData();

        retailCurrency.initialise(currency as CurrencyCode);

        setShop({
          shopId,
          shopName,
          deviceIndex,
        });

        newRelic.init({ shopId });
        newRelic.setAttribute('shopId', shopId);
      } catch (err) {    
        newRelic.logError(`Cannot set shopId from service: ${err}`, 'critical', err);
      }
    }
    getData();
  }, []);

  return { shop };
};

export const ShopContext = createContext<ReturnType<typeof useShopProvider> | null>(null);

type ShopProviderProps = {
  children: React.ReactNode
}

const ShopProvider = ({ children }: ShopProviderProps) => {
  const { shop } = useShopProvider();

  return (
    <ShopContext.Provider value={{ shop }}>
      {children}
    </ShopContext.Provider>
  );
};

export default ShopProvider;
