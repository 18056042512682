import styled, { css } from 'styled-components';

export const CalendarPopupWrapper = styled.div`
  transform: translate(-15px, -465px);
  height: 0;
`;

type CalendarButtonStyledProps = {
  hasDate?: boolean;
  theme?: any;
}

export const CalendarButtonStyled = styled.div<CalendarButtonStyledProps>`
  width: 100%;
  ${(props) => props.hasDate ? null : css`color: ${props.theme.colors['grey500']};`}
`;