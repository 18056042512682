import styled, { css } from 'styled-components';

interface StyledSelectableListProps {
  height?: string;
  listMargin?: string;
  options: string[];
  wide?: boolean;
}

interface StyledSelectableListItemProps {
  itemPadding?: string;
  selected?: boolean;
}

export const StyledSelectableList = styled.ul<StyledSelectableListProps>`
  ${(props) => props.height ? css`height: ${props.height};` : null};
  ${(props) => props.listMargin ? css`margin: ${props.listMargin}` : null};
  ${(props) => props.wide ? css`width: 100%;` : null};
  
  color: ${(props) => props.theme.colors.grey900};
  border: 1px solid ${(props) => props.theme.colors.grey300};
  border-radius: 4px;
  font-family: WHHoxton-regular;
  overflow: hidden;
  overflow-y: scroll;
  
  &::-webkit-scrollbar {
    width: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.grey300};
    border-radius: 2px;
  }
`;

export const StyledSelectableListItem = styled.li<StyledSelectableListItemProps>`
  ${(props) => props.selected ? css`background-color: ${props.theme.colors.primary25};` : null};
  ${(props) => props.selected ? css`color: ${props.theme.colors.primary600};` : null};
  ${(props) => props.itemPadding ? css`padding: ${props.itemPadding}` : null};
  
  font-size: 20px;
  font-family: WHHoxton-regular;
  font-style: normal;
  font-weight: normal;
`;

export const StyledSelectableListSeparator = styled.hr`
  color: ${(props) => props.theme.colors.grey300};
  opacity: 30%;
  margin: 0 2%;
`;
