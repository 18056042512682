import { useEffect, useState } from 'react';
import moment from 'moment';
import PubSub from 'pubsub-js';

import Button from 'components/Buttons';
import ConnectedPlayerDrawer from 'components/Drawer/PlayerDrawer/ConnectedPlayerDrawer';
import DatePickerModal from 'components/Modal/DatePickerModal';
import Flex from 'styled/Flex';
import HistoryTable from 'components/Table/HistoryTable';
import useDisclosure from 'hooks/useDisclosure';
import useRightDrawer from 'hooks/useRightDrawer';
import { CalendarIcon } from 'components/Modal/DatePickerModal/DatePickerModal.styled';
import { HistoryStyled, HistoryWrapper, CurrentDateHeading, SearchHeader, AngleDownIcon, TerminalPickerLabel } from './History.styled';
import { Message, Messages } from 'components/Message';
import { GeneralAlert } from 'types/alert/GeneralAlert';
import { SummarySession } from 'types/session/SummarySession';
import { azSorter } from 'utils/sorters';
import { createTerminalDisplayName, createTerminalDisplayNameFromMachineId } from 'utils/formatTerminal';
import { getCurrentDate } from 'utils/formatDate';
import TerminalPickerModal from 'components/Modal/TerminalPickerModal';
import { MachineMap } from 'types/machine/MachineType';
import { HistoryTypes } from 'types/History';

const History = ({ sessionsData, mutateSessions, selectedDate, setSelectedDate, machines, historyType }: HistoryProps) => {
  const [sessions, setSessionsList] = useState(sessionsData.sessions);
  const [liveSessions, setLiveSessionsList] = useState(sessionsData.liveSessions);
  const [tableType, setTableType] = useState<HistoryTypes>(historyType);
  const [dedupedLiveSessions, setDedupedLiveSessions] = useState(liveSessions);
  const [selectedTerminals, setSelectedTerminals] = useState([] as MachineMap[]);
  const [alert, setAlert] = useState<GeneralAlert | null>();

  const datePicker = useDisclosure();
  const terminalPicker = useDisclosure();
  const sessionDrawer = useRightDrawer(sessions);
  const liveSessionDrawer = useRightDrawer(liveSessions);
  const liveAndPastSessions = [
    ...dedupedLiveSessions,
    ...sessions,
  ];

  const todaySelected = moment(selectedDate).isSame(moment(), 'day');

  useEffect(() => {
    setSessionsList(sessionsData.sessions);
  }, [sessionsData.sessions]);

  useEffect(() => {
    setLiveSessionsList(sessionsData.liveSessions);
  }, [sessionsData.liveSessions]);

  useEffect(() => {
    PubSub.subscribe('app.error.history.sessions', onError);
    return () => {
      PubSub.unsubscribe(onError);
    };
  }, []);

  useEffect(() => {
    if (!sessions || !liveSessions) {
      return;
    }

    const deduped = liveSessions.filter(l => sessions.findIndex(s => s.sessionId === l.sessionId) == -1);
    const different = deduped.length < liveSessions.length;

    setDedupedLiveSessions(different ? deduped : liveSessions);
  }, [liveSessions, sessions]);

  const tagPlayerHandler = (sessionId: string) => {
    const index = sessions.findIndex(s => s.sessionId === sessionId);

    return sessionDrawer?.openWith(index);
  };

  const tagLiveSessionHandler = (sessionId: string) => {
    const index = liveSessions.findIndex(s => s.sessionId === sessionId);
    return liveSessionDrawer?.openWith(index);
  };

  const gameLogHandler = (sessionId: string) => {
    console.log(sessionId, 'sessionId');
    setTableType('logs');
  };

  const updateUiAfterTaggingSession = (session: SummarySession) => {
    const updatedSessions: SummarySession[] = [
      ...sessions,
    ];
    const i = updatedSessions.findIndex(s => s.sessionId === session.sessionId);
    updatedSessions[i] = session;
    setSessionsList(updatedSessions);
  };

  const updateUiAfterTaggingLiveSession = (session: SummarySession) => {
    const updatedSessions: SummarySession[] = [
      ...liveSessions,
    ];
    const i = updatedSessions.findIndex(s => s.sessionId === session.sessionId);
    updatedSessions[i] = session;
    setLiveSessionsList(updatedSessions);
  };

  let displayedSessions = (todaySelected ? liveAndPastSessions : sessions) || [];
  if (selectedTerminals.length > 0) {
    displayedSessions = displayedSessions.filter(s => selectedTerminals.find(t => {
      //TODO - filter by deviceUuid only, when FOBT sessions are populating deviceUuid 
      return s.deviceUuid ? s.deviceUuid === t.deviceUuid : `${s.thingType}-${s.shopId}-${s.shopDeviceIndex}` === t.machineId;
    })
    );
  }

  const getDisplayedDeviceName = (device: MachineMap) => {
    return device.machineState.shop_device_index
      ? createTerminalDisplayName(device.thingType, device.machineState.shop_device_index)
      : createTerminalDisplayNameFromMachineId(device.machineId);
  };

  const getTerminalPickerLabel = () => {
    if (selectedTerminals.length === 0 || selectedTerminals.length === machines.length) {
      return 'All Terminals';
    } else {
      const labels: string[] = [];
      selectedTerminals.map(t => labels.push(getDisplayedDeviceName(t)));
      return labels.sort(azSorter).join(', ');
    }
  };

  const onError = (topic: string, data: any) => {
    if (!data) {
      setAlert(null);
      return;
    }

    const error: GeneralAlert = {
      iconName: 'critical',
      message: data.toString(),
      type: 'error',
    };
    setAlert(error);
  };

  return (
    <>
      <HistoryWrapper>
        {alert && alert.iconName !== 'critical' && (
          <Messages margin='10px 10px 24px' data-test-id='message-box'>
            <Message type={alert.type} iconName={alert.iconName} >
              {alert.message}
            </Message>
          </Messages>
        )}
        {alert && alert.iconName === 'critical' && (
          <Messages margin='10px 10px 24px' data-test-id='message-box'>
            <Message type={alert.type} iconName={alert.iconName} >
              {alert.message}.&nbsp;
              Please refresh the page.&nbsp;
              If the problem persists, please contact Helpdesk.
              &nbsp;
              <Button primary onClick={() => window.location.reload()} data-test-id='refresh-button'>Refresh</Button>
            </Message>
          </Messages>
        )}
        <HistoryStyled>
          <CurrentDateHeading>{getCurrentDate()}</CurrentDateHeading>
          <SearchHeader>Search / Filters:</SearchHeader>
          <Flex justifyContent='space-between'>
            <Flex flex='1 1 0' padding='0 5px 0 0'>
              {/* <Input prefix={<SearchIcon />} placeholder='Search by player - TODO: RCP-1977' padding='13px 5px 10px 5px' wide /> */}
            </Flex>
            <Flex flex='1 1 0' padding='0 5px'>
              <Button onClick={terminalPicker.open} justifyContent='space-between' wide>
                <TerminalPickerLabel>{getTerminalPickerLabel()}</TerminalPickerLabel>
                <AngleDownIcon />
              </Button>
            </Flex>
            <Flex flex='1 1 0' padding='0 0 0 5px'>
              <Button onClick={datePicker.open} justifyContent='space-between' wide>
                <div><CalendarIcon />{selectedDate.toLocaleDateString('en-GB')}</div>
                <AngleDownIcon />
              </Button>
            </Flex>
          </Flex>
          <HistoryTable
            sessions={displayedSessions}
            tagPlayerHandler={tagPlayerHandler}
            tagLiveSessionHandler={tagLiveSessionHandler}
            loading={Boolean(sessionsData.loading)}
            tableType={tableType}
            gameLogHandler={gameLogHandler}
          />
        </HistoryStyled>

        {sessionDrawer.contextElement &&
          <ConnectedPlayerDrawer
            close={sessionDrawer.close}
            isLive={false}
            isOpen={sessionDrawer.isOpen}
            mutateSessions={mutateSessions}
            session={sessionDrawer.contextElement}
            updateUiAfterTaggingSession={updateUiAfterTaggingSession}
            initialActionType={'tagging'}
          />
        }

        {liveSessionDrawer.contextElement &&
          <ConnectedPlayerDrawer
            close={liveSessionDrawer.close}
            isLive={true}
            isOpen={liveSessionDrawer.isOpen}
            mutateSessions={mutateSessions}
            session={liveSessionDrawer.contextElement}
            updateUiAfterTaggingSession={updateUiAfterTaggingLiveSession}
            initialActionType={'tagging'}
          />
        }
        {datePicker.isOpen &&
          <DatePickerModal
            isOpen={datePicker.isOpen}
            close={datePicker.close}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        }
        {terminalPicker.isOpen &&
          <TerminalPickerModal
            isOpen={terminalPicker.isOpen}
            close={terminalPicker.close}
            machines={machines}
            currentTerminals={selectedTerminals}
            setCurrentTerminals={setSelectedTerminals}
          />
        }
      </HistoryWrapper>

    </>
  );
};

interface HistoryProps {
  sessionsData: {
    sessions: SummarySession[];
    liveSessions: SummarySession[];
    error: any;
    loading: boolean;
  };
  mutateSessions: () => void;
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  machines: MachineMap[];
  historyType: HistoryTypes;
}

export default History;
