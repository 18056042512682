import styled, { css } from 'styled-components';
import Flex from 'styled/Flex';

export type AlertType = 'info' | 'success' | 'warning' | 'error';

export const AlertContainer = styled.div<{ type: AlertType }>`
  display: flex;
  justify-content: space-between;

  padding: 16px;
  margin: 12px 20px 12px 20px;
  border-radius: 8px;

  ${({ theme, type }) => type === 'info' && css`background-color: ${theme.colors.labelInfo};`};
  ${({ theme, type }) => type === 'success' && css`background-color: ${theme.colors.labelSuccess};`};
  ${({ theme, type }) => type === 'warning' && css`background-color: ${theme.colors.labelWarning};`};
  ${({ theme, type }) => type === 'error' && css`background-color: ${theme.colors.labelError};`};

`;

export const AlertButtons = styled(Flex)`
  gap: 10px;
`;

export const AlertMessageContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
`;

export const AlertMessage = styled.p`
  font-size: 24px;
`;
