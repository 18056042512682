import { useState, useEffect, useRef, memo } from 'react';
import moment, { Moment } from 'moment';

import Button from 'components/Buttons';
import Calendar from 'components/Calendar';
import Flex from 'styled/Flex';
import Modal from '../Modal';
import { CalendarIcon, DatePickerWrapper, DatePickerSubheading, DatePickerTitle, SelectedDate } from './DatePickerModal.styled';
import { ModalBody } from '../Modal.styled';

interface DatePickerModalProps {
  isOpen: boolean;
  close: () => void;
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  unrestrictedAfter?: boolean;
  unrestrictedBefore?: boolean;
}

const DatePickerModal = ({ isOpen, close, selectedDate, setSelectedDate, unrestrictedAfter = false, unrestrictedBefore = false }: DatePickerModalProps) => {
  const [nextDate, setNextDate] = useState(selectedDate);
  const bodyRef = useRef<HTMLBodyElement>(document.querySelector('body'));

  const onClose = () => {
    close();
  };

  const onSelect = (moment: Moment) => {
    setNextDate(new Date(moment.toDate()));
  };

  const onApply = () => {
    setSelectedDate(nextDate);
    close();
  };

  useEffect(() => {
    return () => {
      if (bodyRef.current) {
        bodyRef.current.style.overflow = '';
      }
    };
  }, []);

  const ModalTitle = () => {
    return (
      <Flex alignItems='center' justifyContent='space-between' wide>
        <div>
          <DatePickerTitle>Date picker</DatePickerTitle>
          <DatePickerSubheading>Select one date</DatePickerSubheading>
        </div>
        <SelectedDate>
          <CalendarIcon />
          {nextDate.toLocaleDateString('en-GB')}
        </SelectedDate>
      </Flex>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={<ModalTitle />} showCloseButton={false}>
      <ModalBody>
        <DatePickerWrapper>
          <Flex justifyContent='center'>
            <Calendar 
              onSelect={onSelect}
              restrictBefore={!unrestrictedBefore ? moment().subtract(14, 'days').startOf('day') : undefined}
              restrictAfter={!unrestrictedAfter ? moment(): undefined}
              selectedDate={nextDate} />
          </Flex>
          <Flex gap='8px' padding='0px 20px 20px 20px'>
            <Button onClick={onClose} wide primary color='primary600' background='white'>Cancel</Button>
            <Button onClick={onApply} wide secondary>Apply</Button>
          </Flex>
        </DatePickerWrapper>
      </ModalBody>
    </Modal>
  );
};

export default memo(DatePickerModal);