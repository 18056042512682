import { useState } from 'react';
import Button from 'components/Buttons';
import { lockTerminal } from 'api/iotTransactionApi';
import useMachines from 'hooks/useMachines';
import { Card, Header, CardContent } from './RetailCard.styled';
import { Lock as LockIcon, Unlock as UnlockIcon } from 'components/Icon';
import { MachineMode, MachineModeState } from 'types/machine/MachineType';
import { getConstant } from 'utils/constants';

const RetailCard = ({ name, equals, withMoreTiles, children }: any) => {
  const { machines } = useMachines();
  const [loading, setLoading] = useState<boolean>(false);
  const [lockedState, setLockedState] = useState<MachineModeState>(MachineMode.READY);

  const onLockUnlockClicked = () => {
    setLoading(true);
    switch (lockedState) {
      case 'ready':
        setLockedState(MachineMode.LOCKED);
        machines.forEach(({ machineId, deviceUuid, machineState }) => {
          machineState?.machine_mode && lockTerminal(machineId, deviceUuid, MachineMode.LOCKED);
        });
        break;
      case 'locked':
        setLockedState(MachineMode.READY);
        machines.forEach(({ machineId, deviceUuid, machineState }) => {
          machineState?.machine_mode && lockTerminal(machineId, deviceUuid, MachineMode.READY);
        });
        break;
      default:
        break;
    }

    setTimeout(() => {
      setLoading(false);
    }, 15000);
  };

  const isDisabled = machines.length === 0 || loading || name !== 'FOBT';
  const buttonStateText = lockedState === MachineMode.LOCKED ? 'Unlock All' : 'Lock All';
  const icon = lockedState === MachineMode.LOCKED ? <UnlockIcon /> : <LockIcon />;
  const lockingFeature = getConstant('REACT_APP_LOCKING') === 'true';
  
  return (
    <Card data-test-id='card-name' name={name} equals={equals} withMoreTiles={withMoreTiles}>
      <Header data-test-id={'card-header'}>
        {lockingFeature && <Button data-test-id={'lock-button'} disabled={isDisabled} onClick={onLockUnlockClicked}>{icon}{buttonStateText}</Button>}
        {name}
      </Header>
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default RetailCard;
