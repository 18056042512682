import { Player, PlayerFormValues } from 'types/Player';
import { whitespaceTrim } from 'utils/string';

const isValidFreeTextField = (value: string) => /^(?!\s)(?!.*\s$)(?:(?!=|\[|\]|\{|\}).)*$/.test(value);
const isValidLongFreeTextField = (value: string) => /^(?!\s)(?!.*\s$)(?:(?!=|\[|\]|\{|\}).|\n)*$/.test(value);
const INVALID_CHARACTERS = 'Invalid characters: = { } [ ]';

const validation = (v: PlayerFormValues, initialPlayer: Player, players: Player[]) => {
  const validated = {
    nickname: whitespaceTrim(v.nickname),
    fullName: whitespaceTrim(v.fullName),
    description: whitespaceTrim(v.description),
    comments: whitespaceTrim(v.comments),
    lastSgiAt: v.lastSgiAt,
    selfExclusions: v.selfExclusions,
    refusalOfServiceId: whitespaceTrim(v.refusalOfServiceId),
  };
  const {
    nickname,
    fullName,
    description,
    comments,
    refusalOfServiceId,
  } = validated;

  const playerNicknamesLowercase = players.map((player: Player) => player.nickname.toLowerCase());

  const errors: values = {};

  if (!nickname) {
    errors.nickname = 'Please enter a Nom de plume';
  } else if (!isValidFreeTextField(nickname)) {
    errors.nickname = INVALID_CHARACTERS;
  } else if (nickname.toLowerCase() !== initialPlayer.nickname.toLowerCase() && playerNicknamesLowercase.includes(nickname.toLowerCase())) {
    errors.nickname = 'Nom de plume already exists';
  } else if (nickname.length > 50) {
    errors.nickname = 'Nom de plume has reached character limit';
  }

  if (fullName) {
    if (!isValidFreeTextField(fullName)) {
      errors.fullName = INVALID_CHARACTERS;
    } else if (fullName.length > 50) {
      errors.fullName = 'Full name has reached character limit';
    }
  }

  if (description) {
    if (!isValidLongFreeTextField(description)) {
      errors.description = INVALID_CHARACTERS;
    } else if (description.length > 255) {
      errors.description = 'Description has reached character limit';
    }
  }

  if (comments) {
    if (!isValidLongFreeTextField(comments)) {
      errors.comments = INVALID_CHARACTERS;
    } else if (comments.length > 255) {
      errors.comments = 'Comments had reached character limit';
    }

  }

  if (refusalOfServiceId && !/^\d{4,6}$/.test(refusalOfServiceId)) {
    errors.refusalOfServiceId = 'Refusal of Service ID must be a number between 4 and 6 digits.';
  }
  
  return {
    validated,
    errors, 
  };
  
};

interface values {
  [key: string]: string;
}

export default validation;