import Icon from 'components/Icon';
import { PlayerFlagStyled } from './PlayerFlag.styled';
import { PlayerSelfExclusionStatus } from 'types/Player';

interface PlayerFlagProps {
  label: string;
  status?: PlayerSelfExclusionStatus;
}

const PlayerFlag = ({ label, status = 'active' }: PlayerFlagProps) => {
  const sliceIndex = label.includes(' - ') ? label.indexOf(' - ') : label.length;
  const dataTestId = `player-${label.toLowerCase().slice(0, sliceIndex).replace(/\s/g, '-')}-flag`;

  return (
    <PlayerFlagStyled data-test-id={dataTestId} status={status}>
      <Icon name='critical' width={32} height={32}/>
      {label}
    </PlayerFlagStyled>
  );
};

export default PlayerFlag;