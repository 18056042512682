import React from 'react';
import kebabCase from 'lodash.kebabcase';
import Input from 'components/Input';
import labelFormat from 'utils/labelFormatter';
import { InputComment, InputLabel, LabelledInputStyled } from './LabelledInput.styled';
import { PlayerFormValues } from 'types/Player';

interface LabelledInputProps {
  charLimit?: number,
  isEditMode?: boolean;
  inputHeight: string;
  label: string;
  onInputChange: (inputName: keyof PlayerFormValues, inputValue: string) => [string, string] | void;
  placeholder?: string;
  validationErrors: Record<string, string>;
  value?: string;
}

const LabelledInput = ({ charLimit, isEditMode, inputHeight, label, onInputChange, placeholder, validationErrors, value }: LabelledInputProps) => {

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onInputChange(`${labelFormat(label)}` as keyof PlayerFormValues, e.target.value);
  };

  const hasError: boolean = Object.keys(validationErrors).includes(labelFormat(label));
  const errorMessage: string = validationErrors[labelFormat(label)];

  return (
    <LabelledInputStyled data-test-id='labelled-input'>
      <InputLabel data-test-id={`${kebabCase(label)}-input-label`}>{label}</InputLabel>
      <Input borderColor={hasError ? 'error600' : 'grey300'} dataTestId={`${kebabCase(label)}-input`} disabled={!isEditMode} height={inputHeight} id={`${label.toLowerCase()}-input`} maxlength={charLimit} onChange={handleInputChange} padding='15px 12px 12px 12px' placeholder={placeholder} value={value || ''}/>
      {isEditMode && (
        <InputComment hasError={hasError}>
          {hasError ? errorMessage : charLimit && `Max ${charLimit} Characters`}
        </InputComment>
      )}
    </LabelledInputStyled>
  );
};

export default LabelledInput;