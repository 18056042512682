import styled from 'styled-components';

export const RetailsWrapper = styled.div`
  position: relative;
  overflow: hidden;
  & > div {
    margin: 20px 0 0 20px;
    &:last-child {
      margin-right: 20px;
    }
  };
`;

export default RetailsWrapper;
