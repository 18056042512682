import styled from 'styled-components';

export const TerminalPickerWrapper = styled.div`
  width: 70vw;
`;

export const TerminalPickerTitle = styled.div`
  font-size: 32px;
`;

export const TerminalPickerSubheading = styled.div`
  color: ${(props) => props.theme.colors.grey500};
  font-size: 20px;
`;

export const TerminalSelectionsWrapper = styled.div`
  background: ${(props) => props.theme.colors.grey50};;
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  height: 690px;
  overflow: scroll;
`;

export const Footer = styled.div`
  display: flex;
  padding: 12px 8px;
  justify-content: space-between;
  padding: 20px;
  height: 100px;
`;
