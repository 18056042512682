import moment, { Moment } from 'moment';
import Button from 'components/Buttons/Button';
import { AddButton, DateInput, EditorInput, ErrorMessage, SelfExclusionEditorStyled, SelfExclusionHistory, ValidDate, ValidToDate } from './SelfExclusionEditor.styled';
import SelfExclusion from './SelfExclusion';
import { PlayerFormValues, PlayerSelfExclusion, PlayerSelfExclusionsUpdate } from 'types/Player';
import { useEffect, useState } from 'react';
import useShop from 'hooks/useShop';
import { formatShortDate, oneYearFrom, startOfUTCDay } from 'utils/formatDate';
import { dateSorter } from 'utils/sorters';
import DatePicker from 'components/Calendar/DatePicker/DatePicker';

moment.locale('en-gb');

interface SelfExclusionEditorProps {
  onInputChange: (inputName: keyof PlayerFormValues, inputValue: string | PlayerSelfExclusionsUpdate) => [string, string] | void;
  selfExclusions?: PlayerSelfExclusion[]
}
  
const SelfExclusionEditor = ({ onInputChange, selfExclusions }: SelfExclusionEditorProps) => {
  const [selectedDate, setSelectedDate] = useState(startOfUTCDay(new Date()));
  const [idsToDelete, setIdsToDelete] = useState([] as string[]);
  const [errorMessage, setErrorMessage] = useState('');
  const [unsavedSelfExclusion, setUnsavedSelfExclusion] = useState({} as PlayerSelfExclusion);
  const { shopId } = useShop();

  useEffect(() => {
    return () => {
      onInputChange('selfExclusions', {});
    };
  }, []);

  const validateSelectedDate = (selectedDate: Date) => {
    if (selfExclusions?.map(se => formatShortDate(se.selfExclusionValidFrom)).includes(formatShortDate(selectedDate))) {
      setErrorMessage(`Self Exclusion already exists with Valid From date ${formatShortDate(selectedDate)}`);
      return false;
    } else {
      setErrorMessage('');
      return true;
    }
  };

  const onDateSelected = (date?: Moment) => {
    const selectedDate = startOfUTCDay(date?.toDate() || new Date ());
    setSelectedDate(selectedDate);
    validateSelectedDate(selectedDate);
  };

  const sanitiseInputChange = (object: PlayerSelfExclusionsUpdate) => {
    const { addValidFrom, deleteIds } = object;
    return {
      ...(addValidFrom ? { addValidFrom } : {}),
      ...(!!deleteIds && deleteIds.length > 0 ? { deleteIds } : {}),
    };
  };

  const onDelete = (selfExclusionId: string, undo?: boolean) => {
    const nextIdsToDelete = [];
    if (undo) {
      nextIdsToDelete.push(...idsToDelete.filter(id => id !== selfExclusionId));
    } else {
      nextIdsToDelete.push(...idsToDelete, selfExclusionId);
    }
    setIdsToDelete(nextIdsToDelete);
    onInputChange('selfExclusions', sanitiseInputChange({
      addValidFrom: unsavedSelfExclusion?.selfExclusionValidFrom,
      deleteIds: nextIdsToDelete,
    }));
  };

  const onAdd = () => {
    if (validateSelectedDate(selectedDate)) {
      setErrorMessage('');
      onInputChange('selfExclusions', sanitiseInputChange({
        addValidFrom: selectedDate,
        deleteIds: idsToDelete,
      }));
      setUnsavedSelfExclusion({
        selfExclusionId: 'unsaved',
        selfExclusionShopId: shopId,
        selfExclusionValidFrom: selectedDate,
        selfExclusionValidTo: oneYearFrom(selectedDate),
      });
    }
  };

  const revertUnsavedExclusion = () => {
    onInputChange('selfExclusions', sanitiseInputChange({
      deleteIds: idsToDelete,
    }));
    setUnsavedSelfExclusion({} as PlayerSelfExclusion);
  };

  const unsavedValidFrom = unsavedSelfExclusion.selfExclusionValidFrom;
  return (
    <SelfExclusionEditorStyled data-test-id='self-exclusion-editor'>
      {!unsavedValidFrom && (
        <>
          <EditorInput>
            <ValidDate>
            Valid from
              <DateInput>
                <DatePicker dataTestId='self-exclusion-date-picker' selectedDate={selectedDate} onSelect={onDateSelected} clearable={false}/>
              </DateInput>
            </ValidDate>
            <ValidDate>
            Valid to
              <ValidToDate data-test-id='self-exclusion-valid-to'>
                {formatShortDate(oneYearFrom(selectedDate))}
              </ValidToDate>
            </ValidDate>
            <AddButton>
              <Button data-test-id='add-self-exclusion-button' onClick={onAdd} color='white' background='primary600' borderless>Add</Button>
            </AddButton>
          </EditorInput>
          {errorMessage && <ErrorMessage data-test-id='self-exclusion-error'>{errorMessage}</ErrorMessage>}
        </>
      )}
      <SelfExclusionHistory data-test-id='self-exclusion-history'>
        Self exclusion history
        {!!unsavedValidFrom && <SelfExclusion dataTestId='unsaved-self-exclusion-row' onDelete={revertUnsavedExclusion} selfExclusion={unsavedSelfExclusion}/>}
        {selfExclusions && selfExclusions.length > 0
          ? selfExclusions
            .sort((a, b) => dateSorter(b.selfExclusionValidTo, a.selfExclusionValidTo))
            .map(se => <SelfExclusion key={se.selfExclusionId} dataTestId='self-exclusion-row' onDelete={onDelete} selfExclusion={se}/>)
          : !unsavedValidFrom && <div data-test-id='self-exclusion-history-empty'>No saved self exclusions</div>
        }
      </SelfExclusionHistory>
    </SelfExclusionEditorStyled>
  );
};

export default SelfExclusionEditor;