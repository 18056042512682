import styled, { css } from 'styled-components';
import { PlayerSelfExclusionStatus } from 'types/Player';

type DropdownProps = {
  status?: PlayerSelfExclusionStatus | undefined;
}

export const InformationDropdownStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const DropdownHeader = styled.div<DropdownProps>`
  align-items: center;
  background-color: ${props => props.theme.colors.primary25};
  ${props => props.status === 'active' && css`background-color: ${props => props.theme.colors.error20};`}
  ${props => props.status === 'review' && css`background-color: ${props => props.theme.colors.warning100};`}
  border-radius: 4px;
  display: flex;
  flex-shrink: 0;
  font-weight: 400;
  font-size: 24px;
  height: 68px;
  justify-content: space-between;
  line-height: 28px;
  margin-bottom: 24px;
  padding: 12px;
`;

export const DropdownHeaderLeft = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  gap: 16px;
`;

export const IconStyled = styled.div<DropdownProps>`
  align-items: center;
  display: flex;
  justify-content: center;

  svg {
    path {
      fill: ${props => props.theme.colors.primary500};
      ${props => props.status === 'active' && css`fill: ${props => props.theme.colors.error600};`}
      ${props => props.status === 'review' && css`fill: ${props => props.theme.colors.warning800};`}
    }
  }
`;