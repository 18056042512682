import moment from 'moment';
import { format as datefnsFormat } from 'date-fns';

moment.locale('en-gb');

export const getCurrentDate = () => {
  const newDate = new Date();
  const date = newDate.getDate();
  const year = newDate.getFullYear();
  const longMonth = newDate.toLocaleString('en-GB', { month: 'long' });
  const longDay = newDate.toLocaleString('en-GB', { weekday: 'long' });
  
  return `${longDay} ${date} ${longMonth} ${year}`;
};

export const getMonthAndYear = (date: Date) => {
  const year = date.getFullYear();
  const longMonth = date.toLocaleString('en-GB', { month: 'long' });
  
  return `${longMonth} ${year}`;
};

export const formatTime = (date: Date | undefined | null, defaultValue: string): string => {
  return date ? moment(date).format('HH:mm:ss') : defaultValue;
};

export const oneYearFrom = (date: Date): Date => {
  return moment(date).add(1, 'year').toDate();
};

export const dateInPast = (x: number, unit: moment.unitOfTime.DurationConstructor): Date => {
  return moment(new Date()).subtract(x, unit).toDate();
};

const addHours = (numOfHours: number, date: Date) => {
  date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);

  return date;
};

export const formatEpochSecond = (second: number, format = 'dd/MM/yyyy, HH:mm:ss') => datefnsFormat(addHours(-1, new Date(0)).setSeconds(second), format);

export const formatShortDate = (date: Date | string): string => {
  return moment(date).format('DD/MM/YYYY');
};

export const startOfUTCDay = (date: Date) => {
  const offSetMins = date.getTimezoneOffset();
  return moment(date).startOf('day').subtract(offSetMins, 'minutes').toDate();
};