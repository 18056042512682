export const constants = {
  get: getConstant,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare global { interface Window { env:any; } }

export function getConstant(key: string, defaultValue?: string) {
  let value;
  
  if (typeof window !== 'undefined' && window.env) {
    value = window.env[key];
  }
  if (value === undefined && typeof process.env[key] != 'undefined') {
    value = process.env[key];
  }
  return value === undefined ? defaultValue || '' : value;
}

export const getWindowDimensions = () => {
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  return {
    vw,
    vh, 
  };
};
