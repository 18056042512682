import Badge, { BadgeProps } from 'components/Badge';

interface StatusBadgeProps {
  text: string;
  color: BadgeProps['color'];
  textColor: BadgeProps['textColor'];
  testId?: string;
}

const StatusBadge = (props: StatusBadgeProps) => <Badge {...props} borderRadius='4px' />;

export default StatusBadge;
