import { DefaultTheme } from 'styled-components';
import { Text as TextStyled } from './Text.styled';

type Color = keyof DefaultTheme['colors']

type TextProps = {
  children: string | null
  color?: Color
  dataTestId?: string
  fontSize?: string
  fontStyle?: 'normal' | 'italic' | 'oblique'
  fontWeight?: string
  lineHeight?: string
  margin?: string
}

const Text = ({ children, color = 'grey900', dataTestId, fontSize = '24px', fontStyle = 'normal', fontWeight = '400', lineHeight = '48px', margin = '0px' }: TextProps): JSX.Element => (
  <TextStyled
    color={color}
    data-test-id={dataTestId}
    fontSize={fontSize}
    fontStyle={fontStyle}
    fontWeight={fontWeight}
    lineHeight={lineHeight}
    margin={margin}
  >
    { children }
  </TextStyled>
);

export { Text };
