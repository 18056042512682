/* Transactions is an umbrella term to describe actions which change the state of the terminal,
 * such locking, unlocking, crediting and debiting
 */
import { buildPayloadGaming, buildPayloadRetailCommand, generateCorrelationId } from 'utils/payloadBuilder';
import { getConstant } from 'utils/constants';
import { logError } from 'newrelic';
import { validateRequired } from 'utils/requestValidator';
import { getPubSubIot, iot } from 'aws/Aws';

const gmOverRetail = getConstant('REACT_APP_GM_OVER_RETAIL', 'true') === 'true';

const lockTerminalGaming = async (machineId: string, lockedState: string) => {
  validateRequired({
    machineId,
    lockedState, 
  });
  const payload = { mode: lockedState };
  const body = buildPayloadGaming(payload);
  try {
    await getPubSubIot().publish(`${machineId}/machine/mode`, body);
  } catch (err) {
    logError('Cannot lock Gaming terminal', 'high', err);
  }
};

const lockTerminalRetail = async (machineId: string, deviceUuid: string | undefined, lockedState: string) => {
  validateRequired({
    machineId,
    deviceUuid,
    lockedState, 
  });
  const machineParts = machineId.split('-');
  const clientId = 'backoffice-' + machineParts[1];
  const payload = buildPayloadRetailCommand('lock', clientId, machineId, {
    locked: lockedState === 'locked',
  });
  try {
    await getPubSubIot().publish(`cmd/device/${deviceUuid}/transaction`, payload);
  } catch (err) {
    logError('Cannot lock terminal', 'high', err);
  }
};

export const lockTerminal = (machineId: string, deviceUuid: string | undefined, lockedState: string) => {
  if (machineId.startsWith('gm')) {
    lockTerminalGaming(machineId, lockedState);
  }
  if (!machineId.startsWith('gm') || gmOverRetail) {
    lockTerminalRetail(machineId, deviceUuid, lockedState);
  }
};

const creditTerminalGaming = async (machineId: string, type: string, amount: number, accountId: string) => {
  validateRequired({
    machineId,
    type,
    amount,
  });

  try {
    const payload = buildPayloadGaming({
      amount,
      account_id: accountId,
      transaction_correlation_id: generateCorrelationId(),
      tags: { source: 'bo' },
    });
    await getPubSubIot().publish(`${machineId}/cashin/request`, payload);
  } catch (err) {
    logError('Cannot credit terminal', 'high', err);
  }
};

const creditTerminalRetail = async (machineId: string, deviceUuid: string | undefined, type: string, amount: number) => {
  validateRequired({
    machineId,
    deviceUuid,
    type,
    amount,
  });
  const machineParts = machineId.split('-');
  const clientId = 'backoffice-' + machineParts[1];
  const payload = buildPayloadRetailCommand('credit', clientId, machineId, {
    type,
    amount,
  });
  try {
    await getPubSubIot().publish(`cmd/device/${deviceUuid}/transaction`, payload);
  } catch (err) {
    logError('Cannot credit terminal', 'high', err);
  }
};

// Type is NOTE or MANUAL_CARD. Amount is in pence. Pass in negative amount to debit the balance.
export const creditTerminal = (machineId: string, deviceUuid: string | undefined, type: string, amount: number, accountId: string | undefined) => {
  if (machineId.startsWith('gm') && accountId) {
    creditTerminalGaming(machineId, type, amount, accountId);
  }
  if (!machineId.startsWith('gm') || gmOverRetail) {
    creditTerminalRetail(machineId, deviceUuid, type, amount);
  }
};

export const logsSync = async (machineId: string) => {
  try {
    const targets = await getTargetsByMachineId(machineId);
    const params = buildParams(machineId, targets);
    await iot.createJob(params).promise();
  } catch (err) {
    logError('Cannot publish job sync logs', 'high', err);
  }
};

export const resetMachineState = async (machineId: string) => {
  if (!machineId.startsWith('gm')) {
    console.log('Not implemented');

    return;
  }

  try {
    const payload = buildPayloadGaming({
      transaction_correlation_id: generateCorrelationId(),
      force: true,
    });
    console.log(`${machineId}/state/reset/request`, payload);
    await getPubSubIot().publish(`${machineId}/state/reset/request`, payload);
  } catch (err) {
    logError('Cannot reset terminal state', 'high', err);
  }
};

const getTargetsByMachineId = async (machineId: string) => {
  const targets: string[] = [];
  const describeThingResponse = await iot.describeThing({ thingName: machineId }).promise();
  if (describeThingResponse && describeThingResponse.thingArn) {
    targets.push(describeThingResponse.thingArn);
  }

  return targets;
};

const buildParams = (machineId: string, targets: string[]) => {
  const time = new Date().getTime();
  const jobId = `${machineId}_sync_logs_${time}`;
  const document = JSON.stringify({ operation: 'sync_logs' });

  return {
    jobId,
    document,
    targets,
  };
};
