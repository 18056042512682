import styled, { css } from 'styled-components';

type StyledTextCellProps = {
  justifyContent?: string;
  color?: string;
  backgroundColor?: string;
}

type TextCellProps = {
  children: React.ReactNode;
  justifyContent?: string;
  color?: string;
  backgroundColor?: string;
}

const StyledTextCell = styled.div<StyledTextCellProps>`
  align-items: flex-start;
  display: flex;
  font-family: WH Hoxton;
  font-size: 20px;
  ${(props) => props.justifyContent ? css`justify-content: ${props.justifyContent}` : null};
  ${(props) => props.color ? css`color: ${props.color}` : null};
  ${(props) => props.backgroundColor ? css`background-color: ${props.backgroundColor}` : null};
`;

const TextCell = ({ children, justifyContent, color, backgroundColor }: TextCellProps) => {
  return (
    <StyledTextCell justifyContent={justifyContent} color={color} backgroundColor={backgroundColor}>
      {children}
    </StyledTextCell>
  );
};

export default TextCell;