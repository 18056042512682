import HeaderCell from './Cells/HeaderCell';
import PlayerCell from './Cells/PlayerCell';
import TextCell from './Cells/TextCell';
import { Column, DataSource } from 'types/session/TableData';
import { azSorter, dateSorter } from 'utils/sorters';
import { theme } from 'styled/theme';

const displayDateIfNotToday = (text: string) => {
  const date = new Date(text);
  if (date.toLocaleDateString('en-GB') === new Date().toLocaleDateString('en-GB')) {
    return date.toLocaleTimeString();
  } else {
    return `${date.toLocaleDateString('en-GB')}, ${date.toLocaleTimeString()}`;
  }
};

export const gamingHistoryColumns: Column[] = [
  {
    title: <HeaderCell>Start time</HeaderCell>,
    dataIndex: 'start',
    key: 'start',
    sorter: (a: DataSource, b: DataSource) => dateSorter(a.start, b.start),
    sortDirections: ['descend', 'ascend'],
    width: '10%',
    render(text: string) {
      return <TextCell>{text ? displayDateIfNotToday(text) : 'Not Started'}</TextCell>;
    },
    onCell: (record: DataSource) => {
      return { style: { background: record.isLiveSession ? theme.colors.primary10 : null } };
    },
  },
  {
    title: <HeaderCell>End time</HeaderCell>,
    dataIndex: 'end',
    key: 'end',
    sorter: (a: DataSource, b: DataSource) => dateSorter(a.end, b.end),
    sortDirections: ['descend', 'ascend'],
    width: '10%',
    render(text: string) {
      return <TextCell data-test-id={text? 'end-set' : 'no-end-set' }>{text ? displayDateIfNotToday(text) : '-'}</TextCell>;
    },
    onCell: (record: DataSource) => {
      return { style: { background: record.isLiveSession ? theme.colors.primary10 : null } };
    },
  },
  {
    title: <HeaderCell>Game name</HeaderCell>,
    dataIndex: 'gameName',
    key: 'gameName',
    sorter: (a: DataSource, b: DataSource) => azSorter(a.player, b.player), // todo, change values to player name
    sortDirections: ['ascend', 'descend'],
    width: '10%',
    render(text: string, record: DataSource) {
      return <PlayerCell playerName={text} sessionData={record} />;
    },
    onCell: (record: DataSource) => {
      return { style: { background: record.isLiveSession ? theme.colors.primary10 : null } };
    },
  },
  {
    title: <HeaderCell>Stake played</HeaderCell>,
    dataIndex: 'totalStakes',
    key: 'totalStakes',
    sorter: (a: DataSource, b: DataSource) => a.staked - b.staked,
    sortDirections: ['ascend', 'descend'],
    width: '10%',
    render(text: string) {
      return <TextCell>{text}</TextCell>;
    },
    onCell: (record: DataSource) => {
      return { style: { background: record.isLiveSession ? theme.colors.primary10 : null } };
    },
  },
  {
    title: <HeaderCell>Win Amount</HeaderCell>,
    dataIndex: 'winAmount',
    key: 'winAmount',
    width: '10%',
    render(text: string) {
      return <TextCell>{text}</TextCell>;
    },
    onCell: (record: DataSource) => {
      return { style: { background: record.isLiveSession ? theme.colors.primary10 : null } };
    },
  },
  {
    title: <HeaderCell>Game log</HeaderCell>,
    dataIndex: 'gameLog',
    key: 'gameLog',
    width: '50%',
    render() {
      return <TextCell>&gt;</TextCell>;
    },
    onCell: (record: DataSource) => {
      return {
        onClick: () => record.gameLogHandler(record.sessionId),
      };
    },
  },
];
