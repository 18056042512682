import Icon, { IconType } from 'components/Icon';
import { ReactElement, useState } from 'react';
import { DropdownHeader, DropdownHeaderLeft, IconStyled, InformationDropdownStyled } from './InformationDropdown.styled';
import { PlayerSelfExclusionStatus } from 'types/Player';

interface InformationDropdownProps {
  children: ReactElement;
  dataTestId?: string;
  icon: IconType;
  status?: PlayerSelfExclusionStatus | undefined;
  title: string;
}

const InformationDropdown = ({ children, dataTestId, icon, status, title }: InformationDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  
  return (
    <InformationDropdownStyled data-test-id={dataTestId || 'information-dropdown'}>
      <DropdownHeader onClick={toggleDropdown} status={status}>
        <DropdownHeaderLeft>
          <IconStyled status={status}>
            <Icon name={icon} width={32} height={32}/>
          </IconStyled>
          {title}
        </DropdownHeaderLeft>
        <Icon name={`angle-${isOpen ? 'up': 'down'}`} width={24} height={24}/>
      </DropdownHeader>
      {isOpen && children}
    </InformationDropdownStyled>
  );
};

export default InformationDropdown;