// create search component without redux

import { useState, memo } from 'react';
import Button from 'components/Buttons';
import { SearchIcon, SearchInput } from './Search.styled';
import { useNavigate } from 'react-router-dom';

const Search = () => {
  const navigate = useNavigate();
  const [searchActive, setSearchActive] = useState(false);
  const [search, setSearch] = useState('');

  const onKeyUpHandler = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      setSearchActive(false);
      navigate(`history/voucher/${search}`); // might change?
    }
  };

  return (
    <>
      {searchActive && (
        <SearchInput onChange={(e) => setSearch(e.target.value)} onKeyUp={onKeyUpHandler} placeholder='Search voucher ID' data-test-id='search-field'/>
      )}
      <Button background={searchActive ? 'primary600' : 'white'} borderless={true} onClick={() => setSearchActive(!searchActive)}><SearchIcon active={searchActive} data-test-id='search-icon'/></Button>
    </>
  );
};

export default memo(Search);