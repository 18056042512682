import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 20px;
  gap: 20px;
  flex-direction: column;

  background: ${props => props.theme.colors.grey50};
`;

export const Card = styled.div<{ type?: string }>`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.type && ['success, error'].includes(props.type) ? '20px' : '24px'};

  background: ${(props) => props.type === 'success' ? props.theme.colors.success25 : props.theme.colors.white};
  background: ${(props) => props.type === 'error' ? props.theme.colors.labelError : props.theme.colors.white};
  border-radius: 8px;
`;

export const CardHeading = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid ${props => props.theme.colors.grey200};
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const CardHeader = styled.h3`
  font-size: 24px;
  align-items: baseline;
`;

export const CardInfoTable = styled.div`
  width: 36%;
  display: flex;
  flex-direction: column;
`;

export const CardInfoRow = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const Label = styled.p`
  color: ${props => props.theme.colors.grey500};
  font-size: 18px;
`;

export const Value = styled.p`
  color: ${props => props.theme.colors.grey900};
  font-size: 24px;
`;

export const Badges = styled.div`
  display: flex;
  gap: 8px;
`;

export const CardInfoGrid = styled.div`
  margin-top: 20px;
  display: grid;
  gap: 26px;
  grid-template-columns: 1fr 1fr;
`;

export const CardInfoCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DrawerBody = styled.div`
  background: ${(props) => props.theme.colors.grey50};
  height: 100%;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const DrawerCard = styled.div<{p?: string}>`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.white};
  width: 100%;
  box-shadow: 0px 0px 16px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  padding: ${(props) => props.p ? props.p : 0};
`;

export const H4 = styled.h4`
  font-size: 20px;
  color: ${(props) => props.theme.colors.grey900};
  margin-bottom: 4px;
`;

export const TitleContainer = styled.div`
  padding: 20px 24px;
  border-bottom: 1px solid #E4E7EC;
`;

export const H3 = styled.h3`
  font-size: 24px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.grey900};
`;

export const P = styled.p`
  font-size: 20px;
  color: ${(props) => props.theme.colors.grey500};
  line-height: 30px;
  margin-bottom: 24px;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const CardInfoCells = styled.div`
  padding: 24px;
`;

export const AlertText = styled.p`
  font-size: 24px;
  line-height: 28px;
`;

export const Bold = styled.span`
    font-family: 'WHHoxton-bold';
`;
