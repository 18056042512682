import { theme } from '../styled/theme';

const sessionHighLightThresholdPence = 50000;
const tagNowLabel = 'Tag Now';
const tagLabel = 'Tag Player';

export const sessionHighlightCell = (staked : number, isLiveSession : boolean, player : string | null | undefined = '') => {
  const background =  ( sessionHighlightThresholdExceeded(staked) && !isSessionTagged(player))
    ? theme.colors.stakeHighlight : isLiveSession
      ? theme.colors.primary10 : null;
  return { style: { background: background } };
};

const sessionHighlightThresholdExceeded = (staked : number) => {
  return staked >= sessionHighLightThresholdPence;
};

export const isSessionTagged = (player : string | undefined | null) => {
  return player != null && player !== '' && player !== undefined;
};

export const shouldDisplayTagNow = (staked : number) => {
  return staked >= sessionHighLightThresholdPence;
};

export const getTagButtonLabel = (staked : number) => {
  return staked >= sessionHighLightThresholdPence ? tagNowLabel : tagLabel;
};
