import { useState } from 'react';
import en_GB from 'antd/lib/locale-provider/en_GB';
import moment, { Moment } from 'moment';
import { Calendar as CalendarComponent, ConfigProvider } from 'antd';
import 'moment/locale/en-gb';

import { AngleLeft, AngleRight } from 'components/Icon';
import { CalendarStyled, CalendarHeader, CalendarArrow } from './Calendar.styled';
import { getMonthAndYear } from 'utils/formatDate';

moment.locale('en-gb');

const Calendar = ({ border, onSelect, restrictAfter, restrictBefore, selectedDate, shadow, width }: CalendarProps) => {
  const [navigateDate, setNavigateDate] = useState(selectedDate);

  const restrictedDates = (date: Moment) => !!(restrictAfter && date.isAfter(restrictAfter)) || !!(restrictBefore && date.isBefore(restrictBefore));

  const onSelectDate = (moment: Moment) => {
    setNavigateDate(moment.toDate());
    if (!restrictedDates(moment)) {
      onSelect(moment);
    }
  };

  const headerRender = ({ onChange }: any) => {
    return (
      <CalendarHeader>
        <CalendarArrow data-test-id='month-back-button' onClick={() => onChange(moment(navigateDate).subtract(1, 'month'))}><AngleLeft /></CalendarArrow>
        {getMonthAndYear(navigateDate)}
        <CalendarArrow data-test-id='month-forward-button' onClick={() => onChange(moment(navigateDate).add(1, 'month'))}><AngleRight /></CalendarArrow>
      </CalendarHeader>
    );
  };

  return (
    <ConfigProvider locale={en_GB}>
      <CalendarStyled
        border={border}
        data-test-id='calendar'
        selectedDateIsValid={!restrictedDates(moment(navigateDate))}
        shadow={shadow}
        width={width}
      >
        <CalendarComponent headerRender={headerRender} defaultValue={moment(navigateDate)} fullscreen={false} onSelect={onSelectDate} disabledDate={restrictedDates} />
      </CalendarStyled>
    </ConfigProvider>
  );
};

interface CalendarProps {
  border?: string;
  onSelect: (date: Moment) => void;
  restrictAfter?: Moment;
  restrictBefore?: Moment;
  selectedDate: Date;
  shadow?: string;
  width?: string;
}

export default Calendar;
