import { useState } from 'react';
import PubSub from 'pubsub-js';
import { logError } from 'newrelic';
import { Moment } from 'moment';

import Button from 'components/Buttons/Button';
import Flex from 'styled/Flex';
import Input from 'components/Input';
import Label from 'components/Label';
import useShop from 'hooks/useShop';
import { AddPlayerResponse } from 'types/response/AddPlayerResponse';
import { Player } from 'types/Player';
import DatePicker from 'components/Calendar/DatePicker';

const AddingPlayerContent = (props: AddingPlayerContentProps) => {

  const { close, initialActionType, playersList, setActionType, setSearchInput, addPlayerToPlayerList, addPlayerToShop } = props;
  const { shopId } = useShop();
  const [newPlayerName, setNewPlayerName] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [comments, setComments] = useState('');
  const [loading, setLoading] = useState(false);
  const [lastSgiAt, setLastSgiAt] = useState(undefined as undefined | Date);

  const handleAddPlayer = async () => {
    setLoading(true);
    const playerNamesLowercase = playersList.map(player => player.nickname.toLowerCase());
    
    if (playerNamesLowercase.includes(newPlayerName.toLowerCase())) {
      PubSub.publish('app.error.drawer.player', `The name '${newPlayerName}' already exists in the player list`);
      setLoading(false);
    }
    else {
      try {
        const response = await addPlayerToShop(newPlayerName, shopId, shortDescription, comments, lastSgiAt);
        setLoading(false);

        if (!response?.nicknameId) {
          console.error('Did not receive nicknameId in response to request to add player');
          return;
        }

        const newPlayer = {
          createdAt: new Date(),
          deleted: false,
          merged: false,
          nickname: newPlayerName,
          nicknameId: response?.nicknameId,
          shopId: shopId,
          updatedAt: new Date(),
        };

        PubSub.publish('app.alert.drawer.player', {
          type: 'success',
          message: `'${newPlayerName}' has been successfully added to the player list.`,
          iconName: 'check',
        });

        addPlayerToPlayerList(newPlayer);
        if (initialActionType != 'adding') {
          setActionType('tagging');
        } else {
          setActionType('addPlayerSuccess'); // addPlayerSuccess not currently used
        }
      } catch (err) {
        setLoading(false);
        logError(`Could not add player: ${err}`, 'high', err);
      }
    }
  };

  const onCancel = () => {
    PubSub.publish('app.error.drawer.player', null);
    if (initialActionType != 'adding') {
      setActionType('tagging');
      setSearchInput('');
    } else {
      close();
    }
  };

  const onSelectDate = (moment?: Moment) => {
    if (!moment) {
      setLastSgiAt(undefined);
    } else {
      const offSetMins = moment.toDate().getTimezoneOffset();
      setLastSgiAt(moment.startOf('day').subtract(offSetMins, 'minutes').toDate());
    }
  };

  const withMaxLength = (value: string, length: number): string => value.length >= length ? value.substring(0, length) : value;

  return (
    <>
      <Label data-test-id='nom-de-plume-title' padding='24px 24px 0' fontSize='18px' color='grey900' margin='0 0 8px 0'>Nom de plume*</Label>
      <Flex>
        <Input borderColor='grey300' dataTestId='add-player-name-input' value={newPlayerName} onChange={(e) => setNewPlayerName(e.target.value)} placeholder='Enter nickname' margin='0 24px 8px' padding='11px' wide />
      </Flex>

      <Label padding='24px 24px 0' fontSize='18px' color='grey900' margin='0 0 8px 0'>Short Description</Label>
      <Flex>
        <Input borderColor='grey300' value={shortDescription} onChange={(e) => setShortDescription(withMaxLength(e.target.value, 200))} placeholder='Write a short description' margin='0 24px 8px' padding='11px' height='150px' wide />
      </Flex>
      <Label fontSize='16px' color='grey500' margin='0 24px' textAlign='right'>Max. 200 characters</Label>

      <Label padding='24px 24px 0' fontSize='18px' color='grey900' margin='0 0 8px 0'>Comments</Label>
      <Flex>
        <Input borderColor='grey300' value={comments} onChange={(e) => setComments(withMaxLength(e.target.value, 200))} placeholder='Add some comments' margin='0 24px 8px' padding='11px' height='150px' wide />
      </Flex>
      <Label fontSize='16px' color='grey500' margin='0 24px' textAlign='right'>Max. 200 characters</Label>

      <Label padding='24px 24px 0' fontSize='18px' color='grey900' margin='0 0 8px 0'>SGI Date</Label>
      <Label fontSize='18px' color='grey500' margin='0 24px 8px' >Please enter the date when the last SGI was done with the customer.</Label>
      <Flex padding='0 24px'>
        <DatePicker selectedDate={lastSgiAt} onSelect={onSelectDate} />
      </Flex>

      <Flex padding='24px' gap='8px'>
        <Button data-test-id='cancel-button' onClick={onCancel} background='grey100' borderless wide primary color='primary600'>Cancel</Button>
        <Button data-test-id='player-drawer-add-player-button' onClick={handleAddPlayer} wide secondary loading={loading} disabled={loading || !newPlayerName}>Add Player</Button>
      </Flex>
    </>
  );
};

interface AddingPlayerContentProps {
  addPlayerToShop: (nickname: string, shopId: string, description: string, comments: string, lastSgiAt?: Date) => Promise<AddPlayerResponse>;
  close: () => void;
  initialActionType: string;
  playersList: Player[],
  setActionType: (actionType: string) => void;
  setSearchInput: (actionType: string) => void;
  addPlayerToPlayerList: (player: Player) => void;
}

export default AddingPlayerContent;
