import { useState, memo, useEffect } from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { DatePicker, Table, TimePicker } from 'antd';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import type { Moment } from 'moment';
import moment from 'moment';
import useDisclosure from 'hooks/useDisclosure';
import useMachines from 'hooks/useMachines';
import { MachineMap } from 'types/machine/MachineType';
import Flex from 'styled/Flex';
import Button from 'components/Buttons';
import Input from 'components/Input';
import TerminalPickerModal from 'components/Modal/TerminalPickerModal';
import ConnectedPlayerDrawer from 'components/Drawer/PlayerDrawer/ConnectedPlayerDrawer';
import { AngleDownIcon, SearchIcon } from 'components/History/History.styled';
import { createTerminalDisplayName, createTerminalDisplayNameFromMachineId } from 'utils/formatTerminal';
import { azSorter } from 'utils/sorters';
import {
  Filters,
  ListingContainer,
  SelectedDate,
  SummaryTiles,
  Tile,
  TileTitle,
  TileValue,
  SearchFilters,
  FiltersHeader,
  TerminalPickerLabel,
} from '../VoucherListing/VoucherListing.styled';
import useVouchers from 'hooks/useVouchers';
import useShop from 'hooks/useShop';
import useRightDrawer from 'hooks/useRightDrawer';

import { sessionHistoryColumns } from 'components/Table/HistoryTable/Columns';
import { SessionHistory as SessionType } from 'types/session/SummarySession';
import useSWR from 'swr';
import { SESSION_HISTORY_CACHE } from 'api';
import { getSessionHistoryInShop } from 'api/sessionApi';
import Icon from 'components/Icon';
import debounce from 'lodash.debounce';
import { retailCurrency } from 'retail-currency';

const { RangePicker } = DatePicker;

const LIMIT = 50;
const SWR_CONFIG = {
  refreshInterval: 60 * 1000,
  revalidateOnMount: true,
};

const SessionHistory = () => {
  const { shopId } = useShop();
  const [offset, setOffset] = useState(0);
  const terminalPicker = useDisclosure();
  const { machines } = useMachines();
  const navigate = useNavigate();
  const [totalCredits, setTotalCredits] = useState(0);
  const [totalCash, setTotalCash] = useState(0);
  const [selectedTerminals, setSelectedTerminals] = useState<MachineMap[]>([]);
  const [selectedDate, setSelectedDate] = useState({
    dateFrom: new Date(new Date().setHours(0, 0, 0)),
    dateTo: new Date(new Date().setHours(23, 59, 59)),
  });
  const [selectedHours, setSelectedhours] = useState({
    hourFrom: moment().startOf('day').hours(),
    hourTo: moment().endOf('day').hours(),
  });
  const [selectedPlayer, setSelectedPlayer] = useState<string | null>(null);

  const { data: sessions = [], error, mutate } = useSWR([SESSION_HISTORY_CACHE, shopId, offset, selectedDate.dateFrom, selectedDate.dateTo], (...args) => {
    return getSessionHistoryInShop({
      fromDate: selectedDate.dateFrom,
      toDate: selectedDate.dateTo,
      shopId,
      limit: LIMIT,
      offset: Number(args[2]),
    });
  }, SWR_CONFIG);

  const { data: nextPageSessions = [] } = useSWR([SESSION_HISTORY_CACHE, shopId, offset + LIMIT, selectedDate.dateFrom, selectedDate.dateTo], (...args) => {
    return getSessionHistoryInShop({
      fromDate: selectedDate.dateFrom,
      toDate: selectedDate.dateTo,
      shopId,
      limit: LIMIT,
      offset: Number(args[2]),
    });
  }, SWR_CONFIG);

  const goToNextPage = () => {
    setOffset(prev => prev + LIMIT);
    window.scrollTo({
      top: 0,
    });
  };
  const goToPrevPage = () => {
    setOffset(prev => Math.max(prev - LIMIT, 0));
    window.scrollTo({
      top: 0,
    });
  };

  const loading = !sessions && !error;

  const { totals, isLoading } = useVouchers({
    dateFrom: selectedDate.dateFrom,
    dateTo: selectedDate.dateTo,
    shopId,
  });

  const [sessionsData, setSessionsData] = useState({
    sessions: sessions || [],
    error,
    loading,
  });
  const [sess, setSess] = useState(sessionsData.sessions);

  const sessionDrawer = useRightDrawer(sessions);

  const onPlayerFilterChange = (e: Event) => {
    debounce(() => {
      const { value } = e.target as HTMLInputElement;
      if (value) {
        setSelectedPlayer(value.toLowerCase());
      } else {
        setSelectedPlayer(null);
      }
    }, 500)();
  };

  const playerFilterValidation = (session: SessionType) => {
    if (selectedPlayer && session.nickname) {
      return session.nickname.toLocaleLowerCase().includes(selectedPlayer);
    }
    if (selectedPlayer && session.realName) {
      return session.realName.toLocaleLowerCase().includes(selectedPlayer);
    }
  };

  const onChangeDateRange = (props: [Moment, Moment] | null) => {
    if (!props) {
      const today = new Date(new Date().setDate(new Date().getDate()));
      const dayFourteenAgo = new Date(new Date().setDate(new Date().getDate() - 14));
      setSelectedDate({
        dateTo: new Date(today.setHours(23, 59, 59)),
        dateFrom: new Date(dayFourteenAgo.setHours(0, 0, 0)),
      });
      return;
    }

    const [dateFrom, dateTo] = props;
    setSelectedDate({
      dateFrom: new Date(dateFrom.toDate().setHours(0, 0, 0, 0)),
      dateTo: new Date(dateTo.toDate().setHours(23, 59, 59, 99)),
    });
  };

  const onChangeTimeRange = (props: [Moment, Moment] | null) => {
    if (!props) {
      setSelectedhours({
        hourFrom: moment().startOf('day').hours(),
        hourTo: moment().endOf('day').hours(),
      });
      return;
    }

    setSelectedhours({
      hourFrom: moment(props[0]).hours(),
      hourTo: moment(props[1]).hours(),
    });
  };

  const getDisplayedDeviceName = (device: MachineMap) => {
    return device.machineState.shop_device_index
      ? createTerminalDisplayName(device.thingType, device.machineState.shop_device_index)
      : createTerminalDisplayNameFromMachineId(device.machineId);
  };

  const getTerminalPickerLabel = () => {
    if (selectedTerminals.length === 0 || selectedTerminals.length === machines.length) {
      return 'All Terminals';
    } else {
      const labels: string[] = [];
      selectedTerminals.map(t => labels.push(getDisplayedDeviceName(t)));
      return labels.sort(azSorter).join(', ');
    }
  };

  const tagPlayerHandler = (sessionId: string) => {
    const index = sessions.findIndex(s => s.sessionId === sessionId);
    return sessionDrawer?.openWith(index);
  };

  const updateUiAfterTaggingSession = (session: any) => {
    const updatedSessions: any[] = [
      ...sessions,
    ];
    const i = updatedSessions.findIndex(s => s.sessionId === session.sessionId);
    updatedSessions[i] = session;
    setSess(updatedSessions);
  };

  let displayedSessions = sessions;

  displayedSessions.forEach(e => e.tagPlayerHandler = tagPlayerHandler);
  if (selectedTerminals.length > 0) {
    displayedSessions = sessions.filter(s => selectedTerminals.find(t => {
      //TODO - filter by deviceUuid only, when FOBT sessions are populating deviceUuid
      return s.terminalId === t.machineId;
    })
    );
  }
  if (selectedHours.hourFrom !== 0 || selectedHours.hourTo !== 23) {
    displayedSessions = sessions.filter((s) => {
      const start = s.startedAt ? moment(s.startedAt).hours() : 0;
      const end = s.endedAt ? moment(s.endedAt).hours() : 23;
      return start >= selectedHours.hourFrom && end <= selectedHours.hourTo;
    });
  }
  if (selectedPlayer) {
    displayedSessions = displayedSessions.filter(session => playerFilterValidation(session));
  }

  useEffect(() => {
    setSessionsData({
      ...sessionsData,
      loading: true,
    });
    mutate().then(() =>
      setSessionsData({
        ...sessionsData,
        loading: false,
      })
    );
    const credit = sessionsData.sessions.reduce((a, b) => a + b.totalLoaded, 0) / 100;
    const cash = sessionsData.sessions.reduce((a, b) => a + b.cashLoaded, 0) / 100;
    setTotalCredits(credit);
    setTotalCash(cash);
  }, [selectedDate]);

  useEffect(() => {
    setSess(sessionsData.sessions);
  }, [sessionsData.sessions]);

  const onCell = (record: SessionType) => {
    return {
      onClick: () => navigate(`/history/session/${record.sessionId}`),
    };
  };

  const clickableColumns = sessionHistoryColumns.map(c => {
    if (c.key !== 'player') {
      return {
        ...c,
        onCell: onCell,
      };
    }

    return c;
  });

  const canGoToPreviousPage = offset > 0;
  const canGoToNextPage = nextPageSessions.length > 0;

  return (
    <>
      <ListingContainer>
        <SelectedDate>
          <>
            <span>{format(selectedDate.dateFrom, 'cccc, d MMMM yyyy')}</span>
            {selectedDate.dateTo && selectedDate.dateTo.getDate() !== selectedDate.dateFrom.getDate() ? <span>{' '}<ArrowRightOutlined />{' '}{format(selectedDate.dateTo, 'cccc, d MMMM yyyy')}</span> : null}
          </>
        </SelectedDate>
        <Filters>
          <SummaryTiles>
            <Tile>
              <TileTitle>Unredeemed</TileTitle>
              {totals && <TileValue>{retailCurrency.format(totals['totalUnredeemed'] / 100)}</TileValue>}
            </Tile>
            <Tile>
              <TileTitle>Credits</TileTitle>
              {totalCredits > 0 && <TileValue>{retailCurrency.format(totalCredits)}</TileValue>}
            </Tile>
            <Tile>
              <TileTitle>Total</TileTitle>
              {totalCash > 0 && <TileValue>{retailCurrency.format(totalCash)}</TileValue>}
            </Tile>
          </SummaryTiles>
          <SearchFilters>
            <FiltersHeader>Search / Filters:</FiltersHeader>
            <Flex justifyContent='space-between'>
              <Flex flex='1 1 0' padding='0 5px 0 0'>
                <Input borderColor='grey300' onChange={onPlayerFilterChange} prefix={<SearchIcon />} placeholder='Search by player / ticket' padding='13px 5px 10px 5px' wide />
              </Flex>
              <Flex flex='1 1 0' padding='0 5px'>
                <Button onClick={terminalPicker.open} justifyContent='space-between' wide>
                  <TerminalPickerLabel>{getTerminalPickerLabel()}</TerminalPickerLabel>
                  <AngleDownIcon />
                </Button>
              </Flex>
              <Flex flex='1 1 0' padding='0 0 0 5px'>
                {/*
                  // @ts-ignore */}
                <RangePicker value={[moment(selectedDate.dateFrom), moment(selectedDate.dateTo)]} size='large' style={{ flex: '1' }} onChange={onChangeDateRange} />
              </Flex>
              <Flex flex='1 1 0' padding='0 0 0 5px'>
                {/*
                  // @ts-ignore */}
                <TimePicker.RangePicker defaultValue={moment('00')} format='HH:00' size='large' style={{ flex: '1' }} onChange={onChangeTimeRange} />
              </Flex>
            </Flex>
          </SearchFilters>
        </Filters>
        {<Table pagination={false} columns={clickableColumns} loading={isLoading} dataSource={displayedSessions || []} />}
        <Flex gap='1rem' justifyContent='flex-end'>
          <Button onClick={goToPrevPage} disabled={!canGoToPreviousPage}><Icon name='angle-left' /></Button>
          <Button onClick={goToNextPage} disabled={!canGoToNextPage}><Icon name='angle-right' /></Button>
        </Flex>
      </ListingContainer>
      {terminalPicker.isOpen &&
        <TerminalPickerModal
          isOpen={terminalPicker.isOpen}
          close={terminalPicker.close}
          machines={machines}
          currentTerminals={selectedTerminals}
          setCurrentTerminals={setSelectedTerminals}
        />
      }
      {sessionDrawer.contextElement &&
        <ConnectedPlayerDrawer
          close={sessionDrawer.close}
          isLive={false}
          isOpen={sessionDrawer.isOpen}
          mutateSessions={mutate}
          session={sessionDrawer.contextElement}
          updateUiAfterTaggingSession={updateUiAfterTaggingSession}
          initialActionType={'tagging'}
        />
      }
    </>
  );
};

export default memo(SessionHistory);
