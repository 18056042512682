import Amplify, { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import { getConstant } from 'utils/constants';

// Set to DEBUG if having IoT pub-sub issues
Amplify.Logger.LOG_LEVEL = 'INFO';

const awsconfig = {
  region: getConstant('REACT_APP_REGION'),
  credentials: new AWS.CognitoIdentityCredentials({
    // @ts-ignore
    IdentityPoolId: getConstant('REACT_APP_COGNITO_IDENTITY_POOL_ID'),
  }),
};

console.log('Configuring AWS.config using', awsconfig);
AWS.config.update(awsconfig);
console.log('AWS.config.credentials', AWS.config.credentials);

const providerconfig = {
  aws_pubsub_region: getConstant('REACT_APP_REGION'),
  aws_pubsub_endpoint: `wss://${getConstant('REACT_APP_ENDPOINT')}/mqtt`,
};

const iotProvider = new AWSIoTProvider(providerconfig);

console.log('Configuring AWS.config using', providerconfig);
Amplify.addPluggable(
  iotProvider
);

const amplifyconfig = {
  Auth: {
    region: getConstant('REACT_APP_REGION'),
    identityPoolId: getConstant('REACT_APP_COGNITO_IDENTITY_POOL_ID'),
  },
};

console.log('Configuring Amplify and Auth using', amplifyconfig);
Amplify.configure(amplifyconfig);
Auth.configure(amplifyconfig);
iotProvider.configure(providerconfig);

// Hack to get PubSub working on a static website
if (typeof window !== 'undefined') {
  // @ts-ignore
  window.PubSubIot = iotProvider;
}

export const iot = new AWS.Iot({
  region: getConstant('REACT_APP_REGION'),
});

export const iotdata = new AWS.IotData({
  region: getConstant('REACT_APP_REGION'),
  endpoint: `https://${getConstant('REACT_APP_ENDPOINT')}`,
});

// @ts-ignore
export const getPubSubIot = () => typeof window !== 'undefined' && window.env ? window.PubSubIot : Amplify.PubSub;

export const cloudWatchLogs = new AWS.CloudWatchLogs();

export const s3 = new AWS.S3();
