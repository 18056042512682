import { useEffect, useState } from 'react';
import PubSub from 'pubsub-js';

import AddingPlayerContent from './AddingPlayerContent';
import Drawer from 'components/Drawer';
import PlayerDrawerTitle from './PlayerDrawerTitle';
import TaggingPlayerContent from './TaggingPlayerContent';
import TerminalInfoContent from './TerminalInfoContent';
import { AddPlayerResponse } from 'types/response/AddPlayerResponse';
import { DrawerBody, DrawerContent } from 'components/Tile/Tile.styled';
import { Message, Messages } from 'components/Message';
import { OnTagSessionParams, OnUntagSessionParams } from 'utils/mutateHelpers';
import { Player } from 'types/Player';
import { GeneralAlert } from 'types/alert/GeneralAlert';
import { SummarySession } from 'types/session/SummarySession';
import { createGroupName, createTerminalDisplayName } from 'utils/formatTerminal';

const PlayerDrawer = ({ initialActionType, session, mutateSessions, updateUiAfterTaggingSession, playersData, isOpen, close, addPlayerToShop, onTagSession, onUntagSession }: PlayerDrawerProps) => {
  const { players, loading = false, mutate: mutatePlayers } = playersData;
  const terminalName = createTerminalDisplayName(session?.thingType, session?.shopDeviceIndex);
  const groupName = createGroupName(session?.thingType);

  const [currentPlayer, setCurrentPlayer] = useState({
    nickname: session?.nickname || '',
    nicknameId: session?.nicknameId || '',
  });
  const [alert, setAlert] = useState<GeneralAlert | null>();
  const [playersList, setPlayersList] = useState(players || []);
  const [searchInput, setSearchInput] = useState('');
  const [actionType, setActionType] = useState(initialActionType);
 
  useEffect(() => {
    PubSub.subscribe('app.alert.drawer.player', onAlert);
    PubSub.subscribe('app.error.drawer.player', onError);
  }, []);

  useEffect(() => {
    setPlayersList(players || []);
  }, [players]);

  const onAlert = (topic: string, data: GeneralAlert) => {
    setAlert(data);
  };

  const onError = (topic: string, data: any) => {
    if (!data) {
      setAlert(null);
      return;
    }

    const error: GeneralAlert = {
      iconName: 'critical',
      message: data.toString(),
      type: 'error',
    };
    setAlert(error);
  };

  const onClose = () => {
    setSearchInput('');
    setAlert(null);
    setActionType(initialActionType);
    if (mutateSessions) mutateSessions();
    mutatePlayers(players || []);
    close();
  };

  const addPlayerToPlayerList = (player: Player) => {
    setPlayersList([...playersList, player]);
  };

  return (
    <Drawer
      dataTestId='player-drawer'
      isOpen={isOpen}
      onClose={onClose}
      title={<PlayerDrawerTitle initialActionType={initialActionType} terminalName={terminalName} groupName={groupName} />}
      position='right'
    >
      <DrawerBody>
        {alert &&
          <Messages>
            <Message type={alert.type} iconName={alert.iconName}>
              {alert.message}
            </Message>
          </Messages>
        }

        <DrawerContent data-test-id={`drawer-content-${actionType}`}>
          {actionType === 'terminalInfo' &&
            <TerminalInfoContent
              nickname={currentPlayer?.nickname || ''}
              setActionType={setActionType}
            />
          }

          {actionType === 'tagging' && session &&
            <TaggingPlayerContent
              currentPlayer={currentPlayer}
              loading={loading}
              onClose={onClose}
              onTagSession={onTagSession}
              onUntagSession={onUntagSession}
              players={playersList}
              searchInput={searchInput}
              session={session}
              setActionType={setActionType}
              setCurrentPlayer={setCurrentPlayer}
              setSearchInput={setSearchInput}
              updateUiAfterTaggingSession={updateUiAfterTaggingSession}
            />
          }

          {actionType === 'adding' &&
            <AddingPlayerContent
              addPlayerToPlayerList={addPlayerToPlayerList}
              addPlayerToShop={addPlayerToShop}
              playersList={playersList}
              setActionType={setActionType}
              setSearchInput={setSearchInput}
              initialActionType={initialActionType}
              close={close}
            />
          }
        </DrawerContent>
      </DrawerBody>
    </Drawer>
  );
};

interface PlayerDrawerProps {
  isOpen: boolean;
  initialActionType: string;
  close: () => void;
  session?: SummarySession;
  mutateSessions?: () => void;
  addPlayerToShop: (nickname: string, shopId: string, description: string, comments: string, lastSgiAt?: Date) => Promise<AddPlayerResponse>;
  updateUiAfterTaggingSession?: (session: SummarySession) => void;
  playersData: {
    players: Player[] | undefined;
    error: any;
    loading: boolean;
    mutate: (players: Player[]) => void;
  };
  onTagSession: (props: OnTagSessionParams) => Promise<void>;
  onUntagSession: (props: OnUntagSessionParams) => Promise<void>;
}

export default PlayerDrawer;
