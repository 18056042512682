import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px;
`;

export const ContentSection = styled.div`
  margin-bottom: 24px;
`;

export const Paragraph = styled.p`
  font-size: 20px;
  margin-bottom: 24px;
`;

export const PaymentMethodButton = styled.button<{ isActive: boolean, center?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;

  width: 100%;
  height: 60px;
  ${props => props.center ? 'text-align: center;' : null}
  /* white */
  background: ${props => props.isActive ? props.theme.colors.primary25 : props.theme.colors.white};
  ${props => props.isActive ? `color: ${props.theme.colors.primary600}` : null};

  /* grey/300 */
  ${props => !props.isActive ? `border: 1px solid ${props.theme.colors.grey300};` : 'border: none;'};
  border-radius: 4px;

  span {
    padding: 10px 20px;
    border-radius: 4px;
    background: ${props => props.isActive ? props.theme.colors.primary600 : props.theme.colors.grey100};
    margin-right: 16px;

    svg {
      path {
        ${props => props.isActive ? 'stroke: white;' : null}
      }
    }
  }
`;

export const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  grid-row: 52px;
`;

export const PaymentMethods = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

export const PaymentValues = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const PaymentInputContainer = styled.div`
  display: flex;
  grid-column: 1/4;
`;

export const PaymentInputLabel = styled.div`
  padding: 11px 12px;
  border: 1px solid ${props => props.theme.colors.grey300};
  border-radius: 4px 0px 0px 4px;
`;

export const PaymentInput = styled.input`
  width: 100%;
  padding: 11px 12px;
  /* white */

  background: ${props => props.theme.colors.white};
  /* grey/300 */

  border: 1px solid ${props => props.theme.colors.grey300};
  border-radius: 0px 4px 4px 0px;
  border-left: none;
`;

export const DepositLimit = styled.div`
  margin-top: 18px;
  p {
    font-size: 16px;
    color: ${props => props.theme.colors.grey500};
  }
`;
