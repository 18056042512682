import styled, { css } from 'styled-components';

type AlignItemsValues =
  | 'normal'
  | 'stretch'
  | 'center'
  | 'start'
  | 'end'
  | 'flex-start'
  | 'flex-end'
  | 'baseline'
  | 'first baseline'
  | 'last baseline'
  | 'safe center'
  | 'unsafe center'
  | 'inherit'
  | 'initial'
  | 'revert'
  | 'unset';

type JustifyContentValues =
  | 'center'
  | 'start'
  | 'end'
  | 'flex-start'
  | 'flex-end'
  | 'left'
  | 'right'
  | 'normal'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'stretch'
  | 'safe center'
  | 'unsafe center'
  | 'inherit'
  | 'initial'
  | 'revert'
  | 'unset';

type DirectionValues = 'column' | 'column-reverse' | 'inherit' | 'initial' | 'revert' | 'row' | 'row-revert' | 'unset';

type WrapValues = 'nowrap' | 'wrap-reverse' | 'wrap';

interface FlexAttributes {
  alignItems?: AlignItemsValues;
  justifyContent?: JustifyContentValues;
  direction?: DirectionValues;
  wrap?: WrapValues;
  gap?: string;
  padding?: string;
  flex?: string;
  height?: string;
  wide?: boolean;
}

type DivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

type FlexProps = FlexAttributes & DivProps;

const Flex = styled.div<FlexProps>`
  display: flex;
  ${(props) => props.alignItems ? css`align-items: ${props.alignItems}` : null};
  ${(props) => props.justifyContent ? css`justify-content: ${props.justifyContent}` : null};
  ${(props) => props.direction ? css`flex-direction: ${props.direction}` : null};
  ${(props) => props.wrap ? css`flex-wrap: ${props.wrap}` : null};
  ${(props) => props.gap ? css`grid-gap: ${props.gap}` : null};
  ${(props) => props.padding ? css`padding: ${props.padding}` : null};
  ${(props) => props.flex ? css`flex: ${props.flex}` : null};
  ${(props) => props.height ? css`height: ${props.height}` : null};
  ${(props) => props.wide ? css`width: 100%` : null};
`;

export default Flex;
