import { Routes, Route } from 'react-router-dom';

import AlertsLogs from 'components/AlertsLogs';
import ConnectedHistory from 'components/History/ConnectedHistory';
import EventLogs from 'components/EventLogs';
import GamingHistory from 'components/GamingHistory/GamingHistory';
import HistoryRoot from 'components/History/HistoryRoot';
import Home from 'components/Home/Home';
import Layout from 'components/Layout';
import NotFound from 'components/NotFound';
import PlayerManagement from 'components/PlayerManagement/PlayerManagement';
import ReportsRoot from 'components/Reports/ReportsRoot';
import SessionDetails from 'components/History/SessionDetails';
import SessionHistory from 'components/History/SessionHistory';
import VoucherDetails from 'components/History/VoucherDetails';
import VoucherListing from 'components/History/VoucherListing';

const AppRoutes = () => (
  <Routes>
    <Route path='/' element={<Layout />}>
      <Route path='/player-tagging' element={<ConnectedHistory historyType='history' />} />
      <Route path='/player-management' element={<PlayerManagement />} />
      <Route index element={<Home />} />
      <Route path='/dashboard' element={<Home />} />
      <Route path='/history' element={<HistoryRoot />}>
        <Route index element={<SessionHistory />} />
        <Route path='redeemed' element={<VoucherListing type='redeemed' />} />
        <Route path='unredeemed' element={<VoucherListing type='unredeemed' />} />
        <Route path='gaming-activity' element={<GamingHistory />}/>
        <Route path='alerts' element={<AlertsLogs />} />
        <Route path='event-log' element={<EventLogs />} />
      </Route>
      <Route path='history/:uid' element={<VoucherDetails />} />
      <Route path='history/session/:uid' element={<SessionDetails />} />
      <Route path='reports' element={<ReportsRoot />} />
      <Route path='*' element={<NotFound />} />
    </Route>
  </Routes>
);

export default AppRoutes;

