import { useEffect, useState } from 'react';
import PubSub from 'pubsub-js';
import Button from 'components/Buttons/Button';
import Icon from 'components/Icon';
import { EditIconStyled } from './EditButtons.styled';

interface EditButtonsProps {
  canEdit: boolean;
}

const EditButtons = ({ canEdit }: EditButtonsProps) => {
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    PubSub.subscribe('app.modal.success', onModalSuccess);

    return () => {
      PubSub.unsubscribe(onModalSuccess);
    };
  }, []);

  const onModalSuccess = () => {
    setIsEditMode(false);
    PubSub.publish('app.player.edit.disable');
  };

  const onSave = () => {
    PubSub.publish('app.player.edit.validate');
  };

  const onCancel = () => {
    PubSub.publish('app.modal.open', 'cancel-update');
  };

  const onEdit = () => {
    PubSub.publish('app.player.edit.enable');
    setIsEditMode(true);
  };

  return isEditMode
    ? <>
      <Button data-test-id='save-edits-button' onClick={onSave} color='white' background='primary600' borderless>Save</Button>
      <Button data-test-id='cancel-edits-button' onClick={onCancel} color='primary600' background='grey100' border='4px solid #D8E1FF'>Cancel</Button>
    </>
    : <Button data-test-id='edit-button' onClick={onEdit} disabled={!canEdit} color={`${canEdit ? 'primary600' : 'grey300'}`} background={`${canEdit ? 'grey100' : 'grey25'}`} border={`${canEdit ? '4px solid #D8E1FF' : 'none'}`}>
      <EditIconStyled disabled={!canEdit}>
        <Icon name='edit1' width={24} height={24}/>
      </EditIconStyled>
      Edit
    </Button>;
};

export default EditButtons;