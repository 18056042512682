import PubSub from 'pubsub-js';
import { useEffect, useState } from 'react';
import ConfirmationModal from 'components/Modal/PlayerManagementModals/ConfirmationModal';
import ConnectedPlayerDrawer from 'components/Drawer/PlayerDrawer/ConnectedPlayerDrawer';
import MultiSelectList from 'components/PlayerManagement/MultiSelectList';
import Dashboard from './Dashboard';
import useDisclosure from 'hooks/useDisclosure';
import useShop from 'hooks/useShop';
import { EditModeOverlay, PlayerManagementWrapper } from './PlayerManagement.styled';
import { PlayerFetchData, Player, PlayerAction, PlayerNickname, PlayerWithAction } from 'types/Player';
import { getPlayers } from 'utils/fetchHelpers';
import { getPlayerById } from 'api/playerApi';

const PlayerManagement = () => {
  const [selectedPlayers, setSelectedPlayers] = useState<PlayerNickname[]>([]);
  const [viewedPlayers, setViewedPlayers] = useState<PlayerFetchData[]>([]);
  const [currentPlayerAction, setCurrentPlayerAction] = useState<PlayerAction>();
  const [isEditMode, setIsEditMode] = useState(false);
  const confirmationDialog = useDisclosure();
  const playerDrawer = useDisclosure();
  const { shopId } = useShop();
  const playersData = getPlayers(false, shopId);

  useEffect(() => {
    PubSub.subscribe('app.modal.open', openConfirmationDialog);
    PubSub.subscribe('app.modal.dismiss', closeConfirmationDialog);
    PubSub.subscribe('app.player.edit.enable', enableEditMode);
    PubSub.subscribe('app.player.edit.disable', disableEditMode);
    return () => {
      PubSub.unsubscribe(openConfirmationDialog);
      PubSub.unsubscribe(closeConfirmationDialog);
      PubSub.unsubscribe(enableEditMode);
      PubSub.unsubscribe(disableEditMode);
    };
  }, []);

  useEffect(() => {
    PubSub.subscribe('app.modal.success', updateViewedPlayers);
    return () => {
      PubSub.unsubscribe(updateViewedPlayers);
    };
  }, [playersData.players]);

  const openConfirmationDialog = (topic: string, playerAction: PlayerAction) => {
    setCurrentPlayerAction(playerAction);
    confirmationDialog.open();
  };

  const closeConfirmationDialog = () => {
    setCurrentPlayerAction(undefined);
    confirmationDialog.close();
  };

  const updateViewedPlayers = (topic: string, { playerAction, player }: PlayerWithAction) => {
    if (playerAction === 'update') {
      playersData.mutate().then(() => {
        if (player?.nickname) {
          onPlayerHighlighted([player]);
          setSelectedPlayers([player as PlayerNickname]);
        }
      });
    }

    if (playerAction === 'delete') {
      if (playersData.players) {
        const nextHighlightedPlayer = playersData.players.filter(p => p.nicknameId !== player?.nicknameId)[0];
        setViewedPlayers([{
          player: nextHighlightedPlayer,
          loading: true,
        }]);
        onPlayerHighlighted([nextHighlightedPlayer]);
      }
      setSelectedPlayers([]);
      playersData.mutate();
    }

    if (playerAction === 'merge' && player) {
      playersData.mutate().then(() => {
        setViewedPlayers([{
          player: player,
          loading: true,
        }]);
        onPlayerHighlighted([player]);
        setSelectedPlayers([]);
      });
    }
  };

  const enableEditMode = () => setIsEditMode(true);

  const disableEditMode = () => setIsEditMode(false);

  const onOverlayClicked = () => PubSub.publish('app.modal.open', 'cancel-update');

  const onAddPlayer = () => {
    PubSub.publish('app.alert.drawer.player', null);
    playerDrawer.open();
  };

  const onPlayerHighlighted = (highlightedPlayers: PlayerNickname[]) => {
    const nextViewedPlayers: PlayerFetchData[] = [];
    viewedPlayers.map(vp => {
      vp?.player && highlightedPlayers.map(hp => hp.nicknameId).includes(vp.player.nicknameId) && nextViewedPlayers.push(vp);
    });

    const { nickname: newHighlightedPlayerNickname, nicknameId: newHighlightedPlayerNicknameId } = highlightedPlayers.find(hp => !nextViewedPlayers.map(vp => vp?.player?.nicknameId).includes(hp.nicknameId)) || {} as PlayerNickname;

    if (newHighlightedPlayerNickname && newHighlightedPlayerNicknameId) {
      const loadingPlayer: PlayerFetchData = {
        player: {
          nickname: newHighlightedPlayerNickname,
          nicknameId: newHighlightedPlayerNicknameId,
        } as Player,
        loading: true,
      };
      setViewedPlayers([...nextViewedPlayers, loadingPlayer]);

      getPlayerById(newHighlightedPlayerNicknameId).then(player => {
        const nextPlayer: PlayerFetchData = {
          player,
          loading: false,
        };
        setViewedPlayers([...nextViewedPlayers, nextPlayer]);
      }
      ).catch(err => {
        const errorPlayer: PlayerFetchData = {
          player: {
            nickname: newHighlightedPlayerNickname,
            nicknameId: newHighlightedPlayerNicknameId,
          } as Player,
          loading: false,
          error: err,
        };
        setViewedPlayers([...nextViewedPlayers, errorPlayer]);
      });
    } else {
      setViewedPlayers(nextViewedPlayers);
    }
  };

  return (
    <PlayerManagementWrapper>
      <MultiSelectList
        playersData={playersData}
        isEditMode={isEditMode}
        selectedPlayers={selectedPlayers}
        setSelectedPlayers={setSelectedPlayers}
        onPlayerHighlighted={onPlayerHighlighted}
      />
      <Dashboard
        addPlayer={onAddPlayer}
        allPlayers={playersData.players}
        isEditMode={isEditMode}
        selectedPlayers={selectedPlayers}
        viewedPlayers={viewedPlayers}
      />

      {confirmationDialog.isOpen && currentPlayerAction &&
        <ConfirmationModal
          isOpen={confirmationDialog.isOpen}
          close={confirmationDialog.close}
          options={selectedPlayers.map(p => p.nickname)}
          playerAction={currentPlayerAction}
        />
      }

      {isEditMode && <EditModeOverlay onClick={onOverlayClicked}/>}

      {playerDrawer &&
        <ConnectedPlayerDrawer
          close={playerDrawer.close}
          isLive={false}
          isOpen={playerDrawer.isOpen}
          initialActionType={'adding'}
        />
      }
    </PlayerManagementWrapper>
  );

};

export default PlayerManagement;