import isObject, { AnyObject } from './isObject';

interface BonusResponse {
  [k: string]: unknown;
  [k: symbol]: unknown;
  payload: {
    slug: string;
  }
}

export const isValidBonusResponse = (update: unknown): update is BonusResponse => {
  if (!isObject(update)) {
    return false;
  }
  
  if (!('payload' in update)) {
    return false;
  }

  const payload = update.payload as Record<'update', unknown>;
  if (!isObject(payload) || !('slug' in payload)) {
    return false;
  }

  return true;
};

export const getTopicFromUpdate = (update: AnyObject) => {
  return update[Object.getOwnPropertySymbols(update)[0]];
};

export const isValidTopic = (topic: unknown): topic is string => {
  return typeof topic === 'string';
};

export const isComplianceTopic = (topic: string) => {
  return topic.includes('compliance');
};
