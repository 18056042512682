import { useState, memo } from 'react';
import { EventLogsContainer, LogsContainer, LogRow, LogElement, LogElementCategory, DropdownsContainer, WinValue } from './Logs.styled';
import { formatTime } from 'utils/formatDate';
import Button from 'components/Buttons';
import { CalendarIcon } from 'components/Modal/DatePickerModal/DatePickerModal.styled';
import { AngleDownIcon } from 'components/History/History.styled';
import useDisclosure from 'hooks/useDisclosure';
import DatePickerModal from 'components/Modal/DatePickerModal';
import { Dropdown, Menu, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { MenuInfo } from 'rc-menu/lib/interface';
import { LogEventType, AlertEventType, MenuItemType, EventMenuItemType, AlertsMenuItemType, GroupedGameLogTypes, GamesMessageType, LogGameType } from 'types/logs/Logs';
import { Table as TableComponent } from 'antd';
import Icon from 'components/Icon';
import { retailCurrency } from 'retail-currency';

interface LogsPropsTyoe {
  type: 'games' | 'events' | 'alerts';
  eventTypes?: EventMenuItemType[];
  alertTypes?: AlertsMenuItemType[];
  machinesFilters: MenuItemType[],
  selectedTerminal: string;
  selectedEventType?: string; 
  filtredLogs?: LogEventType[] | AlertEventType[];
  gamingLogs?: GroupedGameLogTypes;
  nextLogsToken: string;
  selectedDate: Date;
  onNextLogButtonHandler: (token: string) => void;
  onEventsMenuItemClickHandler?: (eventType: MenuInfo) => void;
  onTerminalMenuItemClickHandler: (terminal: MenuInfo) => void;
  setSelectedDate: (date: Date) => void;
}

type WithTimestamp = { 
  timestamp: number 
}

const byTimestamp = (a: WithTimestamp, b: WithTimestamp) => b.timestamp - a.timestamp;

const Logs = ({ type, eventTypes, alertTypes, machinesFilters = [], selectedTerminal, selectedEventType, filtredLogs = [], gamingLogs = {}, nextLogsToken, selectedDate, onNextLogButtonHandler, onEventsMenuItemClickHandler, onTerminalMenuItemClickHandler, setSelectedDate }: LogsPropsTyoe) => {
  const tableColumns = [
    {
      title: 'Start time',
      dataIndex: 'start_time',
      key: 'start_time',
    },
    {
      title: 'End Time',
      dataIndex: 'end_time',
      key: 'end_time',
    },
    {
      title: 'Game name',
      dataIndex: 'game_name',
      key: 'game_name',
    },
    {
      title: 'Stake played',
      dataIndex: 'stake',
      key: 'stake',
    },
    {
      title: 'Win Amount',
      dataIndex: 'win',
      key: 'win',
      render: (text: string) => {
        const value = Number.parseFloat(text);
        if (Number.isFinite(value)) {
          return <WinValue>{value > 0 && <Icon name='arrow-up' width={20} height={20}/>} {retailCurrency.format(value)}</WinValue>;
        }
        return '-';
      },
    },
    {
      title: 'Game log',
      dataIndex: 'game_log',
      key: 'game_log',
      onCell: (record: GamesMessageType) => {
        return {
          onClick: () => {
            setLogsTypeState('details');
            setSelectedLog(gamingLogs[record.log_group_id]);
          },
        };
      },
    },
  ];
  const datePicker = useDisclosure();
  const [logsTypeState, setLogsTypeState] = useState<'games' | 'events' | 'details' | 'alerts'>(type);
  const [selectedLog, setSelectedLog] = useState<LogGameType[]>([]);

  const terminalsMenu = (
    <Menu
      items={machinesFilters}
      onClick={(i) => onTerminalMenuItemClickHandler(i)}
    />
  );

  const eventsMenu = (
    <Menu
      items={eventTypes}
      onClick={(i) => onEventsMenuItemClickHandler && onEventsMenuItemClickHandler(i)}
    />
  );

  const alertsMenu = (
    <Menu
      items={alertTypes}
      onClick={(i) => onEventsMenuItemClickHandler && onEventsMenuItemClickHandler(i)}
    />
  );

  const getMoreLogs = () => onNextLogButtonHandler(nextLogsToken);

  const dataSource = () => {
    const data = [];
    for (const key in gamingLogs) {
      data.push({
        ...gamingLogs[key][0].message,
        timestamp: gamingLogs[key][0].timestamp, 
      });
    }
    return data.sort(byTimestamp);
  };

  return (
    <EventLogsContainer direction='column'>
      {logsTypeState !== 'details' ?
        <DropdownsContainer justifyContent='end'>
          <Button style={{ marginRight: '10px' }} onClick={getMoreLogs}>More Logs</Button>
          <Dropdown overlay={terminalsMenu} trigger={['click']} arrow={true}>
            <Space>
              {selectedTerminal}
              <DownOutlined />
            </Space>
          </Dropdown>
          {logsTypeState === 'events' && 
            <Dropdown overlay={eventsMenu} trigger={['click']} arrow={true}>
              <Space>
                {selectedEventType}
                <DownOutlined />
              </Space>
            </Dropdown>
          }
          {logsTypeState === 'alerts' && 
            <Dropdown overlay={alertsMenu} trigger={['click']} arrow={true}>
              <Space>
                {selectedEventType}
                <DownOutlined />
              </Space>
            </Dropdown>
          }
          <Button onClick={datePicker.open} justifyContent='space-between'>
            <div><CalendarIcon />{selectedDate.toLocaleDateString('en-GB')}</div>
            <AngleDownIcon />
          </Button>
        </DropdownsContainer> 
        : 
        <DropdownsContainer justifyContent='end'>
          <Button onClick={() => setLogsTypeState('games')}>Go Back</Button>
        </DropdownsContainer>
      }
      <LogsContainer direction='column'>
        {logsTypeState === 'events' &&
          filtredLogs.sort(byTimestamp).map((log, index) => (
            <LogRow key={index}>
              {log.timestamp && (
                <>
                  <LogElement>{new Date(log.timestamp).toLocaleDateString('en-GB')}</LogElement>
                  <LogElement>{formatTime(new Date(log.timestamp), '')}</LogElement>
                </>
              )}
              <LogElement>{log.message.event_message}</LogElement>
            </LogRow>
          ))
        }
        {logsTypeState === 'alerts' &&
          filtredLogs.sort(byTimestamp).map((log, index) => (
            <LogRow key={index}>
              {log.timestamp && (
                <>
                  <LogElement>{new Date(log.timestamp).toLocaleDateString('en-GB')}</LogElement>
                  <LogElement>{formatTime(new Date(log.timestamp), '')}</LogElement>
                </>
              )}
              <LogElementCategory>{log.message.category}</LogElementCategory>
              <LogElement>{log.message.message}</LogElement>
            </LogRow>
          ))
        }
        {logsTypeState === 'games' &&
          <TableComponent dataSource={dataSource()} rowKey='log_group_id' columns={tableColumns} pagination={false}/>
        }
        {logsTypeState === 'details' &&
          selectedLog.map((log, index) => (
            <LogRow key={index}>
              {log.timestamp && (
                <>
                  <LogElement>{new Date(log.timestamp).toLocaleDateString('en-GB')}</LogElement>
                  <LogElement>{formatTime(new Date(log.timestamp), '')}</LogElement>
                </>
              )}
              <LogElement>{log.message.log_message}</LogElement>
            </LogRow>
          ))
        }
      </LogsContainer>
      {datePicker.isOpen &&
        <DatePickerModal
          isOpen={datePicker.isOpen}
          close={datePicker.close}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      }
    </EventLogsContainer>
  );
};

export default memo(Logs);
