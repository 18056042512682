import { Critical, Demo, Lock, Plus1, Printer, Warning } from 'components/Icon';
import { TileState } from 'components/Tile/Tile';
import { IconsContainer } from 'components/Tile/Tile.styled';
import { BackofficeAlertWithDate } from 'types/alert/GeneralAlert';
import { MachineModeState, Notification } from 'types/machine/MachineType';

interface DeviceAlertIconsProps {
  state: TileState;
  machineMode: MachineModeState;
  alerts: BackofficeAlertWithDate[];
  isMachineConnected: boolean;
  isPlusSession?: boolean;
}

const criticalIconModes = ['error', 'manager'];

const DeviceAlertIcons = ({ state, machineMode, alerts, isMachineConnected, isPlusSession = false }: DeviceAlertIconsProps) => {
  const isCritical = criticalIconModes.includes(machineMode) || !isMachineConnected;
  
  return (
    <IconsContainer state={state}>
      {machineMode === 'demo' ? <span><Demo width={32} height={32}/></span> : null}
      {isCritical ? <span><Critical width={32} height={32}/></span> : null}
      {machineMode === 'locked' ? <span><Lock width={32} height={32}/></span> : null}
      {alerts.some(alerts => alerts.source === 'printer') && <span><Printer width={500} height={500}/></span>}
      {isPlusSession ? <span><Plus1 width={45} height={45} /></span> : null}
      {isMachineConnected ? null : <span><Warning width={32} height={32}/></span>}
    </IconsContainer>
  );
};

export default DeviceAlertIcons;
