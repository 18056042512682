import { DefaultTheme } from 'styled-components';
import { Divider as DividerStyled } from './Divider.styled';

type Color = keyof DefaultTheme['colors']

type DividerProps = {
  color?: Color
  dataTestId?: string
  margin?: string
  width?: string
}

const Divider = ({ color = 'grey300', dataTestId, margin = '24px 0px', width = '100%' }: DividerProps): JSX.Element => (
  <DividerStyled
    color={color}
    data-test-id={dataTestId}
    margin={margin}
    width={width}
  />
);

export { Divider };
