import styled from 'styled-components';

export const PlayerComplianceInfoStyled = styled.div`
  display: flex;
  flex: 1 0 48%;
  flex-direction: column;
  gap: 16px;
`;

export const PlayerFlags = styled.div`
  display: flex;
  gap: 16px;
`;

export const CustomerUrn = styled.div`
  color: ${props => props.theme.colors.grey500};
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  white-space: nowrap;
`;