import { memo } from 'react';
import Logs from 'components/Logs/Logs';
import useAlertsLogs from 'hooks/useAlertsLogs';

const AlertLogs = () => {
  const events = useAlertsLogs();

  return (
    <Logs type='alerts' {...events}/>
  );
};

export default memo(AlertLogs);