import Badge from 'components/Badge';
import Button from 'components/Buttons';
import { payoutVoucher } from 'api/iotVoucherApi';
import Icon, { Check } from 'components/Icon';
import { useEffect, useRef, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { BadgeProps } from 'components/Badge';
import useDisclosure from 'hooks/useDisclosure';
import Drawer from 'components/Drawer';
import * as Styled from './VoucherDetails.styled';
import useVoucher from 'hooks/useVoucher';
import { getPubSubIot } from 'aws/Aws';
import Flex from 'styled/Flex';
import { useTheme } from 'styled-components';
import { EncodedVoucherPayload, FraudFlagType, isOnlineVoucher, Voucher, VoucherOffline } from 'types/Voucher';
import { formatEpochSecond } from 'utils/formatDate';
import { format } from 'date-fns';
import StatusBadge from '../StatusBadge';
import { getFraudFlagType } from 'api/voucherApi';
import useApi from 'hooks/useApi';
import { sleep } from 'utils/sleep';
import { VoucherData } from 'types/ApiTypes';
import { retailCurrency } from 'retail-currency';

const resolveTitleBadgeProps = ({ voucherSource }: Voucher | VoucherOffline | EncodedVoucherPayload): BadgeProps => {
  switch (voucherSource) {
    case 'FOBT':
      return {
        color: 'fobt25',
        textColor: 'secondaryFobt700',
        text: 'FOBT',
        borderRadius: '4px',
      };
    case 'SMART H':
      return {
        color: 'grey50',
        textColor: 'grey700',
        borderRadius: '4px',
      };
    case 'SSBT':
    default:
      return {
        color: 'ssbt',
        textColor: 'secondarySsbt700',
        text: 'SSBT',
        borderRadius: '4px',
      };
  }
};

type VoucherDetailsRouteParams = {
  uid: string;
}

const VoucherDetails = () => {
  const timer = useRef<null | number | NodeJS.Timeout>(null);
  const { api } = useApi();
  const { uid } = useParams<VoucherDetailsRouteParams>();
  const location = useLocation();
  const encodedVoucherPayload = location.state as EncodedVoucherPayload;
  const [isAuthorized, setIsAuthorized] = useState(false);
  const drawer = useDisclosure();
  const theme = useTheme();
  const authorize = () => setIsAuthorized(true);
  const [fraudFlagType, setFraudFlagType] = useState<FraudFlagType>('no');
  const { voucher: voucherFromRvs, isLoading, error, mutateVoucher, statusCode } = useVoucher(uid || '');
  const voucher = voucherFromRvs ? voucherFromRvs : (encodedVoucherPayload as EncodedVoucherPayload);
  const [, , terminalNumber] = (voucher?.machineId || '').split('-');
  const [redeemCallStatus, setRedeemCallStatus] = useState<'idle' | 'waiting'>('idle');
  const [currentVoucher, setCurrentVoucher] = useState<Voucher | EncodedVoucherPayload | undefined>(voucher);

  useEffect(() => {
    setCurrentVoucher(voucher);
  }, [voucher]);

  useEffect(() => {
    let sub: any;
    if (currentVoucher) {
      sub = getPubSubIot().subscribe([
        `${currentVoucher.machineId}/voucher/redeem/response/success`,
        `${currentVoucher.machineId}/voucher/redeem/response/error`,
      ])
        // @ts-ignore
        .subscribe((data) => {
          const response = data?.value || data;
          if (response?.payload?.message === 'Voucher redeemed successfully' || response?.payload?.message?.includes('succeeded')) {
            mutateVoucher(currentVoucher);
            drawer.close();
          }
          setRedeemCallStatus('idle');
        });
    }
    return () => sub && sub.unsubscribe();
  }, [currentVoucher?.machineId]);

  useEffect(() => {
    let sub: any;
    if (voucher) {
      sub = api?.subscribeTo(['vouchers/data'])
        .then(() => {
          api.onVoucherData((event: VoucherData) => {
            console.log('event', event);
            if (event?.payload?.barcode) {
              // @ts-ignore
              setCurrentVoucher((prev) => ({
                ...prev,
                ...event.payload,
                synchronized: false,
              }));
              drawer.close();
            }
            setRedeemCallStatus('idle');
          });
        });

      api?.subscribeTo(['vouchers/redeem/response/success'])
        .then(() => {
          api.onVoucherRedeemSuccess((event: any) => {
            if (event?.payload?.message) {
              // @ts-ignore
              setCurrentVoucher((prev) => ({
                ...prev,
                ...event.payload,
              }));
              drawer.close();
            }
            setRedeemCallStatus('idle');
          });
        });

      api?.subscribeTo(['vouchers/redeem/response/error'])
        .then(() => {
          api.onVoucherRedeemError((event: any) => {
            if (event?.payload?.error_message?.includes('has already been redeemed')) {
              drawer.close();
            }
          });
        });
    }
  }, [api, voucher]);

  useEffect(() => {
    if (api && voucher && !isOnlineVoucher(voucher) && !voucher.paidOut) {
      api.getSvsVoucher(voucher);
    }
  }, [api, voucher]);

  useEffect(() => {
    if (voucher && isOnlineVoucher(voucher)) {
      getFraudFlagType(voucher)
        .then(type => setFraudFlagType(type))
        .catch(error => console.error(`Cannot determine fraud flag type for voucher ${voucher.barcode}`, error));
    }
  }, [voucher?.barcode]);

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, []);

  const handleConfirm = async () => {
    setRedeemCallStatus('waiting');
    if (currentVoucher && api) {
      try {
        await payoutVoucher(currentVoucher, api);
        sleep(2000).then(() => {
          mutateVoucher(currentVoucher);
          drawer.close();
        }); //fallback to missing iot message on success topic
      }
      catch (error) {
        console.error(error);
        drawer.close();
        setRedeemCallStatus('idle');
      }
    }

    timer.current = setTimeout(() => {
      setRedeemCallStatus('idle');
    }, 10000);
  };

  const handleCancel = () => {
    drawer.close();
  };

  const isRedeemed = currentVoucher?.paidOut || currentVoucher?.redeemed;
  const isUnredeemed = !isRedeemed;

  const canConfirmPayout = !isRedeemed && redeemCallStatus === 'idle';

  if (!uid) {
    return <Navigate to='/history' />;
  }

  if (error) {
    if (statusCode && error.message){
      return (
        <Styled.Container>
          <p>{error.message}</p>
        </Styled.Container>
      );
    }
    console.error(error);
  }

  if (isLoading) {
    return (
      <Styled.Container>
        <LoadingSpinner />
      </Styled.Container>
    );
  }

  if (!currentVoucher) {
    return (
      <Styled.Container>
        <p>An error occurred while fetching voucher details</p>
      </Styled.Container>
    );
  }

  const voucherAmountPounds = currentVoucher.amount / 100;

  const timePrintedFormatted = () => {
    // TODO - once RVS Java 17 upgrade is in prod, we can get rid of this if statement, as returned value will be a string date
    if (typeof (currentVoucher.createdAt) === 'object') {
      return formatEpochSecond(currentVoucher.createdAt?.epochSecond);
    }

    return format(new Date(currentVoucher?.createdAt), 'dd/MM/yyyy, hh:mm:ss');
  };

  const redeemedAtDate = (theVoucher: Voucher) => {
    return format(new Date(theVoucher?.redeemedAt), 'dd/MM/yyyy, hh:mm:ss');
  };

  const isFraud = currentVoucher?.potentialFraud;
  const isMultipleAlert = fraudFlagType === 'multiple';
  const isVerifyTicketAlertShown = fraudFlagType !== 'no' && !isAuthorized && !isRedeemed && isFraud;
  const isPayoutBlockedBadgeShown = fraudFlagType !== 'no' && !isRedeemed && isFraud;

  return (
    <Styled.Container data-test-id='voucher-details'>
      {isVerifyTicketAlertShown && (
        <Styled.Card type='error'>
          <Flex gap='18px'>
            <Flex alignItems='center'>
              <Icon name='critical' width={32} height={32} fill={theme.colors.error600} />
            </Flex>
            <div>
              <Styled.AlertText>
                <Styled.Bold>Verify Ticket:</Styled.Bold> Please contact your security department before paying this ticket. <br />
                Please contact the security support line on 4901{isMultipleAlert ? ' and advise this is a multiple alert' : ''}. This ticket must be authorised before payment.  Have you contacted security to confirm as OK to pay out?
              </Styled.AlertText>
            </div>
            <Flex alignItems='center' flex='1' justifyContent='end'>
              <Button secondary onClick={authorize} width='100px'>Yes</Button>
            </Flex>
          </Flex>
        </Styled.Card>
      )}
      {isRedeemed && (
        <Styled.Card type='success'>
          <Styled.CardHeading>
            <Styled.CardHeader data-test-id='redeemed-ticket'>
              <Check style={{ marginRight: '8px' }} color='#346964' />
              This ticket has been redeemed and paid out.
            </Styled.CardHeader>
          </Styled.CardHeading>
          <Styled.CardInfoTable>
            <Styled.CardInfoRow>
              <Styled.Label>Payout Amount</Styled.Label>
              <Styled.Value>{retailCurrency.format(voucherAmountPounds)}</Styled.Value>
            </Styled.CardInfoRow>
            {currentVoucher.redeemedVia ? (
              <Styled.CardInfoRow>
                <Styled.Label>Redeemed via</Styled.Label>
                <Styled.Value data-test-id='redeemed-via'>{currentVoucher.redeemedVia}</Styled.Value>
              </Styled.CardInfoRow>
            ) : null}
            {voucher?.redeemedAt && (
              <Styled.CardInfoRow>
                <Styled.Label>Date and time</Styled.Label>
                <Styled.Value>{redeemedAtDate(voucher)}</Styled.Value>
              </Styled.CardInfoRow>
            )}
          </Styled.CardInfoTable>
        </Styled.Card>
      )}
      <Styled.Card>
        <Styled.CardHeading>
          <Styled.CardHeader>
            Ticket Information
          </Styled.CardHeader>
          {isUnredeemed && (
            <Button
              secondary
              width='100px'
              color={isVerifyTicketAlertShown && !isAuthorized ? 'white' : undefined}
              background={isVerifyTicketAlertShown && !isAuthorized ? 'primary200' : undefined}
              disabled={isVerifyTicketAlertShown && !isAuthorized}
              onClick={drawer.open}
            >
              Payout
            </Button>
          )}
        </Styled.CardHeading>
        <Styled.CardInfoGrid>
          <Styled.CardInfoCell>
            <Styled.Label>Reference number</Styled.Label>
            <Styled.Value>{currentVoucher.barcode}</Styled.Value>
          </Styled.CardInfoCell>
          <Styled.CardInfoCell>
            <Styled.Label>Status</Styled.Label>
            <Styled.Badges>
              {currentVoucher.online === false && <StatusBadge text='Ticket printed offline' textColor='primary600' color='grey100' testId='printed-offline' />}
              {isRedeemed && <StatusBadge text='Redeemed' textColor='success900' color='success25' testId='redeemed-text' />}
              {isUnredeemed && <StatusBadge text='Unredeemed' textColor='primary600' color='grey100' testId='unredeemed-text' />}
              {isPayoutBlockedBadgeShown ? <StatusBadge text='Payout blocked' textColor='error700' color='labelError' /> : null}
            </Styled.Badges>
          </Styled.CardInfoCell>
          <Styled.CardInfoCell>
            <Styled.Label>Time printed</Styled.Label>
            <Styled.Value>{timePrintedFormatted()}</Styled.Value>
          </Styled.CardInfoCell>
          <Styled.CardInfoCell>
            <Styled.Label>Payout amount</Styled.Label>
            <Styled.Value>{retailCurrency.format(voucherAmountPounds)}</Styled.Value>
          </Styled.CardInfoCell>
        </Styled.CardInfoGrid>
      </Styled.Card>
      <Drawer
        dataTestId='voucher-drawer'
        isOpen={drawer.isOpen}
        onClose={drawer.close}
        title={<Flex gap='16px'>
          <h3>Terminal {terminalNumber}</h3>
          <Badge {...resolveTitleBadgeProps(currentVoucher)} />
        </Flex>}
        position='right'>
        <Styled.DrawerBody>
          <Styled.DrawerCard>
            <Styled.TitleContainer>
              <Styled.H3>Ticket / voucher information</Styled.H3>
            </Styled.TitleContainer>
            <Styled.CardInfoCells>
              <Styled.CardInfoCell>
                <Styled.Label>Reference number</Styled.Label>
                <Styled.Value>{currentVoucher.barcode}</Styled.Value>
              </Styled.CardInfoCell>
              <Styled.CardInfoGrid>
                <Styled.CardInfoCell>
                  <Styled.Label>Time printed</Styled.Label>
                  <Styled.Value>{timePrintedFormatted()}</Styled.Value>
                </Styled.CardInfoCell>
                <Styled.CardInfoCell>
                  <Styled.Label>Payout amount</Styled.Label>
                  <Styled.Value data-test-id='payout-amount'>{retailCurrency.format(voucherAmountPounds)}</Styled.Value>
                </Styled.CardInfoCell>
              </Styled.CardInfoGrid>
            </Styled.CardInfoCells>
          </Styled.DrawerCard>
          <Styled.DrawerCard p={'24px'}>
            <Styled.H4>Redeem voucher / ticket</Styled.H4>
            <Styled.P>
              If you choose to redeem the voucher/ticket, please give the customer {retailCurrency.format(voucherAmountPounds)} from the cash register and select Confirm
            </Styled.P>
            <Styled.Actions>
              <Button wide onClick={handleCancel}>Cancel</Button>
              <Button wide disabled={!canConfirmPayout} onClick={handleConfirm} secondary data-test-id='confirm-button'>Confirm</Button>
            </Styled.Actions>
          </Styled.DrawerCard>
        </Styled.DrawerBody>
      </Drawer>
    </Styled.Container>
  );
};

export default VoucherDetails;
