import React from 'react';

import { Close as CloseIcon } from 'components/Icon';
import { DrawerContainer, DrawerDialog, DrawerHeading, DrawerClose, DrawerTitle, Backdrop, SquareButton } from './Drawer.styled';

type Title = string | React.ReactElement;

interface DrawerProps {
  children: React.ReactNode;
  dataTestId: string;
  isOpen: boolean;
  onClose: () => void;
  position: 'left' | 'right';
  title: Title;
}

const Drawer = ({ children, dataTestId, isOpen, onClose, position, title }: DrawerProps) => {
  return (
    <DrawerContainer data-test-id={dataTestId}>
      <DrawerDialog isOpen={isOpen} position={position}>
        <DrawerHeading>
          <DrawerTitle>{title}</DrawerTitle>
          <DrawerClose data-test-id={`${dataTestId}-close-button`} onClick={onClose}>
            <SquareButton>
              <CloseIcon width={24} height={24} />
            </SquareButton>
          </DrawerClose>
        </DrawerHeading>
        {children}
      </DrawerDialog>
      <Backdrop isOpen={isOpen} onClick={onClose} />
    </DrawerContainer>
  );
};

export default Drawer;
