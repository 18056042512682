import { useState } from 'react';

import useDisclosure from './useDisclosure';

const useRightDrawer = <T>(context: T[]) => {
  const { isOpen, close, open } = useDisclosure();
  const [contextElement, setContextElement] = useState<T | undefined>();

  const openWith = (index: number) => () => {
    if (context[index]) {
      setContextElement(context[index]);
      open();
    }
  };

  return {
    isOpen,
    close: () => { setContextElement(context[-1]); close(); },  // to force re-render when selection changes
    openWith,
    contextElement,
  };
};
export default useRightDrawer;
