import Badge from 'components/Badge';
import Flex from 'styled/Flex';
import { DrawerTitleContent } from 'components/Tile/Tile.styled';

interface TileDrawerTitleProps {
  terminalName: string;
  groupName: string;
}

const TileDrawerTitle = ({ terminalName, groupName }: TileDrawerTitleProps) => {
  return (
    <Flex>
      <DrawerTitleContent>
        {terminalName}
        <Badge text={groupName.toUpperCase()} color={'secondaryFobt25'} textColor={'secondaryFobt700'} />
      </DrawerTitleContent>
    </Flex>
  );
};

export default TileDrawerTitle;
