import { createContext, PropsWithChildren, useEffect, useState, useCallback } from 'react';
import { VoucherData, VoucherRedeemResponseSuccess, ErrorEvent } from 'types/ApiTypes';
import MqttApi from 'api';
import { logError } from 'newrelic';
import { useLocation, useNavigate } from 'react-router-dom';
import { Voucher, VoucherOffline } from 'types/Voucher';

const useApiProviderSettings = () => {
  const [api, setApi] = useState<MqttApi | undefined>(undefined);
  const location = useLocation();
  const [notificationAvailable, setNotificationAvaialble] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [svsUnredeemedVouchers, setSvsUnredeemedVouchers] = useState<VoucherOffline[]>([]);
  const [svsRedeemedVouchers, setSvsRedeemedVouchers] = useState<VoucherOffline[]>([]);

  const navigate = useNavigate();

  const showNotification = (message: string) => {
    setNotificationAvaialble(true);
    setNotificationMessage(message);
  };

  const redirectToVoucherDetails = (event: VoucherData) => {
    return navigate(`history/${event.payload.barcode}`, {
      state: {
        ...event.payload,
        amount: event.payload.amount,
        barcode: event.payload.barcode,
        uid: event.payload.barcode,
        createdAt: event.payload.createdAt, 
        expirationDate: event.payload.expirationDate, 
        machineId: event.payload.machine_id, 
        online: event.payload.online, 
        potentialFraud: event.payload.potential_fraud, 
        paidOut: event.payload.redeemed, 
        shop: event.payload.shop_id, 
        voucherType: event.payload.voucher_type,
        redeemed: event.payload.redeemed ? event.payload.redeemed : false,
        redeemedAt: null,
        redeemedVia: null,
        fraudFlagType: null,
        voucherSource: 'FOBT',
      }, 
    });
  };

  const hideNotifcation = useCallback(() => setNotificationAvaialble(false), [setNotificationAvaialble]);

  const addOfflineVouchers = (event: any, setter: React.Dispatch<React.SetStateAction<VoucherOffline[]>>, redeemed: boolean) => {
    const addOfflineVouchers: VoucherOffline[] = [];

    if (event?.payload?.length > 0)
      event.payload.forEach((voucher: any) => {
        addOfflineVouchers.push({
          ...voucher,
          amount: voucher.amount,
          barcode: voucher.barcode,
          uid: voucher.barcode,
          createdAt: voucher.createdAt, 
          expirationDate: voucher.expirationDate, 
          machineId: voucher.machine_id, 
          online: voucher.online, 
          potentialFraud: voucher.potential_fraud, 
          paidOut: voucher.redeemed, 
          shop: voucher.shop_id, 
          voucherType: voucher.voucher_type,
          redeemed,
          redeemedAt: null,
          redeemedVia: null,
          fraudFlagType: null,
          voucherSource: 'FOBT',
          synchronized: 'synchronized' in voucher ? voucher.synchronized : false,
        });
      });

    setter(addOfflineVouchers);
  };

  useEffect(() => {
    const localApi = new MqttApi();
    localApi
      .init()
      .then(() => {
        localApi.onVouchersRedeemSuccess((event: any) => {
          addOfflineVouchers(event, setSvsRedeemedVouchers, true);
        });
        localApi.onVouchersRedeemError((event: any) => {
          console.log('onVouchersRedeemError', event);
        });
        localApi.onVouchersUnredeemSuccess((event: any) => {
          addOfflineVouchers(event, setSvsUnredeemedVouchers, false);
        });
        localApi.onVouchersUnredeemError((event: any) => {
          console.log('onVouchersUnredeemError', event);
        });
        localApi.onVoucherRedeemSuccess((event: VoucherRedeemResponseSuccess) => {
          return showNotification(event.payload.message);
        });
        localApi.onVoucherRedeemError((event: ErrorEvent) => {
          return showNotification(event.payload.error_message);
        });
        localApi.onVouchersResponseError((event: any) => {
          console.log('onVouchersResponseError', event);
        });
        localApi.onVoucherData((event: VoucherData) => {
          console.log('onVoucherData', event);
          if ( window.location.pathname === `/history/${event.payload.barcode}`) return false;
          return redirectToVoucherDetails(event);
        });
        localApi.onVoucherError((event: ErrorEvent) => {
          if (!event.payload.error_message.includes('Cannot find voucher with barcode')) {
            showNotification(event.payload.error_message);
          }
        });
        setApi(localApi);
      })
      .catch(err => {
        logError(`Cannot connect to local MqttApi: ${err}`, 'high', err);
      });
  }, []);

  return {
    api,
    notificationAvailable,
    notificationMessage,
    svsRedeemedVouchers,
    svsUnredeemedVouchers,
    hideNotifcation,
  };
};

type ApiContextData = ReturnType<typeof useApiProviderSettings>;

export const ApiContext = createContext<ApiContextData | null>(null);

type ApiProviderProps = PropsWithChildren<unknown>

const ApiProvider = ({ children }: ApiProviderProps) => {
  const { api, notificationAvailable, notificationMessage, svsRedeemedVouchers, svsUnredeemedVouchers, hideNotifcation } = useApiProviderSettings();

  return <ApiContext.Provider value={{
    api,
    notificationAvailable,
    notificationMessage,
    svsRedeemedVouchers,
    svsUnredeemedVouchers,
    hideNotifcation,
  }}>{children}</ApiContext.Provider>;
};

export default ApiProvider;
