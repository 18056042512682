import { TerminalSelectionStyled, TerminalSelectionTitle, TitleStyled, SelectAllStyled } from './TerminalSelection.styled';
import { MachineMap } from 'types/machine/MachineType';
import TerminalSelectorToggle from '../TerminalSelectorToggle';
import Flex from 'styled/Flex';
import { ThingType } from 'types/aws/ThingListTypes';
import { inferIndexFromMachineId } from 'utils/formatTerminal';

const TerminalSelection = ({ title, terminalList, selectedTerminals, onSelect, onSelectAll }: TerminalSelectionProps) => {

  const terminalIsSelected = (terminal: MachineMap) => Boolean(selectedTerminals && selectedTerminals.find(st => st.machineId === terminal.machineId));

  const selectAllHandler = () => onSelectAll(terminalList, true);

  const deselectAllHandler = () => onSelectAll(terminalList, false);

  return (
    <TerminalSelectionStyled>
      <TerminalSelectionTitle>
        <TitleStyled>{title}</TitleStyled>
        <SelectAllStyled>
          {terminalList.length === selectedTerminals.length
            ? <div onClick={deselectAllHandler}>Deselect all</div>
            : <div onClick={selectAllHandler}>Select all</div>
          }
        </SelectAllStyled>
      </TerminalSelectionTitle>
      <Flex gap='8px' flex-wrap='wrap'>
        {terminalList.map((terminal: MachineMap) => (
          <TerminalSelectorToggle
            key={terminal.machineId}
            clickHandler={() => onSelect(terminal, !terminalIsSelected(terminal))}
            terminalIndex={terminal.machineState.shop_device_index || inferIndexFromMachineId(terminal.machineId)}
            deviceType={terminal.thingType as ThingType}
            selected={terminalIsSelected(terminal)}
          />
        ))}
      </Flex>
    </TerminalSelectionStyled>
  );
};

interface TerminalSelectionProps {
  title: string,
  terminalList: MachineMap[],
  selectedTerminals: MachineMap[],
  onSelect: (machine: MachineMap, selected: boolean) => void;
  onSelectAll: (machine: MachineMap[], selected: boolean) => void;
}

export default TerminalSelection;
