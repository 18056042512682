import { useContext } from 'react';
import { ShopContext } from 'providers/ShopProvider';
import { Shop } from 'types/Shop';

const useShop = (): Shop => {
  const context = useContext(ShopContext);

  if (!context) {
    throw new Error('useShop must be used within a ShopProvider');
  }

  return {
    shopId: context.shop.shopId,
    shopName: context.shop.shopName,
    deviceIndex: context.shop.deviceIndex,
  };
};

export default useShop;
