import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import moment from 'moment';

import AppRoutes from 'routes';
import GlobalStyle from 'styled/GlobalStyle';
import MachinesProvider from 'providers/MachinesProvider';
import ShopIdProvider from 'providers/ShopProvider';
import ApiProvider from 'providers/ApiProvider';
import Modal from 'components/Modal/NotificationModal';
import ConnectionStatusProvider from 'providers/ConnectionStatusProvider';
import { ThemeProvider } from 'styled-components';
import { theme } from 'styled/theme';

const App = () => {
  const handleVisibilityChange = () => {
    const lastLoad = window.localStorage.getItem('lastUboLoad');
    if (moment(lastLoad) < moment().subtract(60, 'minute')) {
      console.log('Last reload was more than 60 minutes ago. Reloading window now.');
      window.location.reload();
    }    
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange, false);
    const timeOfLoad = moment().toISOString(true);
    console.log('Setting UBO load time in localstorage: ' + timeOfLoad);
    window.localStorage.setItem('lastUboLoad', timeOfLoad);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <BrowserRouter>
      <GlobalStyle />
      <ConnectionStatusProvider>
        <ApiProvider>
          <ShopIdProvider>
            <MachinesProvider>
              <ThemeProvider theme={theme}>
                <AppRoutes/>
              </ThemeProvider>
            </MachinesProvider>
          </ShopIdProvider>
          <Modal />
        </ApiProvider>
      </ConnectionStatusProvider>
    </BrowserRouter >
  );
};

export default App;
