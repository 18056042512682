import styled, { css } from 'styled-components';
import { PlayerSelfExclusionStatus } from 'types/Player';

interface PlayerFlagProps {
  status: PlayerSelfExclusionStatus;
}

export const PlayerFlagStyled = styled.div<PlayerFlagProps>`
  align-items: center;
  ${props => props.status === 'active' && css`background-color: ${props => props.theme.colors.error20};`}
  ${props => props.status === 'review' && css`background-color: ${props => props.theme.colors.warning100};`}
  ${props => props.status === 'expired' && css`background-color: ${props => props.theme.colors.primary10};`}
  border-radius: 8px;
  display: flex;
  font-weight: 400;
  font-size: 24px;
  gap: 16px;
  line-height: 28px;
  padding: 18px 16px;
  width: 320px;

  svg {
    ${props => props.status === 'active' && css`fill: ${props => props.theme.colors.error600};`}
    ${props => props.status === 'review' && css`fill: ${props => props.theme.colors.warning800};`}
    ${props => props.status === 'expired' && css`fill: ${props => props.theme.colors.primary600};`}
  }
`;