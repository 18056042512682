import { HistoryRootContainer } from './HistoryRoot.styled';
import { TabsList, Tab, Content } from 'styled/Tabs';
import { Outlet, NavLink, useLocation } from 'react-router-dom';

const HistoryRoot = () => {
  const { pathname } = useLocation();

  return (
    <HistoryRootContainer>
      <TabsList>
        <NavLink to='/history' end>
          <Tab active={pathname === '/history'}>Session history</Tab>
        </NavLink>
        <NavLink to='/history/redeemed'>
          <Tab active={pathname === '/history/redeemed'}>Redeemed</Tab>
        </NavLink>
        <NavLink to='/history/unredeemed'>
          <Tab active={pathname === '/history/unredeemed'}>Unredeemed</Tab>
        </NavLink>
        <NavLink to='/history/gaming-activity'>
          <Tab active={pathname === '/history/gaming-activity'}>Gaming activity</Tab>
        </NavLink>
        <NavLink to='/history/alerts'>
          <Tab active={pathname === '/history/alerts'}>Alerts</Tab>
        </NavLink>
        <NavLink to='/history/event-log'>
          <Tab active={pathname === '/history/event-log'}>Event log</Tab>
        </NavLink>
      </TabsList>
      <Content>
        <Outlet />
      </Content>
    </HistoryRootContainer>
  );
};

export default HistoryRoot;
