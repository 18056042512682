import { TagStyled } from './Tag.styled';

const Tag = ({ label }: TagProps) => {

  return (
    <TagStyled data-test-id='tag'>
      {label}
    </TagStyled>
  );
};

interface TagProps {
  label: string;
}

export default Tag;