import React, { useCallback, MouseEventHandler } from 'react';

import Table from 'components/Table';
import { DataSource } from 'types/session/TableData';
import { HistoryTypes } from 'types/History';
import { SummarySession } from 'types/session/SummarySession';
import { columns } from './Columns';
import { gamingHistoryColumns } from './GamingHistoryColumns';
import { getWindowDimensions } from 'utils/constants';
import { logsColumns } from './LogsColumns';
import { retailCurrency } from 'retail-currency';

interface TableProps {
  gameLogHandler: (sessionId: string) => void;
  loading: boolean;
  sessions: SummarySession[];
  tableType: HistoryTypes;
  tagLiveSessionHandler: (sessionId: string) => MouseEventHandler<Element> | undefined;
  tagPlayerHandler: (sessionId: string) => MouseEventHandler<Element> | undefined;
}

const HistoryTable = ({ sessions, tagPlayerHandler, tagLiveSessionHandler, gameLogHandler, loading = false, tableType }: TableProps) => {
  const mapSummarySessionToTableDataSource = (summarySession: SummarySession, index: number) => ({
    deviceIndex: summarySession.shopDeviceIndex,
    end: summarySession.endedAt,
    gameLogHandler: gameLogHandler,
    isLiveSession: summarySession.isLiveSession || false,
    key: index.toString(),
    player: summarySession.nickname,
    plusUser: !!summarySession.plusAccountId,
    sessionId: summarySession.sessionId,
    staked: summarySession.staked || 0,
    start: summarySession.startedAt,
    tagPlayerHandler: summarySession.isLiveSession ? tagLiveSessionHandler : tagPlayerHandler,
    thingType: summarySession.thingType,
    totalStakes: retailCurrency.format((summarySession.staked || 0) / 100),
  });

  const dataSource: DataSource[] = sessions.map((session: SummarySession, index: number) => mapSummarySessionToTableDataSource(session, index));
  const dataTestId = `history-table${sessions.length <= 0 ? '-no-sessions' : ''}`;

  const columnsToUse = useCallback(() => {
    switch (tableType) {
      case 'games':
        return gamingHistoryColumns;
      case 'logs':
        return logsColumns;
      default:
        return columns;
    }
  }, [tableType, sessions]);

  return (
    <div data-test-id={dataTestId}>
      <Table dataSource={dataSource} columns={columnsToUse()} pagination={false} scroll={{ y: (0.66 * getWindowDimensions().vh) }} loading={loading} />
    </div>
  );
};

export default React.memo(HistoryTable);
