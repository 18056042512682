import styled from 'styled-components';

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 16px;
`;

export const TitleStyled = styled.div`
  color: ${props => props.theme.colors.grey900};
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
`;

export const SubtitleStyled = styled.div`
  color: ${props => props.theme.colors.grey500};
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
`;

export const TextLineStyled = styled.div`
  color: ${props => props.theme.colors.grey900};
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
`;

export const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 36px;
`;

export const ButtonsStyled = styled.div`
  display: flex;
  flex: 1;
  gap: 8px;

  button {
    line-height: 30px;
    max-width: 65%;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export const RadioButtonsStyled = styled.div`
  .ant-radio {
    top: 0;
  }

  .ant-radio-group {
    display: flex;
    flex-wrap: wrap;
  }

  .ant-radio-inner {
    height: 24px;
    width: 24px;

    &::after {
      height: 28px;
      margin-left: -14px;
      margin-top: -14px;
      width: 28px;
    }
  }

  .ant-radio-wrapper {
    margin-right: 24px;
  }

  label {
    align-items: center;
    display: flex;
    font-size: 18px;
    line-height: 28px;
  }
`;

export const SpinnerStyled = styled.div`
  display: flex;
  path {
    stroke: white
  }
`;