import { useEffect, useState } from 'react';
import PubSub from 'pubsub-js';
import Button from 'components/Buttons/Button';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import UnsuccessfulModal from '../UnsuccessfulModal/UnsuccessfulModal';
import Modal from '../../Modal';
import { ButtonsStyled, ContentStyled, SpinnerStyled, SubtitleStyled, TextLineStyled, TitleStyled, TitleWrapper } from './ConfirmationModal.styled';
import { formatString } from 'utils/string';
import { getConfirmationModalContent } from '../modalContent';
import { Player, PlayerAction } from 'types/Player';
import { logError } from 'newrelic';

interface ConfirmationModalProps {
  close: () => void;
  isOpen: boolean;
  options: string[];
  playerAction: PlayerAction;
}

const ConfirmationModal = ({ isOpen, close, options, playerAction }: ConfirmationModalProps) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [loading, setLoading] = useState(false);
  const [successDelay, setSuccessDelay] = useState<number | undefined>();
  const [failure, setFailure] = useState(false);

  useEffect(() => {
    PubSub.subscribe('app.modal.option.selected', onOptionSelected);
    return () => {
      PubSub.unsubscribe(onOptionSelected);
    };
  }, []);

  const onOptionSelected = (topic: string, option: string) => {
    setSelectedOption(option);
  };

  const { title, subtitle, textContent, cancelLabel, confirmLabel } = getConfirmationModalContent(options)[playerAction];

  const Title = () => {
    return (
      <TitleWrapper>
        <TitleStyled data-test-id='modal-title'>{title}</TitleStyled>
        <SubtitleStyled>{subtitle}</SubtitleStyled>
      </TitleWrapper>
    );
  };

  const onCancel = () => {
    PubSub.publish('app.modal.dismiss');
    close();
  };

  const onConfirm = () => {
    PubSub.publish(`app.modal.confirm.${playerAction}`);
    setLoading(true);
  };

  useEffect(() => {
    PubSub.subscribe('app.modal.success', onModalSuccess);
    return () => {
      PubSub.unsubscribe(onModalSuccess);
    };
  }, []);

  const onModalSuccess = () => {
    setLoading(false);
    close();
  };

  useEffect(() => {
    PubSub.subscribe('app.modal.fail', onModalFail);
    return () => {
      PubSub.unsubscribe(onModalFail);
    };
  }, []);

  const onModalFail = () => {
    setFailure(true);
    logError(`Could not ${playerAction} player: ${Error}`, 'high', Error);
  };

  const formatContent = (contentText: string) => {
    const unselectedOption = options.find(o => o !== selectedOption) || '';
    return formatString(contentText, {
      selectedOption,
      unselectedOption, 
    });
  };

  const renderTextLine = (textLine: string | JSX.Element | null, index: number) => {
    if (!textLine) return;

    const line = typeof textLine === 'string' ? formatContent(textLine): textLine;

    return <TextLineStyled key={`text-content-${index}`}>{line}</TextLineStyled>;
  };

  return (
    <>
      {failure && playerAction != 'cancel-update' ? 
        <UnsuccessfulModal isOpen={isOpen} close={close} options={options} playerAction={playerAction}/>  
        : 
        <Modal dataTestId='confirmation-modal' isOpen={isOpen} onClose={onCancel} showCloseButton={false} title={<Title/>} width='620px'>
          <ContentStyled>
            {textContent.map(renderTextLine)}
            <ButtonsStyled>
              <Button data-test-id='modal-cancel-button' onClick={onCancel} color='primary600' background='grey100' borderless wide>
                {cancelLabel}
              </Button>
              <Button data-test-id='modal-confirm-button' onClick={onConfirm} color='white' background='primary600' borderless wide disabled={loading}>
                {loading
                  ? <SpinnerStyled data-test-id='modal-button-loading-spinner'><LoadingSpinner/></SpinnerStyled>
                  : <span>{formatContent(confirmLabel)}</span>
                }
              </Button>
            </ButtonsStyled>
          </ContentStyled>
        </Modal>
      }
    </>
  );
};

export default ConfirmationModal;
