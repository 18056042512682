import { IconProps } from 'components/Icon';
import { DateTime } from 'luxon';
import { Nullable } from 'types/Util';
import { MachinePeripheralsKeys } from 'types/machine/MachineType';

export type GeneralAlert = {
  type: 'warning' | 'error' | 'success';
  message: string;
  iconName: IconProps['name']
};

export enum AlertTypeEnum {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error'
}

export enum VisibilityTypeEnum {
  EVERYWHERE = 'everywhere',
  DASHBOARD = 'dashboard',
  DETAILS = 'details',
  NOWHERE = 'nowhere'
}

export type VisibilityType = VisibilityTypeEnum.EVERYWHERE | VisibilityTypeEnum.DASHBOARD | VisibilityTypeEnum.DETAILS | VisibilityTypeEnum.NOWHERE;

export type AlertType = AlertTypeEnum.INFO | AlertTypeEnum.WARNING | AlertTypeEnum.ERROR;
export type AlertCategory = 'Responsible gaming' | 'Terminal' | 'Printer';
export type Actions = ['yes', 'no'] | ['close'];

export interface BackofficeAlert {
  alert_id: string;
  name: string;
  message: string;
  alert_type: AlertType;
  category: AlertCategory;
  thing_id: string;
  session_id: Nullable<string>;
  acknowledged: Nullable<string>;
  actions: Actions;
  visibility: VisibilityType;
  hardware_alert?: boolean;
  source?: MachinePeripheralsKeys,
}

export interface BackofficeAlertWithDate extends BackofficeAlert {
  date: DateTime;
}
