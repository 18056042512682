import React from 'react';
import styled from 'styled-components';

interface MenuProps {
  children: JSX.Element | JSX.Element[];
  onClickElement: () => void;
}

const UnorderedList = styled.ul`
  padding: 24px 20px;
`;

const Menu = ({ children, onClickElement }: MenuProps) => (
  <UnorderedList>
    {React.Children.map(children, child => React.cloneElement(child, { onClick: onClickElement }))}
  </UnorderedList>
);

export default Menu;
