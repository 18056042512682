import { useContext } from 'react';
import { ApiContext } from 'providers/ApiProvider';

const useApi = () => {
  const context = useContext(ApiContext);

  if (!context) {
    throw new Error('useApi must be used within a ApiProvider');
  }

  return context;
};

export default useApi;
