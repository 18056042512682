import styled from 'styled-components';

export const PlayerBioStyled = styled.div`
  display: flex;
  flex: 1 0 48%;
  gap: 24px;
`;

export const PlayerNames = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
`;