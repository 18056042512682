import styled, { DefaultTheme, css } from 'styled-components';

type Color = keyof DefaultTheme['colors']

type InputWrapperProps = {
  background?: string;
  borderColor: Color;
  fontSize?: string;
  height?: string;
  margin?: string;
  position?: string;
  wide?: boolean;
}

type InputProps = {
  fontSize?: string;
  padding?: string;
}

export const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10px;
`;

export const InputWrapper = styled.div<InputWrapperProps>`
  ${(props) => props.position ? css`position: ${props.position}` : null};
  ${(props) => props.height ? css`height: ${props.height};` : 'max-height: 50px'};
  ${(props) => props.margin ? css`margin: ${props.margin}` : null};
  ${(props) => props.wide ? css`width: 100%;` : null};
  border: 1px solid ${(props) => props.theme.colors[props.borderColor]};
  border-radius: 4px;
  display: flex;;
`;

export const TextArea = styled.textarea<InputProps>`
  ${(props) => props.padding ? css`padding: ${props.padding}` : null};
  ${(props) => props.fontSize ? css`font-size: ${props.fontSize}` : 'font-size: 20px'};
  background-color: transparent;
  border: none;
  font-family: WHHoxton-regular;
  font-style: normal;
  font-weight: normal;
  resize: none;
  width: 100%;
  
  ::-webkit-input-placeholder {
    ${(props) => props.fontSize ? css`font-size: ${props.fontSize}` : 'font-size: 20px'};
    color: ${(props) => props.theme.colors.grey500};
  }
  
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.grey300};
    border-radius: 2px;
  }
`;

export const Input = styled.input<InputProps>`
  ${(props) => props.padding ? css`padding: ${props.padding}` : null};
  ${(props) => props.fontSize ? css`font-size: ${props.fontSize}` : 'font-size: 20px'};
  background-color: transparent;
  border: none;
  font-family: WHHoxton-regular;
  font-style: normal;
  font-weight: normal;
  resize: none;
  width: 100%;
  
  ::-webkit-input-placeholder {
    ${(props) => props.fontSize ? css`font-size: ${props.fontSize}` : 'font-size: 20px'};
    color: ${(props) => props.theme.colors.grey500};
  }
  
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.grey300};
    border-radius: 2px;
  }
`;