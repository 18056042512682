import { useState, useEffect } from 'react';
import PubSub from 'pubsub-js';
import moment from 'moment';
import { ErrorBoundary } from 'react-error-boundary';

import ErrorFallback from 'components/ErrorFallback';
import History from './History';
import useMachines from 'hooks/useMachines';
import useShop from 'hooks/useShop';
import { SummarySession } from 'types/session/SummarySession';
import { getSessions, machinesToSummarySessions } from 'utils/fetchHelpers';
import { HistoryTypes } from 'types/History';

interface ConnectedHistoryProps {
  historyType: HistoryTypes
}

const ConnectedHistory = ({ historyType }: ConnectedHistoryProps) => {
  const { shopId } = useShop();
  const { machines } = useMachines();

  const twoMinutes = 120;
  const [timer, setTimer] = useState(twoMinutes);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const fromDate = moment(selectedDate).startOf('day').toDate();
  const toDate = moment(selectedDate).endOf('day').toDate();

  const { sessions = [], error, loading, mutate } = getSessions(false, {
    offset: 0,
    limit: 1000,
    shopId,
    toDate,
    fromDate,
    thingType: 'ssbt',
  });

  const liveSessions: SummarySession[] = machinesToSummarySessions(machines.filter(m => m.thingType !== 'gm'));
  liveSessions.map(s => s.isLiveSession = true);
  // @todo Retail team, this doesn't make any sense, please take a look at this
  // @ts-ignore 
  sessions.map(s => s.isLiveSession = false);

  const [sessionsData, setSessionsData] = useState({
    sessions: sessions || [],
    liveSessions: liveSessions || [],
    error,
    loading,
  });

  // Could be trigger by IoT activity happening either inside or outside the app
  const onEndSessionsNotification = () => {
    console.log('ConnectedHistory | onEndSessionsNotification called');
    mutate(); // tell getSessions SWR to revalidate
  };

  useEffect(() => {
    PubSub.subscribe('app.live.sessions.ended', onEndSessionsNotification);
    return () => {
      PubSub.unsubscribe(onEndSessionsNotification);
    };
  }, []);

  useEffect(() => {
    setSessionsData({
      ...sessionsData,
      loading: true,
    });
    mutate().then(() =>
      setSessionsData({
        ...sessionsData,
        loading: false,
      })
    );
  }, [selectedDate]);

  useEffect(() => {
    const updatedSessions = {
      sessions: sessions || [],
      liveSessions: liveSessions || [],
      error: error,
      loading: loading,
    };
    setSessionsData(updatedSessions);
  }, [machines, error, loading]);

  useEffect(() => {
    const updatedSessions = {
      ...sessionsData,
      sessions: sessions,
      loading: loading,
    };
    !error && setSessionsData(updatedSessions);
  }, [sessions, loading]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timer <= 0) {
        console.log('ConnectedHistory | restarting timer to', twoMinutes, 'seconds');
        setTimer(twoMinutes);
        mutate();
      }
      else {
        setTimer((t) => t - 1);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timer]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <History sessionsData={sessionsData} mutateSessions={mutate} selectedDate={selectedDate} setSelectedDate={setSelectedDate} machines={machines} historyType={historyType} />
    </ErrorBoundary>
  );
};

export default ConnectedHistory;
