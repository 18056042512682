import Drawer from 'components/Drawer';
import HamburgerButton from 'components/Buttons/HamburgerButton';
import Menu, { MenuItem } from 'components/Menu';
import Search from 'components/Search';
import {
  Dashboard as DashboardIcon,
  History as HistoryIcon,
  PlayerManagement as PlayerManagementIcon,
  Report as ReportIcon,
} from 'components/Icon';
import {
  ButtonsWrapper,
  HeaderStyled,
  Version,
} from './Header.styled';
import useDisclosure from 'hooks/useDisclosure';
import { getConstant } from 'utils/constants';
import { version } from '../../../package.json';
import Breadcrumbs from 'components/Breadcrumbs';

const NORMAL_ICON_SIZE = {
  width: 24,
  height: 24,
};

const environment = getConstant('REACT_APP_NEW_RELIC_ENV');
const isDevEnvironment: boolean = environment === 'dev';

const Header = () => {

  const leftDrawer = useDisclosure();

  return (
    <HeaderStyled data-test-id={'app-header'}>
      <ButtonsWrapper>
        <HamburgerButton  dataTestId='hamburger-menu' onClickHandler={leftDrawer.toggle}/>
        <Breadcrumbs/>
      </ButtonsWrapper>
      <ButtonsWrapper>
        <Version data-test-id='ubo-version'>{version}</Version>
        {isDevEnvironment ? <Search /> : null} {/* kept only for dev purpose */}
      </ButtonsWrapper>
      <Drawer dataTestId='header-drawer' isOpen={leftDrawer.isOpen} onClose={leftDrawer.close} title='Menu' position='left'>
        <Menu onClickElement={leftDrawer.close}>
          <MenuItem dataTestId='menu-dashboard'icon={<DashboardIcon {...NORMAL_ICON_SIZE} />} link='/dashboard'>Dashboard</MenuItem>
          <MenuItem dataTestId='menu-ssbt-player-tagging' icon={<PlayerManagementIcon {...NORMAL_ICON_SIZE} />} link='/player-tagging'>SSBT Player Tagging</MenuItem>
          <MenuItem dataTestId='menu-history'icon={<HistoryIcon {...NORMAL_ICON_SIZE} />} link='/history'>History</MenuItem>
          <MenuItem dataTestId='menu-player-management'icon={<DashboardIcon {...NORMAL_ICON_SIZE} />} link='/player-management'>Player Management</MenuItem>
          <MenuItem dataTestId='menu-reports'icon={<ReportIcon {...NORMAL_ICON_SIZE} />} link='/reports'>Reports</MenuItem>
        </Menu>
      </Drawer>
    </HeaderStyled >
  );
};

export default Header;

