import { camelCase } from 'lodash';

const labelFormat = (input: string): string => {
  let output: string;

  switch (input) {
    case 'Nom de plume':
      output = 'nickname';
      break;
    default:
      output = camelCase(input);
      break;
  }

  return output;
};
export default labelFormat;