import { CalendarOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const CalendarIcon = styled((props) => <CalendarOutlined {...props}/>)`
  color: ${(props) => props.theme.colors.primary600};
  margin-right: 12px;
`;

export const DatePickerTitle = styled.div`
  font-size: 32px;
`;

export const DatePickerWrapper = styled.div`
  width: 440px;
`;

export const DatePickerSubheading = styled.div`
  color: ${(props) => props.theme.colors.grey500};
  font-size: 20px;
`;

export const SelectedDate = styled.div`
  border: 1px solid ${(props) => props.theme.colors.grey300};
  border-radius: 4px;
  color: ${(props) => props.theme.colors.grey900};
  font-size: 20px;
  height: 52px;
  line-height: 30px;
  padding: 11px 12px;
`;
