import { ErrorBoundary } from 'react-error-boundary'; 
import Flex from 'styled/Flex';

import { ReactComponent as Admin } from './svg/admin.svg';
import { ReactComponent as ArrowDown } from './svg/arrow-down.svg';
import { ReactComponent as AngleDown } from './svg/angle-down.svg';
import { ReactComponent as AngleLeft } from './svg/angle-left.svg';
import { ReactComponent as AngleRight } from './svg/angle-right.svg';
import { ReactComponent as ArrowUp } from './svg/arrow-up.svg';
import { ReactComponent as AngleUp } from './svg/angle-up.svg';
import { ReactComponent as Card } from './svg/card.svg';
import { ReactComponent as Cash } from './svg/cash.svg';
import { ReactComponent as Check } from './svg/check.svg';
import { ReactComponent as Close } from './svg/close.svg';
import { ReactComponent as Critical } from './svg/critical.svg';
import { ReactComponent as Dashboard } from './svg/dashboard.svg';
import { ReactComponent as Demo } from './svg/demo.svg';
import { ReactComponent as Edit1 } from './svg/edit1.svg';
import { ReactComponent as Edit2 } from './svg/edit2.svg';
import { ReactComponent as Edit3 } from './svg/edit3.svg';
import { ReactComponent as EyeClosed } from './svg/eye-closed.svg';
import { ReactComponent as EyeOpen } from './svg/eye-open.svg';
import { ReactComponent as History } from './svg/history.svg';
import { ReactComponent as Info } from './svg/info.svg';
import { ReactComponent as Load } from './svg/load.svg';
import { ReactComponent as Lock } from './svg/lock.svg';
import { ReactComponent as Menu } from './svg/menu.svg';
import { ReactComponent as NewNotification } from './svg/new-notification.svg';
import { ReactComponent as Notification } from './svg/notification.svg';
import { ReactComponent as Offers } from './svg/offers.svg';
import { ReactComponent as Player } from './svg/player.svg';
import { ReactComponent as PlayerManagement } from './svg/player-management.svg';
import { ReactComponent as Plus1 } from './svg/plus-1.svg';
import { ReactComponent as Plus } from './svg/plus.svg';
import { ReactComponent as Printer } from './svg/printer.svg';
import { ReactComponent as Report } from './svg/report.svg';
import { ReactComponent as Search } from './svg/search.svg';
import { ReactComponent as Stats } from './svg/stats.svg';
import { ReactComponent as Unlock } from './svg/unlock.svg';
import { ReactComponent as Warning } from './svg/warning.svg';
import { ReactComponent as WhPlus } from './svg/whplus.svg';

const ICON_NAMES = {
  'admin': Admin,
  'arrow-down': ArrowDown,
  'angle-down': AngleDown,
  'angle-left': AngleLeft,
  'angle-right': AngleRight,
  'arrow-up': ArrowUp,
  'angle-up': AngleUp,
  'card': Card,
  'cash': Cash,
  'check': Check,
  'close': Close,
  'critical': Critical,
  'dashboard': Dashboard,
  'demo': Demo,
  'edit1': Edit1,
  'edit2': Edit2,
  'edit3': Edit3,
  'eye-closed': EyeClosed,
  'eye-open': EyeOpen,
  'history': History,
  'info': Info,
  'load': Load,
  'lock': Lock,
  'menu': Menu,
  'new-notification': NewNotification,
  'notification': Notification,
  'offers': Offers,
  'player-management': PlayerManagement,
  'player': Player,
  'plus-1': Plus1,
  'plus': Plus,
  'printer': Printer,
  'report': Report,
  'search': Search,
  'stats': Stats,
  'unlock': Unlock,
  'warning': Warning,
  'whplus': WhPlus,
};

type IconWrapperProps = {
  iconName: string;
  children: React.ReactNode;
};

const NoIcon = () => <div data-test-id='no-icon'></div>;

const IconWrapper = ({ iconName, children }: IconWrapperProps) => (
  <Flex data-test-id={iconName}>
    <ErrorBoundary
      FallbackComponent={NoIcon}
      onReset={() => { /* do nothing */ }}
    >
      {children}
    </ErrorBoundary>
  </Flex>
);

export type IconType = keyof typeof ICON_NAMES;

export type IconProps = {
  name: keyof typeof ICON_NAMES
} & React.SVGProps<SVGSVGElement> & {
  title?: string | undefined;
};

const Icon = ({ name, ...rest }: IconProps) => {
  const IconFound = ICON_NAMES[name];

  if (!IconFound) {
    return null;
  }

  return <IconFound {...rest} />;
};

export default Icon;

export {
  Admin,
  ArrowDown,
  ArrowUp,
  AngleDown,
  AngleLeft,
  AngleRight,
  AngleUp,
  Card,
  Cash,
  Check,
  Close,
  Critical,
  Dashboard,
  Demo,
  Edit1,
  Edit2,
  Edit3,
  EyeClosed,
  EyeOpen,
  History,
  IconWrapper,
  Info,
  Load,
  Lock,
  Menu,
  NewNotification,
  Notification,
  Offers,
  Player,
  PlayerManagement,
  Plus,
  Plus1,
  Printer,
  Report,
  Search,
  Stats,
  Unlock,
  Warning,
  WhPlus,
  ICON_NAMES,
};
