import Flex from 'styled/Flex';
import styled from 'styled-components';

export const StyledModal = styled.dialog`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  &:not([open]) {
    display: none;
  }
`;

export const ContentContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 10%;
  background: white;
`;

export const ModalText = styled.p`
  font-weight: normal;
  font-size: 22px;
`;
