import PubSub from 'pubsub-js';
import { version } from '../../package.json';

let shopId: string;

type Severity = 'medium' | 'high' | 'critical';

export const init = (data: any) => {
  if (!data.shopId) {
    console.error('Shop ID not supplied to New Relic initialisation');
    const errorMessage = 'Cannot find shop';
    PubSub.publish('app.error.dashboard', errorMessage);
    PubSub.publish('app.error.history.sessions', errorMessage);
    return;
  }

  console.log('New relic init called with', data);

  shopId = data.shopId;

  PubSub.subscribe('app.error', onError);
};

const onError = (topic: string, error: any) => {
  logError(error || topic, error?.severity || 'medium', error);
};

/**
 * Only call this method if the error you are trying to log is not covered by an existing `app.error.some.topic.name`
 * PubSub topic. Errors handled by error topics will be handled automatically.
 * 
 * Will also `console.error` the error message.
 */
export const logError = (message: string, severity?: Severity, err?: unknown) => {
  const { newrelic } = window as any;
  const payload = {
    shopId,
    message,
    severity: severity || 'medium',
    uboRelease: version,
  };

  console.warn(message + ' PAYLOAD', payload);
  if (err) {
    console.error(message + ' CAUSE', err);
  }
  if (!newrelic) {
    console.warn('window.newrelic not found!');
    return;
  }
  if (!message) {
    console.warn('New relic log error rejected as no message!');
    return;
  }

  newrelic.noticeError(JSON.stringify(payload));
};

export const setAttribute = (key: string, value: string) => {
  const { newrelic } = window as any;
  if (!newrelic) {
    console.warn('window.newrelic not found!');
  } else {
    console.log('New relic set attribute called with', key, value);
    newrelic.setCustomAttribute(key, value);
  }
};