import { Navigate, useParams } from 'react-router-dom';
import useSession from 'hooks/useSession';
import * as Styled from './SessionDetails.styled';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { Color } from 'types/Color';
import Icon from 'components/Icon';
import { Table } from 'antd';
import moment from 'moment';
import { retailCurrency } from 'retail-currency';

const columns = [
  {
    title: 'Time',
    dataIndex: 'eventTime', // todo: events are not yet present in rss get session response
  },
  {
    title: 'Event',
    dataIndex: 'eventMessage',
  },
  {
    title: 'Event type',
    dataIndex: 'eventType',
  },
];

type SessionDetailsRouteParams = {
  uid: string;
}

interface TileProps {
  label: string;
  value: string;
  onEditClick?: () => void;
  backgroundColor: Color
  labelColor: Color
  valueColor: Color
  trend?: 'growing' | 'failing'
}

const Tile = ({ label, value, backgroundColor, labelColor, valueColor, trend, onEditClick }: TileProps) => {
  return (
    <Styled.Tile backgroundColor={backgroundColor}>
      <Styled.TileLabel color={labelColor}>{label}</Styled.TileLabel>
      <Styled.TileValue color={valueColor}>
        {trend ? (
          <Styled.TrendIconSpan><Icon width={24} height={24} name={trend === 'growing' ? 'arrow-up' : 'arrow-down'} /></Styled.TrendIconSpan>
        )
          : null
        }
        {value}
        {onEditClick ? <Styled.EditIconSpan onClick={onEditClick} color='secondaryFobt700'><Icon width={24} height={24} name='edit1' /></Styled.EditIconSpan> : null}
      </Styled.TileValue>
    </Styled.Tile>
  );
};

const SessionDetails = () => {
  const { uid } = useParams<SessionDetailsRouteParams>();
  const handleClickSingleRow = () => {
    return {
      onClick: () => console.log('click row'),
    };
  };

  if (!uid) {
    return <Navigate to='/history' />;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { session, isLoading, error } = useSession(uid); 

  if (error) {
    console.error(error);
  }

  if (isLoading) {
    return (
      <Styled.Container>
        <LoadingSpinner />
      </Styled.Container>
    );
  }

  if (!session) {
    return (
      <Styled.Container>
        <p>An error occurred while fetching session details</p>
      </Styled.Container>
    );
  }

  const win = (session.grossWin - session.staked) / 100;

  return (
    <Styled.Container>
      <Styled.Card>
        <Styled.Tiles>
          <Tile
            label='Player'
            value={session.nickname || ''}
            onEditClick={() => console.log('edit player click')}
            backgroundColor={'secondaryFobt25'}
            labelColor={'grey500'}
            valueColor={'grey900'}
          />
          <Tile
            label='Loss limit'
            value={String(session.monetaryLimit)}
            backgroundColor={'grey100'}
            labelColor={'primary600'}
            valueColor={'primary600'}
          />
          <Tile
            label='Time limit'
            value={moment.utc(moment.duration(session.timeLimitInMin, 'minutes').asMilliseconds()).format('HH:mm')}
            backgroundColor={'grey100'}
            labelColor={'primary600'}
            valueColor={'primary600'}
          />
          <Tile
            label='Total stakes'
            value={retailCurrency.format(session.staked / 100)}
            backgroundColor={'secondaryFobt25'}
            labelColor={'grey500'}
            valueColor={'grey900'}
          />
          <Tile
            label='Win'
            value={retailCurrency.format(win)}
            backgroundColor={'success25'}
            labelColor={'success900'}
            valueColor={'success900'}
            trend={'growing'}
          />
        </Styled.Tiles>
        <Table columns={columns} loading={isLoading} dataSource={session.events} onRow={handleClickSingleRow}/>
      </Styled.Card>
    </Styled.Container>
  );
};

export default SessionDetails;
