import PlayerDrawer from './PlayerDrawer';
import { SessionHistory, SummarySession } from 'types/session/SummarySession';
import { addPlayerToShop } from '../../../api/playerApi';
import { getPlayers } from 'utils/fetchHelpers';
import { onTagLiveSession, onUntagLiveSession, onTagSession, onUntagSession } from 'utils/mutateHelpers';
import useShop from 'hooks/useShop';
import { useEffect } from 'react';

const ConnectedPlayerDrawer = ({ isLive, initialActionType, session, mutateSessions, updateUiAfterTaggingSession, isOpen, close }: ConnectedPlayerDrawerProps) => {
  const { shopId } = useShop();
  const playersData = getPlayers(false, shopId);
  const actuallyLive = isLive && session?.sessionId;

  useEffect(() => {
    console.log('session', session);
  }, [session]);

  return (
    <PlayerDrawer
      initialActionType={initialActionType}
      addPlayerToShop={addPlayerToShop}
      close={close}
      isOpen={isOpen}
      mutateSessions={mutateSessions}
      onTagSession={actuallyLive ? onTagLiveSession : onTagSession}
      onUntagSession={actuallyLive ? onUntagLiveSession : onUntagSession}
      playersData={playersData}
      session={session}
      updateUiAfterTaggingSession={updateUiAfterTaggingSession}
    />
  );
};

interface ConnectedPlayerDrawerProps {
  isLive: boolean;
  isOpen: boolean;
  initialActionType: string;
  close: () => void;
  session?: SummarySession | SessionHistory;
  mutateSessions?: () => void;
  updateUiAfterTaggingSession?: (session: SummarySession) => void;
}

export default ConnectedPlayerDrawer;
