import styled from 'styled-components';
import { Search } from 'components/Icon';
import { Input } from 'antd';

interface SearchProps {
  active: boolean;
}

export const SearchIcon = styled(Search)<SearchProps>`
  path {
    fill: ${({ active, theme }) => active && `${theme.colors.white} !important`};
  }
`;

export const SearchInput = styled(Input)`
  margin-left: 10px;
  width: 615px;
  height: 56px;
  background: ${({ theme }) => theme.colors.grey50};
  border: none;
  font-size: 1.5rem;
`;
