import styled from 'styled-components';
import Flex from 'styled/Flex';
import { Color } from 'types/Color';

export const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 20px;
  gap: 20px;
  flex-direction: column;

  background: ${props => props.theme.colors.grey50};
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
`;

export const Tiles = styled(Flex)`
  flex-direction: row;
  gap: 8px;
  margin-bottom: 16px;
`;

export const Tile = styled(Flex)<{ backgroundColor: Color }>`
  flex-direction: column;
  padding: 24px 20px;
  gap: 8px;
  border-radius: 4px;
  background: ${(props) => props.theme.colors[props.backgroundColor]};
  min-width: 220px;
`;

export const TileLabel = styled.p<{ color: Color }>`
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: ${(props) => props.theme.colors[props.color]};
`;

export const TileValue = styled(Flex)<{ color: Color }>`
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: ${(props) => props.theme.colors[props.color]};
  justify-content: space-between;
`;

export const TrendIconSpan = styled.span`
  margin-right: 13px;
`;

export const EditIconSpan = styled.span<{ color: Color }>`
  cursor: pointer;
  svg {
    path {
      fill: ${(props) => props.theme.colors[props.color]};
    }
  }
`;
