import { PlayerFetchData, PlayerNickname, Player as PlayerType } from 'types/Player';
import ActionButtons from '../ActionButtons';
import InformationDropdown from '../InformationDropdown';
import LabelledTags from '../LabelledTags';
import Player from '../Player';
import PlayerPerformanceTable from '../PlayerPerformanceTable';
import { DashboardStyled, MoreInformationStyled, PlayerErrorStyled, PlayerLoadingStyled, PlayersStyled } from './Dashboard.styled';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { Message, Messages } from 'components/Message';

interface DashboardProps {
  addPlayer: () => void;
  allPlayers: PlayerType[] | undefined;
  isEditMode?: boolean;
  selectedPlayers: PlayerNickname[];
  viewedPlayers: PlayerFetchData[];
}

const Dashboard = ({ addPlayer, allPlayers, isEditMode, selectedPlayers, viewedPlayers }: DashboardProps) => {
  
  const renderPlayer = (playerData: PlayerFetchData, index: number) => {
    const { player, loading, error } = playerData;
    if (loading) {
      return player && (
        <PlayerLoadingStyled data-test-id='player-loading-spinner' key={player.nicknameId}>
          Loading {player?.nickname}
          <LoadingSpinner />
        </PlayerLoadingStyled>
      );
    }
    if (error) {
      return player && (
        <PlayerErrorStyled key={player.nicknameId}>
          <Messages>
            <Message type={'error'} iconName={'critical'}>
              Failed to load {player.nickname}
            </Message>
          </Messages>
        </PlayerErrorStyled>
      );
    }
    return player && (
      <Player key={player.nicknameId} allPlayers={allPlayers || []} isEditMode={isEditMode} player={player} isLastElement={index === viewedPlayers.length-1}/>
    );
  };

  return (
    <DashboardStyled data-test-id='player-management-dashboard'>
      <ActionButtons addPlayer={addPlayer} selectedPlayers={selectedPlayers} viewedPlayers={viewedPlayers}/>
      <PlayersStyled>
        {viewedPlayers.map(renderPlayer)}
      </PlayersStyled>
      {/* this probably needs moving into Player component 
      <InformationDropdown icon='info' title='More Information'>
        <MoreInformationStyled>
          <LabelledTags label='Linked to' tags={['Gilmour LBO - Fred Smith', 'London LBO - Big Fred', 'Glasgow LBO - F Smith']} />
          <LabelledTags label='Exclusion from' tags={['Gilmour LBO', 'London LBO', 'Glasgow LBO', 'London LBO', 'London LBO', 'George St LBO', 'George St LBO']} />
        </MoreInformationStyled>
      </InformationDropdown> */}
      {/* <InformationDropdown icon='stats' title={`Freddie's Performance`}>
        <PlayerPerformanceTable selectedDate={selectedDate} openDatePicker={openDatePicker}/>
      </InformationDropdown> */}
    </DashboardStyled>
  );
};

export default Dashboard;