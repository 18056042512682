import { logError } from 'newrelic';
import { IdentityResponse } from 'types/response/IdentityResponse';
import { getConstant } from 'utils/constants';
import { throwErrorIfBadResponse } from 'utils/fetchHelpers';

export const getIdentity = async (): Promise<IdentityResponse> => {
  const url = `${getConstant('REACT_APP_IDENTITY_MANAGER_URL')}/status`;

  try {
    const response = await fetch(url);
    return throwErrorIfBadResponse(response);
  } catch (err: unknown) {
    logError(`Failed to call identity manager: ${err}`, 'critical', err);
    throw new Error('Failed to call identity manager');
  }
};
