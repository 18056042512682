import styled from 'styled-components';

const CashReportContainer = styled.div`
  background: #F7F8FA;
  border-radius: 12px;
  padding: 20px;
  width: 882px;
`;

const CashReportTileContainer = styled.div`
  background: #FFFFFF;
  border-radius: 8px;
  filter: drop-shadow(0px 0px 16px rgba(16, 24, 40, 0.06));
  min-height: 240px;
  padding: 16px 0px;
  width: 270px;
`;

export { CashReportContainer, CashReportTileContainer };
