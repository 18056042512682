import styled from 'styled-components';

export const StyledCheckbox = styled.input`
  appearance: none;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  height: 24px;
  margin: 20px;
  outline: none;
  position: relative;
  width: 24px;

  &:checked {
    background-color: #007bff;
    border-color: #007bff;

    &::before {
      border-bottom: 3px solid white;
      border-right: 3px solid white;
      content: "";
      display: block;
      height: 12px;
      left: 50%;
      position: absolute;
      top: 43%;
      transform: translate(-50%, -50%) rotate(45deg);
      width: 8px;
    }
  }
  &:disabled {
    background-color: ${props => props.theme.colors.grey300};
  }
`;

export const LabelledCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelText = styled.span`
  font-size: 22px;
  white-space: nowrap;
`;
