import styled from 'styled-components';

export const PlayerManagementWrapper = styled.div`
  background-color: ${props => props.theme.colors.grey50};
  color: ${props => props.theme.colors.grey900};
  display: flex;
  flex: 1;
  gap: 24px;
  padding: 24px;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100vw;
`;

export const EditModeOverlay = styled.div`
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 1;
`;
