export const formatString = (stringTemplate : string, values : Record<string,string>) => {
  return Object.keys(values)
    .reduce(
      (template, token) => template.split(`{${token}}`).join(values[token] || 'b'),
      stringTemplate
    );
};

export const whitespaceTrim = (value?: string | null): string => {
  if (!value) {
    return '';
  }
  value = value.replace(/^[ \t]+|[ \t]+$/gm, '');
  return value;
};