import { useState, useEffect } from 'react';
import { CashReportItem } from 'types/cashReport/CashReportType';
import CashReportDashboard from '../components/CashReport/CashReportDashboard';
import { getCashManagementCISReport } from '../api/RetailApi';

interface CashReportProviderProps {
  shop_id: string
}

const CashReportProvider = (props: CashReportProviderProps) => {
  const [cashReport, setCashReport] = useState<CashReportItem[]>([]);

  useEffect(() => {
    const report = async () => {
      setCashReport(await getCashManagementCISReport(props.shop_id));
    };

    report();
  }, [props.shop_id]);

  return (
    <div>
      {cashReport.length !== 0 && cashReport.length !== undefined ?
        <CashReportDashboard reportItems={cashReport} />
        : <></>}
    </div>
  );
};

export default CashReportProvider;
