import styled, { css } from 'styled-components';
import { ButtonProps } from './Button';

const ButtonBaseStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: WHHoxton-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0;
  padding: 10px 18px;
  gap: 15px;
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  background: white;
  cursor: pointer;
`;

const PrimaryButtonStyles = css`
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.grey700};
  border: 1px solid ${(props) => props.theme.colors.grey300};

  svg {
    path {
      fill: ${(props) => props.theme.colors.grey700};
    }
  }
`;

const SecondaryButtonStyles = css`
  background: ${(props) => props.theme.colors.primary600};
  color: ${(props) => props.theme.colors.white};
  border: none;

  svg {
    path {
      stroke: ${(props) => props.theme.colors.white};
    }
  }
`;

const CTAButtonStyles = css`
  background: ${(props) => props.theme.colors.grey100};
  color: ${(props) => props.theme.colors.primary600};
  border: none;

  svg {
    path {
      fill: ${(props) => props.theme.colors.primary600};
    }
  }
`;

const TagNowButtonStyles = css`
  border: 1px solid ${(props) => props.theme.colors.tagNowBorder};
`;

export const StyledButton = styled.button<ButtonProps>`
  ${ButtonBaseStyles};

  ${(props) => props.background ? css`background: ${props.theme.colors[props.background]}` : null};
  ${(props) => props.border && css`border: ${props.border}`};
  ${(props) => props.borderless && css`border: none`};
  ${(props) => props.disabled && css`color: ${props.theme.colors.grey300}`};
  ${(props) => props.color ? css`color: ${props.theme.colors[props.color]}` : null};
  ${(props) => props.cta ? CTAButtonStyles : null};
  ${(props) => props.tagNow ? TagNowButtonStyles : null};
  ${(props) => props.justifyContent && css`justify-content: ${props.justifyContent}`};
  ${(props) => props.margin ? css`margin: ${props.margin}` : null};
  ${(props) => props.padding ? css`padding: ${props.padding}` : null};
  ${(props) => props.primary ? PrimaryButtonStyles : null};
  ${(props) => props.secondary ? SecondaryButtonStyles : null};
  ${(props) => props.wide ? css`width: 100%;` : null};
  ${(props) => props.width ? css`width: ${props.width}` : null};
  :disabled {
    cursor: not-allowed;
    color: #D0D5DD;
  }
`;
